<div [class]="screenSize.getClasses() + ' quoting-theme ptsview'">
  <h1 class="ptsview-title">
    Parts
    <mat-spinner
      *ngIf="searchLoading.isLoading()"
      [diameter]="30"
      class="ptsview-title-spinner"
    ></mat-spinner>
  </h1>
  <h6>
    Parts are only used for quoting and do not represent existing parts in Sage.
  </h6>
  <ng-container *ngIf="!tagsLoading">
    <div class="ptsview-controls">
      <mat-form-field
        appearance="outline"
        class="ptsview-controls-ff ptsview-controls-item"
      >
        <mat-label>Search</mat-label>
        <input
          (keydown)="$event.key == 'Enter' ? search() : undefined"
          [formControl]="searchControl"
          matInput
          type="text"
          class="ptsview-controls-ff-in"
        />
      </mat-form-field>
      <!-- <app-searchable-msel
        (onSelectChange)="onTagsChange($event)"
        [disabled]="searchLoading.isLoading()"
        [options]="tagSearchOptions"
        [defaultSelected]="tagsControl.value"
        placeholder="Tags"
        class="ptsview-controls-ff-in ptsview-controls-ff-tags ptsview-controls-item"
      ></app-searchable-msel> -->
      <mat-slide-toggle
        *ngIf="screenSize.getClassesArray().includes('smd')"
        [checked]="showInactive.value"
        (change)="showInactive.setValue($event.checked)"
        class="ptsview-controls-inac ptsview-controls-item"
      >
        Show Inactive Parts
      </mat-slide-toggle>
      <mat-slide-toggle
        *ngIf="screenSize.getClassesArray().includes('smd')"
        [checked]="searchDesc.value"
        (change)="searchDesc.setValue($event.checked)"
        class="ptsview-controls-sdesc ptsview-controls-item ptsview-controls-tgl"
      >
        Search Description
      </mat-slide-toggle>
      <button
        (click)="search()"
        color="accent"
        mat-flat-button
        class="ptsview-controls-search ptsview-controls-item"
      >
        Search
      </button>
    </div>
    <div class="ptsview-excontrols">
      <mat-slide-toggle
        *ngIf="!screenSize.getClassesArray().includes('smd')"
        [checked]="showInactive.value"
        (change)="showInactive.setValue($event.checked)"
        class="ptsview-excontrols-inac"
      >
        Show Inactive Parts
      </mat-slide-toggle>
      <mat-slide-toggle
        *ngIf="!screenSize.getClassesArray().includes('smd')"
        [checked]="searchDesc.value"
        (change)="searchDesc.setValue($event.checked)"
        class="ptsview-excontrols-sdesc"
      >
        Search Description
      </mat-slide-toggle>
    </div>

    <div class="ptsview-selectors">
      <mat-checkbox
        [checked]="areAllSelected()"
        (change)="selectAllChanged($event.checked)"
        class="ptsview-selectors-all"
      >
        Select All
      </mat-checkbox>
      <button (click)="upsertPartsFromSage()" color="primary" mat-flat-button>
        Upsert Sage Parts
      </button>
    </div>

    <div class="ptsview-list">
      <mat-card
        *ngFor="let part of parts; let pIndex = index"
        [class.selected]="part.selected.value"
        class="ptsview-list-item"
      >
        <div class="ptsview-card-header">
          <div class="ptsview-card-header-front">
            <mat-checkbox
              *ngIf="part.editable"
              [formControl]="part.selected"
              (change)="selectedChanged(pIndex, $event.checked)"
              class="ptsview-card-header-front-cb"
            ></mat-checkbox>
            <div class="ptsview-card-header-front-title">
              <h3 class="ptsview-card-header-front-title-txt">
                <app-grow-input
                  (inputEmitter)="codeControlInput(pIndex, $event)"
                  [control]="part.partCodeControl"
                  [useNativeInput]="true"
                  minWidth="25px"
                  maxWidth="250px"
                  class="ptsview-card-header-front-title-txt-in"
                ></app-grow-input>
              </h3>
            </div>
            <div class="ptsview-card-header-front-quan"></div>
          </div>
          <div class="ptsview-card-header-end">
            <div class="ptsview-card-header-end-content">
              <div class="ptsview-card-header-end-content">
                <h4 class="ptsview-card-header-end-content-cost">
                  <span class="ptsview-card-header-end-content-cost-icon"
                    >${{ ' ' }}</span
                  >
                  <app-grow-input
                    [control]="part.partCostControl"
                    (inputEmitter)="costControlInput(pIndex, $event)"
                    (blurEmitter)="costControlBlur(pIndex, $event)"
                    [useNativeInput]="true"
                    minWidth="25px"
                    maxWidth="100px"
                    class="ptsview-card-header-front-quan-in"
                  ></app-grow-input>
                </h4>
              </div>
            </div>
          </div>
        </div>
        <span *ngIf="part.costControl == 0" class="ptsview-card-noprice"
          >No price associated with this part!</span
        >
        <div
          [class.editable]="part.editable"
          class="ptsview-card-content active"
        >
          <div class="ptsview-card-content-tainac">
            <div class="ptsview-card-content-tainac-tags">
              <span
                *ngFor="let tag of part.tagsControl.controls"
                (click)="removeTag(pIndex, tag.value)"
                [matTooltip]="getTag({ tagName: tag.value })?.Tag_Desc"
                [style.background]="getTagColor(tag.value)"
                class="ptsview-card-content-tainac-tags-item"
              >
                {{ tag.value }}
              </span>
              <span
                *ngIf="getAvailableTags(pIndex).length > 0"
                (click)="part.tagPickerOpen = !part.tagPickerOpen"
                cdkOverlayOrigin
                #tagPill="cdkOverlayOrigin"
                matTooltip="Add a tag"
                style.background="#fff"
                [style.color]="'#000'"
                class="ptsview-card-content-tainac-tags-item"
              >
                <i class="fa fa-plus"></i>
                <ng-template
                  cdkConnectedOverlay
                  [cdkConnectedOverlayOrigin]="tagPill"
                  [cdkConnectedOverlayOpen]="part.tagPickerOpen"
                >
                  <app-searchable-sel
                    *ngIf="part.data.Part != undefined"
                    (onSelectChange)="tagPickerControlChange(pIndex, $event)"
                    placeholder="Tags"
                    [options]="getAvailableTags(pIndex)"
                    [required]="true"
                    class="ptsview-card-location-item-sel"
                  ></app-searchable-sel>
                </ng-template>
              </span>
            </div>
            <div class="ptsview-card-content-tainac-inac">
              <span
                *ngIf="!screenSize.getClassesArray().includes('smd')"
                class="ptsview-card-content-tainac-inac-txt"
              >
                Part Inactive
              </span>
              <mat-slide-toggle
                (change)="
                  part.partInactiveControl.setValue(
                    part.partInactiveControl.value == 'Y' ? 'N' : 'Y'
                  )
                "
                [checked]="part.partInactiveControl.value == 'Y'"
                class="ptsview-card-content-tainac-inac-tgl"
              ></mat-slide-toggle>
              <span
                *ngIf="screenSize.getClassesArray().includes('smd')"
                class="ptsview-card-content-tainac-inac-txt"
              >
                Part Inactive
              </span>
            </div>
          </div>
          <div class="ptsview-card-content-desc">
            <!-- {{ part.data.Part.Part_Desc }} -->
            <mat-form-field
              appearance="outline"
              class="ptsview-card-content-desc-ff"
            >
              <mat-label>Description</mat-label>
              <textarea
                *ngIf="part.editable"
                [formControl]="part.partDescControl"
                matInput
                class="ptsview-card-content-desc-ff-in"
              ></textarea>
            </mat-form-field>
          </div>
        </div>
        <div class="ptsview-card-actions">
          <button
            [disabled]="saveDisabled(pIndex)"
            (click)="saveChanges(pIndex)"
            color="primary"
            mat-flat-button
            class="ptsview-card-actions-save"
          >
            Save
          </button>
          <button
            [disabled]="cancelDisabled(pIndex)"
            (click)="cancelChanges(pIndex)"
            color="warn"
            mat-flat-button
            class="ptsview-card-actions-cancel"
          >
            Cancel
          </button>
        </div>
      </mat-card>
      <div *ngIf="showNoResults" class="ptsview-list-empty">
        <h5 class="ptsview-list-empty-txt">No Results</h5>
      </div>
      <div class="ptsview-list-more">
        <button
          *ngIf="showLoadMore"
          [disabled]="searchLoading.isLoading()"
          (click)="loadParts(this.page + 1)"
          color="accent"
          mat-flat-button
          class="ptsview-list-more-btn"
        >
          Load More
        </button>
      </div>
    </div>
    <div *ngIf="areAnySelected()" class="ptsview-actions">
      <button
        (click)="deleteSelected()"
        color="warn"
        mat-flat-button
        class="ptsview-actions-delete"
      >
        Delete Selected
      </button>
    </div>
  </ng-container>
</div>
