const kitPartPhases = [
  { valueOf: () => 'Rough-In 010', toString: () => 'Rough-In 010' },
  { valueOf: () => 'Vanguard 020', toString: () => 'Vanguard 020' },
  { valueOf: () => 'Top Out 030', toString: () => 'Top Out 030' },
  { valueOf: () => 'Drop-In 035', toString: () => 'Drop-In 035' },
  { valueOf: () => 'Trim 050', toString: () => 'Trim 050' },
  { valueOf: () => 'Water 012', toString: () => 'Water 012' },
  { valueOf: () => 'Gas 040', toString: () => 'Gas 040' },
  { valueOf: () => 'Sewer 011', toString: () => 'Sewer 011' },
  { valueOf: () => 'Rock', toString: () => 'Rock 013' },
];
export default kitPartPhases;
