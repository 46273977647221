import { filter } from 'rxjs/operators';
import { DatePipe, formatDate } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AlertifyService } from 'src/app/_services/alertify/alertify.service';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { SageApiService } from 'src/app/_services/sageApi/sageApi.service';
import { BuilderDashboard } from 'src/app/_services/sageApi/interfaces/pullReport/BuilderDashboard';
import { Builder } from 'protractor';
import { FormControl } from '@angular/forms';
import {
  Chart,
  ChartConfiguration,
  ChartData,
  ChartDataset,
  registerables,
} from 'chart.js';
@Component({
  selector: 'app-builder-dashboard',
  templateUrl: './builder-dashboard.component.html',
  styleUrls: ['./builder-dashboard.component.css'],
})
export class BuilderDashboardComponent implements OnInit {
  // @Input() Date = this.Date;
  startDate: Date | null = null;
  endDate: Date | null = null;
  DateContrlMin = new Date();
  DateContrlMax = new Date();
  username = '';
  originalSalesOrder = [];
  salesOrders = [];
  salesOrdersLoading = false;
  range: any;
  assignedOptions: string[];
  assigned: FormControl;
  barChart: Chart;
  upcomingDue: BuilderDashboard[] = [];
  Today: Date;
  constructor(
    private sageApi: SageApiService,
    private alertify: AlertifyService,
    private modalService: BsModalService,
    private authService: AuthService,
    private datePipe: DatePipe
  ) {
    Chart.register(...registerables);
  }

  ngOnInit(): void {
    this.username = this.authService.decodedToken.nameid;
    const DateInfoMin = new Date();
    DateInfoMin.setDate(DateInfoMin.getDate() - 1);
    this.startDate = DateInfoMin;
    const DateInfo = new Date();
    DateInfo.setDate(DateInfo.getDate() + 35);
    this.endDate = DateInfo;
    this.getInformation();
  }

  stackedBarChart() {
    this.barChart = new Chart('barChartByDate', {
      type: 'bar',
      data: this.dataByDate(),
      options: {
        plugins: {
          legend: {
            display: true,
          },
          title: {
            display: true,
            text: 'Total Per Day',
          },
        },
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
            beginAtZero: true,
          },
        },
        responsive: true,
      },
    });
  }
  dataByDate() {
    const map = new Map();
    this.salesOrders.sort(
      (a, b) =>
        new Date(a.UDF_DATE_SCHEDULED).getTime() -
        new Date(b.UDF_DATE_SCHEDULED).getTime()
    );
    const warehouseCodes = [
      ...new Set(this.salesOrders.map(so => so.WarehouseCode)),
    ];
    this.salesOrders.forEach(so => {
      const key = so.UDF_DATE_SCHEDULED;
      const col = map.get(key);
      if (!col) {
        map.set(key, [so]);
      } else {
        col.push(so);
      }
    });
    const labels = [];
    const warehouseData = [];
    const colors = this.poolColors(warehouseCodes.length);
    warehouseCodes.forEach(el => {
      const index = warehouseCodes.indexOf(el);
      const obj = {
        label: el,
        backgroundColor: colors[index],
        data: [],
      };
      warehouseData.push(obj);
    });
    map.forEach(x => {
      const date = x[0].UDF_DATE_SCHEDULED;
      const formattedDated = this.datePipe.transform(date, 'MM/dd/yyyy');
      labels.push(formattedDated);
      warehouseData.forEach(wh => {
        const test = x.filter(x => x.WarehouseCode == wh.label);
        wh.data.push(test.length);
      });
    });
    return {
      labels: labels,
      datasets: warehouseData,
    };
  }

  getInformation() {
    if (this.barChart != undefined) {
      this.barChart.destroy();
    }
    this.salesOrders = [];
    this.salesOrdersLoading = true;
    this.assignedOptions = [];
    this.assigned = new FormControl();
    let DateMin = '';
    let DateMax = '';
    const FromDateFormatted = formatDate(this.startDate, 'MM-dd-yyyy', 'en-US');
    const ToDateFormatted = formatDate(this.endDate, 'MM-dd-yyyy', 'en-US');
    this.sageApi
      .pullReport(
        'BuilderDashboard/' + FromDateFormatted + '/' + ToDateFormatted
      )
      .subscribe(
        (rows: Array<BuilderDashboard>) => {
          rows.forEach(row => {
            if (!this.assignedOptions.includes(row.UDF_ASSIGNED_TO_BUILD)) {
              this.assignedOptions.push(row.UDF_ASSIGNED_TO_BUILD);
            }

            if (row.UDF_DATE_SCHEDULED != undefined) {
              if (DateMin == '' || DateMax == '') {
                DateMin = row.UDF_DATE_SCHEDULED;
                DateMax = row.UDF_DATE_SCHEDULED;
              } else if (row.UDF_DATE_SCHEDULED < DateMin) {
                DateMin = row.UDF_DATE_SCHEDULED;
              } else if (row.UDF_DATE_SCHEDULED > DateMax) {
                DateMax = row.UDF_DATE_SCHEDULED;
              }
              this.salesOrders.push(row);
            }
          });
          this.salesOrdersLoading = false;
          this.originalSalesOrder = this.salesOrders;
          this.DateContrlMin = new Date(DateMin);
          this.DateContrlMax = new Date(DateMax);
        },
        err => {
          console.log(err);
          this.alertify.error(err.message);
          this.salesOrdersLoading = false;
        },
        () => {
          this.salesOrdersLoading = false;
          this.assigned.setValue(this.assignedOptions);
          this.stackedBarChart();
          this.CheckForUpcoming();
        }
      );
  }
  normalizeDate(val) {
    return formatDate(val.UDF_DATE_SCHEDULED, 'MM/dd/yyyy', 'en-US');
  }

  CheckForUpcoming() {
    this.upcomingDue = [];
    const today = new Date();
    today.setDate(today.getDate());
    this.Today = today;
    const FromDateFormatted = formatDate(today, 'MM-dd-yyyy', 'en-US');
    if (today.getDay() == 5) {
      today.setDate(today.getDate() + 3);
    } else if (today.getDay() == 6) {
      today.setDate(today.getDate() + 2);
    } else {
      today.setDate(today.getDate() + 1);
    }
    const ToDateFormatted = formatDate(today, 'MM-dd-yyyy', 'en-US');
    this.sageApi
      .pullReport(
        'BuilderDashboard/' + FromDateFormatted + '/' + ToDateFormatted
      )
      .subscribe((rows: Array<BuilderDashboard>) => {
        rows.forEach(row => {
          if (row.PhasesBuilt == 1) {
            this.upcomingDue.push(row);
          }
        });
      });
  }

  dynamicColors() {
    const r = Math.floor(Math.random() * 255);
    const g = Math.floor(Math.random() * 255);
    const b = Math.floor(Math.random() * 255);
    return 'rgba(' + r + ',' + g + ',' + b + ', 0.5)';
  }
  poolColors(a) {
    const pool = [];
    for (let i = 0; i < a; i++) {
      pool.push(this.dynamicColors());
    }
    return pool;
  }
}
