import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import KitRegions from '../KitRegions';
import { AlertifyService } from '../../../_services/alertify/alertify.service';
import {
  Chart,
  ChartConfiguration,
  ChartData,
  ChartDataset,
  registerables,
} from 'chart.js';
import { QuoteInformationInterface } from 'src/app/_services/sageApi/interfaces/pullReport/QuotePDFInterface';
import { SageApiService } from 'src/app/_services/sageApi/sageApi.service';

@Component({
  selector: 'app-QuoteDashboardComponent',
  templateUrl: './QuoteDashboardComponent.component.html',
  styleUrls: ['./QuoteDashboardComponent.component.css'],
})
export class QuoteDashboardComponentComponent implements OnInit {
  loading: boolean;
  chart: any;
  allQuotes: QuoteInformationInterface[] = [];
  constructor(
    private sageApi: SageApiService,
    private alertify: AlertifyService
  ) {
    Chart.register(...registerables);
  }

  ngOnInit() {
    this.getAllQuotes();
  }

  changeToRegion() {
    this.allQuotes.forEach(element => {
      let item = KitRegions.filter(x => x.valueOf() == element.Region);
      element.Region = item.toString();
    });
  }

  getAllQuotes() {
    this.loading = true;
    this.sageApi.pullReport('AllQuoteInformation').subscribe(
      (quoteInformation: QuoteInformationInterface[]) => {
        if (Array.isArray(quoteInformation)) {
          this.allQuotes = quoteInformation;
          this.changeToRegion();
          this.barChart();
          this.createDoughnut();
          this.create2DChart();
        }
      },
      error => {
        this.alertify.error('Error loading quotes');
      },
      () => {
        this.loading = false;
      }
    );
  }

  chartData() {
    const labelOfQuotes = [];
    this.allQuotes.forEach(element => {
      if (!labelOfQuotes.includes(element.Status)) {
        labelOfQuotes.push(element.Status);
      }
    });
    const labelDataSet = [];
    this.allQuotes.forEach(quotes => {
      if (!labelDataSet.includes(quotes.Builder)) {
        labelDataSet.push(quotes.Builder);
      }
    });
    const colors = this.poolColors(labelDataSet.length);
    const dataToAdd = [];
    labelDataSet.forEach(element => {
      let dataObj = {
        label: '',
        data: [],
        backgroundColor: '',
      };
      dataObj.label = element;
      for (let i = 0; i < labelOfQuotes.length; i++) {
        let test = this.allQuotes.filter(
          x => x.Status == labelOfQuotes[i] && x.Builder == element
        );
        let totalPrice = 0;
        test.forEach(element => {
          totalPrice += element.TotalPrice;
        });
        dataObj.data.push(totalPrice.toFixed(2));
        dataObj.backgroundColor = colors[labelDataSet.indexOf(element)];
      }
      dataToAdd.push(dataObj);
    });
    return {
      data: {
        labels: labelOfQuotes,
        datasets: dataToAdd,
      },
    };
  }

  create2DChart() {
    let labels = this.chartData();

    new Chart('BuilderChart', {
      type: 'bar',
      data: {
        labels: labels.data.labels,
        datasets: labels.data.datasets,
      },
      options: {
        plugins: {
          title: {
            display: true,
            text: 'Total Per Builder By Status',
          },
        },
        indexAxis: 'y',
      },
    });
  }
  barChart() {
    new Chart('newAttempt', {
      type: 'bar',
      data: this.data(),
      options: {
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: true,
            text: 'Total Per Builder',
          },
        },
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });
  }

  createDoughnut() {
    new Chart('newDoughnut', {
      type: 'doughnut', // can change to "pie"
      data: this.donutData(),
      options: {
        plugins: {
          title: {
            display: true,
            text: 'Total By City',
          },
        },
      },
    });
  }

  data() {
    const builders = [];
    this.allQuotes.forEach(element => {
      if (!builders.includes(element.Builder)) {
        builders.push(element.Builder);
      }
    });
    const builderPrice = [];
    builders.forEach(element => {
      let count = 0;
      this.allQuotes.forEach(quote => {
        if (element == quote.Builder) {
          count += quote.TotalPrice;
        }
      });
      builderPrice.push(count.toFixed(2));
    });
    const dataInformation = builderPrice;

    const labels = builders;
    const data = {
      labels: labels,
      datasets: [
        {
          label: 'Builders',
          data: dataInformation,
          backgroundColor: this.poolColors(dataInformation.length),
          borderWidth: 1,
        },
      ],
    };
    return data;
  }

  donutData() {
    const regionOfQuotes = [];

    this.allQuotes.forEach(element => {
      if (!regionOfQuotes.includes(element.Region)) {
        regionOfQuotes.push(element.Region);
      }
    });

    const countOccurrence = [];
    regionOfQuotes.forEach(element => {
      let count = 0;
      this.allQuotes.forEach(quote => {
        if (element == quote.Region) {
          count += quote.TotalPrice;
        }
      });
      countOccurrence.push(count.toFixed(2));
    });
    const labels = regionOfQuotes;
    const data = {
      labels: labels,
      datasets: [
        {
          label: 'Regions',
          data: countOccurrence,
          backgroundColor: this.poolColors(regionOfQuotes.length),
          borderWidth: 1,
        },
      ],
    };
    return data;
  }

  dynamicColors() {
    var r = Math.floor(Math.random() * 255);
    var g = Math.floor(Math.random() * 255);
    var b = Math.floor(Math.random() * 255);
    return 'rgba(' + r + ',' + g + ',' + b + ', 0.5)';
  }
  poolColors(a) {
    var pool = [];
    for (let i = 0; i < a; i++) {
      pool.push(this.dynamicColors());
    }
    return pool;
  }
}
