import { Component, OnInit } from '@angular/core';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { SageApiService } from '../../../_services/sageApi/sageApi.service';
import { AuthService } from '../../../_services/auth/auth.service';
import { AlertifyService } from '../../../_services/alertify/alertify.service';
import { DatePipe } from '@angular/common';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { concat } from 'rxjs';

@Component({
  selector: 'app-clear-date',
  templateUrl: './clear-date.component.html',
  styleUrls: ['./clear-date.component.css'],
})
export class ClearDateComponent implements OnInit {
  loading = false;
  bsConfig: Partial<BsDatepickerConfig>;
  clearDate: Date;
  information: {
    SalesOrderNo: string;
    address: string;
    builder: string;
    clearDate: Date;
    recallDate: Date;
  };
  recallDate: Date;
  salesOrderNo: string;
  dataToSubmit: {
    SalesOrderNo: string;
    address: string;
    builder: string;
    clearDate: Date;
    recallDate: Date;
  }[] = [];
  startDate: Date;
  endDate: Date;
  allSalesOrders = [];
  address: string;
  builder: string;
  constructor(
    private sageApi: SageApiService,
    private alertify: AlertifyService,
    private authService: AuthService,
    private datpipe: DatePipe
  ) {}

  ngOnInit() {
    this.getAllRoughins();
  }

  addrow() {
    if (this.salesOrderNo != undefined && this.clearDate != undefined) {
      this.dataToSubmit.push({
        SalesOrderNo: this.salesOrderNo,
        address: this.address,
        builder: this.builder,
        clearDate: this.clearDate,
        recallDate: this.recallDate,
      });
      document.getElementById('salesOrder').focus();
      this.clear();
    }
  }
  addClearDate(event: MatDatepickerInputEvent<Date>) {
    this.clearDate = event.value;
  }
  addRecallDate(event: MatDatepickerInputEvent<Date>) {
    this.recallDate = event.value;
  }
  addRecallDateRow(event: MatDatepickerInputEvent<Date>, row) {
    row.recallDate = event.value;
  }
  addClearDateRow(event: MatDatepickerInputEvent<Date>, row) {
    row.clearDate = event.value;
  }

  addSalesOrderNo(event: any) {
    this.salesOrderNo = event;
  }

  addStartDate(event: MatDatepickerInputEvent<Date>) {
    this.startDate = event.value;
  }
  addEndDate(event: MatDatepickerInputEvent<Date>) {
    this.endDate = event.value;
  }

  clear() {
    const date = new Date();
    this.information = {
      SalesOrderNo: '',
      address: '',
      builder: '',
      clearDate: date,
      recallDate: date,
    };
    this.salesOrderNo = '';
    this.address = '';
    this.builder = '';
    this.clearDate = undefined;
    this.recallDate = undefined;
  }

  getAllRoughins() {
    this.allSalesOrders = [];
    this.sageApi.pullReport(`ClearDate/`).subscribe(
      (addresses: Array<any>) => {
        if (Array.isArray(addresses)) {
          addresses.forEach(address => {
            const obj = {
              addressInfo: address.SalesOrderNo + ' - ' + address.address,
              SalesOrderNo: address.SalesOrderNo,
              Address: address.address,
              Builder: address.Builder,
              clearDate:
                address.UDF_CLEAR_DATE_GIBSON == undefined
                  ? address.UDF_CLEAR_DATE_GIBSON
                  : new Date(address.UDF_CLEAR_DATE_GIBSON),
              recallDate:
                address.UDF_RECALL_CLEAR_GIBSON == undefined
                  ? address.UDF_RECALL_CLEAR_GIBSON
                  : new Date(address.UDF_RECALL_CLEAR_GIBSON),
            };
            this.allSalesOrders.push(obj);
          });
        }
      },
      err => {
        console.log(err);
        this.alertify.error(err.message);
      }
    );
  }

  addressSelected(event: TypeaheadMatch) {
    this.salesOrderNo = event.item.SalesOrderNo;
    this.address = event.item.Address;
    this.builder = event.item.Builder;
    this.clearDate = event.item.clearDate;
    this.recallDate = event.item.recallDate;
  }

  removeFromArray(row) {
    const index = this.dataToSubmit.indexOf(row);
    this.dataToSubmit.splice(index, 1);
  }
  removeSalesOrderFromArray(row) {
    const array = row.split(',');
    array.forEach(element => {
      if (element != '') {
        const obj = this.dataToSubmit.find(x => x.SalesOrderNo == element);
        const index = this.dataToSubmit.indexOf(obj);
        this.dataToSubmit.splice(index, 1);
      }
    });
  }
  putRequest() {
    this.loading = true;
    const observables = [];
    const body = {
      username: this.authService.decodedToken.nameid,
      Lines: [],
    };
    this.dataToSubmit.forEach(line => {
      let clearDate = '';
      let recallDate = '';

      if (clearDate != undefined) {
        clearDate = line.clearDate.toISOString();
      }
      if (line.recallDate != undefined) {
        recallDate = line.recallDate.toISOString();
      }

      const obj = {
        SalesOrderNo: line.SalesOrderNo,
        clearDate: clearDate,
        recallDate: recallDate,
      };
      body.Lines.push(obj);
    });
    observables.push(this.sageApi.putRequest('ClearDate/AddDates', body));

    concat(...observables).subscribe(
      response => {
        this.loading = true;
      },
      err => {
        console.log(err);
        this.removeSalesOrderFromArray(err.error.Message);
        if (err.error.Message != '') {
          this.alertify.success('Updated: ' + err.error.Message);
        }
        var errorSO = '';
        this.dataToSubmit.forEach(element => {
          errorSO += element.SalesOrderNo + ' ';
        });
        this.alertify.error(
          'Error updating: ' + errorSO + '\nSales order may be open in Sage.'
        );
        this.loading = false;
      },
      () => {
        this.alertify.success('Updated');
        this.clear();
        this.dataToSubmit = [];
        this.allSalesOrders = [];
        this.getAllRoughins();
        this.loading = false;
      }
    );
  }
}
