import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ScreenSizeService } from 'src/app/_services/ScreenSizeService/ScreenSizeService';
import KitDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/KitDtoInterface';
import { SageApiService } from 'src/app/_services/sageApi/sageApi.service';
import { ConfirmModalComponent } from '../../Platform/confirm-modal/confirm-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import KitRegions from '../KitRegions';
import KitUtil from 'src/app/utils/KitUtil';
import KitPhases from '../KitPartPhases';
import QuoteStatuses from '../QuoteStatuses';
import QuoteDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/QuoteDtoInterface';
import DateValidators from 'src/app/Validators/DateValidators';
import { QuoteQuestionRowAnswerChangeInterface } from '../QuoteQuestionRowComponent/QuoteQuestionRowComponent';
import ContextQuoteQuestionDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextQuoteQuestionDtoInterface';
import { MatSnackBar } from '@angular/material/snack-bar';
import InputDecimalSanitizer from 'src/app/utils/InputDecimalSanitizer';
import ContextQuoteDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextQuoteDtoInterface';
import { MultiIsLoadingService } from 'src/app/_services/multi-is-loading/multi-is-loading.service';

@Component({
  selector: 'app-quotesview',
  templateUrl: './QuotesViewComponent.html',
  styleUrls: ['./QuotesViewComponent.css', '../QuotingTheme.scss'],
  providers: [{ provide: 'loading', useClass: MultiIsLoadingService }],
})
export class QuotesViewComponent extends KitUtil {
  quoteControl = new FormControl('');
  regionControl = new FormControl('All');
  statusControl = new FormControl('All');
  builderControl = new FormControl('');
  quotePage = 0;
  quotePageSize = 5;
  quotePageHasMore = false;
  hasSearched = false;

  quotes: QuoteDtoInterface[] = [];

  newQuoteOpen = false;
  newQuoteLoading = false;
  newQuoteErrorMsg = null;

  newQuoteNameControl = new FormControl('', [
    Validators.required,
    Validators.minLength(5),
    Validators.maxLength(30),
  ]);
  newQuoteBuilderControl = new FormControl('', [
    Validators.required,
    Validators.maxLength(30),
  ]);
  newQuoteSquareFootageControl = new FormControl(1000, [
    Validators.required,
    Validators.min(1),
    Validators.max(999999.99),
  ]);
  newQuoteExpMin = new Date().toISOString();
  newQuoteRegionControl = new FormControl('', [
    Validators.required,
    Validators.maxLength(30),
  ]);
  newQuoteStatusControl = new FormControl('', [
    Validators.required,
    Validators.maxLength(30),
  ]);
  newQuoteDescControl = new FormControl('', [Validators.maxLength(255)]);
  newQuoteFixturesControl = new FormControl('', [
    Validators.required,
    Validators.maxLength(255),
  ]);
  newQuoteStoriesControl = new FormControl('', [
    Validators.required,
    Validators.maxLength(100),
  ]);
  newQuoteBathControl = new FormControl('', [
    Validators.required,
    Validators.maxLength(50),
  ]);
  newQuoteWaterHeatersControl = new FormControl('', [
    Validators.required,
    Validators.maxLength(255),
  ]);

  quoteStatuses = QuoteStatuses;
  regions = KitRegions;
  quoteBuilders: string[] = [];

  quoteQuestions: ContextQuoteQuestionDtoInterface[] = [];

  constructor(
    public api: SageApiService,
    @Inject('loading') public loading: MultiIsLoadingService,
    private router: Router,
    public screenSize: ScreenSizeService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar
  ) {
    super();
  }

  disableSearchControls() {
    this.quoteControl.disable();
    this.regionControl.disable();
    this.statusControl.disable();
    this.builderControl.disable();
  }

  enableSearchControls() {
    this.quoteControl.enable();
    this.regionControl.enable();
    this.statusControl.enable();
    this.builderControl.enable();
  }

  searchQuotes(page?: number) {
    this.hasSearched = true;
    if (page != undefined) {
      this.quotePage = page;
    } else {
      this.quotePage = 0;
      this.quotePageHasMore = false;
    }

    if (!this.loading.isLoading()) {
      const lid = this.loading.startLoading();
      this.disableSearchControls();
      let qurl = `quotes-overview?size=${this.quotePageSize}&offset=${
        this.quotePage != 0 ? this.quotes.length : 0
      }&Quote_Name=${this.quoteControl.value}`;
      if (this.regionControl.value != 'All') {
        qurl += `&Quote_Region=${this.regionControl.value}`;
      }
      if (this.statusControl.value != 'All') {
        qurl += `&Quote_Status=${this.statusControl.value}`;
      }
      if (this.builderControl.value != '') {
        qurl += `&Quote_Builder=${this.builderControl.value}`;
      }
      this.api.pullReport(qurl).subscribe(
        (data: QuoteDtoInterface[]) => {
          this.quotes = page != undefined ? this.quotes.concat(data) : data;
          this.loading.stopLoading([lid]);
          this.enableSearchControls();
          this.quotePageHasMore = data.length == this.quotePageSize;
        },
        err => {
          this.loading.stopLoading([lid]);
          this.enableSearchControls();
          console.log(err);
          this.snackBar.open(
            'There was an error loading the quotes.',
            'Dismiss',
            {
              duration: Infinity,
            }
          );
        }
      );
    }
  }

  deleteQuote(quote: QuoteDtoInterface, quoteIndex: number) {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: {
        title: `Really delete ${quote.Quote_Name}?`,
        message: `Are you sure you want to delete ${quote.Quote_Name}?`,
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        const lid = this.loading.startLoading();
        this.api.deleteRequest(`quote/${quote.Quote_guid}`).subscribe(
          () => {
            this.quotes.splice(quoteIndex, 1);
            this.loading.stopLoading([lid]);
          },
          err => {
            console.log(err);
            this.loading.stopLoading([lid]);
          }
        );
      }
    });
  }

  createQuote() {
    this.newQuoteLoading = true;
    this.api
      .postRequest('quote', {
        Quote_Name: this.newQuoteNameControl.value,
        Quote_Expiration: null,
        Quote_SquareFootage: this.newQuoteSquareFootageControl.value,
        Quote_Builder: this.newQuoteBuilderControl.value,
        Quote_Region: this.newQuoteRegionControl.value,
        Quote_Status: this.newQuoteStatusControl.value,
        Quote_Desc: this.newQuoteDescControl.value,
        Quote_Fixtures: this.newQuoteFixturesControl.value,
        Quote_Stories: this.newQuoteStoriesControl.value,
        Quote_Bath: this.newQuoteBathControl.value,
        Quote_WaterHeaters: this.newQuoteWaterHeatersControl.value,
      })
      .subscribe(
        dataRaw => {
          const data: QuoteDtoInterface = (dataRaw as unknown) as QuoteDtoInterface;

          const kitsToAdd: { Kit_guid: string; quantity: 1 }[] = [];
          const partsToAdd: {
            Part_guid: string;
            quantity: 1;
            phase: string;
            cost: number;
          }[] = [];

          if ([...kitsToAdd, ...partsToAdd].length > 0) {
            // Make a request to add-to-quote using kitsToAdd and partsToAdd
            this.api
              .postRequest(`add-to-quote/${data.Quote_guid}`, {
                kits: kitsToAdd,
                parts: partsToAdd,
                customKits: [],
              })
              .subscribe(
                () => {
                  this.newQuoteOpen = false;
                  this.router.navigate([`/quoting/quote/${data.Quote_guid}`]);
                  this.newQuoteLoading = false;
                },
                err => {
                  // Show an error message
                  const continueDespiteQuestionError = this.snackBar.open(
                    'Created the quote, but there was a problem.',
                    'Go to quote anyway',
                    {
                      duration: Infinity,
                    }
                  );
                  continueDespiteQuestionError.onAction().subscribe(() => {
                    this.router.navigate([`/quoting/quote/${data.Quote_guid}`]);
                  });
                  console.log(err);
                }
              );
          } else {
            this.newQuoteOpen = false;
            this.router.navigate([`/quoting/quote/${data.Quote_guid}`]);
            this.newQuoteLoading = false;
          }
        },
        err => {
          console.log(err);
          if (err.error) {
            this.newQuoteErrorMsg = err.error;
            if (err.error.includes('Quote_Name')) {
              this.newQuoteNameControl.setErrors({ unique: true });
            }
            if (err.error.includes('Quote_Desc')) {
              this.newQuoteDescControl.setErrors({ unique: true });
            }
            if (err.error.includes('Quote_SquareFootage')) {
              this.newQuoteSquareFootageControl.setErrors({ unique: true });
            }
            if (err.error.includes('Quote_Builder')) {
              this.newQuoteBuilderControl.setErrors({ unique: true });
            }
            if (err.error.includes('Quote_Region')) {
              this.newQuoteRegionControl.setErrors({ unique: true });
            }
            if (err.error.includes('Quote_Status')) {
              this.newQuoteStatusControl.setErrors({ unique: true });
            }
          }
          this.newQuoteLoading = false;
        }
      );
  }

  openNewQuoteSheet() {
    this.newQuoteNameControl = new FormControl('', [
      Validators.required,
      Validators.minLength(5),
      Validators.maxLength(30),
    ]);
    this.newQuoteRegionControl = new FormControl('', [
      Validators.required,
      Validators.maxLength(30),
    ]);
    this.newQuoteStatusControl = new FormControl('', [
      Validators.required,
      Validators.maxLength(30),
    ]);
    this.newQuoteSquareFootageControl = new FormControl(1000, [
      Validators.required,
      Validators.min(1),
      Validators.max(999999.99),
    ]);
    this.newQuoteBuilderControl = new FormControl('', [
      Validators.required,
      Validators.maxLength(30),
    ]);
    this.newQuoteDescControl = new FormControl('', [Validators.maxLength(255)]);
    this.newQuoteFixturesControl = new FormControl('', [
      Validators.required,
      Validators.maxLength(255),
    ]);
    this.newQuoteStoriesControl = new FormControl('', [
      Validators.required,
      Validators.maxLength(100),
    ]);
    this.newQuoteBathControl = new FormControl('', [
      Validators.required,
      Validators.maxLength(50),
    ]);
    this.newQuoteWaterHeatersControl = new FormControl('', [
      Validators.required,
      Validators.maxLength(255),
    ]);
    this.newQuoteErrorMsg = null;
    this.newQuoteOpen = true;
  }

  newQuoteValid() {
    return (
      this.newQuoteNameControl.valid &&
      this.newQuoteDescControl.valid &&
      this.newQuoteStatusControl.valid &&
      this.newQuoteSquareFootageControl.valid &&
      this.newQuoteBuilderControl.valid &&
      this.newQuoteFixturesControl.valid &&
      this.newQuoteStoriesControl.valid &&
      this.newQuoteBathControl.valid &&
      this.newQuoteWaterHeatersControl.valid &&
      this.newQuoteLoading == false
    );
  }

  newQuoteSquareFootageInput(e: InputEvent) {
    const val = InputDecimalSanitizer(e, 6, 2);
    this.newQuoteSquareFootageControl.setValue(val);
  }

  copyQuote(quote) {
    const copyingSnackbar = this.snackBar.open('Copying quote...', '', {
      duration: Infinity,
    });

    this.api.postRequest(`copy-quote/${quote.Quote_guid}`, {}).subscribe(
      (data: ContextQuoteDtoInterface) => {
        copyingSnackbar.dismiss();
        this.router.navigate([`/quoting/quote/${data.Quote.Quote_guid}`]);
      },
      err => {
        console.log(err);
        copyingSnackbar.dismiss();
        this.snackBar.open('There was an error copying the quote.', 'Dismiss', {
          duration: Infinity,
        });
      }
    );
  }
}
