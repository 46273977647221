import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import * as Rx from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/concatAll';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';
import { AuthService } from '../../../_services/auth/auth.service';
import { SageApiService } from '../../../_services/sageApi/sageApi.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';
import { AlertifyService } from '../../../_services/alertify/alertify.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { concat } from 'rxjs';
import { WasabiApiService } from '../../../_services/wasabiApi/wasabiApi.service';

@Component({
  selector: 'app-qa-workticket',
  templateUrl: './qa-workticket.component.html',
  styleUrls: ['./qa-workticket.component.css'],
})
export class qaWorkticketComponent implements OnInit {
  bsConfig: Partial<BsDatepickerConfig>;

  addressName = '';
  addressLoaded: boolean;
  addressList = [
    {
      name: '1234 Test House - 0011787',
      address: '1234 Test House',
      salesOrderNo: '0011787',
    },
  ];

  backorderList = [
    {
      ItemCode: 'TestP',
      ICDesc: 'Test Part',
      QuantityOrdered: 2,
      QuantityBackordered: 1,
    },
  ];

  LWCheck = [];

  addressPart: boolean;

  mobile: boolean;
  isLoading: boolean;
  imgLoaded: boolean;
  loadedwtnumber: string;
  wtnumber: string;
  WTNUMBER_LENGTH = 10;
  loadingStatus: string;
  MESSAGES = {
    INVALID_WORKTICKET: 'Please enter a valid Work Ticket Number',
  };
  wtLoaded: boolean;
  wtModel: any = [];
  punModel: any = [];
  qaModel: any = [];
  scoutComment: string;
  materialOnScout: string;
  completePipesPrevious: number;
  partialPipesPrevious: number;
  damagedPipesPrevious: number;
  pipesOutExtendedPrevious: number;
  tubBoxPrevious: number;

  importTemplateInfo = {
    templateName: '',
    template: '',
    step: '',
  };
  modalTemplate: BsModalRef;

  phases = [];
  selectedPhaseName: string;
  cleanPhaseName: string;
  phaseLoaded: boolean;
  fromReport: string;
  writeAddress: string;
  writeSubdivision: string;
  writeTechName: string;
  techPhoneNumber: string;
  writeStatus: string;
  writeScheduleDate: Date;
  writeDIP: string;
  builderName: string;
  writeTrackingComment: string;
  loadingStatusChipping: string;
  isErrorChipping: boolean;
  isLoadingChipping: boolean;
  readyStatus: string;
  qaItemsTestCheck: string;
  choices = ['Y', 'N', 'N/A'];
  qaItemList = [];
  statuses = [
    'NO CHANGE',
    'COM',
    'REC',
    'REP',
    'WOB',
    'WOG',
    'INS',
    'CLD',
    'PTL',
    'RCI',
    'STC',
    'BIN',
  ];
  statusCode: any = [
    { code: '', desc: 'No Change' },
    { code: 'COM', desc: 'Complete (COM)' },
    { code: 'REC', desc: 'Re-Work Crew (REC)' },
    { code: 'REP', desc: 'Re-Work Punch (REP)' },
    { code: 'WOB', desc: 'Waiting on Builder (WOB)' },
    { code: 'WOG', desc: 'Waiting on Gibson (WOG)' },
    { code: 'INS', desc: 'Need to Call Inspection (INS)' },
    { code: 'CLD', desc: 'Inspection Called (CLD)' },
    { code: 'PTL', desc: 'Partial Inspection Passed (PTL)' },
    { code: 'STC', desc: 'Service Ticket Closed (STC)' },
    { code: 'BIN', desc: 'Builder Inspection Called (BIN)' },
  ];
  phaseFilter = [
    'ZROUGHIN',
    'ZSEWER',
    'ZWATER',
    'ZVANGUARD',
    'ZTOPOUT',
    'ZGAS',
    'ZTRIM',
    'ZDROPIN',
    'ZPUNCH',
    'ZSERVICE',
  ];
  newStatus: string;
  newComment: string;
  unlockComment: boolean;
  commentApplied: boolean;
  batchNeeded: boolean;
  expectedCompletionDate: Date;
  buttonsList = [];
  urlParams: URLSearchParams;

  // IMAGES
  fileLoading: boolean;
  fileArray = [];
  fileList = [];
  imageAdded: boolean;
  chippingImage: boolean;
  fileAddress: string;

  displayCurrentPunchSection: boolean;
  displayCurrentBackOrderSection: boolean;

  imgAddress = '';
  imagesForViewing: Array<string> = [];
  fileNamesW: Array<string> = [];
  displayScoutImages: boolean;
  imageUploadComplete = false;
  isImageLoading = false;
  imageArr: Array<any> = [];
  engSpan = true;
  confirmWaterMeter = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private sageApi: SageApiService,
    private alertify: AlertifyService,
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private wasabiApi: WasabiApiService // private qaPage: QaPageService,
  ) {}

  ngOnInit() {
    if (window.screen.width <= 375) {
      this.mobile = true;
    }
    this.displayCurrentPunchSection = true;
    this.displayCurrentBackOrderSection = true;
    this.loadAddressPhaseList();
    this.selectedPhaseName = '';
    this.newStatus = '';
    this.phaseLoaded = false;
    this.wtnumber = '';
    this.qaItemsTestCheck = 'N/A';
    this.unlockComment = false;
    this.batchNeeded = false;
    this.imageAdded = false;
    this.newComment = '';
    this.chippingImage = false;
    this.fromReport = 'N';
    this.fileAddress = '';
    this.urlParams = new URLSearchParams(window.location.search);
    this.route.queryParams.subscribe(params => {
      this.fromReport = localStorage.getItem('fromReportOrDash');
      this.wtnumber = params.workticket;
      this.loadQATicket();
    });
  }

  loadAddressPhaseList() {
    this.addressList = [];
    this.sageApi.pullReport('QAWorkticket/Addresses').subscribe(
      (rows: Array<any>) => {
        if (Array.isArray(rows)) {
          rows.forEach(row => {
            const address = {
              name: row.ShipToName + ' - ' + row.SalesOrderNo,
              address: row.ShipToName,
              salesOrderNo: row.SalesOrderNo,
            };
            this.addressList.push(address);
          });
        }
      },
      err => {
        this.alertify.error(err.error.Message);
        console.log(err);
      },
      () => {
        // ToDo: Something
      }
    );
  }

  addressSelected(event: TypeaheadMatch) {
    this.addressLoaded = true;
    this.phases = [];
    this.selectedPhaseName = '';
    this.phaseLoaded = false;
    this.wtLoaded = false;
    this.loadPhases(event.item.salesOrderNo);
  }

  phaseSelected(code: string, seriesIndex: number) {
    this.phaseLoaded = true;
    this.selectedPhaseName = code;
    this.phases[seriesIndex].selected = true;
    this.wtnumber =
      this.phases[seriesIndex].salesOrderNo +
      '-' +
      this.phases[seriesIndex].WTNum;
    this.loadQATicket();
  }

  statusSelected(status: any) {
    this.newStatus =
      status.code === ''
        ? (this.newStatus = this.wtModel[0].status)
        : status.code;
    this.unlockComment = true;
    this.commentApplied = false;
  }

  topoutORTrim() {
    return (
      this.wtModel[0].itemCode !== undefined &&
      (this.wtModel[0].itemCode.includes('TOPOUT') ||
        this.wtModel[0].itemCode.includes('TRIM'))
    );
  }

  // ! Function called when WT Number is entered
  onWTKeyUp(event) {
    // enter = 13
    if (event.key === 'Enter') {
      this.loadQATicket();
      // backspace = 8 ------ delete = 46
    } else if (event.key === 8 || event.key === 46) {
      return;
    } else {
      const filteredTicket = this.wtnumber
        ? this.wtnumber.replace(/-/g, '')
        : this.wtnumber;
      if (filteredTicket.length > 6 && filteredTicket.length < 10) {
        this.wtnumber =
          filteredTicket.substr(0, 7) + '-' + filteredTicket.substr(7);
        this.wtnumber = this.wtnumber.substr(0, 15);
      } else if (filteredTicket.length >= 10) {
        this.wtnumber =
          filteredTicket.substr(0, 7) +
          '-' +
          filteredTicket.substr(7, 3) +
          '-' +
          filteredTicket.substr(10);
        this.wtnumber = this.wtnumber.substr(0, 15);
      } else {
        this.wtnumber = filteredTicket;
      }
    }
  }

  loadPhases(salesOrderNo: string) {
    this.sageApi
      .pullReport('QAWorkticket?salesOrderNo=' + salesOrderNo)
      .subscribe(
        (rows: Array<any>) => {
          if (Array.isArray(rows)) {
            rows.forEach(row => {
              const phase = {
                address: row.ShipToName,
                salesOrderNo: row.SalesOrderNo,
                WTNum: row.WTNumber,
                WTParentPhase: row.HdrParentItemCode,
                phaseName:
                  '(' +
                  row.WTNumber +
                  ') ' +
                  (row.HdrParentItemCode !== undefined
                    ? row.HdrParentItemCode.substring(1)
                    : 'undefined'),
                selected: false,
              };
              if (
                !this.phases.some(
                  e =>
                    e.salesOrderNo === phase.salesOrderNo &&
                    e.WTParentPhase === phase.WTParentPhase &&
                    e.WTNum === phase.WTNum
                ) &&
                this.phaseFilter.includes(phase.WTParentPhase)
              ) {
                this.phases.push(phase);
              }
            });
          }
        },
        err => {
          this.alertify.error(err.error.Message);
          console.log(err);
        },
        () => {
          // ToDo: Something
        }
      );
  }

  loadQAChecklist(
    parentItemCode: string,
    cityName: string,
    builderName: string,
    subdivision: string,
    billToInfo: string
  ) {
    this.qaItemList = [];
    this.sageApi.pullReport('QAWorkticket/QAChecklist').subscribe(
      (rows: Array<any>) => {
        if (Array.isArray(rows)) {
          rows.forEach(row => {
            const item = {
              itemKey: row.ItemKey,
              inspectionItem: row.InspectionItem,
              cityName: row.CityName === null ? 'ALL' : row.CityName,
              phaseName: row.PhaseName === null ? 'ALL' : row.PhaseName,
              builderName: row.BuilderName === null ? 'ALL' : row.BuilderName,
              Subdivision:
                row.Subdivision === null ? 'ALL' : row.Subdivision.trim(),
              phaseNameClean:
                row.PhaseName !== undefined
                  ? row.PhaseName === 'ALL'
                    ? 'ALL'
                    : row.PhaseName.substring(1)
                  : 'undefined',
              selected: false,
            };

            if (
              (parentItemCode.toLocaleUpperCase() ===
                item.phaseName.toLocaleUpperCase() ||
                item.phaseName === 'ALL') &&
              (item.cityName.toLocaleUpperCase() ===
                cityName.toLocaleUpperCase() ||
                item.cityName === 'ALL') &&
              (item.builderName.toLocaleUpperCase() ===
                billToInfo.toLocaleUpperCase() ||
                item.builderName.toLocaleUpperCase() === 'ALL') &&
              (item.Subdivision.toLocaleUpperCase() ===
                subdivision.toLocaleUpperCase() ||
                item.Subdivision.toLocaleUpperCase() === 'ALL')
            ) {
              this.qaItemList.push(item);
            }
          });
        }
      },
      err => {
        this.alertify.error(err.error.Message);
        console.log(err);
      },
      () => {
        this.addMissingChecks();
      }
    );
  }

  logDate(event) {
    if (this.writeScheduleDate.getTime() !== new Date(event).getTime()) {
      this.writeScheduleDate = new Date(event);
      // updated = true;
    }
  }

  onChippingSubmit() {
    this.isLoadingChipping = true;
    this.loadingStatusChipping = 'Submitting pipes info...';
    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const username = this.authService.decodedToken.nameid;
    const wtnumberSplit = this.wtnumber.split('-');

    const imgComment = '';

    const salesOrderNumber = wtnumberSplit[0],
      wtNumber = wtnumberSplit[1],
      wtStep = wtnumberSplit[2];

    const observables = [];
    const chippingLinesArray = [];

    if (
      this.wtModel[0].damagedPipes > 0 &&
      this.wtModel[0].damagedPipesUpdated
    ) {
      const arr = [
        '/TOP OUT - CHIPPING', // * ItemCode
        `${
          this.wtModel[0].damagedPipes - this.damagedPipesPrevious
        } damaged pipes`, // * ItemCodeDesc
        `${this.wtModel[0].damagedPipes - this.damagedPipesPrevious}`, // * QtyOrdered
        '0', // * QtyShipped
        `${
          this.wtModel[0].damagedPipes - this.damagedPipesPrevious < 0
            ? '-20'
            : '20'
        }`, // * UnitCost
        'Y', // * JT158_WTPart
        wtNumber, // * JT158_WTNumber
        '020', // * JT158_WTStep
        'Y', // * JT158_WTChargePart
        'N', // * JT158_WTParent
        this.wtModel[0].parentLineKey, // * JT158_WTParentLineKey
        this.wtModel[0].subvendor, // * UDF_SUB_VENDOR
        this.wtModel[0].techCode, // * UDF_TECHNICIAN_CODE
      ];
      chippingLinesArray.push(arr);
    }

    if (
      this.wtModel[0].partialPipes > 2 &&
      this.wtModel[0].partialPipesUpdated
    ) {
      const arr = [
        '/TOP OUT - CHIPPING', // * ItemCode
        `${
          this.wtModel[0].partialPipes - 2 - this.partialPipesPrevious
        } pipes partially out of wall (after first two)`, // * ItemCodeDesc
        `${this.wtModel[0].partialPipes - 2 - this.partialPipesPrevious}`, // * QtyOrdered
        '0', // * QtyShipped
        `${
          this.wtModel[0].partialPipes - this.partialPipesPrevious < 0
            ? '-10'
            : '10'
        }`, // * UnitCost
        'Y', // * JT158_WTPart
        wtNumber, // * JT158_WTNumber
        '020', // * JT158_WTStep
        'Y', // * JT158_WTChargePart
        'N', // * JT158_WTParent
        this.wtModel[0].parentLineKey, // * JT158_WTParentLineKey
        this.wtModel[0].subvendor, // * UDF_SUB_VENDOR
        this.wtModel[0].techCode, // * UDF_TECHNICIAN_CODE
      ];
      chippingLinesArray.push(arr);
    }

    if (
      this.wtModel[0].completePipes > 0 &&
      this.wtModel[0].completePipesUpdated
    ) {
      const arr = [
        '/TOP OUT - CHIPPING', // * ItemCode
        `${
          this.wtModel[0].completePipes - this.completePipesPrevious
        } pipes 6'or less out of wall`, // * ItemCodeDesc
        `${this.wtModel[0].completePipes - this.completePipesPrevious}`, // * QtyOrdered
        '0', // * QtyShipped
        `${
          this.wtModel[0].completePipes - this.completePipesPrevious < 0
            ? '-25'
            : '25'
        }`, // * UnitCost
        'Y', // * JT158_WTPart
        wtNumber, // * JT158_WTNumber
        '020', // * JT158_WTStep
        'Y', // * JT158_WTChargePart
        'N', // * JT158_WTParent
        this.wtModel[0].parentLineKey, // * JT158_WTParentLineKey
        this.wtModel[0].subvendor, // * UDF_SUB_VENDOR
        this.wtModel[0].techCode, // * UDF_TECHNICIAN_CODE
      ];
      chippingLinesArray.push(arr);
    }

    if (
      this.wtModel[0].pipesOutExtended > 0 &&
      this.wtModel[0].pipesOutExtendedUpdated
    ) {
      const arr = [
        '/TOP OUT - CHIPPING', // * ItemCode
        `${
          this.wtModel[0].pipesOutExtended - this.pipesOutExtendedPrevious
        } pipes 6'or more out of wall`, // * ItemCodeDesc
        `${this.wtModel[0].pipesOutExtended - this.pipesOutExtendedPrevious}`, // * QtyOrdered
        '0', // * QtyShipped
        `${
          this.wtModel[0].pipesOutExtended - this.pipesOutExtendedPrevious < 0
            ? '-40'
            : '40'
        }`, // * UnitCost
        'Y', // * JT158_WTPart
        wtNumber, // * JT158_WTNumber
        '020', // * JT158_WTStep
        'Y', // * JT158_WTChargePart
        'N', // * JT158_WTParent
        this.wtModel[0].parentLineKey, // * JT158_WTParentLineKey
        this.wtModel[0].subvendor, // * UDF_SUB_VENDOR
        this.wtModel[0].techCode, // * UDF_TECHNICIAN_CODE
      ];
      chippingLinesArray.push(arr);
    }

    if (this.wtModel[0].tubBox > 0 && this.wtModel[0].tubBoxUpdated) {
      const arr = [
        '/TOP OUT - CHIPPING', // * ItemCode
        `${this.wtModel[0].tubBox - this.tubBoxPrevious} tub box(es)`, // * ItemCodeDesc
        `${this.wtModel[0].tubBox - this.tubBoxPrevious}`, // * QtyOrdered
        '0', // * QtyShipped
        `${this.wtModel[0].tubBox - this.tubBoxPrevious < 0 ? '-40' : '40'}`, // * UnitCost
        'Y', // * JT158_WTPart
        wtNumber, // * JT158_WTNumber
        '020', // * JT158_WTStep
        'Y', // * JT158_WTChargePart
        'N', // * JT158_WTParent
        this.wtModel[0].parentLineKey, // * JT158_WTParentLineKey
        this.wtModel[0].subvendor, // * UDF_SUB_VENDOR
        this.wtModel[0].techCode, // * UDF_TECHNICIAN_CODE
      ];
      chippingLinesArray.push(arr);
    }

    const soScout = {
      username: this.authService.decodedToken.nameid,
      password: this.authService.decodedToken[
        'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'
      ],
      salesOrderNo: salesOrderNumber,
      chippingLines: chippingLinesArray,
    };
    observables.push(this.sageApi.putRequest('UpdScoutSO', soScout));

    const wtScout = {
      username: this.authService.decodedToken.nameid,
      password: this.authService.decodedToken[
        'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'
      ],
      salesorderno: salesOrderNumber,
      wtnumber: wtNumber,
      readyStatus: '',
      scoutDescription: '',
      pipesOutOfTheWall: this.wtModel[0].completePipes || 0,
      pipesShadingTheWall: this.wtModel[0].partialPipes || 0,
      pictureLinks: imgComment,
      materialOnScout: this.materialOnScout || '',
      pipesDamaged: this.wtModel[0].damagedPipes || 0,
      pipesExtendedOutOfTheWall: this.wtModel[0].pipesOutExtended || 0,
      tubBox: this.wtModel[0].tubBox || 0,
    };

    observables.push(this.sageApi.putRequest('UpdScoutWT', wtScout));

    concat(...observables).subscribe(
      e => {
        // ToDo: Something
      },
      err => {
        console.log(err);
        this.alertify.error('Error submitting chipping: ' + err.error.Message);
        this.isLoading = false;
        this.isLoadingChipping = false;
        this.isErrorChipping = true;
      },
      () => {
        this.isLoading = false;
        this.isLoadingChipping = false;
        this.isErrorChipping = false;
        this.imageAdded = false;
        this.chippingImage = false;
        this.loadingStatusChipping = 'Chipping Submitted to SAGE ✔️';
        this.alertify.success(this.loadingStatusChipping);
        setTimeout(() => {
          this.closeModal();
        }, 2500);
      }
    );
  }

  // * Action Items Functions Below //
  onQuickActionButtonClick(status: string) {
    this.newStatus = status;
    if (status === 'LW') {
      const obj = {
        soNum: this.wtModel[0].soNum,
        soWT: this.wtModel[0].soWT,
        address: this.wtModel[0].address,
        scheduleDate: this.wtModel[0].scheduleDate,
        parentItemCode: this.wtModel[0].parentItemCode,
        itemCode: '/QA',
        subdivision: this.wtModel[0].subdivision,
        dip: this.wtModel[0].dip,
        techCode: this.wtModel[0].techCode,
        statusCode: this.wtModel[0].statusCode,
        itemCodeDesc: 'QA Item',
        parentLineKey: this.wtModel[0].parentLineKey,
        location: 'GENERAL',
        isComplete: '',
        isCompleteNew: 'N',
        code: `989 - Crew Hasn't Started House Yet`,
        notes: '',
        origNotes: '',
        lineKey: 'none',
      };
      this.qaModel.push(obj);
    }
    this.submitQA();
  }

  get isValidWTNumber(): boolean {
    if (this.wtnumber.length < this.WTNUMBER_LENGTH) {
      return false;
    }
    return true;
  }

  checkStat(isComplete: string) {
    // ToDo: Something
  }

  addMissingChecks() {
    if (Array.isArray(this.qaItemList)) {
      this.qaItemList.forEach(qa => {
        if (
          !this.qaModel.some(
            e =>
              e.code === qa.itemKey ||
              (e.itemCode.length > 3 ? e.itemCode.substring(3) : '') ===
                qa.itemKey
          )
        ) {
          const icSplit = qa.inspectionItem.split('/--/');
          const obj = {
            salesOrderNo: this.wtModel[0].salesOrderNo,
            wtNumber: this.wtModel[0].wtNumber,
            wtStep: this.wtModel[0].wtStep,
            address: this.wtModel[0].address,
            subdivision: this.wtModel[0].subdivision,
            city: this.wtModel[0].city,
            builder: this.wtModel[0].builder,
            status: this.wtModel[0].status,
            dip: this.wtModel[0].dip,
            tech: this.wtModel[0].tech,
            parentLineKey: this.wtModel[0].parentLineKey,
            lineKey: 'none',
            parentLineCode: this.wtModel[0].parentLineCode,
            trackingComment: this.wtModel[0].trackingComment,
            scheduleDate: this.wtModel[0].scheduleDate,
            promiseDate: this.wtModel[0].promiseDate,
            itemCode: '/QA' + qa.itemKey,
            itemCodeDesc: qa.inspectionItem,
            ICEnglish: icSplit[0],
            ICSpanish: icSplit.length < 2 ? icSplit[0] : icSplit[1],
            code: qa.itemKey,
            updated: 'false',
            notes: '',
            origNotes: '',
            completePipes: this.wtModel[0].completePipes,
            partialPipes: this.wtModel[0].partialPipes,
            damagedPipes: this.wtModel[0].damagedPipes,
            pipesOutExtended: this.wtModel[0].pipesOutExtended,
            tubBox: this.wtModel[0].tubBox,
            pictures: this.wtModel[0].pictures,
            materialOnScout: this.wtModel[0].materialOnScout,
            scoutComment: this.wtModel[0].scoutComment,
            completePipesUpdated: false,
            partialPipesUpdated: false,
            damagedPipesUpdated: false,
            pipesOutExtendedUpdated: false,
            tubBoxUpdated: false,
            isComplete: 'Y',
            isCompleteNew: 'Y',
            location: this.wtModel[0].location,
            expectedCompletionDate:
              this.wtModel[0].expectedCompletionDate !== null
                ? this.wtModel[0].expectedCompletionDate
                : '17530101',
            // BUTTON GROUPS
            groupName: 'group' + qa.itemKey,
            YID: 'optY' + qa.itemKey,
            NID: 'optN' + qa.itemKey,
            NoneID: 'optNone' + qa.itemKey,
          };
          this.wtModel.push(obj);
          this.qaModel.push(obj);
        }
      });
    }
  }

  submitQA() {
    if (this.qaModel.some(e => e.isCompleteNew === 'N/A')) {
      this.closeModal();
      this.alertify.error(
        'Error: all QA Items need to be checked yes or no. (NO N/A)'
      );
    } else {
      const observables = [];
      this.loadingStatus = 'Sending Data to Sage..';
      const date = new Date();
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      const username = this.authService.decodedToken.nameid;
      const timestamp = `${month}/${day}/${year} - ${username}`;
      this.isLoading = true;
      const hour = date.getHours();
      const minute = date.getMinutes();
      const second = date.getSeconds();
      const wtnumberSplit = this.loadedwtnumber.split('-');

      const salesOrderNumber = wtnumberSplit[0],
        wtNumber = wtnumberSplit[1],
        wtStep = wtnumberSplit[2];
      const status = this.newStatus;
      const today = year + '' + month + '' + day;
      const time = ('0' + hour).slice(-2) + ('0' + minute).slice(-2);
      const secs = ('0' + second).slice(-2);

      if (this.newComment !== '') {
        this.wtModel[0].combinedComment =
          this.newComment +
          ' ' +
          timestamp +
          ' ' +
          this.wtModel[0].trackingComment;
      }

      const qaItems = [];

      // * For the SO
      const soObj = {
        key: '',
        date: today,
        time,
        seconds: secs,
        soNum: salesOrderNumber,
      };

      this.qaModel.forEach(item => {
        if (
          item.isCompleteNew !== item.isComplete ||
          item.notes !== item.origNotes
        ) {
          const qa = [
            item.lineKey === 'none' || item.lineKey === ''
              ? 'none'
              : item.lineKey,
            item.salesOrderNo,
            item.wtNumber !== undefined
              ? item.wtNumber
              : this.wtModel[0].WTNumber,
            item.parentLineKey,
            item.isCompleteNew,
            item.code,
            item.location,
            item.notes,
            item.expectedCompletionDate,
            item.itemCode,
            item.itemCodeDesc,
          ];
          qaItems.push(qa);

          const index = this.qaModel.findIndex(e => e === item);
        }
      });

      const obj = {
        username: this.authService.decodedToken.nameid,
        password: this.authService.decodedToken[
          'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'
        ],
        // wt Items
        salesOrderNo: this.wtModel[0].salesOrderNo,
        wtNumber: this.wtModel[0].wtNumber,
        parentLineKey: this.wtModel[0].parentLineKey,
        status: this.newStatus,
        trackComment: this.newComment,
        serviceCode: '',
        batchNeeded: this.batchNeeded,
        waterMeterPresent: this.wtModel[0].waterMeterPresent ? 'Y' : 'N',
        qaItem: qaItems,
      };

      observables.push(this.sageApi.putRequest('QAWorkticket', obj));
      this.alertify.message('Submitting to WT...', 10);
      concat(...observables).subscribe(
        response => {
          // ToDo: Something
        },
        err => {
          this.alertify.error(
            'Error on QA Ticket Submit: ' + err.error.Message
          );
          this.closeModal();
        },
        () => {
          this.closeModal();
          this.onReset();
          this.alertify.success('QA Ticket Submitted to Queue.');
          if (this.fromReport === 'R') {
            localStorage.setItem('fromReportOrDash', '');
            this.router.navigate(['/supers/super-reports']);
          } else if (this.fromReport === 'D') {
            localStorage.setItem('fromReportOrDash', '');
            this.router.navigate(['/supers/dashboard-leadman']);
          }
        }
      );
    }
  }

  LoadQALWCheck() {
    this.isLoading = true;
    const salesOrderNumber = this.wtnumber.split('-')[0];
    const wtNumber = this.wtnumber.split('-')[1];
    this.LWCheck = [];

    const LWSalesOrder = this.sageApi
      .pullReport(
        'QAWorkticket/LWCheck?salesOrderNo=' +
          salesOrderNumber +
          '&WTNum=' +
          wtNumber
      )
      .subscribe(
        (qa: Array<any>) => {
          if (Array.isArray(qa)) {
            qa.forEach(q => {
              this.LWCheck.push(q);
            });
          }
        },
        err => {
          console.log(err);
          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        }
      );
  }

  loadQABackorder() {
    this.isLoading = true;
    const salesOrderNumber = this.wtnumber.split('-')[0];
    const wtNumber = this.wtnumber.split('-')[1];
    this.wtLoaded = false;
    this.backorderList = [];

    const boSalesOrder = this.sageApi
      .pullReport(
        'QAWorkticket/Backorders?salesOrderNo=' +
          salesOrderNumber +
          '&WTNum=' +
          wtNumber
      )
      .subscribe(
        (qa: Array<any>) => {
          if (Array.isArray(qa)) {
            qa.forEach(q => {
              const obj = {
                ItemCode: q.ItemCode,
                ICDesc: q.ItemCDesc,
                QuantityOrdered: q.QuantityOrdered,
                QuantityBackordered: q.QuantityBackordered,
              };
              this.backorderList.push(obj);
            });
          }
        },
        err => {
          console.log(err);
          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        }
      );
  }

  loadQATicket() {
    this.newComment = '';
    this.fileAddress = '';
    this.imageAdded = false;
    this.chippingImage = false;
    this.isLoading = true;
    this.imgLoaded = false;
    this.loadedwtnumber = this.wtnumber;
    if (!this.wtnumber || this.wtnumber.length < this.WTNUMBER_LENGTH) {
      this.loadingStatus = this.MESSAGES.INVALID_WORKTICKET;
      this.isLoading = false;
      return;
    }
    const salesOrderNumber = this.wtnumber.split('-')[0];
    const wtNumber = this.wtnumber.split('-')[1];
    this.loadPhases(salesOrderNumber);
    this.wtLoaded = false;

    const qaSalesOrder = this.sageApi
      .pullReport(
        'QAWorkticket?salesOrderNo=' + salesOrderNumber + '&WTNum=' + wtNumber
      )
      .subscribe(
        (qa: Array<any>) => {
          this.wtModel = [];
          this.punModel = [];
          this.qaModel = [];
          if (Array.isArray(qa)) {
            qa.forEach(q => {
              const icSplit = q.ItemCDesc.split('/--/');
              const obj = {
                salesOrderNo: q.SalesOrderNo,
                wtNumber: q.WTNumber,
                wtStep: q.WTStep,
                address: q.ShipToName,
                subdivision: q.UDF_SUBDIVISION,
                city: q.UDF_CITY,
                builder: q.BillToName,
                status: q.StatusCode,
                punStatus:
                  q.DIP < 0
                    ? q.HdrParentItemCode === 'ZPUNCH' ||
                      q.HdrParentItemCode === 'ZPUNCHB' ||
                      q.HdrParentItemCode === 'ZPUNCHT'
                      ? 'Future Punch'
                      : q.StatusCode
                    : q.StatusCode === 'QA Complete'
                    ? 'Builder Inspection'
                    : q.StatusCode,
                dip: q.DIP,
                tech: q.techName,
                techCode: q.UDF_TECHNICIAN_CODE,
                parentLineKey: q.JT158_WTParentLineKey,
                lineKey: q.LineKey,
                parentLineCode: q.HdrParentItemCode,
                trackingComment: q.UDF_TRACKING_COMMENT,
                scheduleDate: new Date(q.UDF_DATE_SCHEDULED || '1753-01-01'),
                promiseDate: q.PromiseDate,
                itemCode: q.ItemCode,
                itemCodeDesc: q.ItemCDesc,
                ICEnglish: icSplit[0],
                ICSpanish: icSplit.length < 2 ? icSplit[0] : icSplit[1],
                code: q.UDF_ASSIGNED_CODE,
                updated: 'false',
                notes: q.UDF_INSP_NOTES,
                origNotes: q.UDF_INSP_NOTES,
                completePipes: q.UDF_SCOUT_LM_PIPES_OUT,
                partialPipes: q.UDF_SCOUT_LM_PIPES_SHADING,
                damagedPipes: q.UDF_SCOUT_LM_DMG_PIPES,
                pipesOutExtended: q.UDF_SCOUT_LM_PIPES_OUT_EXT,
                tubBox: q.UDF_SCOUT_LM_TUB_BOX,
                pictures: q.UDF_SCOUT_LM_PICTURES,
                materialOnScout: q.UDF_MATERIAL_ON_SCOUT,
                scoutComment: q.UDF_SCOUT_LM_DESCRIPTION,
                completePipesUpdated: false,
                partialPipesUpdated: false,
                damagedPipesUpdated: false,
                pipesOutExtendedUpdated: false,
                tubBoxUpdated: false,
                isComplete: q.UDF_INSP_COMPL,
                isCompleteNew: q.UDF_INSP_COMPL,
                expectedCompletionDate: q.UDF_QA_EXP_COMPL_DATE || '    ',
                location: q.UDF_LOCATION,
                waterMeterPresent: q.UDF_WATER_METER_PRESENT === 'Y',
                techPhoneNumber:
                  q.TechPhoneNumber !== undefined || q.TechPhoneNumber !== null
                    ? q.TechPhoneNumber
                    : '',
                // BUTTON GROUPS
                groupName: 'group' + q.LineKey,
                YID: 'optY' + q.LineKey,
                NID: 'optN' + q.LineKey,
                NoneID: 'optNone' + q.LineKey,
                billToInfo: q.BillToInformation,
              };
              if (obj.itemCode.substring(0, 3) === '/QA') {
                this.qaModel.push(obj);
              }
              if (obj.itemCode === '/PUNCH') {
                this.punModel.push(obj);
              }
              this.wtModel.push(obj);

              this.imgAddress = obj.address;
              this.scoutComment = obj.scoutComment;
              this.materialOnScout = obj.materialOnScout;
              this.completePipesPrevious = obj.completePipes;
              this.partialPipesPrevious = obj.partialPipes;
              this.damagedPipesPrevious = obj.damagedPipes;
              // //* Pipes that are 6 inches or more outside of the wall
              this.pipesOutExtendedPrevious = obj.pipesOutExtended;
              this.tubBoxPrevious = obj.tubBox;
            });
          }
          if (!(Array.isArray(this.wtModel) && this.wtModel.length)) {
            this.writeAddress = '';
            this.addressName = 'BAD TICKET';
            this.writeSubdivision = '';
            this.writeTechName = '';
            this.techPhoneNumber = '';
            this.writeStatus = '';
            this.writeScheduleDate = new Date();
            this.writeDIP = '';
            this.builderName = '';
            this.writeTrackingComment = '';
            this.isLoading = false;
          } else {
            this.wtModel[0].address === undefined
              ? (this.writeAddress = '')
              : (this.writeAddress = this.wtModel[0].address);
            this.wtModel[0].address === undefined
              ? (this.addressName = 'BAD TICKET')
              : (this.addressName = this.wtModel[0].address);
            this.wtModel[0].subdivision === undefined
              ? (this.writeSubdivision = '')
              : (this.writeSubdivision = this.wtModel[0].subdivision);
            this.wtModel[0].tech === undefined
              ? (this.writeTechName = '')
              : (this.writeTechName = this.wtModel[0].tech);
            this.wtModel[0].techPhoneNumber === undefined
              ? (this.techPhoneNumber = '')
              : (this.techPhoneNumber = this.wtModel[0].techPhoneNumber);
            this.wtModel[0].status === undefined
              ? (this.writeStatus = '')
              : (this.writeStatus = this.wtModel[0].status);
            this.wtModel[0].scheduleDate === undefined
              ? (this.writeScheduleDate = new Date())
              : (this.writeScheduleDate = this.wtModel[0].scheduleDate);
            this.wtModel[0].dip === undefined || this.wtModel[0].dip === null
              ? (this.writeDIP = '')
              : (this.writeDIP = this.wtModel[0].dip);

            this.wtModel[0].builder === undefined ||
            this.wtModel[0].builder === null
              ? (this.builderName = '')
              : (this.builderName = this.wtModel[0].builder);

            this.wtModel[0].trackingComment === undefined
              ? (this.writeTrackingComment = '')
              : (this.writeTrackingComment = this.wtModel[0].trackingComment);
          }
        },
        err => {
          console.log(err);
          this.isLoading = false;
        },
        () => {
          if (!(Array.isArray(this.wtModel) && this.wtModel.length)) {
            //  alertrify message for bad phase

            this.wtLoaded = false;
          } else {
            this.loadQAChecklist(
              this.wtModel[0].parentLineCode,
              this.wtModel[0].city,
              this.wtModel[0].builder,
              this.wtModel[0].subdivision,
              this.wtModel[0].billToInfo
            );
            this.wtLoaded = true;
            const salesOrderNum = this.wtnumber.slice(0, 7);
            this.cleanPhaseName = this.wtModel[0].parentLineCode.substring(1);
            this.isLoading = false;
            this.newStatus = this.wtModel[0].status;
            this.wtnumber =
              this.wtModel[0].salesOrderNo +
              '-' +
              this.wtModel[0].wtNumber +
              '-' +
              this.wtModel[0].wtStep;
            this.loadedwtnumber = this.wtnumber;
            this.fileAddress = this.wtModel[0].address;
            this.confirmWaterMeter = !(
              this.topoutORTrim() && !this.wtModel[0].waterMeterPresent
            );
          }
        }
      );
    this.LoadQALWCheck();
    this.loadQABackorder();
  }

  openModal(template: TemplateRef<any>, bigModal: boolean) {
    this.loadImagesForViewing();
    this.importTemplateInfo = {
      templateName: '',
      template: '',
      step: '',
    };
    if (bigModal) {
      this.modalTemplate = this.modalService.show(template, {
        class: 'modal-lg',
      });
    } else {
      this.modalTemplate = this.modalService.show(template);
    }
  }

  closeModal() {
    if (!this.imageAdded) {
      this.chippingImage = false;
    }
    this.modalTemplate.hide();
  }

  fileChosen(event) {
    const files = event.target.files;
    for (const index in files) {
      if (files[index] instanceof File) {
        const obj = {
          name: files[index].name,
          data: files[index],
        };

        this.fileArray = [obj];
      }
    }
  }

  fileNames() {
    const route = `QAWorkticket/images?wtNumber=${this.loadedwtnumber}&address=${this.fileAddress}`;
    this.fileList = [];
    this.sageApi.pullReport(route).subscribe(
      (response: any) => {
        const splitFiles = response.split(', \\\\gp-nas2\\SO_WT_Files');
        splitFiles.forEach(file => {
          this.fileList.push({ name: file.split(this.fileAddress + '\\')[1] });
        });
      },
      err => {
        console.log(err);
        this.alertify.error(err.error.Message);
      },
      () => {
        // ToDo: Something
      }
    );
  }

  fileNamesChip() {
    const route = `QAWorkticket/images?wtNumber=${this.loadedwtnumber}&address=${this.fileAddress}`;
    this.fileList = [];
    this.sageApi.pullReport(route).subscribe(
      (response: any) => {
        const splitFiles = response.split(', \\\\gp-nas2\\SO_WT_Files');
        splitFiles.forEach(file => {
          const name = file.split(this.fileAddress + '\\')[1];
          if (name.includes('_Chipping_')) {
            this.fileList.push({
              name: file.split(this.fileAddress + '\\')[1],
            });
          }
        });
      },
      err => {
        console.log(err);
        this.alertify.error(err.error.Message);
      },
      () => {
        // ToDo: Something
      }
    );
  }

  fileDownload(fileName: string) {
    const route = `QAWorkticket/images/download?wtNumber=${this.loadedwtnumber}&fileName=${fileName}&address=${this.fileAddress}`;
    this.sageApi.getFile(route).subscribe(
      (response: any) => {
        const dataType = response.type;
        const binaryData = [];
        binaryData.push(response);
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(
          new Blob(binaryData, { type: dataType })
        );
        if (fileName) {
          downloadLink.setAttribute('download', fileName);
        }
        document.body.appendChild(downloadLink);
        downloadLink.click();
        downloadLink.parentNode.removeChild(downloadLink);
      },
      err => {
        console.log(err);
        this.alertify.error(err.error.Message);
      }
    );
  }

  fileUploadChip(chip) {
    this.fileLoading = true;
    const route = `QAWorkticket/images/upload?wtNum=${this.loadedwtnumber}&address=${this.fileAddress}&chip=${chip}`;
    const sentFiles = [];

    this.fileArray.forEach(file => {
      sentFiles.push(file.data);
    });

    this.sageApi.uploadFile(route, sentFiles).subscribe(
      res => {
        // ToDo: Something
      },
      err => {
        console.log(err);
        this.fileLoading = false;
      },
      () => {
        this.resetUpload();
        if (chip === 'Y') {
          this.imageAdded = true;
          this.alertify.success('Image Uploaded: You may now submit chipping');
          this.fileNamesChip();
        } else {
          this.closeModal();
          this.alertify.success('Files Uploaded');
        }
      }
    );
  }

  fileUploadChipping() {
    this.fileLoading = true;
    const route = `QAWorkticket/images/upload/Chip?wtNum=${this.loadedwtnumber}&address=${this.fileAddress}`;
    const sentFiles = [];

    this.fileArray.forEach(file => {
      sentFiles.push(file.data);
    });

    this.sageApi.uploadFile(route, sentFiles).subscribe(
      res => {
        // ToDo: Something
      },
      err => {
        console.log(err);
        this.fileLoading = false;
      },
      () => {
        this.resetUpload();
        if (this.chippingImage) {
          this.imageAdded = true;
          this.alertify.success('Image Uploaded: You may now submit chipping');
        }
        this.closeModal();
      }
    );
  }

  resetUpload() {
    this.fileArray = [];
    this.fileLoading = false;
  }

  onReset() {
    this.selectedPhaseName = '';
    this.newStatus = '';
    this.phaseLoaded = false;
    this.wtnumber = '';
    this.unlockComment = false;
    this.batchNeeded = false;
    this.imageAdded = false;
    this.newComment = '';
    this.chippingImage = false;
    this.fileAddress = '';
    this.newComment = '';
    this.fileAddress = '';
    this.chippingImage = false;
    this.isLoading = false;
    this.imgLoaded = false;
    this.wtModel = [];
    this.qaModel = [];
    this.qaItemList = [];
    this.punModel = [];
    this.addressLoaded = false;
    this.phases = [];
    this.selectedPhaseName = '';
    this.phaseLoaded = false;
    this.wtLoaded = false;
    this.resetUpload();
    this.addressName = '';
    this.urlParams.delete('workticket');
    history.replaceState(null, null, 'supers/Workticket');
  }

  loadImagesForViewing() {
    this.onGetTicketImages(this.imgAddress).then(() => {
      this.imagesForViewing = this.fileNamesW;
      this.fileNamesW = [];
    });
    this.displayScoutImages = true;
  }

  async onGetTicketImages(address: string) {
    const response = await this.wasabiApi.getImages(address);
    try {
      this.fileNamesW = response;
    } catch (error) {
      this.alertify.error(
        'There was an error loading the images for this house - please try again'
      );
      console.warn(`Error loading images: ${error}`);
    }
  }

  onFileChangedW(event, modal = 'image') {
    this.imageUploadComplete = false;
    this.isImageLoading = true;
    this.imageArr = [];
    this.fileNamesW = [];

    const files = event.target.files;
    let size = 0;
    for (const index in files) {
      if (files[index] instanceof File) {
        this.fileNamesW.push(files[index].name);

        const obj = {
          name: this.wtnumber + files[index].name,
          data: files[index],
        };
        size += files[index].size;
        this.imageArr.push(obj);
      }
    }
    if (files.length > 0) {
      if (size > 30000000) {
        this.alertify.warning(
          'File size may be too large. Please select fewer photos.'
        );
      }
      this.onFileLoadCompleteW(this.imgAddress);
    }
    if (modal === 'image') {
      this.closeModal();
    }
  }

  onFileLoadCompleteW(address: string) {
    this.wasabiApi.uploadImage(address, this.imageArr).subscribe(
      res => {
        this.imageUploadComplete = true;
      },
      err => {
        console.log(err);
        this.fileNamesW = [];
        this.isImageLoading = false;
        console.log(err.error.text);
        if (err.error.text === 'Stored.') {
          this.imageAdded = true;
        }
      },
      () => {
        this.fileNamesW = [];
        this.isImageLoading = false;
        this.imageAdded = true;
      }
    );
  }

  loadImagesS3() {
    this.isLoading = true;
    const folder = this.imgAddress + '/scout';
    try {
      const images = this.wasabiApi.getImages(this.imgAddress + '/scout').then(
        img => {
          // ToDo: Something
        },
        () => {
          // hack to get the img list to load before the post to sage
          setTimeout(() => {
            // ToDo: Something
          }, 1500);
        }
      );
      this.imgLoaded = true;
    } catch (e) {
      this.alertify.warning('save image Error: ' + e.error.Message);
      this.imageAdded = false;
    } finally {
      this.onChippingSubmit();
    }
  }

  loadImagesS3Solo() {
    this.isLoading = true;
    const folder = this.imgAddress + '/scout';
    const images = this.wasabiApi.getImages(this.imgAddress + '/scout').then(
      img => {
        // ToDo: Something
      },
      () => {
        // hack to get the img list to load before the post to sage
        setTimeout(() => {
          // ToDo: Something
        }, 1500);
      }
    );
    this.alertify.success('image Downloaded');
    this.isLoading = false;
  }

  imageName(imgArrIndex: number) {
    let name = this.imagesForViewing[imgArrIndex].split('%2F')[
      this.imagesForViewing[imgArrIndex].split('%2F').length - 1
    ];
    name = name.replace(/%20/g, ' ');
    const namesplit = name.split('/');
    return 'Open ' + namesplit[namesplit.length - 1];
  }
}
