import { HttpClientModule } from '@angular/common/http';
import { CommonModule, DatePipe } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatBadgeModule } from '@angular/material/badge';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import {
  MatBottomSheetRef,
  MAT_BOTTOM_SHEET_DATA,
} from '@angular/material/bottom-sheet';
import { ModalModule, BsModalRef } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import { OverlayModule } from '@angular/cdk/overlay';

import { SageApiService } from './_services/sageApi/sageApi.service';
import { AuthService } from './_services/auth/auth.service';
import { AlertifyService } from './_services/alertify/alertify.service';
import { WasabiApiService } from './_services/wasabiApi/wasabiApi.service';
import { MetabaseService } from './_services/metabase/metabase.service';
import { QuoteEditableService } from './_services/QuoteEditableService/QuoteEditableService';
import { BaseModalService } from './_services/BaseModalService/BaseModalService';
import { UploadModalService } from './_services/UploadModalService/UploadModalService';

import { SettingsDirective } from './Components/Settings/settings.directive';

import { AppComponent } from './app.component';
import { ScheduleConfirmationComponent } from './Components/Customers/schedule-confirmation/schedule-confirmation.component';
import { ScheduleConfirmationQueueComponent } from './Components/Scheduler/schedule-confirmation-queue/schedule-confirmation-queue.component';
import { LoginPasswordlessComponent } from './Components/UserInfo/login-passwordless/login-passwordless.component';
import { AddressCustomerAssignmentComponent } from './Components/Scheduler/address-customer-assignment/address-customer-assignment.component';

import { SchedulingComponent } from '../app/Components/Scheduler/scheduling/scheduling.component';
import { HomePageComponent } from '../app/Components/home-page/home-page.component';
import { AMDashboardComponent } from '../app/Components/AreaManagers/AMDashboard/AMDashboard.component';
import {
  ScheduleTicketsComponent,
  ScheduleTicketsDialogComponent,
} from '../app/Components/Supers/scheduleTickets/scheduleTickets.component';
import { TechTodoComponent } from './Components/Supers/tech-todo/tech-todo.component';
import { EditWorkticketsComponent } from './Components/Material/edit-worktickets/edit-worktickets.component';
import { ReworkQueueComponent } from './Components/Scheduler/rework-queue/rework-queue.component';
import { UploadPlansComponent } from './Components/UserInfo/upload-plans/upload-plans.component';
import { HouseEditorComponent } from './Components/BuilderAssociates/house-editor/house-editor.component';
import { ReceivingCreateComponent } from './Components/Material/receiving-create/receiving-create.component';
import { ReceivingUpdateComponent } from './Components/Material/receiving-update/receiving-update.component';
import { PhasePayComponent } from './Components/Supers/phase-pay/phase-pay.component';
import { ReceiptOfGoodsApprovalComponent } from './Components/Accounting/receipt-of-goods-approval/receipt-of-goods-approval.component';
import { ReceiptOfInvoiceApprovalComponent } from './Components/Accounting/receipt-of-invoice-approval/receipt-of-invoice-approval.component';
import { AssignScheduleComponent } from './Components/Scheduler/assign-schedule/assign-schedule.component';
import { AssignScheduleTicketItemComponent } from './Components/Scheduler/assign-schedule-ticket-item/assign-schedule-ticket-item.component';
import { qaWorkticketComponent } from './Components/Supers/QA_WorkTicket/qa-workticket.component';
import { TemplateEditorComponent } from './Components/BuilderAssociates/template-editor/template-editor.component';
import { QrpartspullComponent } from './Components/QRCodeURLs/QRPartsPull/qrpartspull.component';
import { SettingsComponent } from './Components/Settings/settings/settings.component';
import {
  PunchAssignableTechsComponent,
  EditTechDialog,
} from './Components/Settings/punch-assignable-techs/punch-assignable-techs.component';
import { QrcrewlabordetailComponent } from './Components/QRCodeURLs/QRCrewlaborDetail/qrcrewlabordetail.component';
import { QrscannerComponent } from './Components/QRCodeURLs/QRScanner/qrscanner.component';
import {
  WomsManagementQueueComponent,
  WomsManagementQueueDialogComponent,
} from './Components/AreaManagers/woms-management-queue/woms-management-queue.component';
import { RoiPendingComponent } from './Components/Accounting/ReceiptOfInvoice/roi-pending/roi-pending.component';
import {
  RoiApproveComponent,
  RoiApproveDeleteDialogComponent,
  RoiApproveHeaderDialogComponent,
  RoiApproveApproveDialogComponent,
  RoiApproveSaveDialogComponent,
} from './Components/Accounting/ReceiptOfInvoice/roi-approve/roi-approve.component';
import { SuperReportsComponent } from './Components/Supers/super-reports/super-reports.component';
import { House360Component } from './Components/Supers/house-360/house-360.component';
import { AddressFilesComponent } from './Components/Supers/address-files/address-files.component';
import { SuperMiscpayComponent } from './Components/Supers/super-miscpay/super-miscpay.component';
import { SuperPayLineItemsTable } from './Components/Supers/super-pay-line-items-table/super-pay-line-items-table.component';
import { SuperMiscpayPunchCreatedTable } from './Components/Supers/super-misc-punch-created-table/super-misc-punch-created-table.component';
import { UnshippedItemsComponent } from './Components/Inventory/unshipped-items/unshipped-items.component';
import { PasswordResetComponent } from './Components/UserInfo/password-reset/password-reset.component';
import { QRCodeModule } from 'angular2-qrcode';
import {
  SubdivisionSetupComponent,
  AddSubdivisionDialog,
} from './Components/Settings/subdivision-setup/subdivision-setup.component';
import {
  PlanSetupComponent,
  PlanSetupCreateDialogComponent,
} from './Components/Settings/plan-setup/plan-setup.component';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { MatExpansionModule } from '@angular/material/expansion';
import { vpoPunchComponent } from './Components/Supers/Punch/vpo-punch.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { WomsMgtComponent } from './Components/WOMs/MGT/woms-mgt.component';
import { WomsAcctComponent } from './Components/WOMs/ACCT/woms-acct.component';
import { WomsPriceComponent } from './Components/WOMs/PRICE/woms-price.component';
import { NgxBarcode6Module } from 'ngx-barcode6';
import { WarehouseTransferComponent } from './Components/Material/warehousetransfer/warehouse-transfer.component';
import { JobRequisitionComponent } from './Components/Material/job-requisition/job-requisition.component';
import { PullTicketCreationComponent } from './Components/ServiceTitan/pull-ticket-creation/pull-ticket-creation.component';
import { PullTicketHistoryComponent } from './Components/ServiceTitan/pull-ticket-history/pull-ticket-history.component';
import { WarehouseTransferTruckComponent } from './Components/ServiceTitan/warehouse-transfer-truck/warehouse-transfer-truck.component';
import { PullTicketAdditionalPartsComponent } from './Components/ServiceTitan/pull-ticket-additional-parts/pull-ticket-additional-parts.component';
import { MaterialScheduledComponent } from './Components/ServiceTitan/material-scheduled/material-scheduled.component';
import { MaterialReturnComponent } from './Components/ServiceTitan/material-return/material-return.component';
import { TruckManagerComponent } from './Components/ServiceTitan/truck-manager/truck-manager.component';
import { BatchingPaymentsComponent } from './Components/ServiceTitan/batching-payments/batching-payments.component';
import { CustomersManagerComponent } from './Components/Scheduler/CustomersManagerComponent/CustomersManagerComponent';

/* Quoting stuff */
import { KitsViewComponent } from './Components/Quoting/KitsViewComponent/KitsViewComponent';
import { KitViewComponent } from './Components/Quoting/KitViewComponent/KitViewComponent';
import { QuoteViewComponent } from './Components/Quoting/QuoteViewComponent/QuoteViewComponent';
import { KitPartRowComponent } from './Components/Quoting/KitPartRowComponent/KitPartRowComponent';
import { QuoteKitPartRowComponent } from './Components/Quoting/QuoteKitPartRowComponent/QuoteKitPartRowComponent';
import { KitPartToAddRowComponent } from './Components/Quoting/KitPartToAddRowComponent/KitPartToAddRowComponent';
import { QuoteSidepanelComponent } from './Components/Quoting/QuoteSidepanelComponent/QuoteSidepanelComponent';
import { KitSidepanelComponent } from './Components/Quoting/KitSidepanelComponent/KitSidepanelComponent';
import { KOQAppBarComponent } from './Components/Quoting/KOQAppBarComponent/KOQAppBarComponent';
import { QuotesViewComponent } from './Components/Quoting/QuotesViewComponent/QuotesViewComponent';
import { QuoteQuestionsEditorViewComponent } from './Components/Quoting/QuoteQuestionsEditorViewComponent/QuoteQuestionsEditorViewComponent';
import { CreateQuoteQuestionAnswerComponent } from './Components/Quoting/CreateQuoteQuestionAnswerComponent/CreateQuoteQuestionAnswerComponent';
import { KitOrPartPickerBotSheetComponent } from './Components/Quoting/KitOrPartPickerBotSheetComponent/KitOrPartPickerBotSheetComponent';
import { KitOrPartPickerComponent } from './Components/Quoting/KitOrPartPickerComponent/KitOrPartPickerComponent';
import { EditQuotePartBotSheetComponent } from './Components/Quoting/EditQuotePartBotSheetComponent/EditQuotePartBotSheetComponent';
import { CustomQuoteKitBotSheetComponent } from './Components/Quoting/CustomQuoteKitBotSheetComponent/CustomQuoteKitBotSheetComponent';
import { CustomQuotePartBotSheetComponent } from './Components/Quoting/CustomQuotePartBotSheetComponent/CustomQuotePartBotSheetComponent';
import { QuotePartBreakdownComponent } from './Components/Quoting/QuotePartBreakdownComponent/QuotePartBreakdownComponent';
import { QuoteExclusionComponent } from './Components/Quoting/QuoteExclusionComponent/QuoteExclusionComponent';
import { QuoteInclusionComponent } from './Components/Quoting/QuoteInclusionComponent/QuoteInclusionComponent';
import { QuoteNoteComponent } from './Components/Quoting/QuoteNoteComponent/QuoteNoteComponent';
import { QuoteBillingTermComponent } from './Components/Quoting/QuoteBillingTermComponent/QuoteBillingTermComponent';
import {
  QuoteViewSummaryComponent,
  QuoteAttachmentDialogComponent,
} from './Components/Quoting/QuoteViewSummaryComponent/QuoteViewSummaryComponent';
import { QuoteDashboardComponentComponent } from './Components/Quoting/QuoteDashboardComponent/QuoteDashboardComponent.component';
import { QuoteQuestionRowComponent } from './Components/Quoting/QuoteQuestionRowComponent/QuoteQuestionRowComponent';
import { QuoteOptionKitPartRowComponent } from './Components/Quoting/QuoteOptionKitPartRowComponent/QuoteOptionKitPartRowComponent';
import { QuoteOptionsComponent } from './Components/Quoting/QuoteOptionsComponent/QuoteOptionsComponent';
import { QuoteOptionComponent } from './Components/Quoting/QuoteOptionComponent/QuoteOptionComponent';
import { QuoteOptionCreditRowComponent } from './Components/Quoting/QuoteOptionCreditRowComponent/QuoteOptionCreditRowComponent';
import { EditQuoteOptionKitPartSheetComponent } from './Components/Quoting/EditQuoteOptionKitPartSheetComponent/EditQuoteOptionKitPartSheetComponent';
import { QuoteDataSlicerComponent } from './Components/Quoting/QuoteDataSlicerComponent/QuoteDataSlicerComponent';
import { PartsViewComponent } from './Components/Quoting/PartsViewComponent/PartsViewComponent';
import { BidComponent } from './Components/Quoting/BidComponent/BidComponent';
import { BidsViewComponent } from './Components/Quoting/BidsViewComponent/BidsViewComponent';
import { BidViewComponent } from './Components/Quoting/BidViewComponent/BidViewComponent';
import { NameDescriptionEditableCardComponent } from './Components/Quoting/NameDescriptionEditableCardComponent/NameDescriptionEditableCardComponent';
import { PreviewBidPdfViewComponent } from './Components/Quoting/PreviewBidPdfViewComponent/PreviewBidPdfViewComponent';

/* Platform stuff */
import { SidebarComponent } from './Components/Platform/sidebar/sidebar.component';
import { DataTableComponent } from './Components/Platform/data-table/data-table.component';
import { SearchableSelComponent } from './Components/Platform/searchable-sel/searchable-sel.component';
import { SearchableMselComponent } from './Components/Platform/searchable-msel/searchable-msel.component';
import { BuffSearchableSelComponent } from './Components/Platform/buff-searchable-sel/buff-searchable-sel.component';
import { MediaGalleryComponent } from './Components/Platform/media-gallery/media-gallery.component';
import { GrowInputComponent } from './Components/Platform/grow-input/grow-input.component';
import { BreadcrumbComponent } from './Components/Platform/breadcrumb/breadcrumb.component';
import { ConfirmModalComponent } from './Components/Platform/confirm-modal/confirm-modal.component';
import { SuperConfirmModalComponent } from './Components/Platform/super-confirm-modal/super-confirm-modal.component';
import { AlertModalComponent } from './Components/Platform/alert-modal/alert-modal.component';
import { UploadModalComponent } from './Components/Platform/upload-modal/upload-modal.component';
import { BottomSheetComponent } from './Components/Platform/bottom-sheet/bottom-sheet.component';
import { AddressCreationComponent } from './Components/Scheduler/address-creation/address-creation.component';
import { WarehouseTransferHistoryComponent } from './Components/Material/warehouse-transfer-history/warehouse-transfer-history.component';

/* Directives */
import { SwipeableDirective } from './Directives/swipeable/swipeable.directive';
import { LongPressDirective } from './Directives/longPress/longPress.directive';
/*
  Huge pain in the ass to add a package, so we are copy-pasting it instead
  https://github.com/changhuixu/ngx-digit-only/tree/main/projects/uiowa/digit-only
*/
import { DigitOnlyModule } from './Directives/DigitOnly/digit-only.module';
import { ClearDateComponent } from './Components/Scheduler/clear-date/clear-date.component';
import { NewUserComponent } from './Components/Settings/new-user/new-user.component';
import { EditUserComponent } from './Components/Settings/edit-user/edit-user.component';
import { ChangeOrderComponent } from './Components/BuilderAssociates/change-order/change-order.component';
import { ChangeOrderApprovalComponent } from './Components/AreaManagers/ChangeOrderApproval/ChangeOrderApproval.component';
import { PreviewQuotePdfViewComponent } from './Components/Quoting/PreviewQuotePdfViewComponent/PreviewQuotePdfViewComponent';
import { BidCreateAssociationComponent } from './Components/Quoting/BidCreateAssociationComponent/BidCreateAssociationComponent';
import { QuoteKitPartFilterService } from './_services/QuoteKitPartFilterService/QuoteKitPartFilterService';
import {
  SalesOrderLookupComponent,
  SalesOrderDialogComponent,
} from './Components/SalesOrderLookup/sales-order-lookup/sales-order-lookup.component';
import { VarDirective } from './Directives/VarDirective/VarDirective';

import { PhoneNumberPipe } from './Pipes/PhoneNumberPipe';
import { SuperintendentManagerComponent } from './Components/Settings/superintendent-manager/superintendent-manager.component';
import { WorkTicketUpdateComponent } from './Components/Settings/work-ticket-update/work-ticket-update.component';
import { BuilderDashboardComponent } from './Components/BuilderAssociates/builder-dashboard/builder-dashboard.component';

@NgModule({
  declarations: [
    AppComponent,
    SettingsDirective,
    SwipeableDirective,
    LongPressDirective,
    VarDirective,
    ScheduleConfirmationComponent,
    ScheduleConfirmationQueueComponent,
    LoginPasswordlessComponent,
    AddressCustomerAssignmentComponent,
    SchedulingComponent,
    HomePageComponent,
    AMDashboardComponent,
    ScheduleTicketsComponent,
    ScheduleTicketsDialogComponent,
    TechTodoComponent,
    EditWorkticketsComponent,
    ReworkQueueComponent,
    UploadPlansComponent,
    HouseEditorComponent,
    ReceivingCreateComponent,
    ReceivingUpdateComponent,
    PhasePayComponent,
    ReceiptOfGoodsApprovalComponent,
    ReceiptOfInvoiceApprovalComponent,
    qaWorkticketComponent,
    AssignScheduleComponent,
    AssignScheduleTicketItemComponent,
    TemplateEditorComponent,
    QrpartspullComponent,
    QrcrewlabordetailComponent,
    SettingsComponent,
    PunchAssignableTechsComponent,
    EditTechDialog,
    WomsManagementQueueComponent,
    WomsManagementQueueDialogComponent,
    RoiPendingComponent,
    RoiApproveComponent,
    RoiApproveSaveDialogComponent,
    RoiApproveDeleteDialogComponent,
    RoiApproveHeaderDialogComponent,
    RoiApproveApproveDialogComponent,
    SuperReportsComponent,
    House360Component,
    AddressFilesComponent,
    SuperMiscpayComponent,
    SuperPayLineItemsTable,
    SuperMiscpayPunchCreatedTable,
    UnshippedItemsComponent,
    QrscannerComponent,
    PasswordResetComponent,
    SubdivisionSetupComponent,
    AddSubdivisionDialog,
    PlanSetupComponent,
    PlanSetupCreateDialogComponent,
    PlanSetupCreateDialogComponent,
    vpoPunchComponent,
    WomsMgtComponent,
    WomsAcctComponent,
    WomsPriceComponent,
    WarehouseTransferComponent,
    JobRequisitionComponent,
    PullTicketCreationComponent,
    PullTicketHistoryComponent,
    WarehouseTransferTruckComponent,
    PullTicketAdditionalPartsComponent,
    MaterialScheduledComponent,
    MaterialReturnComponent,
    DataTableComponent,
    SearchableSelComponent,
    SearchableMselComponent,
    BuffSearchableSelComponent,
    MediaGalleryComponent,
    AddressCreationComponent,
    WarehouseTransferHistoryComponent,
    GrowInputComponent,
    BreadcrumbComponent,
    ConfirmModalComponent,
    SuperConfirmModalComponent,
    AlertModalComponent,
    UploadModalComponent,
    BottomSheetComponent,
    SidebarComponent,
    QuoteViewComponent,
    KitsViewComponent,
    KitViewComponent,
    KitPartRowComponent,
    QuoteKitPartRowComponent,
    KitPartToAddRowComponent,
    QuoteSidepanelComponent,
    KitSidepanelComponent,
    KOQAppBarComponent,
    QuotesViewComponent,
    QuoteQuestionsEditorViewComponent,
    CreateQuoteQuestionAnswerComponent,
    KitOrPartPickerBotSheetComponent,
    KitOrPartPickerComponent,
    EditQuotePartBotSheetComponent,
    CustomQuoteKitBotSheetComponent,
    CustomQuotePartBotSheetComponent,
    QuotePartBreakdownComponent,
    QuoteExclusionComponent,
    QuoteInclusionComponent,
    QuoteNoteComponent,
    QuoteBillingTermComponent,
    QuoteViewSummaryComponent,
    QuoteAttachmentDialogComponent,
    QuoteDashboardComponentComponent,
    QuoteDataSlicerComponent,
    PartsViewComponent,
    PreviewQuotePdfViewComponent,
    QuoteQuestionRowComponent,
    QuoteOptionKitPartRowComponent,
    QuoteOptionsComponent,
    QuoteOptionComponent,
    QuoteOptionCreditRowComponent,
    EditQuoteOptionKitPartSheetComponent,
    TruckManagerComponent,
    BatchingPaymentsComponent,
    ClearDateComponent,
    NewUserComponent,
    EditUserComponent,
    ChangeOrderComponent,
    ChangeOrderApprovalComponent,
    BidComponent,
    BidsViewComponent,
    BidViewComponent,
    PreviewBidPdfViewComponent,
    NameDescriptionEditableCardComponent,
    BidCreateAssociationComponent,
    SalesOrderLookupComponent,
    SalesOrderDialogComponent,
    CustomersManagerComponent,
    PhoneNumberPipe,
    SuperintendentManagerComponent,
    WorkTicketUpdateComponent,
    BuilderDashboardComponent,
  ],
  entryComponents: [],
  exports: [MatSidenavModule],
  imports: [
    HttpClientModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatListModule,
    MatButtonModule,
    MatBadgeModule,
    MatStepperModule,
    MatIconModule,
    MatCardModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatRadioModule,
    MatGridListModule,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatExpansionModule,
    MatSortModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatMenuModule,
    MatBottomSheetModule,
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TypeaheadModule.forRoot(),
    QRCodeModule,
    CdkAccordionModule,
    ScrollingModule,
    NgxBarcode6Module,
    DigitOnlyModule,
    MatTooltipModule,
    MatTabsModule,
    OverlayModule,
    CommonModule,
  ],
  providers: [
    BsModalRef,
    SageApiService,
    AuthService,
    AlertifyService,
    WasabiApiService,
    QuoteEditableService,
    QuoteKitPartFilterService,
    BaseModalService,
    UploadModalService,
    DatePipe,
    MetabaseService,
    { provide: MatBottomSheetRef, useValue: {} },
    { provide: MAT_BOTTOM_SHEET_DATA, useValue: {} },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
