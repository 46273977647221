import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ScreenSizeService } from 'src/app/_services/ScreenSizeService/ScreenSizeService';
import { SageApiService } from 'src/app/_services/sageApi/sageApi.service';
import QuoteNoteDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/QuoteNoteDtoInterface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmModalComponent } from '../../Platform/confirm-modal/confirm-modal.component';
import QuoteNoteEditableInterface from '../../../_services/QuoteEditableService/interfaces/QuoteNoteEditableInterface';
import { QuoteEditableService } from 'src/app/_services/QuoteEditableService/QuoteEditableService';

@Component({
  selector: 'app-qnote',
  templateUrl: './QuoteNoteComponent.html',
  styleUrls: ['./QuoteNoteComponent.css', '../QuotingTheme.scss'],
})
export class QuoteNoteComponent {
  @Input() note: QuoteNoteEditableInterface;
  saving = false;

  constructor(
    public api: SageApiService,
    public screenSize: ScreenSizeService,
    public snackbar: MatSnackBar,
    public dialog: MatDialog,
    private qe: QuoteEditableService
  ) {}

  disableControls() {
    this.note.nameControl.disable();
    this.note.descControl.disable();
  }

  enableControls() {
    this.note.nameControl.enable();
    this.note.descControl.enable();
  }

  resetControls() {
    this.note.nameControl.setValue(this.note.data.QuoteNote_Name);
    this.note.descControl.setValue(this.note.data.QuoteNote_Desc);
  }

  nameInput(e: InputEvent) {
    const value = (e.target as HTMLInputElement).value;
    if (value.length > 100) {
      const newVal = value.slice(0, 100);
      this.note.nameControl.setValue(newVal);
      (e.target as HTMLInputElement).value = newVal;
    }
  }

  isSaveDisabled() {
    if (this.saving) {
      return true;
    }
    const different =
      this.note.nameControl.value !== this.note.data.QuoteNote_Name ||
      this.note.descControl.value !== this.note.data.QuoteNote_Desc;

    const valid = this.note.nameControl.valid && this.note.descControl.valid;

    return !different || !valid;
  }

  nameBlur() {
    if (this.note.nameControl.value === '') {
      this.note.nameControl.setValue('Untitled');
    }
  }

  saveChanges() {
    this.saving = true;
    this.disableControls();
    this.qe.patchQuoteNote({ quoteNote: this.note }).subscribe(
      () => {
        this.saving = false;
        this.enableControls();
      },
      () => {
        this.saving = false;
        this.enableControls();
      }
    );
  }

  deleteNote() {
    this.saving = true;
    this.disableControls();
    this.qe.destroyQuoteNote({ quoteNote: this.note }).subscribe(
      () => {
        this.saving = false;
        this.enableControls();
      },
      () => {
        this.saving = false;
        this.enableControls();
      }
    );
  }
}
