<app-bottom-sheet
  [open]="open"
  (sheetClosed)="closeAddPartsSheet()"
  [sheetWidth]="screenSize.getClassesArray().includes('smd') ? '100vw' : '75vw'"
  sheetBackground="#fafafa"
  [class]="screenSize.getClasses()"
>
  <div class="korpbotsheet">
    <div class="korpbotsheet-header">
      <h1 class="korpbotsheet-header-title">Add to: {{ getTitle() }}</h1>
      <div class="korpbotsheet-header-controls">
        <mat-form-field
          appearance="outline"
          class="korpbotsheet-header-controls-ff korpbotsheet-header-controls-type"
        >
          <mat-label>Type</mat-label>
          <mat-select [formControl]="addPartsTypeControl">
            <mat-option value="Kit">Kit</mat-option>
            <mat-option value="Part">Part</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field
          appearance="outline"
          class="korpbotsheet-header-controls-ff korpbotsheet-header-controls-name"
        >
          <mat-label>Name</mat-label>
          <input
            (keyup.enter)="searchPartOrKit()"
            [formControl]="addPartsNameControl"
            matInput
            type="text"
            class="korpbotsheet-header-controls-ff-in"
          />
        </mat-form-field>

        <mat-form-field
          *ngIf="addPartsTypeControl.value == 'Kit'"
          appearance="outline"
          class="kitsview-controls-region"
        >
          <mat-label>Region</mat-label>
          <mat-select [formControl]="addPartsRegionControl">
            <mat-option [value]="'All'">All</mat-option>
            <mat-option *ngFor="let reg of kitRegions" [value]="reg.valueOf()"
              >{{ reg }}</mat-option
            >
            <mat-option [value]="'NA'">NA</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-checkbox
          (change)="enableSearchDescription = !enableSearchDescription"
          [checked]="enableSearchDescription"
          class="korpbotsheet-header-controls-pdesc nosmall"
        >
          Search {{ addPartsTypeControl.value }} Descriptions
        </mat-checkbox>
        <button
          (click)="searchPartOrKit()"
          [disabled]="addPartsLoading"
          color="accent"
          mat-flat-button
          class="korpbotsheet-header-controls-search"
        >
          Search
        </button>
      </div>
      <mat-checkbox
        (change)="enableSearchDescription = !enableSearchDescription"
        [checked]="enableSearchDescription"
        class="korpbotsheet-header-controls-pdesc noxsmall"
      >
        Search {{ addPartsTypeControl.value }} Descriptions
      </mat-checkbox>
    </div>

    <div class="korpbotsheet-list">
      <ng-container *ngFor="let item of addPartsList; let itemIndex = index">
        <app-kptarow
          [kitPart]="item.data"
          [quantity]="item.quantity"
          [editable]="true"
          (quantityChange)="addPartsList[itemIndex].quantity = $event"
          (selectedChange)="addPartsList[itemIndex].selected = $event"
          (phaseChange)="addPartsList[itemIndex].phase = $event"
        ></app-kptarow>
      </ng-container>

      <div *ngIf="addPartsLoading">
        <mat-spinner diameter="30"></mat-spinner>
      </div>

      <div *ngIf="addPartsHasMore && !addPartsLoading">
        <button (click)="searchPartOrKit(addPartsPage + 1)" mat-button>
          Load more
        </button>
      </div>

      <div
        *ngIf="addPartsList.length == 0 && !addPartsLoading"
        class="korpbotsheet-list-empty"
      >
        No {{ addPartsTypeControl.value }}s found
      </div>
    </div>

    <div class="korpbotsheet-actions">
      <button
        [disabled]="saving"
        (click)="closeAddPartsSheet()"
        color="warn"
        mat-flat-button
        class="korpbotsheet-actions-cancel"
      >
        Cancel
      </button>
      <button
        [disabled]="getSelectedAddPartsToKit().length == 0 || saving"
        (click)="addSelectedPartsToKit()"
        color="primary"
        mat-flat-button
        class="korpbotsheet-actions-submit"
      >
        Add
      </button>
    </div>
  </div>
</app-bottom-sheet>
