<div [class]="screenSize.getClasses() + ' quoting-theme bidsview'">
  <h1 class="bidsview-title">
    Bids
    <mat-spinner
      *ngIf="loading"
      [diameter]="30"
      class="bidsview-title-spinner"
    ></mat-spinner>
  </h1>
  <div class="bidsview-controls">
    <mat-form-field appearance="outline" class="bidsview-controls-kitsearch">
      <mat-label>Bid</mat-label>
      <input
        (keyup.enter)="searchBids()"
        matInput
        type="text"
        [formControl]="bidControl"
        class="bidsview-controls-kitsearch-in"
      />
    </mat-form-field>
    <mat-form-field appearance="outline" class="bidsview-controls-kitsearch">
      <mat-label>Builder</mat-label>
      <input
        (keyup.enter)="searchBids()"
        matInput
        type="text"
        [formControl]="builderControl"
        class="bidsview-controls-kitsearch-in"
      />
    </mat-form-field>
    <mat-form-field appearance="outline" class="kitsview-controls-region">
      <mat-label>Region</mat-label>
      <mat-select [formControl]="regionControl">
        <mat-option [value]="'All'">All</mat-option>
        <mat-option *ngFor="let reg of regions" [value]="reg.valueOf()">
          {{ reg }}
        </mat-option>
        <mat-option [value]="'NA'">NA</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" class="kitsview-controls-region">
      <mat-label>Status</mat-label>
      <mat-select [formControl]="statusControl">
        <mat-option [value]="'All'">All</mat-option>
        <mat-option *ngFor="let stat of bidStatuses" [value]="stat.valueOf()">
          {{ stat }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button
      (click)="searchBids()"
      [disabled]="loading"
      color="accent"
      mat-flat-button
      class="bidsview-controls-search"
    >
      Search
    </button>
  </div>

  <div class="bidsview-add">
    <button (click)="openNewBidSheet()" mat-fab class="bidsview-add-button">
      <i class="fas fa-plus"></i>
    </button>
  </div>

  <div class="bidsview-list">
    <mat-card
      class="bidsview-list-item"
      *ngFor="let bid of bids; let kIndex = index"
    >
      <div
        class="bidsview-list-item-content"
        [class.bidsview-kit-selected]="bid.selected"
      >
        <div class="bidsview-list-item-content-header">
          <span class="bidsview-list-item-content-title">
            {{ bid.Bid.Bid_Name }}
            <sup class="bidsview-list-item-content-status">
              {{ bid.Bid.Bid_Status }}
            </sup>
            <span class="bidsview-list-item-content-builder">
              {{ bid.Bid.Bid_Builder }}
            </span>
          </span>
        </div>
        <div class="bidsview-list-item-content-description">
          {{ bid.Bid.Bid_Desc }}
        </div>
      </div>
      <div class="bidsview-list-item-controls">
        <a [routerLink]="'/quoting/bid/' + bid.Bid.Bid_guid">
          <button
            (contextmenu)="menuTrigger.openMenu(); $event.preventDefault()"
            color="primary"
            mat-mini-fab
            class="bidsview-list-item-controls-view"
          >
            <i class="fas fa-cog"></i>
            <button
              mat-icon-button
              #menuTrigger="matMenuTrigger"
              [matMenuTriggerFor]="menu"
              [style]="{ width: '0px', height: '0px' }"
            ></button>
            <mat-menu #menu="matMenu">
              <button
                [routerLink]="'/quoting/bid/' + bid.Bid.Bid_guid"
                mat-menu-item
              >
                Edit Bid
              </button>
              <button (click)="copyBid(bid)" mat-menu-item>Copy Bid</button>
            </mat-menu>
          </button>
        </a>
        <button
          (click)="deleteBid(bid, kIndex)"
          color="accent"
          mat-mini-fab
          class="bidsview-list-item-controls-delete"
        >
          <span class="bidsview-list-item-controls-delete-icon">&times;</span>
        </button>
      </div>
    </mat-card>
  </div>

  <div *ngIf="!loading && bidPageHasMore" class="bidsview-loadmore">
    <button (click)="searchBids(bidPage + 1)" mat-button>Load more</button>
  </div>

  <div *ngIf="loading && bidPageHasMore" class="bidsview-loading">
    <mat-spinner [diameter]="30"></mat-spinner>
  </div>

  <div
    *ngIf="
      hasSearched &&
      !loading &&
      ((bidPage > 0 && !bidPageHasMore) || (bidPage == 0 && bids.length == 0))
    "
    class="bidsview-empty"
  >
    <span>No Bids found</span>
  </div>

  <app-bottom-sheet
    [open]="newBidOpen"
    (sheetClosed)="newBidOpen = false"
    [sheetWidth]="
      screenSize.getClassesArray().includes('smd') ? '100vw' : '75vw'
    "
    class="bidsview-create"
  >
    <h1>
      Create Bid
      <mat-spinner
        *ngIf="newBidLoading"
        [diameter]="30"
        class="bidsview-create-spinner"
      ></mat-spinner>
    </h1>
    <div class="bidsview-create-controls">
      <mat-form-field appearance="outline" class="bidsview-create-ff">
        <mat-label>Name</mat-label>
        <input
          (keyup.enter)="createBid()"
          [formControl]="newBidNameControl"
          [disabled]="newBidLoading"
          matInput
          type="text"
          class="bidsview-create-ff-in"
        />
      </mat-form-field>
      <mat-form-field appearance="outline" class="bidsview-create-ff">
        <mat-label>Builder</mat-label>
        <input
          [formControl]="newBidBuilderControl"
          [disabled]="newBidLoading"
          matInput
          type="text"
          class="bidsview-create-ff-in"
        />
      </mat-form-field>
      <mat-form-field appearance="outline" class="bidsview-create-ff">
        <mat-label>Region</mat-label>
        <mat-select
          [formControl]="newBidRegionControl"
          [disabled]="newBidLoading"
        >
          <mat-option *ngFor="let reg of regions" [value]="reg.valueOf()">
            {{ reg }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="bidsview-create-ff">
        <mat-label>Status</mat-label>
        <mat-select
          [formControl]="newBidStatusControl"
          [disabled]="newBidLoading"
        >
          <mat-option
            *ngFor="let status of bidStatuses"
            [value]="status.valueOf()"
          >
            {{ status }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <mat-form-field
      appearance="outline"
      class="bidsview-create-ff bidsview-create-desc"
    >
      <mat-label>Description</mat-label>
      <textarea
        [formControl]="newBidDescControl"
        [disabled]="newBidLoading"
        matInput
        type="text"
        class="bidsview-create-ff-in"
      ></textarea>
    </mat-form-field>

    <span *ngIf="newBidErrorMsg != null" class="bidsview-create-error">
      *{{ newBidErrorMsg }}
    </span>
    <div class="bidsview-create-actions">
      <button
        (click)="createBid()"
        [disabled]="!newBidValid()"
        color="primary"
        mat-flat-button
        class="bidsview-create-actions-submit"
      >
        Create Bid
      </button>
    </div>
  </app-bottom-sheet>
</div>
