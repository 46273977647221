<div class="addressbar">
  <input
    class="form-control"
    [(ngModel)]="addressName"
    (typeaheadOnSelect)="addressSelected($event)"
    [typeahead]="addressList"
    typeaheadOptionField="name"
    placeholder="Address List"
    autocomplete="off"
  />
  <small *ngIf="houseLoading">Getting {{ addressName }} Info...</small>
  <button
    class="searchButton"
    mat-button
    mat-flat-button
    color="accent"
    [disabled]="loading || addressName == ''"
    (click)="loadIt(addressName)"
  >
    Search
  </button>
</div>
<div [hidden]="!loading" class="spinner">
  <mat-spinner *ngIf="loading"></mat-spinner>
</div>
<div [hidden]="loading || addressName == ''" class="tables">
  <div>
    <h1>WOMS Status</h1>
    <table
      #womsTable
      mat-table
      [dataSource]="WOMSworkticket"
      matSort
      #womsSort="matSort"
    >
      <ng-container matColumnDef="SalesOrderNo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Sales Order</th>
        <td mat-cell *matCellDef="let row">{{ row.SalesOrderNo }}</td>
      </ng-container>
      <ng-container matColumnDef="Parent">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Parent</th>
        <td mat-cell *matCellDef="let row">{{ row.Parent }}</td>
      </ng-container>
      <ng-container matColumnDef="WOMSStatus">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>WOMS Status</th>
        <td mat-cell *matCellDef="let row">{{ row.WOMSStatus }}</td>
      </ng-container>
      <ng-container matColumnDef="WTNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>WTNumber</th>
        <td mat-cell *matCellDef="let row">{{ row.WTNumber }}</td>
      </ng-container>
      <ng-container matColumnDef="WomsType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>WOMS Type</th>
        <td mat-cell *matCellDef="let row">{{ row.WomsType }}</td>
      </ng-container>
      <ng-container matColumnDef="Action">
        <th mat-header-cell *matHeaderCellDef>Action</th>
        <td mat-cell *matCellDef="let row">
          <button
            matButton
            mat-flat-button
            color="accent"
            (click)="openModal(womUpdate); editWomsStatus(row)"
          >
            <mat-icon>edit</mat-icon> WOMS
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="WomsColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: WomsColumns"></tr>
    </table>
    <!-- <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator> -->
  </div>
  <div>
    <h1>Work Ticket Status</h1>
    <table
      mat-table
      [dataSource]="Statusworkticket"
      matSort
      #statusSort="matSort"
    >
      <ng-container matColumnDef="SalesOrderNo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Sales Order</th>
        <td mat-cell *matCellDef="let row">{{ row.SalesOrderNo }}</td>
      </ng-container>
      <ng-container matColumnDef="Parent">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Parent</th>
        <td mat-cell *matCellDef="let row">{{ row.Parent }}</td>
      </ng-container>
      <ng-container matColumnDef="StatusCode">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status Code</th>
        <td mat-cell *matCellDef="let row">{{ row.StatusCode }}</td>
      </ng-container>
      <ng-container matColumnDef="StatusDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status Date</th>
        <td mat-cell *matCellDef="let row">
          {{ row.StatusDate | date: 'MM/dd/yyyy' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="WTNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>WTNumber</th>
        <td mat-cell *matCellDef="let row">{{ row.WTNumber }}</td>
      </ng-container>
      <ng-container matColumnDef="Action">
        <th mat-header-cell *matHeaderCellDef>Action</th>
        <td mat-cell *matCellDef="let row">
          <button
            mat-button
            mat-flat-button
            color="accent"
            (click)="openModal(StatusUpdate); editStatus(row)"
          >
            <mat-icon>edit</mat-icon> Status
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="StatusColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: StatusColumns"></tr>
    </table>
  </div>
</div>

<ng-template #womUpdate>
  <div id="womUpdate">
    <h1 class="text-center">
      Editing {{ womsUpdate.SalesOrderNo }}-{{ womsUpdate.WTNumber }} WOMS
    </h1>

    <div class="womsStatus">
      <mat-form-field appearance="outline">
        <mat-label>Current WOMS Status</mat-label>
        <input matInput disabled [value]="womsUpdate.WOMSStatus" />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Update WOMS Status:</mat-label>
        <mat-select [(ngModel)]="newWomsStatus">
          <mat-option
            *ngFor="let womsOpt of WomsStatuses"
            [value]="womsOpt.statusCode"
          >
            {{ womsOpt.fullDesc }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="buttonActions">
      <button mat-button mat-flat-button color="primary" (click)="closeModal()">
        Close
      </button>
      <button
        [disabled]="newWomsStatus == ''"
        mat-button
        mat-flat-button
        color="success"
        (click)="updateWoms()"
      >
        Update WOMS status
      </button>
    </div>
  </div>
</ng-template>

<ng-template #StatusUpdate>
  <div id="StatusUpdate">
    <h1 class="text-center">
      Editing {{ workticketUpdate.SalesOrderNo }}-{{
        workticketUpdate.WTNumber
      }}
      Status
    </h1>

    <div class="womsStatus">
      <mat-form-field appearance="outline">
        <mat-label>Current Work Ticket Status</mat-label>
        <input matInput disabled [value]="workticketUpdate.StatusCode" />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Update Work Ticket Status:</mat-label>
        <mat-select [(ngModel)]="newWorkTicketStatus">
          <mat-option
            *ngFor="let statusOpt of StatusCodes"
            [value]="statusOpt.statusCode"
          >
            {{ statusOpt.fullDesc }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="buttonActions">
      <button mat-button mat-flat-button color="primary" (click)="closeModal()">
        Close
      </button>
      <button
        [disabled]="newWorkTicketStatus == ''"
        mat-button
        mat-flat-button
        color="success"
        (click)="updateStatus()"
      >
        Update Status
      </button>
    </div>
  </div>
</ng-template>
