import { Component, OnInit, TemplateRef } from '@angular/core';
import { concat } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { SageApiService } from '../../../_services/sageApi/sageApi.service';
import { AuthService } from '../../../_services/auth/auth.service';
import { AlertifyService } from '../../../_services/alertify/alertify.service';

@Component({
  selector: 'app-schedule-confirmation',
  templateUrl: './schedule-confirmation.component.html',
  styleUrls: ['./schedule-confirmation.component.css'],
})
export class ScheduleConfirmationComponent implements OnInit {
  bsConfig: Partial<BsDatepickerConfig>;
  editConfirmation: BsModalRef;
  editDateKey = '';
  editSubdivisionKey = '';
  editRescheduleDate = '';
  editLeadTimeIndex = 0;
  editTicketIndex = 0;
  editTermsIndex = 0;
  editSlideInitial = true;
  editSlideTerms = false;
  editSlideReschedule = false;

  emailAddress = '';
  currentHour = 0;
  nextDay;

  phaseLeadTimes = [];

  phaseTerms = [];

  scheduleConfirmations = {};

  constructor(
    private sageApi: SageApiService,
    private auth: AuthService,
    private alertify: AlertifyService,
    private modalService: BsModalService
  ) {}

  ngOnInit() {
    this.bsConfig = Object.assign({}, { containerClass: 'theme-red' });
    this.emailAddress = this.auth.decodedToken?.nameid;

    const today = new Date();
    this.nextDay =
      today.getDay() === 5
        ? today.setDate(today.getDate() + 3)
        : today.getDay() === 6
        ? today.setDate(today.getDate() + 2)
        : today.setDate(today.getDate() + 1);
    this.nextDay = new Date(
      this.nextDay - today.getTimezoneOffset() * 60000
    ).toISOString();
    this.currentHour = today.getHours();

    this.loadConfirmations();
    this.loadTerms();
    this.loadLeadTimes();
  }

  openModal(
    template: TemplateRef<any>,
    dateKey: string,
    subdivisionKey: string,
    ticketIndex: number
  ) {
    const today = new Date();
    this.currentHour = today.getHours();

    if (
      this.nextDay.slice(0, 10) === dateKey.slice(0, 10) &&
      this.currentHour >= 15
    ) {
    } else {
      this.editSlide('initial');
      this.editTermsIndex = this.phaseTerms.findIndex(
        p =>
          p.phase.toLowerCase() ===
          this.scheduleConfirmations[dateKey].subdivisions[subdivisionKey][
            ticketIndex
          ].phase.toLowerCase()
      );
      this.editLeadTimeIndex = this.phaseLeadTimes.findIndex(
        p =>
          p.name.toLowerCase() ===
          this.scheduleConfirmations[dateKey].subdivisions[subdivisionKey][
            ticketIndex
          ].phase.toLowerCase()
      );
      this.editDateKey = dateKey;
      this.editSubdivisionKey = subdivisionKey;
      this.editTicketIndex = ticketIndex;
      this.editRescheduleDate = '';

      this.editConfirmation = this.modalService.show(template);
    }
  }

  closeModal() {
    this.editConfirmation.hide();
  }

  editSlide(chosenSlide: string) {
    switch (chosenSlide) {
      case 'initial':
        this.editSlideInitial = true;
        this.editSlideReschedule = false;
        this.editSlideTerms = false;
        break;

      case 'terms':
        this.editSlideInitial = false;
        this.editSlideReschedule = false;
        this.editSlideTerms = true;
        break;

      case 'reschedule':
        this.editSlideInitial = false;
        this.editSlideReschedule = true;
        this.editSlideTerms = false;
        break;
    }
  }

  showTickets(dateKey: string) {
    this.scheduleConfirmations[dateKey].showTickets = !this
      .scheduleConfirmations[dateKey].showTickets;
  }

  showTicketNotes() {
    this.scheduleConfirmations[this.editDateKey].subdivisions[
      this.editSubdivisionKey
    ][this.editTicketIndex].showNotes = !this.scheduleConfirmations[
      this.editDateKey
    ].subdivisions[this.editSubdivisionKey][this.editTicketIndex].showNotes;
  }

  loadTerms() {
    this.sageApi
      .pullReport('ScheduleConfirmation/terms')
      .subscribe((terms: Array<any>) => {
        if (Array.isArray(terms)) {
          terms.forEach(term => {
            const obj = {
              name: term.TermName,
              description: term.Description,
              phase: term.PhaseName.toLocaleLowerCase(),
            };
            if (
              this.phaseTerms.filter(phaseTerm => phaseTerm.phase === obj.phase)
                .length === 0
            ) {
              this.phaseTerms.push({
                phase: obj.phase,
                terms: [obj],
              });
            } else {
              const phaseIndex = this.phaseTerms.findIndex(
                phase => phase.phase === obj.phase
              );
              this.phaseTerms[phaseIndex].terms.push(obj);
            }
          });
        }
      });
  }

  loadLeadTimes() {
    this.sageApi.pullReport('ScheduleConfirmation/LeadTime').subscribe(
      (leadTimes: Array<any>) => {
        if (Array.isArray(leadTimes)) {
          leadTimes.forEach(leadTime => {
            const obj = {
              id: leadTime.id,
              name: leadTime.PhaseName,
              leadTime: leadTime.LeadTime,
            };

            this.phaseLeadTimes.push(obj);
          });
        }
      },
      err => {
        console.log(err);
        this.alertify.error(err.error.message);
      },
      () => {
        // ToDo: Something
      }
    );
  }

  loadConfirmations() {
    const today = new Date();
    const todayISO = new Date(
      today.getTime() - today.getTimezoneOffset() * 60000
    ).toISOString();

    this.sageApi
      .pullReport('ScheduleConfirmation?email=' + this.emailAddress)
      .subscribe(
        (tickets: Array<any>) => {
          if (Array.isArray(tickets)) {
            tickets.forEach(ticket => {
              const obj = {
                phase: ticket.Phase.toLocaleLowerCase(),
                scheduleDate: ticket.ScheduleDate,
                status: ticket.Status,
                statusDate: ticket.StatusDate,
                statusTime: ticket.StatusTime,
                address: ticket.Address,
                subdivision: ticket.Subdivision,
                salesorderno: ticket.SalesOrderNo,
                wtnumber: ticket.WTNumber,
                wtstep: ticket.WTStep,
                updated: ticket.StatusDate
                  ? ticket.StatusDate.slice(0, 10) === todayISO.slice(0, 10)
                    ? true
                    : false
                  : false,
                notes: ticket.Notes,
                showNotes: false,
                display: true,
              };
              if (
                !this.scheduleConfirmations.hasOwnProperty(obj.scheduleDate)
              ) {
                this.scheduleConfirmations[obj.scheduleDate] = {
                  showTickets: ticket.DisplayTickets,
                  subdivisions: {
                    [obj.subdivision]: [obj],
                  },
                };
              } else if (
                !this.scheduleConfirmations[
                  obj.scheduleDate
                ].subdivisions.hasOwnProperty(obj.subdivision)
              ) {
                this.scheduleConfirmations[obj.scheduleDate].subdivisions[
                  obj.subdivision
                ] = [obj];
              } else {
                this.scheduleConfirmations[obj.scheduleDate].subdivisions[
                  obj.subdivision
                ].push(obj);
              }
            });
          } else {
            // ToDo: Something
          }
        },
        err => {
          console.log('error', err);
        },
        () => {
          // ToDo: Something
        }
      );
  }

  updateConfirmation(statusSubmitted: string) {
    const observables = [];
    const confirmation = {
      salesorderno: this.scheduleConfirmations[this.editDateKey].subdivisions[
        this.editSubdivisionKey
      ][this.editTicketIndex].salesorderno,
      wtnumber: this.scheduleConfirmations[this.editDateKey].subdivisions[
        this.editSubdivisionKey
      ][this.editTicketIndex].wtnumber,
      wtstep: this.scheduleConfirmations[this.editDateKey].subdivisions[
        this.editSubdivisionKey
      ][this.editTicketIndex].wtstep,
      notes: this.scheduleConfirmations[this.editDateKey].subdivisions[
        this.editSubdivisionKey
      ][this.editTicketIndex].notes,
      status: statusSubmitted.toLocaleLowerCase(),
      rescheduleDate:
        this.editRescheduleDate === ''
          ? '1753-01-01T00:00:00'
          : this.editRescheduleDate,
      user: this.auth.decodedToken?.nameid,
    };

    observables.push(this.sageApi.scheduleConfirmationSubmittal(confirmation));

    concat(...observables).subscribe(
      response => {
        // ToDo: Something
      },
      err => {
        console.log(err.message);
        this.alertify.error('Error ' + err.message);
        this.editConfirmation.hide();
      },
      () => {
        this.scheduleConfirmations[this.editDateKey].subdivisions[
          this.editSubdivisionKey
        ][this.editTicketIndex].updated = true;
        this.scheduleConfirmations[this.editDateKey].subdivisions[
          this.editSubdivisionKey
        ][this.editTicketIndex].status = statusSubmitted;
        this.alertify.success('Schedule Confirmation Sucessful');
        this.editConfirmation.hide();
      }
    );
  }
}
