import { Component, OnInit, AfterContentChecked, Inject } from '@angular/core';
import { concat } from 'rxjs';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { AlertifyService } from '../../../_services/alertify/alertify.service';
import { SageApiService } from '../../../_services/sageApi/sageApi.service';
import { AuthService } from '../../../_services/auth/auth.service';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ReworkQueueComponent } from '../../Scheduler/rework-queue/rework-queue.component';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-scheduleTickets',
  templateUrl: './scheduleTickets.component.html',
  styleUrls: ['./scheduleTickets.component.css'],
})
export class ScheduleTicketsComponent implements OnInit, AfterContentChecked {
  loadingTechs: 'loading' | 'loaded' | 'waiting' | 'error' = 'waiting';
  loadingTickets: 'loading' | 'loaded' | 'waiting' | 'error' = 'waiting';

  superSearchCtrl: FormControl = new FormControl('');
  techHide: string[] = [];

  today = new Date();
  supersList = [
    {
      name: '',
      techCode: '',
      techName: '',
      selected: false,
      ticketsDisplayed: 0,
      tickets: {
        unsorted: {
          punch: [],
          phase: [],
        },
      },
    },
  ];
  statusesList = [];
  statusesSelected = [];

  phasesList = [];
  phasesSelected = [];

  firstOfCurrentMonth = new Date(
    this.today.getFullYear(),
    this.today.getMonth(),
    1
  );
  firstOfCalendar;
  calendar = [
    {
      month: 'January',
      days: 31,
    },
    {
      month: 'February',
      days:
        Number(this.today.getFullYear().toString().substr(2)) % 4 === 0
          ? 29
          : 28,
    },
    {
      month: 'March',
      days: 31,
    },
    {
      month: 'April',
      days: 30,
    },
    {
      month: 'May',
      days: 31,
    },
    {
      month: 'June',
      days: 30,
    },
    {
      month: 'July',
      days: 31,
    },
    {
      month: 'August',
      days: 31,
    },
    {
      month: 'September',
      days: 30,
    },
    {
      month: 'October',
      days: 31,
    },
    {
      month: 'November',
      days: 30,
    },
    {
      month: 'December',
      days: 31,
    },
  ];

  colOneDate: string;
  colTwoDate: string;
  colThreeDate: string;
  colOneTotalTime: string;
  colTwoTotalTime: string;
  colThreeTotalTime: string;
  currentSuper: string;
  currentSuperIndex = 0;
  currentSuperTech: string;

  constructor(
    private alertify: AlertifyService,
    private sageApi: SageApiService,
    public authService: AuthService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.currentSuper = this.authService.decodedToken.Super;
    this.currentSuperTech = this.authService.decodedToken.TechCode;
    this.getPunchUserList();
  }

  ngAfterContentChecked() {
    if (
      this.supersList[this.currentSuperIndex]?.tickets[this.colOneDate] !=
        undefined &&
      this.supersList[this.currentSuperIndex]?.tickets[this.colOneDate]?.punch
        ?.length > 0
    ) {
      const tmpCol = this.supersList[this.currentSuperIndex].tickets[
        this.colOneDate
      ].punch.reduce((total: number, ticket) => {
        return total + ticket.punchTimeUnformatted;
      }, 0);
      this.colOneTotalTime =
        tmpCol > 60 ? tmpCol / 60 + ' hrs' : tmpCol + ' mins';
    }
    if (
      this.supersList[this.currentSuperIndex]?.tickets[this.colTwoDate] !=
        undefined &&
      this.supersList[this.currentSuperIndex]?.tickets[this.colTwoDate]?.punch
        .length > 0
    ) {
      const tmpCol = this.supersList[this.currentSuperIndex].tickets[
        this.colTwoDate
      ].punch.reduce((total: number, ticket) => {
        return total + ticket.punchTimeUnformatted;
      }, 0);
      this.colTwoTotalTime =
        tmpCol > 60 ? tmpCol / 60 + ' hrs' : tmpCol + ' mins';
    }
    if (
      this.supersList[this.currentSuperIndex]?.tickets[this.colThreeDate] !=
        undefined &&
      this.supersList[this.currentSuperIndex]?.tickets[this.colThreeDate]?.punch
        .length > 0
    ) {
      const tmpCol = this.supersList[this.currentSuperIndex].tickets[
        this.colThreeDate
      ].punch.reduce((total: number, ticket) => {
        return total + ticket.punchTimeUnformatted;
      }, 0);
      this.colThreeTotalTime =
        tmpCol > 60 ? tmpCol / 60 + ' hrs' : tmpCol + ' mins';
    }
  }

  calculateNextDays(direction: string) {
    let businessDays = 3;
    const colDate = new Date(this.colOneDate);
    const today = new Date();
    const startDate = !this.colOneDate
      ? new Date()
      : new Date(colDate.getTime() + today.getTimezoneOffset() * 60000);
    let counter: number;
    if (direction === 'forward') {
      counter = 1; // set to 1 to count from next business day
    } else if (direction === 'backwards') {
      counter = -1;
    } else {
      return;
    }

    while (businessDays >= 0) {
      const utcDate = new Date();
      const tmp = new Date(
        utcDate.getTime() - utcDate.getTimezoneOffset() * 60000
      );
      tmp.setFullYear(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate() + counter++
      );

      const tmpYear = tmp.getFullYear().toString();
      const tmpMonth = ('0' + (tmp.getMonth() + 1).toString()).slice(-2);
      const tmpDay = ('0' + tmp.getDate().toString()).slice(-2);
      const checkDate = tmpYear + '-' + tmpMonth + '-' + tmpDay + 'T00:00:00';
      switch (tmp.getDay()) {
        // case 0: case 6: // sunday & saturday
        default:
          if (businessDays === 3) {
            if (
              !this.supersList[this.currentSuperIndex].tickets.hasOwnProperty(
                checkDate
              )
            ) {
              this.supersList[this.currentSuperIndex].tickets[checkDate] = {
                punch: [],
                phase: [],
              };
            }
            this.colOneDate = checkDate;
          } else if (businessDays === 2) {
            if (
              !this.supersList[this.currentSuperIndex].tickets.hasOwnProperty(
                checkDate
              )
            ) {
              this.supersList[this.currentSuperIndex].tickets[checkDate] = {
                punch: [],
                phase: [],
              };
            }
            this.colTwoDate = checkDate;
          } else if (businessDays === 1) {
            if (
              !this.supersList[this.currentSuperIndex].tickets.hasOwnProperty(
                checkDate
              )
            ) {
              this.supersList[this.currentSuperIndex].tickets[checkDate] = {
                punch: [],
                phase: [],
              };
            }
            this.colThreeDate = checkDate;
          }
          businessDays--;
      }
    }
  }

  drop(event: CdkDragDrop<string[]>, colDate: string, superListIndex: number) {
    const now = new Date();
    const secondsSinceEpoch = Math.round(now.getTime() / 1000);
    if (event.previousContainer === event.container) {
      if (event.previousIndex === event.currentIndex) {
        return;
      }
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      if (colDate === 'unsorted') {
        return;
      }
      const arrObj = [];
      this.supersList[superListIndex].tickets[colDate].punch.forEach(
        (ticket, index) => {
          const object = {
            salesOrderNo: ticket.soNum,
            wtNumber: ticket.wtNum,
            wtStep: ticket.wtStepNum,
            newScheduleDate:
              colDate === 'unsorted' ? '1753-01-01T00:00:00' : colDate,
            Order: index,
            OrderTime: secondsSinceEpoch.toString(),
            updatedBy: this.authService.decodedToken.nameid,
          };
          arrObj.push(object);
        }
      );
      this.onScheduleTicket(arrObj, colDate, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      this.supersList[superListIndex].tickets[colDate].punch[
        event.currentIndex
      ].superScheduleDate =
        colDate === 'unsorted' ? '1753-01-01T00:00:00' : colDate;
      this.supersList[superListIndex].tickets[colDate].punch[
        event.currentIndex
      ].scheduleGroup = colDate === null ? 'unsorted' : colDate;

      const arrObj = [];
      if (colDate === 'unsorted') {
        const object = {
          salesOrderNo: this.supersList[superListIndex].tickets[colDate].punch[
            event.currentIndex
          ].soNum,
          wtNumber: this.supersList[superListIndex].tickets[colDate].punch[
            event.currentIndex
          ].wtNum,
          wtStep: this.supersList[superListIndex].tickets[colDate].punch[
            event.currentIndex
          ].wtStepNum,
          newScheduleDate:
            colDate === 'unsorted' ? '1753-01-01T00:00:00' : colDate,
          Order: event.currentIndex,
          OrderTime: secondsSinceEpoch.toString(),
          updatedBy: this.authService.decodedToken.nameid,
        };
        arrObj.push(object);
      } else {
        this.supersList[superListIndex].tickets[colDate].punch.forEach(
          (ticket, index) => {
            const object = {
              salesOrderNo: ticket.soNum,
              wtNumber: ticket.wtNum,
              wtStep: ticket.wtStepNum,
              newScheduleDate:
                colDate === 'unsorted' ? '1753-01-01T00:00:00' : colDate,
              Order: index,
              OrderTime: secondsSinceEpoch.toString(),
              updatedBy: this.authService.decodedToken.nameid,
            };
            arrObj.push(object);
          }
        );
      }
      this.onScheduleTicket(arrObj, colDate, event.currentIndex);
    }
  }

  sortTickets(superListIndex: number, colDate: string, keyToSortBy: any) {
    const arr = this.supersList[superListIndex].tickets[colDate].punch;
    arr.sort((a, b) => {
      let ascDirection = true; // sort numbers in ascending
      let ItemOne = a[keyToSortBy];
      let ItemTwo = b[keyToSortBy];

      if (typeof a[keyToSortBy] === 'string') {
        ItemOne = a[keyToSortBy].toUpperCase(); // ignore upper and lowercase
        ItemTwo = b[keyToSortBy].toUpperCase(); // ignore upper and lowercase
        ascDirection = false; // sort epoch descendingly
      }

      if (ItemOne < ItemTwo) {
        return ascDirection ? -1 : 1;
      }
      if (ItemOne > ItemTwo) {
        return ascDirection ? 1 : -1;
      }
      // items must be equal
      return 0;
    });
  }

  onEditPunchTicket(
    currentSuperIndex: number,
    currentDateColumn: string,
    currentTicketIndex: number
  ): void {
    const origTech = this.supersList[currentSuperIndex].tickets[
      currentDateColumn
    ].punch[currentTicketIndex].techCode;
    const dialogRef = this.dialog.open(ScheduleTicketsDialogComponent, {
      data: {
        ticket: this.supersList[currentSuperIndex].tickets[currentDateColumn]
          .punch[currentTicketIndex],
        newDescription: this.supersList[currentSuperIndex].tickets[
          currentDateColumn
        ].punch[currentTicketIndex].description,
        newTechnician: this.supersList[currentSuperIndex].techCode,
        technicianList: this.supersList,
      },
      width: '100%',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (
        origTech.toLowerCase() !==
        this.supersList[currentSuperIndex].tickets[currentDateColumn].punch[
          currentTicketIndex
        ].techCode.toLowerCase()
      ) {
        this.supersList[currentSuperIndex].tickets[
          currentDateColumn
        ].punch.splice(currentTicketIndex, 1);
      }
    });
  }

  onSuperSelected(code: string, superListIndex): void {
    if (!this.supersList[superListIndex].selected) {
      this.supersList.forEach(supers => {
        if (supers.techCode !== code) {
          supers.selected = false;
        }
      });
      this.currentSuperIndex = superListIndex;
      this.currentSuper = this.supersList[superListIndex].name;
      this.getSupersTickets(code, superListIndex);
      this.supersList[superListIndex].selected = true;
    }
  }

  getPunchUserList(): void {
    this.loadingTechs = 'loading';
    this.sageApi
      .pullReport('TechTodo?currentStatus=AVAIL&assignablePunch=Y')
      .subscribe(
        (techs: Array<any>) => {
          if (Array.isArray(techs)) {
            techs.forEach(tech => {
              this.supersList.push({
                name: tech.AssignedSuper,
                techCode: tech.TechCode,
                techName: tech.TechName,
                selected: false,
                ticketsDisplayed: 0,
                tickets: {
                  unsorted: {
                    punch: [],
                    phase: [],
                  },
                },
              });
            });
          }
          this.loadingTechs = 'loaded';
        },
        err => {
          console.log(err);
          this.loadingTechs = 'error';
        },
        () => {
          this.loadInitTech();
        }
      );
  }

  loadInitTech(): void {
    this.supersList.some((superName, superIndex) => {
      if (
        superName.techCode === this.currentSuperTech &&
        superName.name != ''
      ) {
        this.currentSuperIndex = superIndex;
        this.currentSuperTech = this.supersList[superIndex].techCode;
        this.supersList[superIndex].selected = true;
        this.getSupersTickets(this.currentSuperTech, this.currentSuperIndex);
      }
    });
  }

  getSupersTickets(code: string, superListIndex: number): void {
    this.loadingTickets = 'loading';
    this.calculateNextDays('forward');
    this.supersList[superListIndex].tickets = {
      unsorted: {
        punch: [],
        phase: [],
      },
    };
    this.supersList[superListIndex].tickets[this.colOneDate] = {
      punch: [],
      phase: [],
    };
    this.supersList[superListIndex].tickets[this.colTwoDate] = {
      punch: [],
      phase: [],
    };
    this.supersList[superListIndex].tickets[this.colThreeDate] = {
      punch: [],
      phase: [],
    };
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    // Pull the Punch Items
    this.sageApi.pullReport('TechTodo?techcode=' + code).subscribe(
      (list: Array<any>) => {
        if (Array.isArray(list)) {
          list.forEach(item => {
            const obj = {
              superName: item.AssignedLeadman,
              soNum: item.SalesOrderNo,
              wtNum: item.WTNumber,
              wtStepNum: item.WTStep,
              lineKey: item.LineKey,
              address: item.ShipToName,
              subdivision: item.UDF_SUBDIVISION,
              techName: item.techName,
              techCode: item.UDF_TECHNICIAN_CODE,
              trackingComment: item.UDF_TRACKING_COMMENT,
              phase: item.HdrParentItemCode.slice(1),
              status: item.StatusCode,
              description: item.ItemCodeDesc,
              punchTime:
                item.UDF_PUNCH_TIME > 60
                  ? item.UDF_PUNCH_TIME / 60 + ' hrs'
                  : item.UDF_PUNCH_TIME + ' mins',
              punchTimeUnformatted: item.UDF_PUNCH_TIME,
              superOrder: item.UDF_GS_ORDER_OF_IMPORTANCE,
              superOrderEpoch: item.UDF_GS_ORDER_EPOCH,
              superScheduleDate: item.UDF_GS_SCHEDULE_DATE,
              scheduleGroup:
                new Date(item.UDF_GS_SCHEDULE_DATE) <= yesterday &&
                item.status !== 'Completed'
                  ? 'unsorted'
                  : item.UDF_GS_SCHEDULE_DATE,
              dip: item.DIP,
              display: false,
              disabled: false,
              updated: false,
              errorMessage: '',
            };
            if (
              !this.supersList[superListIndex].tickets.hasOwnProperty(
                obj.scheduleGroup
              )
            ) {
              this.supersList[superListIndex].tickets[obj.scheduleGroup] = {
                punch: [obj],
                phase: [],
              };
            } else {
              this.supersList[superListIndex].tickets[
                obj.scheduleGroup
              ].punch.push(obj);
            }

            if (!this.statusesList.some(status => status.name === obj.status)) {
              this.statusesList.push({ name: obj.status, selected: false });
            }
            if (!this.phasesList.some(status => status.name === obj.phase)) {
              this.phasesList.push({ name: obj.phase, selected: false });
            }
          });
        } else {
          // will be a single object
        }
        this.loadingTickets = 'loaded';
      },
      err => {
        console.log('error', err);
        this.loadingTickets = 'error';
      },
      () => {
        Object.keys(this.supersList[superListIndex].tickets).forEach(
          (key: string) => {
            if (
              this.supersList[superListIndex].tickets[key].punch.length > 0 &&
              key !== 'unsorted'
            ) {
              this.sortTickets(superListIndex, key, 'superOrderEpoch');
              this.sortTickets(superListIndex, key, 'superOrder');
            }
          }
        );
      }
    );
  }

  onScheduleTicket(
    arrayObject,
    colDate: string,
    newDateContainerIndex: number
  ): void {
    const observables = [];
    arrayObject.forEach((object, index) => {
      this.supersList[this.currentSuperIndex].tickets[colDate].punch[
        index
      ].disabled = true;
      this.supersList[this.currentSuperIndex].tickets[colDate].punch[
        index
      ].updated = false;
      observables.push(this.sageApi.scheduleTicketSuper(object));
    });

    concat(...observables).subscribe(
      response => {
        // ToDo: Something
      },
      err => {
        console.log(err);
        arrayObject.forEach((object, index) => {
          this.supersList[this.currentSuperIndex].tickets[colDate].punch[
            index
          ].updated = true;
          this.supersList[this.currentSuperIndex].tickets[colDate].punch[
            index
          ].errorMessage = err.error.Message;
        });
        this.alertify.error('Error ' + err);
      },
      () => {
        arrayObject.forEach((object, index) => {
          this.supersList[this.currentSuperIndex].tickets[colDate].punch[
            index
          ].disabled = false;
          this.supersList[this.currentSuperIndex].tickets[colDate].punch[
            index
          ].updated = true;
          this.supersList[this.currentSuperIndex].tickets[colDate].punch[
            index
          ].errorMessage = '';
          this.supersList[this.currentSuperIndex].tickets[colDate].punch[
            index
          ].superOrder = index;
        });
        this.alertify.success(
          'Scheduled ' +
            this.supersList[this.currentSuperIndex].tickets[colDate].punch[
              newDateContainerIndex
            ].address +
            ' To ' +
            colDate
        );
      }
    );
  }

  superNames(): string[] {
    return this.supersList.map(sup => {
      return sup.name;
    });
  }

  superTechNames(): string[] {
    return this.supersList.map(sup => {
      return sup.techName;
    });
  }

  onSuperSearchChange(value: string) {
    if (value.trim() == '') {
      this.techHide = [];
      return;
    }
    this.techHide = this.superTechNames();
    // Loop over techHide and remove any techs that match the search
    this.techHide = this.techHide.filter(tech => {
      return !tech.toLowerCase().includes(value.toLowerCase());
    });
  }

  isThisOptionHidden(option: string) {
    return this.techHide.includes(option);
  }
}

@Component({
  selector: 'app-schedule-tickets-dialog',
  templateUrl: './schedule-tickets-dialog.html',
})
export class ScheduleTicketsDialogComponent {
  technicianListLoading = false;
  updating = false;

  constructor(
    private sageApi: SageApiService,
    private alertify: AlertifyService,
    private authService: AuthService,
    public dialogRef: MatDialogRef<ScheduleTicketsDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      ticket: any;
      newDescription: string;
      newTechnician: string;
      technicianList: Array<any>;
    }
  ) {
    this.getTechnicianList();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  confirmUpdate() {
    this.updating = true;
    let updateTechCode = false;
    let updateDescription = false;
    const endpoint = 'SchSuper/Ticket';
    const observables = [];
    const body: any = {
      Username: this.authService.decodedToken?.nameid.toLocaleLowerCase(),
      SalesOrderNo: this.data.ticket.soNum,
      WTNumber: this.data.ticket.wtNum,
    };

    if (
      this.data.newDescription.toLocaleLowerCase() !==
      this.data.ticket.description.toLocaleLowerCase()
    ) {
      body.Description = this.data.newDescription;
      body.LineKey = this.data.ticket.lineKey;
      updateDescription = true;
    }

    if (
      this.data.newTechnician.toLocaleLowerCase() !==
      this.data.ticket.techCode.toLocaleLowerCase()
    ) {
      body.TechCode = this.data.newTechnician;
      updateTechCode = true;
    }

    observables.push(this.sageApi.putRequest(endpoint, body));

    concat(...observables).subscribe(
      response => {
        // ToDo: Something
      },
      err => {
        console.log(err);
        this.updating = false;
        this.alertify.error('Error ' + err.error.Message);
      },
      () => {
        if (updateTechCode) {
          this.data.ticket.techCode = this.data.newTechnician;
        }
        if (updateDescription) {
          this.data.ticket.description = this.data.newDescription;
        }
        this.updating = false;
        this.alertify.success('Updated Successfully');
      }
    );
  }

  getTechnicianList() {
    this.data.technicianList = [];
    this.technicianListLoading = true;

    this.sageApi.pullReport('SuperReport/QAReports/TechList').subscribe(
      rows => {
        if (Array.isArray(rows)) {
          rows.forEach(row => {
            const technician: any = {
              name: row.UDF_ASSIGNED_LEADMAN,
              techCode: row.TechnicianDeptNo + row.TechnicianNo,
              techName: row.FirstName + ' ' + row.LastName,
            };

            this.data.technicianList.push(technician);
          });
        }
      },
      err => {
        console.log(err);
        this.technicianListLoading = false;
      },
      () => {
        this.data.technicianList.sort((a, b) =>
          a.techName > b.techName ? 1 : -1
        );
        this.technicianListLoading = false;
      }
    );
  }
}
