<div class="container pb-5">
  <div class="card my-5 shadow-lg">
    <div class="row" id="intialDataNeeded" [hidden]="purchaseOrderLoaded">
      <div class="col-sm-4">
        <div class="card m-3 border-0">
          <form>
            <div class="form-group m-0">
              <label for="purchaseOrderNo"> Purchase Order No. </label>
              <input class="form-control" name="purchaseOrderNo" [(ngModel)]="purchaseOrderSearch"
                (typeaheadOnSelect)="purchaseOrderSelected($event)" [typeahead]="purchaseOrderList"
                typeaheadOptionField="name" autocomplete="off" [disabled]="purchaseOrderListLoading">
            </div>
          </form>
        </div>
      </div>
      <div class="col-sm-3 px-0">
        <div class="card m-3 border-0">
          <form>
            <div class="form-group m-0">
              <label for="invoiceNo"> InvoiceNo </label>
              <input class="form-control" type="text" name="invoiceNo" [(ngModel)]="invoiceNo">
            </div>
          </form>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="card m-3 border-0">
          <form>
            <div class="form-group m-0">
              <label for="ediReceipt"> Edi Receipt? </label>
              <div class="btn-group form-control p-0" dropdown>
                <button dropdownToggle type="button" class="btn dropdown-toggle text-capitalize">
                  {{ ediReceipt ? 'Yes' : 'No' }} Selected <span class="caret"></span>
                </button>
                <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
                  <li role="menuitem">
                    <a class="dropdown-item text-capitalize" (click)="ediReceiptSelected(true)">
                      <span>Yes</span>
                      <i *ngIf="ediReceipt" class="fas fa-check fa-border fa-pull-right"></i>
                    </a>
                  </li>
                  <li role="menuitem">
                    <a class="dropdown-item text-capitalize" (click)="ediReceiptSelected(false)">
                      <span>No</span>
                      <i *ngIf="!ediReceipt" class="fas fa-check fa-border fa-pull-right"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="col-sm-2">
        <div class="card my-3 border-0 mx-0">
          <form>
            <div class="form-group my-0 mx-2">
              <label for="createRog"> Create </label>
              <button type="button" class="btn btn-primary form-control" (click)="checkIfPendingReceiptExists()"
                [disabled]="purchaseOrderNo === '' || invoiceNo === ''"><span
                  *ngIf="!purchaseOrderLoading">Create</span><span *ngIf="purchaseOrderLoading"><i
                    class="fas fa-sync fa-spin"></i></span></button>
            </div>
          </form>
        </div>
      </div>
      <ng-container *ngIf="whse003">
        <div class="col-12 text-danger text-center">
          <b>WARNING:</b> The selected PO is currently pointing to Warehouse 003. Please Confirm if this is the right
          destination.
        </div>
      </ng-container>
    </div>
    <div class="" id="poLoadedDetails" [hidden]="!purchaseOrderLoaded">
      <div class="p-2" id="poHeader">
        <form>
          <div class="form-group row m-0">
            <h3>Create Receiver</h3>
          </div>
          <div class="form-group row m-0">
            <label for="purchaseOrderNo" class="col-12 col-md-3 pt-2 font-weight-bold"> Purchase Order </label>
            <div class="col-12 col-md-3">
              <input type="text" readonly class="form-control-plaintext" id="purchaseOrderNo"
                value="{{ purchaseOrderHeader.purchaseOrderNo }}">
            </div>
            <label for="vendor" class="col-12 col-md-3 pt-2 font-weight-bold"> Vendor No. </label>
            <div class="col-12 col-md-3">
              <input type="text" readonly class="form-control-plaintext" id="vendor"
                value="{{ purchaseOrderHeader.vendor }}">
            </div>
          </div>
          <div class="form-group row m-0">
            <label for="invoiceNo" class="col-12 col-md-3 pt-2 font-weight-bold"> Invoice No. </label>
            <div class="col-12 col-md-3">
              <input type="text" class="form-control" id="invoiceNo" name="invoiceNo" (click)="$event.target.select()"
                [(ngModel)]="purchaseOrderHeader.invoiceNo">
            </div>
            <label for="invoiceDate" class="col-12 col-md-3 pt-2 font-weight-bold"> Invoice Date </label>
            <div class="col-12 col-md-3">
              <input type="text" class="form-control" id="invoiceDate" name="invoice" bsDatepicker [bsConfig]="bsConfig"
                (bsValueChange)="this.logDate($event, purchaseOrderHeader.invoiceDate)"
                [(bsValue)]="selectedInvoiceDate">
              <!--                     [(ngModel)]="purchaseOrderHeader.invoiceDate"-->
              <ng-container *ngIf="badInvoiceDate">
                <label class="text-danger">Bad Receipt Date. current set date:
                  {{purchaseOrderHeader.invoiceDate}}</label>
              </ng-container>
            </div>
          </div>
          <div class="form-group row m-0">
            <label for="warehouse" class="col-12 col-md-3 pt-2 font-weight-bold"> Warehouse </label>
            <div class="col-12 col-md-3">
              <input class="form-control input" #input [(ngModel)]="warehouseSelected" name="warehouseSelected"
                (typeaheadOnSelect)="checkWarehouse($event)" [typeahead]="warehouseList" typeaheadOptionField="WhseDesc"
                placeholder="ex. 001" autocomplete="off">
            </div>
              <!-- <label class="font-weight-bold" class="col-12 col-md-3 pt-2 font-weight-bold"> Comments </label>
                <ul class="col-12 col-md-3">
                  <li *ngFor="let comment of comments">{{comment}}</li>
                </ul> -->
          </div>
        </form>
      </div>
      <div class="px-3 pt-3">
        <table mat-table [dataSource]="dataSource" matSort class="poInformation">
          <ng-container matColumnDef="itemCode">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> <h6>Item Code</h6> </th>
            <td mat-cell *matCellDef="let element"> {{ element.itemCode }} </td>
          </ng-container>

          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef > <h6>Description</h6></th>
            <td mat-cell *matCellDef="let element"> {{ element.description  | titlecase}} </td>
          </ng-container>

          <ng-container matColumnDef="binLocation">
            <th mat-header-cell *matHeaderCellDef mat-sort-header><h6>Bin Location</h6></th>
            <td mat-cell *matCellDef="let element"> {{ element.binLocation }} </td>
          </ng-container>

          <ng-container matColumnDef="qtyOrdered">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> <h6>Ordered</h6> </th>
            <td mat-cell [ngStyle]="{'text-align': 'center'}"  *matCellDef="let element"> {{ element.qtyOrdered }} </td>
          </ng-container>

          <ng-container matColumnDef="qtyActuallyReceived">
            <th mat-header-cell *matHeaderCellDef mat-sort-header><h6>Already Received</h6></th>
            <td mat-cell [ngStyle]="{'text-align': 'center'}"  *matCellDef="let element"> {{ element.qtyActuallyReceived}} </td>
          </ng-container>
          <ng-container matColumnDef="qtyAvailable">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header> <h6>Backordered</h6> </th>
            <td mat-cell  [ngStyle]="{'text-align': 'center'}" *matCellDef="let element">{{element.qtyAvailable}} </td>
          </ng-container>
          <ng-container matColumnDef="QuantityToReceive">
            <th mat-header-cell [ngStyle]="{'text-align': 'center'}"  *matHeaderCellDef > <h6>Receive</h6> </th>
            <td mat-cell  [ngStyle]="{'text-align': 'center'}" *matCellDef="let element">
            <input type="number" class="form-control text-center" id="qtyReceived" name="qtyReceived"
            (click)="$event.target.select()" [(ngModel)]="element.qtyReceived"
            [ngClass]="{'is-invalid': element.qtyReceived > element.qtyAvailable}">
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="tableDisplayedColumns; sticky: true;"></tr>
          <tr mat-row *matRowDef="let row; columns: tableDisplayedColumns;"></tr>
        </table>
        <mat-paginator matPaginator [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
      </div>
      <div class="px-3 my-3" id="poLoadedFooter">
        <div class="buttonRow">
          <div>
            <div>
              <button class="btn btn-secondary btn-md" (click)="cancelReceipt()"> Cancel Receiver </button>
            </div>
          </div>
          <div >
            <a class="btn p-0" (click)="openModal(fileExplorer)" (click)="fileNames()"><i
                class="far fa-folder-open fa-3x text-dark"></i></a>
          </div>
          <div>
            <button class="btn btn-primary btn-md" (click)="receiveAll()">Receive All</button>
          </div>
          <div>
            <div>
                <button class="btn btn-primary btn-md" (click)="postCreateReceiptOfGoods()"
                matTooltip="Please select correct warehouse."
                [matTooltipDisabled]="!whseIncorrect"
                [disabled]="
                  itemsOverReceived() ||
                  badInvoiceDate ||
                  whseIncorrect ||
                  receiptLoading
                "
              >
                <span *ngIf="!receiptLoading">Accept</span>
                <span *ngIf="receiptLoading"><i class="fas fa-sync fa-spin"></i></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #fileExplorer>
  <div class="container pb-3">
    <div class="row p-2">
      <div class="col-10 offset-1 text-center">
        <h2>File Contents for PO: {{ purchaseOrderHeader.purchaseOrderNo | titlecase}}</h2>
      </div>
      <div class="col-1">
        <button type="button" class="close" (click)="closeModal()">
          <span><i class="fas fa-window-close"></i></span>
        </button>
      </div>
    </div>

    <div class="row mb-1" id="files">
      <div class="col-sm-3 pt-2">
        <div class="card text-center">
          <label class="pt-2 mb-0">
            <i class="fas fa-file-upload fa-4x text-info"></i>
            <input type="file" (change)="fileChosen($event)" style="display: none;">
          </label>
          <small>Upload</small>
          <ng-container *ngFor="let file of fileArray">
            <br> <small> {{ file.name }}</small>
          </ng-container>
        </div>
      </div>
      <div class="col-sm-3 pt-2" *ngFor="let file of fileList">
        <div class="card text-center">
          <a class="btn p-0 pt-2" (click)="fileDownload(file.name)"><i class="fas fa-file-alt fa-4x"></i></a>
          <small>{{ file.name }}</small>
        </div>
      </div>
    </div>

    <form>
      <div class="form-row pt-2">
        <div class="col">
          <button class="btn btn-primary float-right" (click)="closeModal()">Close</button>
          <button class="btn btn-info float-right mr-2" (click)="fileUpload()" *ngIf="fileArray.length > 0 "><span
              *ngIf="!fileLoading">Upload</span><span *ngIf="fileLoading"><i
                class="fas fa-sync fa-spin"></i></span></button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
