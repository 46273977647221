import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { SageApiService } from '../../../_services/sageApi/sageApi.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import MiscPunchCreatedInterface from 'src/app/_services/sageApi/interfaces/pullReport/MiscPunchCreatedInterface';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertifyService } from 'src/app/_services/alertify/alertify.service';

@Component({
  selector: 'app-super-misc-punch-created-table',
  templateUrl: './super-misc-punch-created-table.component.html',
  styleUrls: ['./super-misc-punch-created-table.component.css'],
})
export class SuperMiscpayPunchCreatedTable implements OnInit, AfterViewInit {
  isMobile: boolean = false;

  // Misc Punch Created table state
  miscPunchCreatedLoading: boolean = true;
  miscPunchCreated: MiscPunchCreatedInterface[] = [];
  miscPunchCreatedData = new MatTableDataSource<MiscPunchCreatedInterface>([]);
  miscPunchCreatedFilterChange: boolean = true;
  miscPunchCreatedAllTechs: string[] = [];
  miscPunchCreatedTechControl = new FormControl([]);
  miscPunchCreatedTechs: string[] = [];
  miscPunchCreatedAddressControl: string = '';
  miscPunchCreatedDateContrlMin: Date;
  miscPunchCreatedDateContrlMax: Date;
  miscPunchCreatedDateContrl = new FormGroup({
    start: new FormControl(null, [Validators.required]),
    end: new FormControl(new Date(), [Validators.required]),
  });
  miscPunchCreatedSearchContrl: boolean = false;
  @ViewChild('miscPunchCreatedDataPaginator', { read: MatPaginator })
  miscPunchCreatedDataPaginator: MatPaginator;
  @ViewChild(MatSort) miscPunchCreatedDataSort: MatSort;

  constructor(
    private sageApi: SageApiService,
    private alertify: AlertifyService,
    breakpointObserver: BreakpointObserver
  ) {
    this.setDefaultDateStates();
    breakpointObserver
      .observe([Breakpoints.Small, Breakpoints.XSmall])
      .subscribe(result => {
        if (result.matches) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }
      });
  }

  ngOnInit(): void {
    this.pullMiscPunchCreated();
  }

  ngAfterViewInit() {
    this.miscPunchCreatedData.paginator = this.miscPunchCreatedDataPaginator;
    this.miscPunchCreatedData.sort = this.miscPunchCreatedDataSort;
  }

  setDefaultDateStates() {
    const miscPunchCreatedMinDate = new Date(
      new Date().toISOString().slice(0, 10)
    );
    miscPunchCreatedMinDate.setMonth(miscPunchCreatedMinDate.getMonth() - 1);
    miscPunchCreatedMinDate.setDate(1);
    miscPunchCreatedMinDate.setHours(0);
    miscPunchCreatedMinDate.setMinutes(0);
    miscPunchCreatedMinDate.setSeconds(0);
    miscPunchCreatedMinDate.setMilliseconds(0);
    const miscPunchCreatedMaxDate = new Date(
      new Date().toISOString().slice(0, 10)
    );
    miscPunchCreatedMaxDate.setMonth(miscPunchCreatedMinDate.getMonth() + 2);
    miscPunchCreatedMaxDate.setDate(
      new Date(
        miscPunchCreatedMaxDate.getFullYear(),
        1 + miscPunchCreatedMaxDate.getMonth(),
        0
      ).getDate()
    );
    miscPunchCreatedMaxDate.setHours(23);
    miscPunchCreatedMaxDate.setMinutes(59);
    miscPunchCreatedMaxDate.setSeconds(59);
    miscPunchCreatedMaxDate.setMilliseconds(999);

    this.miscPunchCreatedDateContrlMin = miscPunchCreatedMinDate;
    this.miscPunchCreatedDateContrlMax = miscPunchCreatedMaxDate;
    this.miscPunchCreatedDateContrl = new FormGroup({
      start: new FormControl(miscPunchCreatedMinDate, [Validators.required]),
      end: new FormControl(miscPunchCreatedMaxDate, [Validators.required]),
    });
  }

  pullMiscPunchCreated() {
    const reqPayLineItems = () => {
      this.sageApi.pullReport('SuperMisc/misc-punch-created').subscribe(
        (result: MiscPunchCreatedInterface[]) => {
          this.miscPunchCreatedLoading = false;
          this.miscPunchCreated = result;
          this.miscPunchCreatedData = new MatTableDataSource<MiscPunchCreatedInterface>(
            result
          );
          const allTechs = this.getAllTechsFromMiscPunchCreated(result);
          this.miscPunchCreatedAllTechs = allTechs;
          this.miscPunchCreatedTechs = allTechs;
          this.miscPunchCreatedTechControl.setValue(allTechs);
          this.miscPunchCreatedData.paginator = this.miscPunchCreatedDataPaginator;
          this.miscPunchCreatedData.sort = this.miscPunchCreatedDataSort;
        },
        err => {
          if (err?.status == 0) {
            this.alertify.error(
              `Could not reach the server for the following resource: "SuperMisc/misc-punch-created"! `
            );
          } else {
            this.alertify.error(
              `Could not load "SuperMisc/pay-line-items"! \nCheck with IT if this persists.`
            );
          }
          this.miscPunchCreatedLoading = false;
          console.log(err);
        }
      );
    };
    reqPayLineItems();
  }

  getAllTechsFromMiscPunchCreated(mpcs: MiscPunchCreatedInterface[]) {
    const techs: string[] = [];
    mpcs.map(mpc => {
      if (!techs.find(t => t == mpc.TechName)) {
        techs.push(mpc.TechName);
      }
    });
    return techs;
  }

  onMiscPunchCreateDate(e) {
    this.setMiscPunchCreatedDateSearchable();
  }

  onMiscPunchCreatedTechSearch(
    value: string | null,
    items: MiscPunchCreatedInterface[]
  ) {
    const newPayLineItemsTechs = [];
    this.miscPunchCreatedAllTechs.map(tech => {
      if (value == null || tech.toLowerCase().includes(value.toLowerCase())) {
        newPayLineItemsTechs.push(tech);
      }
    });
    this.miscPunchCreatedTechs = newPayLineItemsTechs;
  }

  onMiscPunchCreatedTechSelectAll() {
    if (
      this.miscPunchCreatedTechControl.value.sort().join() ==
      this.miscPunchCreatedTechs.sort().join()
    ) {
      this.miscPunchCreatedTechControl.setValue([]);
    } else {
      this.miscPunchCreatedTechControl.setValue(this.miscPunchCreatedTechs);
    }
    this.setMiscPunchCreatedDateSearchable();
  }

  setMiscPunchCreatedDateSearchable() {
    let newMiscPunchCreatedSearchContrl = true;
    if (
      this.miscPunchCreatedDateContrl.value?.start == null ||
      this.miscPunchCreatedDateContrl.value?.end == null
    ) {
      newMiscPunchCreatedSearchContrl = false;
    } else {
      if (
        this.miscPunchCreatedDateContrl.value?.start.getTime() >
        this.miscPunchCreatedDateContrl.value?.end.getTime()
      ) {
        newMiscPunchCreatedSearchContrl = false;
      }
      if (
        this.miscPunchCreatedDateContrl.value?.start.getTime() <
          this.miscPunchCreatedDateContrlMin.getTime() ||
        this.miscPunchCreatedDateContrl.value?.end.getTime() >
          this.miscPunchCreatedDateContrlMax.getTime()
      ) {
        newMiscPunchCreatedSearchContrl = false;
      }
    }
    if (this.miscPunchCreatedTechControl.value.length == 0) {
      newMiscPunchCreatedSearchContrl = false;
    }
    this.miscPunchCreatedSearchContrl = newMiscPunchCreatedSearchContrl;
  }

  onMiscPunchCreatedFilterSearch() {
    const mpcAddressPass = (mpc: MiscPunchCreatedInterface): boolean => {
      return (
        this.miscPunchCreatedAddressControl.trim() == '' ||
        mpc.ShipToName.toLowerCase().includes(
          this.miscPunchCreatedAddressControl.toLowerCase()
        )
      );
    };

    const mpcDatePass = (mpc: MiscPunchCreatedInterface): boolean => {
      if (mpc.UDF_WOMS_STATUS_DATE == null || mpc.UDF_WOMS_STATUS_DATE == '') {
        return true;
      }
      let convDate = new Date();
      if (mpc.UDF_WOMS_STATUS_DATE.includes('-')) {
        convDate = new Date(mpc.UDF_WOMS_STATUS_DATE);
      } else {
        const newDateStr = `${mpc.UDF_WOMS_STATUS_DATE.slice(
          0,
          4
        )}-${mpc.UDF_WOMS_STATUS_DATE.slice(
          4,
          6
        )}-${mpc.UDF_WOMS_STATUS_DATE.slice(6)}`;
        convDate = new Date(newDateStr);
      }
      if (
        convDate.getTime() <
          this.miscPunchCreatedDateContrl.value.end.getTime() &&
        convDate.getTime() >
          this.miscPunchCreatedDateContrl.value.start.getTime()
      ) {
        return true;
      }
      if (
        convDate.toISOString().slice(0, 10) ==
          this.miscPunchCreatedDateContrl.value.start
            .toISOString()
            .slice(0, 10) ||
        convDate.toISOString().slice(0, 10) ==
          this.miscPunchCreatedDateContrl.value.end.toISOString().slice(0, 10)
      ) {
        return true;
      }
      return false;
    };

    this.miscPunchCreatedSearchContrl = false;
    const newData: MiscPunchCreatedInterface[] = [];
    this.miscPunchCreated.map(mpc => {
      if (
        this.miscPunchCreatedTechControl.value.includes(mpc.TechName) &&
        mpcAddressPass(mpc) &&
        mpcDatePass(mpc)
      ) {
        newData.push(mpc);
      }
    });
    this.miscPunchCreatedData = new MatTableDataSource<MiscPunchCreatedInterface>(
      newData
    );
    this.miscPunchCreatedData.paginator = this.miscPunchCreatedDataPaginator;
    this.miscPunchCreatedData.sort = this.miscPunchCreatedDataSort;
  }
}
