import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ScreenSizeService } from 'src/app/_services/ScreenSizeService/ScreenSizeService';
import { SageApiService } from 'src/app/_services/sageApi/sageApi.service';
import QuoteExclusionEditableInterface from '../../../_services/QuoteEditableService/interfaces/QuoteExclusionEditableInterface';
import QuoteExclusionDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/QuoteExclusionDtoInterface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmModalComponent } from '../../Platform/confirm-modal/confirm-modal.component';
import { QuoteEditableService } from 'src/app/_services/QuoteEditableService/QuoteEditableService';

@Component({
  selector: 'app-qexclusion',
  templateUrl: './QuoteExclusionComponent.html',
  styleUrls: ['./QuoteExclusionComponent.css', '../QuotingTheme.scss'],
})
export class QuoteExclusionComponent {
  @Input() exclusion: QuoteExclusionEditableInterface;

  saving = false;

  constructor(
    public api: SageApiService,
    public screenSize: ScreenSizeService,
    public snackbar: MatSnackBar,
    public dialog: MatDialog,
    private qe: QuoteEditableService
  ) {}

  disableControls() {
    this.exclusion.nameControl.disable();
    this.exclusion.descControl.disable();
  }

  enableControls() {
    this.exclusion.nameControl.enable();
    this.exclusion.descControl.enable();
  }

  resetControls() {
    this.exclusion.nameControl.reset(this.exclusion.data.QuoteExclusion_Name);
    this.exclusion.descControl.reset(this.exclusion.data.QuoteExclusion_Desc);
  }

  nameInput(e: InputEvent) {
    const value = (e.target as HTMLInputElement).value;
    if (value.length > 100) {
      const newVal = value.slice(0, 100);
      this.exclusion.nameControl.setValue(newVal);
      (e.target as HTMLInputElement).value = newVal;
    }
  }

  isSaveDisabled() {
    if (this.saving) {
      return true;
    }
    const different =
      this.exclusion.nameControl.value !==
        this.exclusion.data.QuoteExclusion_Name ||
      this.exclusion.descControl.value !==
        this.exclusion.data.QuoteExclusion_Desc;

    const valid =
      this.exclusion.nameControl.valid && this.exclusion.descControl.valid;

    return !different || !valid;
  }

  nameBlur() {
    if (this.exclusion.nameControl.value === '') {
      this.exclusion.nameControl.setValue('Untitled');
    }
  }

  saveChanges() {
    this.saving = true;
    this.qe.changeQuoteExclusion({ quoteExclusion: this.exclusion }).subscribe(
      () => {
        this.saving = false;
        this.enableControls();
      },
      () => {
        this.saving = false;
        this.enableControls();
      }
    );
  }

  deleteExclusion() {
    this.saving = true;
    this.disableControls();
    this.qe.destroyQuoteExclusion({ quoteExclusion: this.exclusion }).subscribe(
      () => {
        this.saving = false;
        this.enableControls();
      },
      () => {
        this.saving = false;
        this.enableControls();
      }
    );
  }
}
