<div [class]="screenSize.getClasses() + ' quoting-theme prevbpdf'">
  <div *ngIf="error" class="prevbpdf-error">
    <h1 class="prevbpdf-error-txt">Error loading Bid</h1>
  </div>
  <div *ngIf="loading" class="prevbpdf-loading">
    <h1 class="prevbpdf-loading-title">Loading Bid</h1>
    <mat-spinner
      *ngIf="loading"
      [diameter]="30"
      class="prevbpdf-loading-title-spinner"
    ></mat-spinner>
  </div>
  <iframe
    *ngIf="content != undefined"
    [src]="content"
    class="prevbpdf-if"
  ></iframe>
</div>
