import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ScreenSizeService } from 'src/app/_services/ScreenSizeService/ScreenSizeService';
import KitDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/KitDtoInterface';
import { SageApiService } from 'src/app/_services/sageApi/sageApi.service';
import { ConfirmModalComponent } from '../../Platform/confirm-modal/confirm-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import KitRegions from '../KitRegions';
import KitUtil from 'src/app/utils/KitUtil';
import KitPhases from '../KitPartPhases';
import BidStatuses from '../QuoteStatuses';
import DateValidators from 'src/app/Validators/DateValidators';
import { MatSnackBar } from '@angular/material/snack-bar';
import InputDecimalSanitizer from 'src/app/utils/InputDecimalSanitizer';
import ContextQuoteDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextQuoteDtoInterface';
import ContextBidDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextBidDtoInterface';
import BidDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/BidDtoInterface';
import BidOverviewDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/BidOverviewDtoInterface';

@Component({
  selector: 'app-bidsview',
  templateUrl: './BidsViewComponent.html',
  styleUrls: ['./BidsViewComponent.css', '../QuotingTheme.scss'],
})
export class BidsViewComponent extends KitUtil {
  loading = false;

  bidControl = new FormControl('');
  regionControl = new FormControl('All');
  statusControl = new FormControl('All');
  builderControl = new FormControl('');
  bidPage = 0;
  bidPageSize = 5;
  bidPageHasMore = false;
  hasSearched = false;

  bids: BidOverviewDtoInterface[] = [];

  newBidOpen = false;
  newBidLoading = false;
  newBidErrorMsg = null;

  newBidNameControl = new FormControl('', [
    Validators.required,
    Validators.minLength(5),
    Validators.maxLength(30),
  ]);
  newBidBuilderControl = new FormControl('', [
    Validators.required,
    Validators.maxLength(30),
  ]);
  newBidRegionControl = new FormControl('', [
    Validators.required,
    Validators.maxLength(30),
  ]);
  newBidStatusControl = new FormControl('', [
    Validators.required,
    Validators.maxLength(30),
  ]);
  newBidDescControl = new FormControl('', [Validators.maxLength(255)]);

  bidStatuses = BidStatuses;
  regions = KitRegions;
  bidBuilders: string[] = [];

  constructor(
    public api: SageApiService,
    private router: Router,
    public screenSize: ScreenSizeService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar
  ) {
    super();
  }

  disableSearchControls() {
    this.bidControl.disable();
    this.regionControl.disable();
    this.statusControl.disable();
    this.builderControl.disable();
  }

  enableSearchControls() {
    this.bidControl.enable();
    this.regionControl.enable();
    this.statusControl.enable();
    this.builderControl.enable();
  }

  searchBids(page?: number) {
    this.hasSearched = true;
    if (page != undefined) {
      this.bidPage = page;
    } else {
      this.bidPage = 0;
      this.bidPageHasMore = false;
    }

    if (!this.loading) {
      this.loading = true;
      this.disableSearchControls();
      let qurl = `bids-overview?size=${this.bidPageSize}&offset=${
        this.bidPage != 0 ? this.bids.length : 0
      }&Bid_Name=${this.bidControl.value}`;
      if (this.regionControl.value != 'All') {
        qurl += `&Bid_Region=${this.regionControl.value}`;
      }
      if (this.statusControl.value != 'All') {
        qurl += `&Bid_Status=${this.statusControl.value}`;
      }
      if (this.builderControl.value != '') {
        qurl += `&Bid_Builder=${this.builderControl.value}`;
      }
      this.api.pullReport(qurl).subscribe(
        (data: BidOverviewDtoInterface[]) => {
          this.bids = page != undefined ? this.bids.concat(data) : data;
          this.loading = false;
          this.enableSearchControls();
          this.bidPageHasMore = data.length == this.bidPageSize;
        },
        err => {
          this.loading = false;
          this.enableSearchControls();
          console.log(err);
          this.snackBar.open(
            'There was an error loading the bids.',
            'Dismiss',
            {
              duration: Infinity,
            }
          );
        }
      );
    }
  }

  deleteBid(bid: BidOverviewDtoInterface, bidIndex: number) {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: {
        title: `Really delete ${bid.Bid.Bid_Name}?`,
        message: `Are you sure you want to delete ${bid.Bid.Bid_Name}?`,
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.api.deleteRequest(`bid/${bid.Bid.Bid_guid}`).subscribe(
          () => {
            this.bids.splice(bidIndex, 1);
          },
          err => {
            console.log(err);
          }
        );
      }
    });
  }

  createBid() {
    this.newBidLoading = true;
    this.api
      .postRequest('bid', {
        Bid_Name: this.newBidNameControl.value,
        Bid_Builder: this.newBidBuilderControl.value,
        Bid_Region: this.newBidRegionControl.value,
        Bid_Status: this.newBidStatusControl.value,
        Bid_Desc: this.newBidDescControl.value,
      })
      .subscribe(
        dataRaw => {
          const data: BidDtoInterface = (dataRaw as unknown) as BidDtoInterface;

          // Now go through all the bidQuestionsAnswers and add the kit or part to the bid
          const kitsToAdd: { Kit_guid: string; quantity: 1 }[] = [];
          const partsToAdd: {
            Part_guid: string;
            quantity: 1;
            phase: string;
            cost: number;
          }[] = [];

          if ([...kitsToAdd, ...partsToAdd].length > 0) {
            // Make a request to add-to-bid using kitsToAdd and partsToAdd
            this.api
              .postRequest(`add-to-bid/${data.Bid_guid}`, {
                kits: kitsToAdd,
                parts: partsToAdd,
                customKits: [],
              })
              .subscribe(
                () => {
                  this.newBidOpen = false;
                  this.router.navigate([`/quoting/bid/${data.Bid_guid}`]);
                  this.newBidLoading = false;
                },
                err => {
                  // Show an error message
                  const continueDespiteQuestionError = this.snackBar.open(
                    'Created the bid, but there was a problem processing your answers to the questions.',
                    'Go to bid anyway',
                    {
                      duration: Infinity,
                    }
                  );
                  continueDespiteQuestionError.onAction().subscribe(() => {
                    this.router.navigate([`/quoting/bid/${data.Bid_guid}`]);
                  });
                  console.log(err);
                }
              );
          } else {
            this.newBidOpen = false;
            this.router.navigate([`/quoting/bid/${data.Bid_guid}`]);
            this.newBidLoading = false;
          }
        },
        err => {
          console.log(err);
          if (err.error) {
            this.newBidErrorMsg = err.error;
            if (err.error.includes('Bid_Name')) {
              this.newBidNameControl.setErrors({ unique: true });
            }
            if (err.error.includes('Bid_Desc')) {
              this.newBidDescControl.setErrors({ unique: true });
            }
            if (err.error.includes('Bid_Builder')) {
              this.newBidBuilderControl.setErrors({ unique: true });
            }
            if (err.error.includes('Bid_Region')) {
              this.newBidRegionControl.setErrors({ unique: true });
            }
            if (err.error.includes('Bid_Status')) {
              this.newBidStatusControl.setErrors({ unique: true });
            }
          }
          this.newBidLoading = false;
        }
      );
  }

  openNewBidSheet() {
    this.newBidNameControl = new FormControl('', [
      Validators.required,
      Validators.minLength(5),
      Validators.maxLength(30),
    ]);
    this.newBidRegionControl = new FormControl('', [
      Validators.required,
      Validators.maxLength(30),
    ]);
    this.newBidStatusControl = new FormControl('', [
      Validators.required,
      Validators.maxLength(30),
    ]);
    this.newBidBuilderControl = new FormControl('', [
      Validators.required,
      Validators.maxLength(30),
    ]);
    this.newBidDescControl = new FormControl('', [Validators.maxLength(255)]);
    this.newBidErrorMsg = null;
    this.newBidOpen = true;
  }

  newBidValid() {
    return (
      this.newBidNameControl.valid &&
      this.newBidDescControl.valid &&
      this.newBidStatusControl.valid &&
      this.newBidBuilderControl.valid &&
      this.newBidLoading == false
    );
  }

  copyBid(bid) {
    const copyingSnackbar = this.snackBar.open('Copying bid...', '', {
      duration: Infinity,
    });

    this.api.postRequest(`copy-bid/${bid.Bid_guid}`, {}).subscribe(
      (data: ContextBidDtoInterface) => {
        copyingSnackbar.dismiss();
        this.router.navigate([`/quoting/bid/${data.Bid.Bid_guid}`]);
      },
      err => {
        console.log(err);
        copyingSnackbar.dismiss();
        this.snackBar.open('There was an error copying the bid.', 'Dismiss', {
          duration: Infinity,
        });
      }
    );
  }
}
