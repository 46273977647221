import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
  forwardRef,
} from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';
import { MatTableDataSource } from '@angular/material/table';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatSelect } from '@angular/material/select';
import { debounceTime, tap } from 'rxjs/operators';

@Component({
  selector: 'app-grow-input',
  templateUrl: './grow-input.component.html',
  styleUrls: ['./grow-input.component.css'],
})
export class GrowInputComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('formFieldElement', { read: ElementRef })
  public formFieldElement: ElementRef;
  @ViewChild('inputElement') public inputElement: ElementRef;

  @Input() control: FormControl = new FormControl('');
  @Input() disabled = false;
  @Input() type = 'text';
  @Input() pattern = '';
  @Input() useNativeInput = false;
  @Input() placeholder = 'Untitled';
  @Input() hideIcon = false;

  @HostBinding('style.--growinput-min-width')
  @Input()
  minWidth = '75px';

  @HostBinding('style.--growinput-max-width')
  @Input()
  maxWidth = '100vw';

  @Input() ffAppearance = 'outline';

  @Output() inputEmitter = new EventEmitter<FormControl>();
  @Output() changeEmitter = new EventEmitter<FormControl>();
  @Output() blurEmitter = new EventEmitter<FormControl>();
  @Output() focusEmitter = new EventEmitter<FormControl>();
  @Output() keydownEmitter = new EventEmitter<FormControl>();
  @Output() keyupEmitter = new EventEmitter<FormControl>();

  onChange: any = () => {
    // ToDo: Nothing
  };
  onTouch: any = () => {
    // ToDo: Nothing
  };

  constructor(private renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.updateInputWidth();
    if (changes.disabled) {
      if (changes.disabled.currentValue) {
        this.control.disable();
      }
      if (!changes.disabled.currentValue) {
        this.control.enable();
      }
    }
  }

  ngOnInit() {
    this.updateInputWidth();
    this.control.valueChanges
      .pipe(tap(val => this.updateInputWidth()))
      .subscribe();
  }

  ngAfterViewInit(): void {
    this.updateInputWidth();
    setTimeout(() => this.updateInputWidth(), 1000);
  }

  updateInputWidth() {
    if (this.inputElement && this.inputElement.nativeElement) {
      const val = `${this.control.value}`;
      const styles = window.getComputedStyle(this.inputElement.nativeElement);

      const fakeEle = document.createElement('div');
      fakeEle.style.position = 'absolute';
      fakeEle.style.top = '0';
      fakeEle.style.left = '-9999px';
      fakeEle.style.overflow = 'hidden';
      fakeEle.style.visibility = 'hidden';
      fakeEle.style.whiteSpace = 'nowrap';
      fakeEle.style.height = '0';

      fakeEle.style.fontFamily = styles.fontFamily;
      fakeEle.style.fontSize = styles.fontSize;
      fakeEle.style.fontStyle = styles.fontStyle;
      fakeEle.style.fontWeight = styles.fontWeight;
      fakeEle.style.letterSpacing = styles.letterSpacing;
      fakeEle.style.textTransform = styles.textTransform;

      fakeEle.style.borderLeftWidth = styles.borderLeftWidth;
      fakeEle.style.borderRightWidth = styles.borderRightWidth;
      fakeEle.style.paddingLeft = styles.paddingLeft;
      fakeEle.style.paddingRight = styles.paddingRight;

      document.body.appendChild(fakeEle);
      fakeEle.innerHTML = val.replace(/\s/g, '&' + 'nbsp;');
      const fakeEleStyles = window.getComputedStyle(fakeEle);
      const newWidth = fakeEleStyles.width;
      document.body.removeChild(fakeEle);

      this.renderer.setStyle(
        this.inputElement.nativeElement,
        'width',
        parseInt(newWidth) + 1 + 'px' // We have to add one pixed because it will ellipse sometimes without it. IDK, weird man. Took me forever to figure out.
      );
      if (this.formFieldElement && this.formFieldElement.nativeElement) {
        this.renderer.setStyle(
          this.formFieldElement.nativeElement,
          'width',
          this.ffAppearance == 'outline'
            ? parseInt(newWidth) + 22 + 'px'
            : newWidth
        );
      }
    }
  }

  inputEvent(event: FormControl) {
    this.inputEmitter.emit(event);
    this.updateInputWidth();
  }

  blurEvent(event: FormControl) {
    this.blurEmitter.emit(event);
    this.updateInputWidth();
  }

  focusEvent(event: FormControl) {
    this.focusEmitter.emit(event);
    this.updateInputWidth();
  }

  changeEvent(event: FormControl) {
    this.changeEmitter.emit(event);
    this.updateInputWidth();
  }

  keydownEvent(event: FormControl) {
    this.keydownEmitter.emit(event);
    this.updateInputWidth();
  }

  keyupEvent(event: FormControl) {
    this.keyupEmitter.emit(event);
    this.updateInputWidth();
  }
}
