import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChildren,
  AfterViewInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { SageApiService } from '../../../_services/sageApi/sageApi.service';
import { Router } from '@angular/router';
import { AuthService } from '../../../_services/auth/auth.service';
import { AlertifyService } from '../../../_services/alertify/alertify.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { concat } from 'rxjs';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';
import { WasabiApiService } from '../../../_services/wasabiApi/wasabiApi.service';
import { BnNgIdleService } from 'bn-ng-idle';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'app-warehouse-transfer',
  templateUrl: './warehouse-transfer.component.html',
  styleUrls: ['./warehouse-transfer.component.css'],
})
export class WarehouseTransferComponent implements OnInit, AfterViewInit {
  // inputs for Autofocus
  @ViewChild('unkownIC', { static: true }) unkownIC: ElementRef;

  @ViewChildren('input') inputs: QueryList<ElementRef>;

  // Open modal
  @ViewChild('unknownCode') unknownCode: TemplateRef<any>;

  bsConfig: Partial<BsDatepickerConfig>;
  modalTemplate: BsModalRef;
  itemMenu = false;
  toWhse = '';
  fromWhse = '';
  itemList = [];
  filtereditems = [];
  ScannedUnknown = '';
  inputlist = ['workticket'];
  isLoading: boolean;
  isSubmitting: boolean;
  itemCollection = [];
  itemListSubmitted = [];
  tempAliasList = [];
  warehouseList = [];
  ICList = [];
  AliasList = [];
  issueDate: Date;
  blockSubmit = false;
  constructor(
    private router: Router,
    private authService: AuthService,
    private sageApi: SageApiService,
    private alertify: AlertifyService,
    private modalService: BsModalService,
    private wasabiApi: WasabiApiService,
    private changeDetectorRef: ChangeDetectorRef,
    private bnIdle: BnNgIdleService
  ) {}

  ngOnInit() {
    this.isSubmitting = false;
    this.itemCollection = [];
    this.itemListSubmitted = [];
    this.isLoading = false;
    this.issueDate = new Date();
    if (localStorage.getItem('token') === null) {
      // ToDo: Something
    } else {
      this.createfilter();
      this.AddBackorderList();
      this.pullWarehouses();
      this.changeDetectorRef.detectChanges();
      this.inputs.last.nativeElement.focus();
    }
  }
  logDate(event) {
    if (this.issueDate.getTime() !== new Date(event).getTime()) {
      this.issueDate = new Date(event);
    }
  }

  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();
    this.inputs.last.nativeElement.focus();
    this.itemList.forEach(item => {
      this.colorChange(item.newQtyShippedTotal, item.QuantityOnHand);
    });
  }

  fromWhseSelected(event: TypeaheadMatch) {
    this.fromWhse = event.item.WarehouseCode.trim();
    this.pullSageItemList(this.fromWhse);
  }

  toWhseSelected(event: TypeaheadMatch) {
    this.toWhse = event.item.WarehouseCode.trim();
  }

  validWhseCheck() {
    const fromWarehouseIndex = this.warehouseList.findIndex(
      x => x.WarehouseCode == this.fromWhse
    );
    const toWarehouseIndex = this.warehouseList.findIndex(
      x => x.WarehouseCode == this.toWhse
    );
    if (fromWarehouseIndex > -1) {
      if (toWarehouseIndex > -1) {
        return true;
      }
    }
  }

  getQuantityOnHand(itemcode: string, index: any) {
    itemcode = itemcode.trim();
    const partIndex = this.filtereditems.findIndex(
      item =>
        item.Alias.toLocaleLowerCase() === itemcode.trim().toLocaleLowerCase()
    );
    let itemBuild = {
      Alias: '',
      ItemCode: '',
      Description: '',
      QuantityOnHand: 0,
    };
    if (itemcode !== '') {
      if (partIndex === -1) {
        this.ScannedUnknown = itemcode;
        this.alertify.error('Error Part not Found');

        const observables = [];
        const endpoint = 'TransactionEntry/IncorrectPart';
        const object = {
          username: this.authService.decodedToken.nameid,
          password: this.authService.decodedToken[
            'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'
          ],
          WarehouseTo: this.toWhse,
          WarehouseFrom: this.fromWhse,
          ItemCode: itemcode,
        };

        observables.push(this.sageApi.putRequest(endpoint, object));

        concat(...observables).subscribe(
          (response: any) => {
            // ToDo: Something
          },
          err => {
            this.alertify.error('Error submitting invalid part name to IT.');
          },
          () => {
            // ToDo: Something
          }
        );

        this.itemList[index] = [];
        this.itemList[index].QuantityOnHand = undefined;
        this.itemList[index].newQtyShippedTotal = 1;
        return;
      } else {
        itemBuild = this.filtereditems[partIndex];
        this.itemList[index].name = itemBuild.ItemCode;
        this.itemList[index].QuantityOnHand = itemBuild.QuantityOnHand;
      }
    }
    if (itemcode === '' || itemcode === null) {
      return;
    }
  }
  clearAndReturnHome() {
    this.itemList = [];
    this.filtereditems = [];
    this.ICList = [];
    this.router.navigate(['/WarehouseTransfer']);
  }
  clear() {
    this.itemList = [];
    this.AddBackorderList();
    this.filtereditems = [];
    this.ICList = [];
  }

  itemSelectedNew(itemcode: string, index: any, quantityShipped: number) {
    itemcode = itemcode.trim();
    const partIndex = this.filtereditems.findIndex(
      item => item.Alias.toLocaleLowerCase() === itemcode.toLocaleLowerCase()
    );
    let itemBuild = {
      Alias: '',
      ItemCode: '',
      Description: '',
      QuantityOnHand: 0,
    };
    if (partIndex === -1) {
      this.ScannedUnknown = itemcode;
      this.alertify.error('Error Part not Found');

      // recording the unknown part.
      const observables = [];
      const endpoint = 'TransactionEntry/IncorrectPart';
      const object = {
        username: this.authService.decodedToken.nameid,
        password: this.authService.decodedToken[
          'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'
        ],
        WarehouseTo: this.toWhse,
        WarehouseFrom: this.fromWhse,
        ItemCode: itemcode,
      };

      observables.push(this.sageApi.putRequest(endpoint, object));

      concat(...observables).subscribe(
        (response: any) => {
          // ToDo: Something
        },
        err => {
          this.alertify.error('Error submitting invalid part name to IT.');
        },
        () => {
          // ToDo: Something
        }
      );
      this.itemList[index].name = '';
      return;
    } else {
      itemBuild = this.filtereditems[partIndex];
      this.itemList[index].name = itemBuild.ItemCode;
    }

    if (itemcode === '' || itemcode === null) {
      return;
    }
    let dupCount = 0;
    let firstDupIndex = 0;
    let ind = 0;
    this.itemList.forEach(tok => {
      if (tok.name === itemBuild.ItemCode) {
        dupCount++;
        if (dupCount === 1) {
          firstDupIndex = ind;
        }
      }
      ind++;
    });
    if (dupCount >= 2) {
      const resp = this.itemList[firstDupIndex].newQtyShippedTotal;

      this.itemList[firstDupIndex].newQtyShippedTotal = resp + quantityShipped;
      this.itemList[index].name = '';
      return;
    }
    const BigObj = {
      name: '',
      ItemDesc: '',
      newQtyOrderedTotal: 0,
      newQtyShippedTotal: 0,
      QuantityOnHand: 0,
      tickets: [],
    };
    const hasTick = false;
    BigObj.name = itemBuild.ItemCode;
    BigObj.ItemDesc = itemBuild.Description;
    BigObj.QuantityOnHand = itemBuild.QuantityOnHand;
    if (!hasTick) {
      BigObj.newQtyShippedTotal = quantityShipped;
      BigObj.newQtyOrderedTotal = quantityShipped;
      const obj = {
        name: itemBuild.ItemCode,
        lineKey: 'none',
        newItem: 'Y',
        newQtyOrdered: quantityShipped,
        newQtyShipped: quantityShipped,
      };
      BigObj.tickets.push(obj);
    }
    this.itemList[index] = BigObj;
    this.AddBackorderList();
  }

  AddBackorderList() {
    const obj = {
      name: '',
      ItemDesc: '',
      newQtyOrderedTotal: 0,
      newQtyShippedTotal: 0,
      tickets: [],
    };
    this.itemList.push(obj);
    this.inputlist.push('item');
    // Autofocus last input element
    this.changeDetectorRef.detectChanges();
    this.inputs.last.nativeElement.focus();
  }

  createfilter() {
    this.filtereditems = [];
  }

  pullWarehouses() {
    if (this.isLoading) {
      this.alertify.warning('Error: Page still loading. Please try again...');
      return;
    }
    this.isLoading = true;
    this.warehouseList = [];
    this.sageApi.pullReport(`TransactionEntry/Warehouses`).subscribe(
      (items: Array<any>) => {
        if (Array.isArray(items)) {
          items.forEach(item => {
            item.WhseDesc = item.WarehouseCode + '-' + item.WarehouseDesc;
            this.warehouseList.push(item);
          });
        }
      },
      err => {
        this.isLoading = false;
        this.alertify.error('Error loading ' + err.error.Message);
        console.log(err);
      },
      () => {
        this.isLoading = false;
      }
    );
  }

  submitTransfer() {
    this.isSubmitting = true;
    let foundWhseTo = false;
    let foundWhseFrom = false;
    this.warehouseList.forEach(whse => {
      if (whse.WarehouseCode === this.toWhse) {
        foundWhseTo = true;
      }
      if (whse.WarehouseCode === this.fromWhse) {
        foundWhseFrom = true;
      }
    });

    if (!foundWhseTo || !foundWhseFrom) {
      if (!foundWhseTo) {
        this.alertify.error(
          'Error: ' +
            this.toWhse +
            ' is not a valid Warehouse Code to move parts To.'
        );
      }
      if (!foundWhseFrom) {
        this.alertify.error(
          'Error: ' +
            this.fromWhse +
            ' is not a valid Warehouse Code to move parts From.'
        );
      }
      this.isSubmitting = false;
      return;
    }

    const observables = [];
    const issueLines = [];
    const transferItems = [];
    const endpoint = '/TransactionEntry/CreateTransaction';

    this.itemList.forEach(item => {
      if (item.name !== '' && item.newQtyShippedTotal !== 0) {
        const arr = [
          item.name,
          item.newQtyShippedTotal < 0 ? 0 : item.newQtyShippedTotal,
        ];
        transferItems.push(arr);
      }
    });

    const object = {
      username: this.authService.decodedToken.nameid,
      password: this.authService.decodedToken[
        'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'
      ],
      transToWare: this.toWhse,
      transFromWare: this.fromWhse,
      issueDate: this.issueDate.toLocaleDateString(),
      transDate: this.issueDate.toLocaleDateString(),
      issueToWare: this.toWhse,
      IssueLines: issueLines,
      TransferLines: transferItems,
    };
    observables.push(this.sageApi.putRequest(endpoint, object));

    concat(...observables).subscribe(
      (response: any) => {
        // ToDo: Something
      },
      err => {
        this.alertify.error('Error sumitting transfer: ' + err.error.Message);
        this.isSubmitting = false;
      },
      () => {
        this.itemList = [];
        this.AddBackorderList();
        this.itemMenu = !this.itemMenu;
        this.toWhse = '';
        this.fromWhse = '';
        this.inputlist = ['workticket'];
        this.itemCollection = [];
        this.itemListSubmitted = [];
        this.alertify.success('Material Transfer Submitted');
        this.isSubmitting = false;
        this.closeModal();
        this.router.navigate(['/WarehouseTransfer']);
      }
    );
  }

  pullSageItemList(toWhse: string) {
    this.ICList = [];
    this.isLoading = true;
    this.sageApi.pullReport('ItemMaterialStock/' + toWhse).subscribe(
      (items: Array<any>) => {
        if (Array.isArray(items)) {
          items.forEach(item => {
            this.ICList.push(item);
          });
          this.ICList.forEach(it => {
            const obj = {
              Alias: it.ItemCode,
              ItemCode: it.ItemCode,
              Description: it.Description,
              QuantityOnHand: it.QuantityOnHand,
            };
            this.filtereditems.push(obj);
          });
        }
      },
      err => {
        console.log(err);
        this.isLoading = false;
      },
      () => {
        this.isLoading = true;
        this.AliasList = [];
        this.sageApi.pullReport('QRCode/GetAlias').subscribe(
          (rows: Array<any>) => {
            if (Array.isArray(rows)) {
              rows.forEach(row => {
                const index = this.ICList.findIndex(
                  token => token.ItemCode === row.ItemCode
                );
                const obj = {
                  Alias: row.AliasItemNo,
                  ItemCode: row.ItemCode,
                  Description:
                    index === -1 ? '' : this.filtereditems[index].Description,
                  QuantityOnHand:
                    index === -1 ? 0 : this.filtereditems[index].QuantityOnHand,
                };
                this.AliasList.push(obj);
                this.filtereditems.push(obj);
              });
            }
          },
          err => {
            console.log(err);
            this.isLoading = false;
          },
          () => {
            this.isLoading = false;
          }
        );
      }
    );
  }
  DeleteItem(item: []) {
    // Delete item
    const number = this.itemList.indexOf(item);
    this.itemList.splice(number, 1);
  }

  colorChange(qtyOrdered: number, qtyOnHand: number) {
    if (qtyOrdered > qtyOnHand) {
      return 'RED';
    }
  }

  checkSubmit() {
    let isGreater = false;
    this.itemList.forEach(item => {
      if (item.newQtyShippedTotal > item.QuantityOnHand) {
        isGreater = true;
      }
    });
    return isGreater;
  }
  AddItem() {
    // add blank item to the list and then have the blank show
    this.itemList.forEach(item => {
      if (
        item.ItemDesc === '' &&
        item.QuantityOnHand === undefined &&
        item.name !== ''
      ) {
        const index = this.itemList.indexOf(item);
        this.itemSelectedNew(item.name, index, item.newQtyShippedTotal);
      }
    });

    const BigObj = {
      name: '',
      ItemDesc: '',
      newQtyOrderedTotal: 0,
      newQtyShippedTotal: 0,
      tickets: [],
    };
    this.itemList.push(BigObj);
  }

  pullMaterial() {
    if (this.isLoading) {
      this.alertify.warning('Error: Page still loading. Please try again...');
      return;
    }
    this.tempAliasList = [];
    this.isLoading = true;

    this.sageApi.pullReport(`UpdMatUsage`).subscribe(
      (items: Array<any>) => {
        if (Array.isArray(items)) {
          items.forEach(item => {
            item.newQtyOrdered = item.QuantityOrdered;
            item.newQtyShipped = item.QuantityShipped;
            item.newCommentText = item.CommentText;
            item.loading = false;
            item.updated = false;
            item.edited = false;
            item.added = false;
          });
          this.itemList = [];
          this.inputlist = ['workticket'];
        }
      },
      err => {
        this.isLoading = false;
        this.alertify.error('Error loading ' + err.error.Message);
        console.log(err);
      },
      () => {
        this.isLoading = false;
        this.AddBackorderList();
      }
    );
  }

  openModal(template: TemplateRef<any>) {
    this.modalTemplate = this.modalService.show(template);
  }

  closeModal() {
    this.modalTemplate.hide();
  }
}
