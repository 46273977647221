<div class="container">
  <mat-card>
    <div *ngIf="isLoading" class="spinner">
      <mat-spinner></mat-spinner>
    </div>
    <div [hidden]="isLoading">
      <div class="newSuperButton">
        <button
          mat-button
          mat-flat-button
          color="success"
          (click)="openModal(createSuperintendent); getAvailableSupers()"
        >
          <mat-icon>add</mat-icon> Superintendent
        </button>
      </div>
      <table mat-table [dataSource]="superData" class="tableContents">
        <ng-container matColumnDef="username">
          <th mat-header-cell *matHeaderCellDef>Username</th>
          <td mat-cell *matCellDef="let super">{{ super.username }}</td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let super">{{ super.name }}</td>
        </ng-container>
        <ng-container matColumnDef="nickname">
          <th mat-header-cell *matHeaderCellDef>Nickname</th>
          <td mat-cell *matCellDef="let super">{{ super.nickname }}</td>
        </ng-container>
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef>Email</th>
          <td mat-cell *matCellDef="let super">{{ super.email }}</td>
        </ng-container>
        <ng-container matColumnDef="phoneNumber">
          <th mat-header-cell *matHeaderCellDef>Phone</th>
          <td mat-cell *matCellDef="let super">{{ super.phoneNumber }}</td>
        </ng-container>
        <ng-container matColumnDef="initials">
          <th mat-header-cell *matHeaderCellDef>Initials</th>
          <td mat-cell *matCellDef="let super">{{ super.initials }}</td>
        </ng-container>
        <ng-container matColumnDef="areaManager">
          <th mat-header-cell *matHeaderCellDef>Area Manager</th>
          <td mat-cell *matCellDef="let super">{{ super.areaManager }}</td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef>Action</th>
          <td mat-cell *matCellDef="let super">
            <div class="actionButtonTable">
              <button
                mat-button
                mat-flat-button
                color="primary"
                (click)="openModal(editSuperintendent, super)"
              >
                <mat-icon>edit</mat-icon>
              </button>
              <button
                mat-button
                mat-flat-button
                color="warn"
                (click)="openModal(deleteSuper, super)"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="Columns"></tr>
        <tr mat-row *matRowDef="let row; columns: Columns"></tr>

        <!-- Row shown when there is no matching data.
  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
  </tr> -->
      </table>
    </div>
  </mat-card>
</div>
<ng-template #editSuperintendent>
  <div class="modalContent">
    <h1>Edit Superintendent</h1>
    <form class="EditForm">
      <mat-form-field appearance="outline">
        <mat-label>Username</mat-label>
        <input
          matInput
          type="text"
          appearance="outline"
          [disabled]="true"
          name="username"
          placeholder="username"
          [(ngModel)]="LeadmanToEdit.username"
        />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Name</mat-label>
        <input
          matInput
          type="text"
          appearance="outline"
          [disabled]="true"
          name="name"
          placeholder="name"
          [(ngModel)]="name"
        />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Nickname</mat-label>
        <input
          matInput
          type="text"
          appearance="outline"
          name="nickname"
          placeholder="nickname"
          [(ngModel)]="nickname"
        />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input
          matInput
          type="text"
          appearance="outline"
          name="email"
          placeholder="email"
          [(ngModel)]="email"
        />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Phone Number</mat-label>
        <input
          matInput
          type="text"
          appearance="outline"
          name="phoneNumber"
          placeholder="phoneNumber"
          [(ngModel)]="phoneNumber"
        />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Initials</mat-label>
        <input
          matInput
          type="text"
          appearance="outline"
          name="initials"
          placeholder="initials"
          [(ngModel)]="initials"
        />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Area Manager</mat-label>
        <input
          matInput
          type="text"
          appearance="outline"
          name="areaManager"
          placeholder="areaManager"
          [(ngModel)]="areaManager"
        />
      </mat-form-field>
    </form>
    <div class="actionButtons">
      <button
        mat-button
        (click)="submit()"
        class="bg-primary text-light"
        [disabled]="isLoading"
      >
        Submit
      </button>
    </div>
  </div>
</ng-template>

<ng-template #deleteSuper>
  <div class="modalContent">
    <h1>Are you sure you want to delete {{ LeadmanToEdit.name }}?</h1>
    <div class="actionButtons">
      <button
        mat-button
        mat-flat-button
        color="warn"
        (click)="deleteSuperintendent(LeadmanToEdit)"
      >
        Yes
      </button>
      <button mat-button mat-flat-button color="primary" (click)="closeModal()">
        No
      </button>
    </div>
  </div>
</ng-template>

<ng-template #createSuperintendent>
  <div class="modalContent">
    <h1>Create Superintendent</h1>
    <form class="EditForm">
      <mat-form-field appearance="outline">
        <mat-label>Username</mat-label>
        <input
          matInput
          type="text"
          appearance="outline"
          name="username"
          placeholder="Username"
          [typeahead]="usernameoptions"
          [typeaheadMinLength]="0"
          (typeaheadOnBlur)="getSuperinformation($event)"
          (typeaheadOnSelect)="getSuperinformation($event)"
          [(ngModel)]="username"
        />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Name</mat-label>
        <input
          matInput
          type="text"
          appearance="outline"
          name="name"
          placeholder="Name"
          [(ngModel)]="name"
        />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Nickname</mat-label>
        <input
          matInput
          type="text"
          appearance="outline"
          name="nickname"
          placeholder="Nickname"
          [(ngModel)]="nickname"
        />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input
          matInput
          type="text"
          appearance="outline"
          name="email"
          placeholder="Email"
          [(ngModel)]="email"
        />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Phone Number</mat-label>
        <input
          matInput
          type="text"
          appearance="outline"
          name="phoneNumber"
          placeholder="Phone Number"
          [(ngModel)]="phoneNumber"
        />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Initials</mat-label>
        <input
          matInput
          type="text"
          appearance="outline"
          name="initials"
          placeholder="Initials"
          [(ngModel)]="initials"
        />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Area Manager</mat-label>
        <input
          matInput
          type="text"
          appearance="outline"
          name="areaManager"
          placeholder="Area Manager"
          [(ngModel)]="areaManager"
        />
      </mat-form-field>
    </form>
    <div class="actionButtons">
      <button
        mat-button
        mat-flat-button
        color="primary"
        (click)="createSuper()"
        [disabled]="canSend()"
      >
        Submit
      </button>
    </div>
  </div>
</ng-template>
