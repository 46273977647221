import { Component, OnInit, TemplateRef } from '@angular/core';
import { concat } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { SageApiService } from '../../../_services/sageApi/sageApi.service';
import { AuthService } from '../../../_services/auth/auth.service';
import { Router } from '@angular/router';
import { AlertifyService } from '../../../_services/alertify/alertify.service';
@Component({
  selector: 'app-warehouse-transfer-truck',
  templateUrl: './warehouse-transfer-truck.component.html',
  styleUrls: ['./warehouse-transfer-truck.component.css'],
})
export class WarehouseTransferTruckComponent implements OnInit {
  itemList = [];
  JobNumber = '';
  loading: boolean;
  backgroundColor = '';
  sending: boolean;

  constructor(
    private sageApi: SageApiService,
    private auth: AuthService,
    private alertify: AlertifyService,
    private modalService: BsModalService,
    private router: Router
  ) {}

  ngOnInit(): void {}
  issueAll() {
    this.itemList.forEach(item => {
      item.Shipped = item.Ordered;
    });
  }
  unissueAll() {
    this.itemList.forEach(item => {
      item.Shipped = 0;
    });
  }
  getTransferList(jobNumber: string) {
    let jobNo = jobNumber.trim().toUpperCase();
    this.loading = true;
    this.sageApi
      .pullReport('PullSheet/' + jobNo)
      .subscribe((items: Array<any>) => {
        if (Array.isArray(items)) {
          items.forEach(item => {
            if (item.Comment != null) {
              item.Shipped = item.Shipped;
            }
            this.itemList.push(item);
          });
        }
        if (this.itemList.length == 0) {
          this.alertify.error('No tickets found for: ' + jobNo);
        }
      });
    this.loading = false;
    //this.clear();
  }
  clear() {
    this.JobNumber = '';
    this.itemList = [];
    this.loading = false;
  }
  reset() {
    this.router.navigate(['']);
    this.itemList = [];
    this.sending = false;
  }

  confirmIfRestock() {
    if (this.JobNumber.startsWith('S') || this.itemList.length == 0) {
      return true;
    } else {
      return false;
    }
  }

  transfer() {
    this.sending = true;
    const endpoint = 'ServiceTitan/Transfer';
    const observables = [];
    const object = {
      Username: this.auth.decodedToken.nameid,
      Password: this.auth.decodedToken[
        'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'
      ],
      itemList: this.itemList,
      jobNumber: this.JobNumber,
    };
    observables.push(this.sageApi.putRequest(endpoint, object));
    concat(...observables).subscribe(
      (response: any) => {
        if (Array.isArray(response)) {
          response.forEach(resp => {
            if (resp != 'Success') {
              this.router.navigate(['']);
              this.alertify.error("Couldn't transfer: " + resp);
            }
          });
        }
      },
      err => {
        this.alertify.error('Error - ' + err.message);
      },
      () => {
        this.alertify.success('Successfully sent');
        this.reset();
      }
    );
  }
  background(item: any) {
    if (item.Comment != null) {
      return 'bold';
    }
  }
}
