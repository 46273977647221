<div class="page">
  <div>
    <div class="addressBar">
      <mat-form-field appearance="outline" class="addressLine">
        <mat-label>Address</mat-label>
        <span class="inputLoading">
          <input
            [disabled]="addressLoading"
            matInput
            [(ngModel)]="addressName"
            (typeaheadOnSelect)="addressSelected($event)"
            [typeahead]="addressList"
            typeaheadOptionField="name"
            placeholder="Address List"
            autocomplete="off"
          />
          <mat-spinner
            class="spinner"
            [diameter]="30"
            *ngIf="addressLoading"
          ></mat-spinner>
        </span>
      </mat-form-field>
      <button mat-flat-button color="warn" (click)="pullInformation()">
        Search
      </button>
    </div>
  </div>

  <div
    *ngIf="salesOrderNo !== '' && !houseInformationLoading"
    class="customerInformation"
  >
    <div>
      <h3><b>Customer:</b> {{ customerInformation.Customer }}</h3>
      <h3><b>Garage Location: </b>{{ customerInformation.GarageLocation }}</h3>
      <h3><b>Plan #: </b>{{ customerInformation.PlanNumber }}</h3>
      <h3><b>Type: </b>{{ customerInformation.Type }}</h3>
      <h3><b>Subdivision: </b>{{ customerInformation.Subdivision }}</h3>
    </div>
    <div>
      <h3><b>Address:</b> {{ customerInformation.Address }}</h3>
      <h3><b>Warehouse:</b> {{ customerInformation.Warehouse }}</h3>
      <h3><b>Super: </b>{{ customerInformation.Super }}</h3>
      <h3><b>Energy Type:</b> {{ customerInformation.EnergyType }}</h3>
      <mat-form-field appearance="outline">
        <mat-label>Pull Sheet By Phase</mat-label>
        <mat-select
          [(ngModel)]="selectedPhase"
          (selectionChange)="onPhaseSelected()"
        >
          <mat-option *ngFor="let phase of phases" [value]="phase">
            {{ phase.ParentItemCode + '-' + phase.WTNumber }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <!-- <button mat-flat-button color="warn" (click)="openDialog()">
        <i class="far fa-folder-open fa-3x text-dark"></i>
      </button> -->
    </div>
  </div>

  <mat-tab-group mat-align-tabs="center">
    <mat-tab label="Sales Order Lines">
      <app-data-table
        [rawData]="houseStatus"
        [tableColumns]="[
          {
            col: 'HdrParentItemCode',
            value: 'HdrParentItemCode',
            title: 'Parent',
            size: 'md'
          },
          {
            col: 'SalesOrderNo',
            value: 'SalesOrderNo',
            title: 'Sales Order',
            size: 'sm'
          },
          { col: 'WTNumber', value: 'WTNumber', title: 'WT#', size: 'xs' },
          {
            col: 'StatusCode',
            value: 'StatusCode',
            title: 'Status',
            size: 'xs'
          },
          {
            col: 'PhaseDate',
            value: 'PhaseDate',
            title: 'Phase Date',
            size: 'sm'
          },
          {
            col: 'StatusDate',
            value: 'StatusDate',
            title: 'Status Date',
            size: 'sm'
          },
          { col: 'TechName', value: 'TechName', title: 'Tech', size: 'md' },
          {
            col: 'UDF_TRACKING_COMMENT',
            value: 'UDF_TRACKING_COMMENT',
            title: 'Comment',
            size: 'md'
          },
          {
            col: 'UDF_LINE_PO',
            value: 'UDF_LINE_PO',
            title: 'PO#',
            size: 'md'
          },
          {
            col: 'UnitPrice',
            value: normalizeMoney,
            title: 'Price',
            size: 'sm'
          }
        ]"
        [isLoading]="houseStatusLoading"
        [mselColumns]="[
          { col: 'TechName', title: 'Tech Code' },
          { col: 'WTNumber', title: 'WT#' },
          { col: 'StatusCode', title: 'Status' }
        ]"
        [inColumns]="[{ col: 'HdrParentItemCode', title: 'Parent' }]"
        [includeColumnSelector]="true"
        [searchOnChange]="true"
        accordianControls="true"
        accordianScreenSize="smd"
        title="Sales Order Lines"
      >
      </app-data-table>
    </mat-tab>
    <mat-tab label="Work Ticket Parts">
      <app-data-table
        [rawData]="houseInformation"
        [tableColumns]="[
    {col: 'SalesOrderNo', value: 'SalesOrderNo', title: 'SO#', size: 'sm'},
    {col: 'WTNumber', value: 'WTNumber', title: 'WT#', size: 'xs'},
    {col: 'WTStep', value: 'WTStep', title: 'WTStep', size: 'xs'},
    {col: 'ItemCode', value: 'ItemCode', title: 'Item Code', size: 'md'},
    {col: 'Location', value: 'Location', title: 'Location', size: 'sm'},
    {col: 'QuantityOrdered', value: 'QuantityOrdered', title: 'Ordered', size: 'xs'},

    {col: 'Description', value: 'Description', title: 'Item Desc', size: 'lg'},
    {col: 'CommentText', value: 'CommentText', title: 'Comment', size: 'md'},
    {col: 'QuantityShipped', value: 'QuantityShipped', title: 'Shipped', size: 'xs'},

  ]"
        [inColumns]="[
          { col: 'ItemCode', title: 'ItemCode' },
          { col: 'Description', title: 'Description' }
        ]"
        [mselColumns]="[
          { col: 'WTNumber', title: 'WT#' },
          { col: 'Location', title: 'Location' },
          { col: 'WTStep', title: 'WTStep' }
        ]"
        [isLoading]="houseInformationLoading"
        [includeColumnSelector]="true"
        [searchOnChange]="true"
        accordianControls="true"
        accordianScreenSize="smd"
        title="Work Ticket Parts"
      ></app-data-table>
    </mat-tab>
    <mat-tab label="Status History">
      <app-data-table
        [rawData]="houseStatusHistory"
        [tableColumns]="[
    {col: 'SalesOrderNo', value: 'SalesOrderNo', title: 'SO#', size: 'sm'},
    {col: 'WTNumber', value: 'WTNumber', title: 'WT#', size: 'xs'},
    {col: 'WTStep', value: 'WTStep', title: 'WTStep', size: 'xs'},
    {col: 'TransactionDate', value: 'TransactionDate', title: 'Transaction Date', size: 'sm'},
    {col: 'OldStatus', value: 'OldStatus', title: 'Old Status', size: 'xs'},
    {col: 'NewStatus', value: 'NewStatus', title: 'New Status', size: 'xs'},
    {col: 'UserUpdated', value: 'UserUpdated', title: 'User Updated', size: 'sm'},
  ]"
        [inColumns]="[
          { col: 'UserUpdated', title: 'User Updated' },
        ]"
        [mselColumns]="[
          { col: 'WTNumber', title: 'WT#' },
          { col: 'UserUpdated', title: 'User Updated' },
          { col: 'NewStatus', title: 'NewStatus' }
        ]"
        [isLoading]="houseStatusHistoryLoading"
        [includeColumnSelector]="false"
        [searchOnChange]="true"
        accordianControls="true"
        accordianScreenSize="smd"
        title="Status History"
      ></app-data-table>
    </mat-tab>
    <mat-tab label="Schedule History">
      <app-data-table
        [rawData]="houseScheduleHistory"
        [tableColumns]="[
    {col: 'SalesOrderNo', value: 'SalesOrderNo', title: 'SO#', size: 'sm'},
    {col: 'WTNumber', value: 'WTNumber', title: 'WT#', size: 'xs'},
    {col: 'dateUpd', value: 'dateUpd', title: 'Date Updated', size: 'sm'},
    {col: 'dateSch', value: 'dateSch', title: 'Scheduled Date', size: 'sm'},

  ]"
        [mselColumns]="[
          { col: 'WTNumber', title: 'WT#' },
        ]"
        [isLoading]="houseScheduleHistoryLoading"
        [includeColumnSelector]="false"
        [searchOnChange]="true"
        accordianControls="true"
        accordianScreenSize="smd"
        title="Status History"
      ></app-data-table>
    </mat-tab>
  </mat-tab-group>

  <!-- <div class="col-4 text-center">
  <a class="btn p-0" (click)="openModal(fileExplorer)" (click)="fileNames()" (click)="loadImagesForViewing()"
    *ngIf="addressLoaded"><i class="far fa-folder-open fa-3x text-dark"></i></a>
</div> -->
</div>
