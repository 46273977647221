<div class="bidview quoting-theme">
  <ng-container *ngIf="initLoading.isLoading()">
    <div class="bidview-loading">
      <mat-spinner
        [diameter]="80"
        class="bidview-loading-spinner"
      ></mat-spinner>
      <h4>Loading Bid...</h4>
    </div>
  </ng-container>

  <div class="bidview-progbarctn">
    <mat-progress-bar
      *ngIf="saving.isLoading()"
      mode="indeterminate"
    ></mat-progress-bar>
  </div>

  <div *ngIf="!initLoading.isLoading()" class="bidview-ctn">
    <div class="bidview-ctn-hdr">
      <div class="bidview-ctn-hdr-title">
        <app-grow-input
          [control]="bidNameCtrl"
          [useNativeInput]="true"
          maxWidth="300px"
          class="bidview-ctn-hdr-title-in"
        ></app-grow-input>
        <div
          *ngIf="bidNameCtrl.value != bid.Bid.Bid_Name"
          class="bidview-ctn-hdr-title-actns"
        >
          <button
            mat-flat-button
            color="primary"
            (click)="saveBidChanges({ Bid_Name: bidNameCtrl.value })"
            [disabled]="saving.isLoading()"
          >
            Save
          </button>
          <button
            [disabled]="saving.isLoading()"
            (click)="resetBidControls({ Bid_Name: true })"
            color="warn"
            mat-flat-button
          >
            Cancel
          </button>
        </div>
      </div>
      <a
        [href]="'/quoting/preview-bid-pdf/' + bid.Bid.Bid_guid"
        target="_blank"
      >
        <button mat-flat-button color="primary">Preview Bid</button>
      </a>
    </div>
    <!-- Create a card with the rest of the controls on them -->
    <!-- <div class="bidview-ctn-ncov"></div> -->
    <mat-tab-group class="bidview-ctn-nav">
      <mat-tab label="Build">
        <mat-card class="bidview-ctn-nav-build">
          <table
            mat-table
            [dataSource]="quotesDataTable"
            class="bidview-ctn-nav-build-tbl"
          >
            <ng-container matColumnDef="Quote_Name">
              <th mat-header-cell *matHeaderCellDef>Name</th>
              <td mat-cell *matCellDef="let element">
                <div class="bidview-ctn-nav-build-tbl-col lg">
                  {{ element.Quote_Name }}
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="Quote_Desc">
              <th mat-header-cell *matHeaderCellDef>Desc</th>
              <td mat-cell *matCellDef="let element">
                <div class="bidview-ctn-nav-build-tbl-col lg">
                  {{ element.Quote_Desc }}
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="Quote_TotalPart">
              <th mat-header-cell *matHeaderCellDef>Parts</th>
              <td mat-cell *matCellDef="let element">
                <div class="bidview-ctn-nav-build-tbl-col sm">
                  {{ element.Quote_TotalPart }}
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="Quote_TotalCost">
              <th mat-header-cell *matHeaderCellDef>Cost</th>
              <td mat-cell *matCellDef="let element">
                <div class="bidview-ctn-nav-build-tbl-col sm">
                  $&nbsp;{{ element.Quote_TotalCost.toFixed(2) }}
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef>Actions</th>
              <td mat-cell *matCellDef="let element; let eindex = index">
                <div
                  class="bidview-ctn-nav-build-tbl-col lg bidview-ctn-nav-build-tbl-actns"
                >
                  <a
                    [href]="'/quoting/quote/' + element.Quote_guid"
                    target="_blank"
                  >
                    <button
                      [disabled]="saving.isLoading()"
                      mat-flat-button
                      color="primary"
                    >
                      Edit
                    </button>
                  </a>
                  <button
                    [disabled]="saving.isLoading()"
                    (click)="removeQuote(eindex)"
                    mat-flat-button
                    color="warn"
                  >
                    Remove
                  </button>
                </div>
              </td>
            </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="[
                'Quote_Name',
                'Quote_Desc',
                'Quote_TotalPart',
                'Quote_TotalCost',
                'actions'
              ]"
            ></tr>
            <tr
              mat-row
              *matRowDef="
                let row;
                columns: [
                  'Quote_Name',
                  'Quote_Desc',
                  'Quote_TotalPart',
                  'Quote_TotalCost',
                  'actions'
                ]
              "
            ></tr>
          </table>
          <div class="bidview-ctn-nav-build-actns">
            <button
              [disabled]="saving.isLoading()"
              (click)="addQuoteBotSheetOpen = true"
              mat-flat-button
              class="bidview-ctn-nav-build-actns-add"
            >
              +
            </button>
          </div>
        </mat-card>
      </mat-tab>
      <mat-tab label="Inclusions">
        <div class="bidview-ctn-nav-ndec">
          <div class="bidview-ctn-nav-ndec-lst">
            <app-namedesceditablecard
              *ngFor="let incl of bid.BidInclusions; let i = index"
              [name]="incl.BidInclusion_Name"
              [desc]="incl.BidInclusion_Desc"
              [disabled]="inclusionLoading(incl.BidInclusion_guid)"
              (save)="saveInclusion($event, i)"
              (delete)="deleteInclusion($event, i)"
            ></app-namedesceditablecard>
          </div>
          <div class="bidview-ctn-nav-ndec-actns">
            <button
              [disabled]="saving.isLoading()"
              (click)="addInclusion()"
              (contextmenu)="menuTrigger.openMenu(); $event.preventDefault()"
              mat-fab
              class="bidview-ctn-nav-ndec-actns-add"
            >
              <span>+</span>
              <button
                mat-icon-button
                #menuTrigger="matMenuTrigger"
                [matMenuTriggerFor]="menu"
                [style]="{ width: '0px', height: '0px' }"
              ></button>
            </button>
            <mat-menu #menu="matMenu">
              <button (click)="addInclusion()" mat-menu-item>
                Add an Inclusion
              </button>
              <button (click)="generateInclusions()" mat-menu-item>
                Generate Inclusions from Fixture Parts
              </button>
            </mat-menu>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Exclusions">
        <div class="bidview-ctn-nav-ndec">
          <div class="bidview-ctn-nav-ndec-lst">
            <app-namedesceditablecard
              *ngFor="let excl of bid.BidExclusions; let i = index"
              [name]="excl.BidExclusion_Name"
              [desc]="excl.BidExclusion_Desc"
              [disabled]="exclusionLoading(excl.BidExclusion_guid)"
              (save)="saveExclusion($event, i)"
              (delete)="deleteExclusion($event, i)"
            ></app-namedesceditablecard>
          </div>
          <div class="bidview-ctn-nav-ndec-actns">
            <button
              [disabled]="saving.isLoading()"
              (click)="addExclusion()"
              mat-flat-button
              color="primary"
              class="bidview-ctn-nav-ndec-actns-add"
            >
              +
            </button>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Billing">
        <div class="bidview-ctn-nav-ndec">
          <div class="bidview-ctn-nav-ndec-lst">
            <app-namedesceditablecard
              *ngFor="let bt of bid.BidBillingTerms; let i = index"
              [name]="bt.BidBillingTerm_Name"
              [desc]="bt.BidBillingTerm_Desc"
              [disabled]="billingTermLoading(bt.BidExclusion_guid)"
              (save)="saveBillingTerm($event, i)"
              (delete)="deleteBillingTerm($event, i)"
            ></app-namedesceditablecard>
          </div>
          <div class="bidview-ctn-nav-ndec-actns">
            <button
              [disabled]="saving.isLoading()"
              (click)="addBillingTerm()"
              mat-flat-button
              color="primary"
              class="bidview-ctn-nav-ndec-actns-add"
            >
              +
            </button>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Specs">
        <div class="bidview-ctn-nav-ndec">
          <div class="bidview-ctn-nav-ndec-lst">
            <app-namedesceditablecard
              *ngFor="let spec of bid.BidSpecs; let i = index"
              [name]="spec.BidSpec_Name"
              [desc]="spec.BidSpec_Desc"
              [disabled]="specLoading(spec.BidSpec_guid)"
              (save)="saveSpec($event, i)"
              (delete)="deleteSpec($event, i)"
            ></app-namedesceditablecard>
          </div>
          <div class="bidview-ctn-nav-ndec-actns">
            <button
              [disabled]="saving.isLoading()"
              (click)="addSpec()"
              mat-flat-button
              color="primary"
              class="bidview-ctn-nav-ndec-actns-add"
            >
              +
            </button>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Summary">
        <mat-card class="bidview-ctn-nav-smry">
          <mat-card-content>
            <div class="bidview-ctn-nav-smry-ctn">
              <div class="bidview-ctn-nav-smry-ctn-row">
                <div class="bidview-ctn-nav-smry-ctn-row-col">
                  <h4>Revision: {{ bid.Bid.Bid_Revision + 1 }}</h4>
                  <mat-form-field
                    appearance="outline"
                    class="bidview-ctn-nav-smry-ctn-row-col-ff"
                  >
                    <mat-label>Builder</mat-label>
                    <input
                      matInput
                      type="text"
                      [formControl]="bidBuilderCtrl"
                      class="bidview-ctn-nav-smry-ctn-row-col-ff-in"
                    />
                  </mat-form-field>
                </div>
                <div class="bidview-ctn-nav-smry-ctn-row-col">
                  <mat-form-field
                    appearance="outline"
                    class="bidview-ctn-nav-smry-ctn-row-col-ff"
                  >
                    <mat-label>Region</mat-label>
                    <mat-select
                      [formControl]="bidRegionCtrl"
                      class="bidview-ctn-nav-smry-ctn-row-col-ff-in"
                    >
                      <mat-option
                        *ngFor="let reg of regions"
                        [value]="reg.valueOf()"
                      >
                        {{ reg }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <!-- <div class="bidview-ctn-nav-smry-ctn-row-col">
                  <mat-form-field
                    appearance="outline"
                    class="bidview-ctn-nav-smry-ctn-row-col-ff"
                  >
                    <mat-label>Status</mat-label>
                    <mat-select
                      [formControl]="bidStatusCtrl"
                      class="bidview-ctn-nav-smry-ctn-row-col-ff-in"
                    >
                      <mat-option
                        *ngFor="let status of statuses"
                        [value]="status.valueOf()"
                      >
                        {{ status }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div> -->
                <!-- Now add a larger text area for the description -->
                <div class="bidview-ctn-nav-smry-ctn-row-col">
                  <mat-form-field
                    appearance="outline"
                    class="bidview-ctn-nav-smry-ctn-row-col-ff bidview-ctn-nav-smry-ctn-row-col-ta"
                  >
                    <mat-label>Description</mat-label>
                    <textarea
                      matInput
                      [formControl]="bidDescCtrl"
                      class="bidview-ctn-nav-smry-ctn-row-col-ff-in bidview-ctn-nav-smry-ctn-row-col-ff-ta"
                    ></textarea>
                    <!-- Show the number of characters left -->
                    <span class="bidview-ctn-nav-smry-ctn-row-col-ff-chars">
                      <span
                        [class.valid]="bidDescCtrl.valid"
                        [class.invalid]="!bidDescCtrl.valid"
                        class="bidview-ctn-nav-smry-ctn-row-col-ff-chars-val"
                      >
                        {{ bidDescCtrl.value.length }}
                      </span>
                      &nbsp;/ 2048
                    </span>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <!-- Add save and cancel buttons here -->
            <div class="bidview-ctn-nav-smry-actns">
              <button
                [disabled]="
                  !bidControlsChanged() ||
                  !bidControlsValid() ||
                  saving.isLoading()
                "
                (click)="
                  saveBidChanges({
                    Bid_Builder: bidBuilderCtrl.value,
                    Bid_Region: bidRegionCtrl.value,
                    Bid_Status: bidStatusCtrl.value,
                    Bid_Desc: bidDescCtrl.value
                  })
                "
                mat-flat-button
                color="primary"
              >
                Save
              </button>
              <button
                [disabled]="!bidControlsChanged() || saving.isLoading()"
                (click)="resetBidControls()"
                mat-flat-button
                color="warn"
              >
                Cancel
              </button>
            </div>
          </mat-card-content>
        </mat-card>
        <div class="bidview-ctn-nav-smry-fin">
          <button
            [disabled]="saving.isLoading() || finalizeSaving"
            (click)="finalizeBid()"
            mat-flat-button
            color="primary"
            class="bidview-ctn-nav-smry-fin-btn"
          >
            {{
              bid.Bid.Bid_Revision > 0 ? 'Create New Revision' : 'Finalize Bid'
            }}
          </button>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>

  <app-bottom-sheet
    [open]="addQuoteBotSheetOpen"
    (sheetClosed)="addQuoteBotSheetOpen = false"
    [sheetWidth]="
      screenSize.getClassesArray().includes('smd') ? '100vw' : '75vw'
    "
  >
    <div class="bidview-addq">
      <h1 class="bidview-addq-ttl">
        Add Quote
        <mat-spinner
          *ngIf="addQuoteBotSheetLoading"
          [diameter]="30"
          class="bidview-addq-spinner"
        ></mat-spinner>
      </h1>
      <div class="bidview-addq-controls">
        <mat-form-field appearance="outline" class="bidview-addq-ff">
          <mat-label>Quote Name</mat-label>
          <input
            (keyup.enter)="
              addQuoteBotSheetLoading ? null : searchForQuoteToAdd()
            "
            [formControl]="addQuoteBotSheetSearchCtrl"
            [disabled]="addQuoteBotSheetOpen"
            matInput
            type="text"
            class="bidview-addq-ff-in"
          />
        </mat-form-field>
        <button
          [disabled]="addQuoteBotSheetLoading"
          (click)="searchForQuoteToAdd()"
          mat-flat-button
          color="accent"
          class="bidview-addq-controls-search"
        >
          Search
        </button>
      </div>
      <div class="bidview-addq-ctnt">
        <!-- Loop over addQuoteBotSheetResults and display them in cards here with a checkbox to add them -->
        <mat-card
          *ngFor="let quote of addQuoteBotSheetResults"
          class="bidview-addq-ctnt-itm"
        >
          <!-- First, add the checkbox using the quote.selected field -->
          <mat-checkbox
            [(ngModel)]="quote.selected"
            class="bidview-addq-ctnt-itm-chk"
          ></mat-checkbox>
          <h4>{{ quote.quote.Quote_Name }}</h4>
        </mat-card>
      </div>
      <div class="bidview-addq-actns">
        <button
          [disabled]="addQuoteBotSheetLoading"
          (click)="addQuoteBotSheetOpen = false"
          mat-flat-button
          color="warn"
        >
          Cancel
        </button>
        <button
          [disabled]="addQuoteBotSheetLoading || !canAddToBid()"
          (click)="addSelectedQuotesToBid()"
          mat-flat-button
          color="primary"
        >
          Add Selected
        </button>
      </div>
    </div>
  </app-bottom-sheet>
</div>
