const GetColorFromString = (tagName: string) => {
  // Shamelessly stolen from https://stackoverflow.com/questions/3426404/create-a-hexadecimal-colour-based-on-a-string-with-javascript/16348977#16348977
  const stringToColor = (str: string) => {
    let hash = 0;
    str.split('').forEach(char => {
      hash = char.charCodeAt(0) + ((hash << 5) - hash);
    });
    let color = '#';
    for (let i = 0; i < 3; i++) {
      let value = (hash >> (i * 8)) & 0xff;
      if (value / 3 != 3 && value > 75) {
        value = parseInt(`${value * 0.5}`);
      }
      color += value.toString(16).padStart(2, '0');
    }
    return color;
  };
  return stringToColor(tagName) + 'aa'; // Added some alpha for #RGBA
};
export default GetColorFromString;
