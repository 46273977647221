import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ScreenSizeService } from 'src/app/_services/ScreenSizeService/ScreenSizeService';
import { SageApiService } from 'src/app/_services/sageApi/sageApi.service';
import ContextQuoteQuestionDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextQuoteQuestionDtoInterface';
import KitOverviewDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/KitOverviewDtoInterface';
import { FormControl } from '@angular/forms';
import QuoteQuestionAnswerDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/QuoteQuestionAnswerDtoInterface';
import ContextPartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextPartDtoInterface';

interface QuoteQuestionKitInterface {
  Kit: KitOverviewDtoInterface;
  QuoteQuestionAnswer: QuoteQuestionAnswerDtoInterface;
}

interface QuoteQuestionPartInterface {
  Part: ContextPartDtoInterface;
  QuoteQuestionAnswer: QuoteQuestionAnswerDtoInterface;
}

export interface QuoteQuestionRowAnswerChangeInterface {
  kitOrPart: QuoteQuestionKitInterface | QuoteQuestionPartInterface;
  index: number;
}

@Component({
  selector: 'app-qqrow',
  templateUrl: './QuoteQuestionRowComponent.html',
  styleUrls: ['./QuoteQuestionRowComponent.css', '../QuotingTheme.scss'],
})
export class QuoteQuestionRowComponent implements OnChanges {
  @Input() quoteQuestion: ContextQuoteQuestionDtoInterface | null = null;
  @Input() disabled = false;

  // Just use FCs since angular only really likes those.
  // Using a bool will not work as expected.
  answersChecked: FormControl[] = [];

  @Output()
  answerChange = new EventEmitter<QuoteQuestionRowAnswerChangeInterface | null>();

  constructor(
    public screenSize: ScreenSizeService,
    public api: SageApiService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    for (const a of this.quoteQuestion.QuoteQuestionAnswers) {
      this.answersChecked.push(new FormControl(false));
    }
  }

  checkChanged(val: boolean, answerIndex: number) {
    if (this.quoteQuestion.QuoteQuestion.QuoteQuestion_Type == 'radio') {
      for (let ai = 0; ai < this.answersChecked.length; ai++) {
        this.answersChecked[ai].setValue(false);
      }
    }

    this.answersChecked[answerIndex].setValue(val);

    if (val && this.quoteQuestion.QuoteQuestionAnswers[answerIndex].Kit) {
      const kitAnswer: QuoteQuestionKitInterface = {
        Kit: this.quoteQuestion.QuoteQuestionAnswers[answerIndex].Kit,
        QuoteQuestionAnswer: this.quoteQuestion.QuoteQuestionAnswers[
          answerIndex
        ].QuoteQuestionAnswer,
      };
      this.answerChange.emit({ kitOrPart: kitAnswer, index: answerIndex });
    } else if (
      val &&
      this.quoteQuestion.QuoteQuestionAnswers[answerIndex].Part
    ) {
      const partAnswer: QuoteQuestionPartInterface = {
        Part: this.quoteQuestion.QuoteQuestionAnswers[answerIndex].Part,
        QuoteQuestionAnswer: this.quoteQuestion.QuoteQuestionAnswers[
          answerIndex
        ].QuoteQuestionAnswer,
      };
      this.answerChange.emit({ kitOrPart: partAnswer, index: answerIndex });
    } else {
      this.answerChange.emit(null);
    }
  }
}
