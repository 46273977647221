<ng-container *ngIf="qkp.data?.QuotePart">
  <mat-card
    [class.hover]="isHovered"
    [class.selected]="isSelected()"
    [class]="screenSize.getClasses() + ' quotekprow-card quotekprow-card-part'"
  >
    <div class="quotekprow-card-header">
      <div class="quotekprow-card-header-front">
        <mat-checkbox
          [checked]="isSelected()"
          (change)="selectedChanged($event.checked)"
          class="quotekprow-card-header-front-cb"
        ></mat-checkbox>
        <h3 class="quotekprow-card-header-front-title">
          {{ qkp.data.QuotePart.QuotePart.QuotePart_Code }}
          <h6 class="quotekprow-card-phase">
            {{ qkp.data.QuoteKitPart.QuoteKitPart_Phase }}
          </h6>
        </h3>
        <div class="quotekprow-card-header-front-quan">
          <i class="quotekprow-card-header-front-quan-icon">&times;</i>
          <span class="quotekprow-card-header-front-quan">
            {{ qkp.data.QuoteKitPart.QuoteKitPart_Quantity }}
          </span>
          <span
            *ngIf="!qkp.editable"
            class="quotekprow-card-header-front-quan-txt"
          >
            {{ qkp.quantityControl.value }}
          </span>
        </div>
      </div>
      <div class="quotekprow-card-header-end">
        <div
          [class.cost-hovered]="isCostHovered"
          class="quotekprow-card-header-end-content"
        >
          <h4
            [class.hidden]="costControlVisible"
            class="quotekprow-card-header-end-content-cost"
          >
            <span class="quotekprow-card-header-end-content-cost-icon">$</span>
            {{ cost.toFixed(2) }}
          </h4>
          <div
            [class.focused]="
              costControlVisible ||
              qkp.costControl.value !=
                qkp.data.QuotePart.QuotePart.QuotePart_Cost
            "
            class="quotekprow-card-header-end-content-explanation"
          >
            <h4 class="quotekprow-card-header-end-content-explanation-cost">
              <span class="quotekprow-card-header-end-content-cost-icon">
                ${{ ' ' }}
              </span>
              <!-- {{ quoteKitPart.QuotePart.QuotePart.QuotePart_Cost.toFixed(2) }} -->
              <span class="quotekprow-card-header-front-quan">
                {{ qkp.data.QuotePart.QuotePart.QuotePart_Cost.toFixed(2) }}
              </span>
              <span class="quotekprow-card-header-end-content-cost-per">
                /part
              </span>
            </h4>
          </div>
        </div>
      </div>
    </div>
    <span *ngIf="cost == 0" class="quotekprow-card-noprice">
      No price associated with this part!
    </span>
    <div [class.editable]="qkp.editable" class="quotekprow-card-content active">
      <div class="quotekprow-card-content-tags">
        <span
          *ngFor="let tag of qkp.data.QuotePart.QuotePartTags"
          [matTooltip]="tag.Tag.Tag_Desc"
          [style.background]="getTagColor(tag.Tag.Tag_Name)"
          class="quotekprow-card-content-tags-item"
        >
          {{ tag.Tag.Tag_Name }}
        </span>
      </div>
      <div class="quotekprow-card-content-desc">
        {{ qkp.data.QuotePart.QuotePart.QuotePart_Desc }}
      </div>
    </div>
  </mat-card>
</ng-container>

<ng-container *ngIf="qkp.data?.QuoteKit">
  <mat-card
    [class.hover]="isHovered"
    [class.selected]="isSelected()"
    [class.expanded]="qkp.expanded"
    [class]="screenSize.getClasses() + ' quotekprow-card quotekprow-card-kit'"
  >
    <div class="quotekprow-card-header">
      <div class="quotekprow-card-header-front">
        <mat-checkbox
          *ngIf="qkp.editable"
          [checked]="isSelected()"
          [indeterminate]="isIndeterminate()"
          (change)="selectedChanged($event.checked)"
          class="quotekprow-card-header-front-cb"
        ></mat-checkbox>
        <h3 class="quotekprow-card-header-front-title">
          {{ qkp.data.QuoteKit.QuoteKit.QuoteKit_Name }}
        </h3>
        <div class="quotekprow-card-header-front-quan">
          <i class="quotekprow-card-header-front-quan-icon">&times;</i>
          <span class="quotekprow-card-header-front-quan">
            {{ qkp.data.QuoteKitPart.QuoteKitPart_Quantity }}
          </span>
          <span
            *ngIf="!qkp.editable"
            class="quotekprow-card-header-front-quan-txt"
            >{{ qkp.quantityControl.value }}</span
          >
        </div>
      </div>
      <div class="quotekprow-card-header-end">
        <div class="quotekprow-card-header-end-content">
          <h5 class="quotekprow-card-header-end-content-parts">
            {{ totalParts * qkp.data.QuoteKitPart.QuoteKitPart_Quantity }} parts
          </h5>
          <h4 class="quotekprow-card-header-end-content-cost">
            <span class="quotekprow-card-header-end-content-cost-icon">$</span>
            {{ cost.toFixed(2) }}
          </h4>
          <div class="quotekprow-card-header-end-content-explanation">
            <h5 class="quotekprow-card-header-end-content-explanation-parts">
              {{ totalParts }} parts/kit
            </h5>
            <h4 class="quotekprow-card-header-end-content-explanation-cost">
              <span class="quotekprow-card-header-end-content-cost-icon">
                $
              </span>
              {{
                (cost / qkp.data.QuoteKitPart.QuoteKitPart_Quantity).toFixed(2)
              }}
              <span class="quotekprow-card-header-end-content-cost-per">
                /kit
              </span>
            </h4>
          </div>
        </div>
        <button
          (click)="qkp.expanded = !qkp.expanded"
          mat-mini-fab
          class="quotekprow-card-header-end-drop mat-elevation-z2"
        >
          <i
            [class.active]="qkp.expanded"
            class="quotekprow-card-header-end-drop-icon fas fa-chevron-down"
          ></i>
        </button>
      </div>
    </div>
    <div [class.active]="qkp.expanded" class="quotekprow-card-content">
      <div class="quotekprow-card-content-subkit">
        <ng-container *ngFor="let row of getSubkits()">
          <app-qopcredrow [qkp]="row" [qop]="qop"></app-qopcredrow>
        </ng-container>
        <ng-container *ngFor="let row of getParts()">
          <app-qopcredrow [qkp]="row" [qop]="qop"></app-qopcredrow>
        </ng-container>
      </div>
    </div>
  </mat-card>
</ng-container>
