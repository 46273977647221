import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ScreenSizeService } from 'src/app/_services/ScreenSizeService/ScreenSizeService';

interface BreadcrumbCrumbInterface {
  name: string;
  template?: TemplateRef<any>;
}

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css'],
})
export class BreadcrumbComponent implements OnInit {
  @Input() crumbs: BreadcrumbCrumbInterface[] = [];
  @Input() queryString: string | null = null;
  @Input() renderHeader = true;

  @Input() selectedCrumb = 0;
  @Input() forceRender: boolean | number[] = false;
  @Output() crumbEmitter = new EventEmitter<number>();

  constructor(
    public screenSize: ScreenSizeService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.queryParamMap.subscribe((params: Params) => {
      this.setSelectedCrumbFromQueryParams();
    });
  }

  ngOnInit(): void {
    if (this.queryString != null && this.queryString.trim() != '') {
      if (
        window.location.search != '' &&
        window.location.search.includes(this.queryString)
      ) {
        /* If the queryString is set, use it */
        this.setSelectedCrumbFromQueryParams();
      } else {
        /* If the queryString isn't set, set it */
        this.setupSelectedCrumbQueryString();
      }
    }
  }

  setSelectedCrumbFromQueryParams() {
    const searchParams = new URLSearchParams(window.location.search);
    const queryValue = searchParams.get(this.queryString);
    if (queryValue != null && queryValue.trim() != '') {
      try {
        const newVal = parseInt(queryValue);
        if (newVal.toString() == 'NaN') {
          throw new Error(
            `Error parsing searchParams['${this.queryString}'] to int for breadcrumb. Defaulting breadcrumb selectedCrumb to 0.`
          );
        }
        this.selectedCrumb = newVal;
      } catch (err) {
        console.error(err);
        this.selectedCrumb = 0;
      }
    }
  }

  setupSelectedCrumbQueryString() {
    const queryParams = {};
    queryParams[this.queryString] = this.selectedCrumb;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams,
      queryParamsHandling: 'merge',
    });
  }

  changeSelectedCrumb(index: number) {
    if (this.queryString != null && this.queryString.trim() != '') {
      const queryParams = {};
      queryParams[this.queryString] = index;
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams,
        queryParamsHandling: 'merge',
      });
    }
    this.selectedCrumb = index;
    this.crumbEmitter.emit(index);
  }

  shouldForceRender(x: boolean | number[], crumbIndex: number) {
    if (x === true) {
      return true;
    } else if (Array.isArray(x)) {
      return x.includes(crumbIndex);
    } else {
      return false;
    }
  }
}
