import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ScreenSizeService } from 'src/app/_services/ScreenSizeService/ScreenSizeService';
import { SageApiService } from 'src/app/_services/sageApi/sageApi.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmModalComponent } from '../../Platform/confirm-modal/confirm-modal.component';
import QuoteInclusionEditableInterface from '../../../_services/QuoteEditableService/interfaces/QuoteInclusionEditableInterface';
import QuoteInclusionDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/QuoteInclusionDtoInterface';
import { QuoteEditableService } from 'src/app/_services/QuoteEditableService/QuoteEditableService';
import BidInclusionDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/BidInclusionDtoInterface';
import { FormControl, Validators } from '@angular/forms';
import { MultiIsLoadingService } from 'src/app/_services/multi-is-loading/multi-is-loading.service';
import { BaseModalService } from 'src/app/_services/BaseModalService/BaseModalService';

@Component({
  selector: 'app-namedesceditablecard',
  templateUrl: './NameDescriptionEditableCardComponent.html',
  styleUrls: [
    './NameDescriptionEditableCardComponent.css',
    '../QuotingTheme.scss',
  ],
})
export class NameDescriptionEditableCardComponent implements OnInit {
  @Input() disabled = false;
  @Input() name: string;
  @Input() desc: string;
  @Input() nameMaxLength = 100;
  @Input() descMaxLength = 2048;
  @Input() nameCtrl = new FormControl('', [
    Validators.required,
    Validators.maxLength(this.nameMaxLength),
  ]);
  @Input() descCtrl = new FormControl('', [
    Validators.maxLength(this.descMaxLength),
  ]);

  @Output() delete = new EventEmitter<{ name: string; desc: string }>();
  @Output() save = new EventEmitter<{ name: string; desc: string }>();

  constructor(
    public api: SageApiService,
    public screenSize: ScreenSizeService,
    public snackbar: MatSnackBar,
    public dialog: MatDialog,
    public bm: BaseModalService
  ) {}

  ngOnInit(): void {
    this.resetControls();
    if (this.disabled == true) {
      this.disableControls();
    }
    if (this.disabled == false) {
      this.enableControls();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.name && changes.name.currentValue !== this.nameCtrl.value) {
      this.resetControls();
    } else if (
      changes.desc &&
      changes.desc.currentValue !== this.descCtrl.value
    ) {
      this.resetControls();
    }
    if (changes.disabled && changes.disabled.currentValue == true) {
      this.disableControls();
    }
    if (changes.disabled && changes.disabled.currentValue == false) {
      this.enableControls();
    }
  }

  resetControls() {
    this.nameCtrl.reset(this.name);
    this.descCtrl.reset(this.desc);
  }

  enableControls() {
    this.nameCtrl.enable();
    this.descCtrl.enable();
  }

  disableControls() {
    this.nameCtrl.disable();
    this.descCtrl.disable();
  }

  nameInput(e: InputEvent) {
    const value = (e.target as HTMLInputElement).value;
    if (value.length > 100) {
      const newVal = value.slice(0, 100);
      this.nameCtrl.setValue(newVal);
      (e.target as HTMLInputElement).value = newVal;
    }
  }

  isSaveDisabled() {
    const different =
      this.nameCtrl.value !== this.name || this.descCtrl.value !== this.desc;

    const valid = this.nameCtrl.valid && this.descCtrl.valid;

    return !different || !valid;
  }

  nameBlur() {
    if (this.nameCtrl.value === '') {
      this.nameCtrl.setValue('Untitled');
    }
  }

  saveChanges() {
    this.save.emit({
      name: this.nameCtrl.value,
      desc: this.descCtrl.value,
    });
  }

  deleteExclusion() {
    this.bm
      .confirm(``, `Are you sure you want to delete this inclusion?`)
      .subscribe(() => {
        this.delete.emit({
          name: this.name,
          desc: this.desc,
        });
      });
  }
}
