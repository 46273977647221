import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ScreenSizeService } from 'src/app/_services/ScreenSizeService/ScreenSizeService';
import QuoteKitDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/QuoteKitDtoInterface';
import { SageApiService } from 'src/app/_services/sageApi/sageApi.service';
import QuoteUtil from 'src/app/utils/QuoteUtil';
import kitPartPhases from '../KitPartPhases';
import QuoteKitPartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/QuoteKitPartDtoInterface';
import QuotePartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/QuotePartDtoInterface';
import QuoteKitPartQuotePartSavedInterface from '../../../_services/QuoteEditableService/interfaces/QuoteKitPartQuotePartSavedInterface';
import QuoteKitPartEditableInterface from '../../../_services/QuoteEditableService/interfaces/QuoteKitPartEditableInterface';
import { QuoteOptionKitPartEditableInterface } from 'src/app/_services/QuoteEditableService/interfaces/QuoteOptionEditableInterface';
import QuoteOptionKitPartSavedInterface from 'src/app/_services/QuoteEditableService/interfaces/QuoteOptionKitPartSavedInterface';
import QuoteOptionKitPartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/QuoteOptionKitPartDtoInterface';
import QuoteOptionKitDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/QuoteOptionKitDtoInterface';
import { MultiIsLoadingService } from 'src/app/_services/multi-is-loading/multi-is-loading.service';

@Component({
  selector: 'app-editqopkpbs',
  templateUrl: './EditQuoteOptionKitPartSheetComponent.html',
  styleUrls: [
    './EditQuoteOptionKitPartSheetComponent.css',
    '../QuotingTheme.scss',
  ],
  providers: [{ provide: 'loading', useClass: MultiIsLoadingService }],
})
export class EditQuoteOptionKitPartSheetComponent
  extends QuoteUtil
  implements OnInit, OnChanges {
  @Input() open = false;
  @Input() qopkp: QuoteOptionKitPartEditableInterface | null = null;

  kitPartPhases = kitPartPhases;

  quantityControl = new FormControl(1, [
    Validators.required,
    Validators.min(1),
    Validators.max(9999),
  ]);
  phaseControl = new FormControl(kitPartPhases[0].valueOf(), [
    Validators.required,
  ]);
  costControl = new FormControl(1, [
    Validators.required,
    Validators.min(0.0001),
    Validators.max(99999999.9999),
  ]);
  nameControl = new FormControl('', [Validators.required]);
  descControl = new FormControl('', [Validators.required]);

  @Output() sheetClosed = new EventEmitter<void>();
  @Output() partSaved = new EventEmitter<QuoteOptionKitPartSavedInterface>();

  constructor(
    public api: SageApiService,
    public screenSize: ScreenSizeService,
    @Inject('loading') public loading: MultiIsLoadingService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.resetControls();
  }

  ngOnInit(): void {
    this.resetControls();
  }

  resetControls() {
    if (this.qopkp.data?.QuoteOptionPart?.QuotePart != null) {
      this.quantityControl = new FormControl(
        this.qopkp.data.QuoteOptionKitPart.QuoteOptionKitPart_Quantity,
        [Validators.required, Validators.min(1), Validators.max(9999)]
      );
      this.phaseControl = new FormControl(
        this.qopkp.data.QuoteOptionKitPart.QuoteOptionKitPart_Phase,
        [Validators.required]
      );
      this.costControl = new FormControl(
        this.qopkp.data.QuoteOptionPart.QuotePart.QuotePart_Cost,
        [
          Validators.required,
          Validators.min(0.0001),
          Validators.max(99999999.9999),
        ]
      );
    } else if (this.qopkp.data.QuoteOptionKit != null) {
      this.quantityControl = new FormControl(
        this.qopkp.data.QuoteOptionKitPart.QuoteOptionKitPart_Quantity,
        [Validators.required, Validators.min(1), Validators.max(9999)]
      );
      this.phaseControl = new FormControl(kitPartPhases[0].valueOf(), [
        Validators.required,
      ]);
      this.costControl = new FormControl(1, [
        Validators.required,
        Validators.min(0.0001),
        Validators.max(99999999.9999),
      ]);
      this.nameControl = new FormControl(
        this.qopkp.data?.QuoteOptionKit.QuoteOptionKit.QuoteOptionKit_Name,
        [Validators.required]
      );
      this.descControl = new FormControl(
        this.qopkp.data?.QuoteOptionKit.QuoteOptionKit.QuoteOptionKit_Desc,
        [Validators.required]
      );
    }
  }

  isSaveDisabled() {
    if (this.qopkp.data?.QuoteOptionPart != null) {
      return !(
        this.quantityControl.valid &&
        this.phaseControl.valid &&
        this.costControl.valid &&
        (this.quantityControl.value !=
          this.qopkp.data.QuoteOptionKitPart.QuoteOptionKitPart_Quantity ||
          this.phaseControl.value !=
            this.qopkp.data.QuoteOptionKitPart.QuoteOptionKitPart_Phase ||
          this.costControl.value !=
            this.qopkp.data.QuoteOptionPart.QuotePart.QuotePart_Cost)
      );
    } else if (this.qopkp.data.QuoteOptionKit != null) {
      return !(
        this.quantityControl.valid &&
        this.phaseControl.valid &&
        this.costControl.valid &&
        this.nameControl.valid &&
        this.descControl.valid &&
        (this.quantityControl.value != 0 ||
          this.phaseControl.value != kitPartPhases[0].valueOf() ||
          this.costControl.value != 0 ||
          this.nameControl.value !=
            this.qopkp.data.QuoteOptionKit.QuoteOptionKit.QuoteOptionKit_Name ||
          this.descControl.value !=
            this.qopkp.data.QuoteOptionKit.QuoteOptionKit.QuoteOptionKit_Desc)
      );
    }
    return false;
  }

  saveChanges() {
    const proms: [
      Promise<QuoteOptionKitPartDtoInterface | null>,
      Promise<QuotePartDtoInterface | null>,
      Promise<QuoteOptionKitDtoInterface | null>
    ] = [Promise.resolve(null), Promise.resolve(null), Promise.resolve(null)];
    if (
      (this.quantityControl.valid &&
        this.quantityControl.value !=
          this.qopkp.data.QuoteOptionKitPart.QuoteOptionKitPart_Quantity) ||
      (this.phaseControl.valid &&
        this.phaseControl.value !=
          this.qopkp.data.QuoteOptionKitPart.QuoteOptionKitPart_Phase)
    ) {
      proms[0] = this.loading
        .loadingUntilComplete(
          this.api.patchRequest(
            `quote-option-kitpart/${this.qopkp.data?.QuoteOptionKitPart.QuoteOptionKitPart_guid}`,
            {
              QuoteOptionKitPart_Quantity: this.quantityControl.value,
              QuoteOptionKitPart_Phase: this.phaseControl.value,
            }
          )
        )
        .toPromise<any>(); // Annoying AF any because rxjs nonsense
    }

    if (
      this.costControl.valid &&
      this.qopkp.data?.QuoteOptionPart?.QuotePart?.QuotePart_guid != null &&
      this.costControl.value !=
        this.qopkp.data?.QuoteOptionPart?.QuotePart?.QuotePart_Cost
    ) {
      proms[1] = this.loading
        .loadingUntilComplete(
          this.api.patchRequest(
            `quotepart/${this.qopkp.data?.QuoteOptionPart.QuotePart.QuotePart_guid}`,
            {
              QuotePart_Cost: this.costControl.value,
            }
          )
        )
        .toPromise<any>(); // Annoying AF any because rxjs nonsense
    }

    if (
      this.nameControl.valid &&
      (this.nameControl.value !=
        this.qopkp.data.QuoteOptionKit.QuoteOptionKit.QuoteOptionKit_Name ||
        this.descControl.value !=
          this.qopkp.data.QuoteOptionKit.QuoteOptionKit.QuoteOptionKit_Desc)
    ) {
      proms[2] = this.loading
        .loadingUntilComplete(
          this.api.patchRequest(
            `quote-option-kit/${this.qopkp.data?.QuoteOptionKit.QuoteOptionKit.QuoteOptionKit_guid}`,
            {
              QuoteKit_Name: this.nameControl.value,
              QuoteKit_Desc: this.descControl.value,
              QuoteKit_Region: this.qopkp.data.QuoteOptionKit.QuoteOptionKit
                .QuoteOptionKit_Region,
            }
          )
        )
        .toPromise<any>(); // Annoying AF any because rxjs nonsense
    }

    Promise.all(proms).then(res => {
      const partSavedObj: QuoteOptionKitPartSavedInterface = {
        QuoteOptionKitPart: res[0],
        QuotePart: res[1],
        QuoteOptionKit: res[2],
      };
      if (res[0]) {
        this.qopkp.data.QuoteOptionKitPart = res[0];
        this.qopkp.quantityControl.setValue(res[0].QuoteOptionKitPart_Quantity);
        this.qopkp.phaseControl.setValue(res[0].QuoteOptionKitPart_Phase);
      }
      if (res[1]) {
        this.qopkp.data.QuoteOptionPart.QuotePart = res[1];
        this.qopkp.costControl.setValue(res[1].QuotePart_Cost);
      }
      if (res[2]) {
        this.qopkp.data.QuoteOptionKit.QuoteOptionKit = res[2];
        this.qopkp.nameControl.setValue(res[2].QuoteOptionKit_Name);
        this.qopkp.descControl.setValue(res[2].QuoteOptionKit_Desc);
      }
      this.partSaved.emit(partSavedObj);
      this.sheetClosed.emit();
    });
  }
}
