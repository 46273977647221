<div [class]="scr.getClasses() + ' cusman'">
  <div class="cusman-hdr">
    <h4 class="cusman-hdr-ttl">Customers Manager</h4>
    <ng-container *ngIf="resultsLoading.isLoading()">
      <div class="cusman-hdr-ldng">
        <mat-spinner diameter="50"></mat-spinner>
      </div>
    </ng-container>
    <ng-container *ngIf="!resultsLoading.isLoading()">
      <div class="cusman-hdr-ctrls">
        <mat-form-field appearance="outline" class="cusman-hdr-ctrls-ff">
          <mat-label>Name</mat-label>
          <input
            [formControl]="customerNameFilterControl"
            type="text"
            matInput
            class="cusman-hdr-ctrls-ff-in"
          />
        </mat-form-field>
        <mat-form-field appearance="outline" class="cusman-hdr-ctrls-ff">
          <mat-label>Email</mat-label>
          <input
            [formControl]="customerEmailFilterControl"
            type="text"
            matInput
            class="cusman-hdr-ctrls-ff-in"
          />
        </mat-form-field>
        <mat-form-field appearance="outline" class="cusman-hdr-ctrls-ff">
          <mat-label>Phone</mat-label>
          <input
            [formControl]="customerPhoneFilterControl"
            type="text"
            matInput
            class="cusman-hdr-ctrls-ff-in"
          />
        </mat-form-field>
      </div>
      <div class="cusman-hdr-actns">
        <button
          color="primary"
          mat-flat-button
          [matMenuTriggerFor]="xportMenu"
          class="cusman-hdr-actns-btn cusman-hdr-actns-xport"
        >
          <i class="fas fa-file-download"></i>
        </button>
        <mat-menu #xportMenu="matMenu">
          <button (click)="exportCustomersToXml()" mat-menu-item>XML</button>
        </mat-menu>

        <button
          mat-flat-button
          color="primary"
          class="cusman-hdr-actns-btn"
          (click)="openCreateCustomerModal()"
        >
          Create Customer
        </button>
      </div>
    </ng-container>
  </div>
  <div class="cusman-ctnt">
    <div class="cusman-ctnt-data">
      <ng-container *ngIf="!resultsLoading.isLoading()">
        <table
          mat-table
          [dataSource]="customers"
          matSort
          class="mat-elevation-z8 cusman-ctnt-data-tbl"
        >
          <ng-container matColumnDef="first">
            <th mat-header-cell *matHeaderCellDef>First</th>
            <td mat-cell *matCellDef="let customer">
              <div class="cusman-ctnt-data-tbl-col sm">
                <mat-form-field
                  appearance="outline"
                  class="cusman-ctnt-data-tbl-col-ff"
                >
                  <input
                    [formControl]="customer.AppCustomer_FirstName"
                    type="text"
                    matInput
                    class="cusman-ctnt-data-tbl-col-ff-in"
                  />
                </mat-form-field>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="last">
            <th mat-header-cell *matHeaderCellDef>Last</th>
            <td mat-cell *matCellDef="let customer">
              <div class="cusman-ctnt-data-tbl-col sm">
                <mat-form-field
                  appearance="outline"
                  class="cusman-ctnt-data-tbl-col-ff"
                >
                  <input
                    [formControl]="customer.AppCustomer_LastName"
                    type="text"
                    matInput
                    class="cusman-ctnt-data-tbl-col-ff-in"
                  />
                </mat-form-field>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="phone">
            <th mat-header-cell *matHeaderCellDef>Phone</th>
            <td mat-cell *matCellDef="let customer">
              <div class="cusman-ctnt-data-tbl-col sm">
                <mat-form-field
                  appearance="outline"
                  class="cusman-ctnt-data-tbl-col-ff"
                >
                  <input
                    [formControl]="customer.AppCustomer_Phone"
                    type="text"
                    matInput
                    class="cusman-ctnt-data-tbl-col-ff-in"
                  />
                </mat-form-field>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef>Email</th>
            <td mat-cell *matCellDef="let customer">
              <div class="cusman-ctnt-data-tbl-col lg">
                <mat-form-field
                  appearance="outline"
                  class="cusman-ctnt-data-tbl-col-ff"
                >
                  <input
                    [formControl]="customer.AppCustomer_Email"
                    type="text"
                    matInput
                    class="cusman-ctnt-data-tbl-col-ff-in"
                  />
                </mat-form-field>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef>Type</th>
            <td mat-cell *matCellDef="let customer">
              <div class="cusman-ctnt-data-tbl-col max xs">
                <mat-form-field
                  appearance="outline"
                  class="cusman-ctnt-data-tbl-col-ff"
                >
                  <mat-select
                    appearance="outline"
                    [formControl]="customer.AppCustomerType_guid"
                    class="cusman-ctnt-data-tbl-col-ff-sel"
                  >
                    <mat-option
                      *ngFor="let customerType of customerTypes"
                      [value]="customerType.AppCustomerType_guid"
                    >
                      {{ customerType.AppCustomerType_Name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let customer">
              <div
                class="cusman-ctnt-data-tbl-col sm cusman-ctnt-data-tbl-actns"
              >
                <ng-container *ngIf="!isRowChanged(customer)">
                  <button
                    mat-flat-button
                    color="warn"
                    (click)="openDeleteCustomerModal(customer)"
                    class="cusman-ctnt-data-tbl-actns-itm"
                  >
                    Delete
                  </button>
                </ng-container>
                <ng-container *ngIf="isRowChanged(customer)">
                  <button
                    mat-flat-button
                    color="warn"
                    (click)="resetRows([customer])"
                    class="cusman-ctnt-data-tbl-actns-itm"
                  >
                    Cancel
                  </button>
                  <button
                    (click)="updateCustomer(customer)"
                    [disabled]="!isRowValid(customer)"
                    mat-flat-button
                    color="primary"
                    class="cusman-ctnt-data-tbl-actns-itm"
                  >
                    Save
                  </button>
                </ng-container>
              </div>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </ng-container>
    </div>
    <mat-paginator
      [hidden]="resultsLoading.isLoading()"
      [pageSizeOptions]="[10, 15, 20]"
      showFirstLastButtons
    ></mat-paginator>
  </div>
  <div class="cusman-actns" *ngIf="changedRows().length > 0">
    <button
      (click)="cancelAllUpdates()"
      mat-flat-button
      color="warn"
      class="cusman-actns-cnclall"
    >
      Cancel
    </button>
    <button
      (click)="updateAllCustomers()"
      mat-flat-button
      color="primary"
      class="cusman-actns-updall"
    >
      Update {{ changedRows().length }} Customers
    </button>
  </div>
</div>

<app-bottom-sheet
  [open]="createCustomerModalOpen || createCustomerLoading"
  (sheetClosed)="createCustomerModalOpen = false; resetCustomerControls()"
  [sheetMaxWidth]="'600px'"
>
  <div class="cusman-ccbs">
    <h1 class="cusman-ccbs-title">Create Customer</h1>
    <div class="cusman-ccbs-ctrls">
      <div class="cusman-ccbs-ctrls-itm">
        <mat-form-field
          appearance="outline"
          class="cusman-ccbs-ctrls-itm-ff cusman-ccbs-ctrls-itm-nm"
        >
          <mat-label>First Name</mat-label>
          <input
            [formControl]="ccFirstCtrl"
            matInput
            class="cusman-ccbs-ctrls-itm-ff-in"
          />
          <mat-error>Invalid first name</mat-error>
        </mat-form-field>
        <mat-form-field
          appearance="outline"
          class="cusman-ccbs-ctrls-itm-ff cusman-ccbs-ctrls-itm-nm"
        >
          <mat-label>Last Name</mat-label>
          <input
            [formControl]="ccLastCtrl"
            matInput
            class="cusman-ccbs-ctrls-itm-ff-in"
          />
          <mat-error>Invalid last name</mat-error>
        </mat-form-field>
      </div>
      <div class="cusman-ccbs-ctrls-itm">
        <mat-form-field
          appearance="outline"
          class="cusman-ccbs-ctrls-itm-ff cusman-ccbs-ctrls-itm-eml"
        >
          <mat-label>Email</mat-label>
          <input
            [formControl]="ccEmailCtrl"
            matInput
            class="cusman-ccbs-ctrls-itm-ff-in"
          />
          <mat-error>Invalid email</mat-error>
        </mat-form-field>
      </div>
      <div class="cusman-ccbs-ctrls-itm">
        <mat-form-field
          appearance="outline"
          class="cusman-ccbs-ctrls-itm-ff cusman-ccbs-ctrls-itm-pn"
        >
          <mat-label>Phone #</mat-label>
          <input
            [formControl]="ccPhoneCtrl"
            matInput
            class="cusman-ccbs-ctrls-itm-ff-in"
          />
          <mat-error>Invalid phone number</mat-error>
        </mat-form-field>
      </div>
      <div class="cusman-ccbs-ctrls-itm">
        <mat-form-field
          appearance="outline"
          class="cusman-ccbs-ctrls-itm-ff cusman-ccbs-ctrls-itm-tp"
        >
          <mat-label>Type</mat-label>
          <mat-select
            [formControl]="ccTypeCtrl"
            class="cusman-ccbs-ctrls-itm-ff-sel"
          >
            <mat-option
              *ngFor="let customerType of customerTypes"
              [value]="customerType.AppCustomerType_guid"
            >
              {{ customerType.AppCustomerType_Name }}
            </mat-option>
          </mat-select>
          <mat-error>Invalid customer type</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="cusman-ccbs-actns">
      <button
        [disabled]="
          saving.isLoading() ||
          ccFirstCtrl.invalid ||
          ccLastCtrl.invalid ||
          ccEmailCtrl.invalid ||
          ccPhoneCtrl.invalid ||
          ccTypeCtrl.invalid
        "
        mat-flat-button
        color="primary"
        class="cusman-ccbs-actns-btn"
        (click)="createCustomer()"
      >
        Create
      </button>
    </div>
  </div>
</app-bottom-sheet>
