import {
  Component,
  OnInit,
  AfterViewInit,
  AfterViewChecked,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../_services/auth/auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { AlertifyService } from '../../../_services/alertify/alertify.service';

@Component({
  selector: 'app-login-passwordless',
  templateUrl: './login-passwordless.component.html',
  styleUrls: ['./login-passwordless.component.css'],
})
export class LoginPasswordlessComponent implements OnInit, AfterViewInit {
  user = '';
  jwtHelper = new JwtHelperService();

  constructor(
    private route: ActivatedRoute,
    private auth: AuthService,
    private router: Router,
    private alertify: AlertifyService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.user = params.token.replace(/GIBSONTOKEN/g, '.');
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.auth.passwordlessLogin(this.user);
      this.routeLogin();
    }, 2000);
  }

  routeLogin() {
    if (this.auth.decodedToken?.role === 'ExternalBuilder') {
      this.logout();
    } else {
      this.router.navigate(['']);
    }
  }

  logout() {
    localStorage.removeItem('token');
    this.auth.decodedToken = '';
    this.alertify.message('logged out');
    this.router.navigate(['']);
  }
}
