import { Component, OnInit } from '@angular/core';
import { WasabiApiService } from '../../../_services/wasabiApi/wasabiApi.service';
import { AlertifyService } from '../../../_services/alertify/alertify.service';
import { SageApiService } from '../../../_services/sageApi/sageApi.service';

@Component({
  selector: 'app-upload-plans',
  templateUrl: './upload-plans.component.html',
  styleUrls: ['./upload-plans.component.css'],
})
export class UploadPlansComponent implements OnInit {
  fileArray = [];
  fileLoading = false;
  addressName = '';
  addressList = [];
  addressLoading = false;

  constructor(
    private alertify: AlertifyService,
    private sageApi: SageApiService,
    private wasabiApi: WasabiApiService
  ) {}

  ngOnInit() {
    this.loadAddressList();
  }

  loadAddressList() {
    this.addressLoading = true;

    this.sageApi.pullReport('Address').subscribe(
      (address: Array<any>) => {
        this.addressList = [];
        address.forEach(add => {
          const obj = {
            name: add.ShipToName,
            salesOrderNo: add.SalesOrderNo,
            orderType: add.OrderType,
            selected: false,
          };

          this.addressList.push(obj);
        });
      },
      err => {
        console.log(err);
      },
      () => {
        this.addressLoading = false;
      }
    );
  }

  fileChosen(event) {
    const files = event.target.files;
    for (const index in files) {
      if (files[index] instanceof File) {
        const obj = {
          name: files[index].name,
          data: files[index],
        };

        // for now a single item per upload
        this.fileArray = [obj];
      }
    }
  }

  fileUpload() {
    this.fileLoading = true;
    const sentFiles = [];

    this.fileArray.forEach(file => {
      sentFiles.push(file.data);
    });

    this.wasabiApi.uploadFile(this.addressName, 'plans/', sentFiles).subscribe(
      res => {
        // ToDo: Something
      },
      err => {
        console.log(err);
        this.fileLoading = false;
      },
      () => {
        this.resetUpload();
        this.alertify.success('File Loaded');
      }
    );
  }

  resetUpload() {
    this.fileArray = [];
    this.fileLoading = false;
    this.addressName = '';
  }
}
