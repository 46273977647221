import { startWith } from 'rxjs/operators';
import {
  AfterViewInit,
  Component,
  OnInit,
  TemplateRef,
  ChangeDetectorRef,
  ViewChildren,
  QueryList,
  ElementRef,
} from '@angular/core';
import { AlertifyService } from '../../../_services/alertify/alertify.service';
import { SageApiService } from '../../../_services/sageApi/sageApi.service';
import { AuthService } from '../../../_services/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { concat } from 'rxjs';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { isBoolean } from 'ngx-bootstrap/chronos/utils/type-checks';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { DatePipe } from '@angular/common';
import { formatDate } from '@angular/common';
import { Observable } from 'rxjs-compat';
import { BnNgIdleService } from 'bn-ng-idle';

@Component({
  selector: 'app-pull-ticket-additional-parts',
  templateUrl: './pull-ticket-additional-parts.component.html',
  styleUrls: ['./pull-ticket-additional-parts.component.css'],
})
export class PullTicketAdditionalPartsComponent implements OnInit {
  @ViewChildren('input') inputs: QueryList<ElementRef>;

  JobScanner = '';
  jobNumber = '';
  ItemList = [];
  filtereditems = [];
  ICList = [];
  isLoading: boolean;
  wantedICode = '';
  ScannedUnknown = '';
  dupFailFix: boolean;
  job: string;
  dupFailAlias = '';
  JobsWithPullSheets = [];
  itemList = [];
  loading: boolean;
  urlParams: URLSearchParams;
  constructor(
    private sageApi: SageApiService,
    private alertify: AlertifyService,
    private authService: AuthService,
    private router: Router,
    private bnIdle: BnNgIdleService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.getJobsWithPullSheets();
    this.pullSageItemList();
    this.bnIdle.startWatching(300).subscribe((isTimedOut: boolean) => {
      if (isTimedOut) {
        if (this.ItemList.length > 1) {
          this.alertify.warning('User Inactive Auto-Submitting to Work Ticket');
          this.submitList();
        }
      }
    });
    this.changeDetectorRef.detectChanges();
    this.inputs.last.nativeElement.focus();
  }

  checkForParams() {
    this.urlParams = new URLSearchParams(window.location.search);
    if (this.urlParams.has('jobnumber')) {
      const answer = this.urlParams.get('jobnumber');
      this.pullMaterial(answer.toString());
    } else if (this.urlParams.has('restocknumber')) {
      const restock = this.urlParams.get('restocknumber');
      this.checkIfRestock(restock.toString());
    }
  }

  checkIfRestock(restocknumber: string) {
    restocknumber = restocknumber.toUpperCase();
    if (
      restocknumber.startsWith('S') &&
      !this.JobsWithPullSheets.includes(restocknumber)
    ) {
      this.sageApi.pullReport('AddRestock/' + restocknumber).subscribe(
        (restock: string) => {
          if (restock !== 'Error') {
            this.jobNumber = restock;
            const blankObj = {
              Name: '',
              ItemDesc: '',
              QtyShipped: 1,
            };
            this.ItemList.push(blankObj);
          } else {
            this.alertify.error('Error creating restock ticket');
          }
        },
        err => {
          this.alertify.error(err.message);
        }
      );
    } else if (this.JobsWithPullSheets.includes(restocknumber)) {
      this.pullMaterial(restocknumber.toString());
    } else {
      this.alertify.error('Error creating restock ticket');
    }
  }

  getJobsWithPullSheets() {
    this.JobsWithPullSheets = [];
    this.sageApi.pullReport('ServiceTitan/JobsWithPullSheets').subscribe(
      (rows: Array<any>) => {
        if (Array.isArray(rows)) {
          rows.forEach(row => {
            const jobNumbers = row;
            this.JobsWithPullSheets.push(jobNumbers);
          });
        }
        this.checkForParams();
      },
      err => {
        this.alertify.error(err.message);
        console.log(err);
        this.isLoading = false;
      }
    );
    return this.JobsWithPullSheets;
  }

  pullMaterial(JobScanner: any) {
    if (this.JobsWithPullSheets.includes(JobScanner.toString())) {
      this.jobNumber = JobScanner.toString();
      const blankObj = {
        Name: '',
        ItemDesc: '',
        QtyShipped: 1,
      };
      this.ItemList.push(blankObj);
    } else if (JobScanner.toString().startsWith('S') == true) {
      this.checkIfRestock(JobScanner);
    } else {
      this.alertify.error('Invalid Work Ticket Number');
    }
  }

  itemSelectedNew(item, index) {
    const partIndex = this.filtereditems.findIndex(
      itemcode => itemcode.Alias.toLocaleLowerCase() == item.toLocaleLowerCase()
    );
    let itemBuild = {
      Alias: '',
      ItemCode: '',
      Description: '',
    };
    if (partIndex === -1) {
      this.alertify.error('That is not a valid part.');
      this.ItemList[index].Name = '';
      this.ItemList[index].ItemDesc = '';
      this.ItemList[index].QtyShipped = 0;
    } else if (partIndex !== -1) {
      itemBuild = this.filtereditems[partIndex];
      this.ItemList[index].Name = itemBuild.ItemCode;
      this.ItemList[index].ItemDesc = itemBuild.Description;
      this.ItemList[index].QtyShipped = 1;

      let duplicateCount = 0;
      let firstDuplicateIndex = 0;
      let ind = 0;
      this.ItemList.forEach(posDup => {
        if (posDup.Name === itemBuild.ItemCode) {
          duplicateCount++;

          if (duplicateCount === 1) {
            firstDuplicateIndex = ind;
          }
        }
        ind++;
      });
      if (duplicateCount >= 2) {
        this.ItemList[firstDuplicateIndex].QtyShipped++;
        this.ItemList[index].Name = '';
        return;
      }

      const blankObj = {
        Name: '',
        Description: '',
        QtyShipped: 0,
      };
      this.ItemList.push(blankObj);
    }
    this.changeDetectorRef.detectChanges();
    this.inputs.last.nativeElement.focus();
  }

  async pullSageItemList() {
    this.ICList = [];
    this.isLoading = true;
    this.sageApi.pullReport('ItemMaterial').subscribe(
      (items: Array<any>) => {
        if (Array.isArray(items)) {
          items.forEach(item => {
            this.ICList.push(item);
          });
          this.ICList.forEach(it => {
            const obj = {
              Alias: it.ItemCode,
              ItemCode: it.ItemCode,
              Description: it.Description,
            };
            this.filtereditems.push(obj);
          });
        }
      },
      err => {
        console.log(err);
        this.isLoading = false;
      }
    );
  }
  DeleteItem(item: []) {
    var number = this.ItemList.indexOf(item);
    this.ItemList.splice(number, 1);
  }

  submitList() {
    const observables = [];
    const endpoint = 'ServiceTitan/OTCScanner';
    const object = {
      Username: this.authService.decodedToken.nameid,
      Password: this.authService.decodedToken[
        'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'
      ],
      itemList: this.ItemList,
      JobNumber: this.jobNumber,
    };
    observables.push(this.sageApi.putRequest(endpoint, object));
    concat(...observables).subscribe(
      (response: any) => {
        if (Array.isArray(response)) {
          response.forEach(resp => {
            this.alertify.error("Couldn't add part - " + resp);
          });
        }
        this.router.navigate(['']);
      },
      err => {
        this.alertify.error('Error - ' + err.message);
        this.router.navigate(['']);
      },
      () => {
        this.alertify.success('Submitted');
        this.router.navigate(['']);
      }
    );
  }
}
