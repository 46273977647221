import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ScreenSizeService } from 'src/app/_services/ScreenSizeService/ScreenSizeService';
import { SageApiService } from 'src/app/_services/sageApi/sageApi.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import ContextKitDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextKitDtoInterface';
import { FormControl, Validators } from '@angular/forms';
import KitPartPhases from '../KitPartPhases';
import QuoteOptionKitPartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/QuoteOptionKitPartDtoInterface';
import QuotePartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/QuotePartDtoInterface';
import QuoteOptionKitDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/QuoteOptionKitDtoInterface';
import QuoteOptionEditableInterface, {
  QuoteOptionKitPartEditableInterface,
  QuoteOptionReplaceEditableInterface,
} from '../../../_services/QuoteEditableService/interfaces/QuoteOptionEditableInterface';
import QuoteOptionDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/QuoteOptionDtoInterface';
import ContextQuoteOptionReplaceDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextQuoteOptionReplaceDtoInterface';
import { QuoteEditableService } from 'src/app/_services/QuoteEditableService/QuoteEditableService';
import QuoteKitPartEditableInterface from 'src/app/_services/QuoteEditableService/interfaces/QuoteKitPartEditableInterface';
import GetColorFromString from 'src/app/utils/GetColorFromString';
import QuoteKitDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/QuoteKitDtoInterface';
import QuoteKitPartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/QuoteKitPartDtoInterface';
import { QuoteKitPartRowOnSaveType } from '../QuoteKitPartRowComponent/QuoteKitPartRowComponent';

export type QuoteOptionKitPartRowOnSaveType =
  | QuoteOptionKitPartDtoInterface
  | QuotePartDtoInterface
  | ContextKitDtoInterface
  | QuoteOptionKitDtoInterface;

@Component({
  selector: 'app-qopcredrow',
  templateUrl: './QuoteOptionCreditRowComponent.html',
  styleUrls: ['./QuoteOptionCreditRowComponent.css', '../QuotingTheme.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuoteOptionCreditRowComponent implements OnInit, OnChanges {
  @Input() qkp: QuoteKitPartEditableInterface;
  @Input() qop: QuoteOptionEditableInterface;

  cost = 0;
  costControlVisible = false;

  totalParts = 0;

  kitPartPhases = KitPartPhases;

  isHovered = false;
  isCostHovered = false;

  constructor(
    public screenSize: ScreenSizeService,
    public dialog: MatDialog,
    public api: SageApiService,
    private cdr: ChangeDetectorRef,
    private qe: QuoteEditableService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.qe.updated.subscribe(() => {
      this.generateCost();
      this.generateTotalParts();
      this.cdr.detectChanges();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.resetControls();
    this.generateCost();
    this.generateTotalParts();
    this.cdr.detectChanges();
  }

  resetControls() {
    this.qkp.quantityControl.setValue(
      this.qkp.data.QuoteKitPart.QuoteKitPart_Quantity
    );

    if (this.qkp?.data?.QuotePart != null) {
      this.qkp.costControl.setValue(
        this.qkp.data.QuotePart.QuotePart.QuotePart_Cost
      );
      this.qkp.phaseControl.setValue(
        this.qkp.data.QuoteKitPart.QuoteKitPart_Phase
      );
    } else if (this.qkp?.data?.QuoteKit != null) {
      this.qkp.nameControl.setValue(
        this.qkp.data.QuoteKit.QuoteKit.QuoteKit_Name
      );
      this.qkp.descControl.setValue(
        this.qkp.data.QuoteKit.QuoteKit.QuoteKit_Desc
      );
    }
    this.cdr.detectChanges();
  }

  generateCost() {
    if (this.qkp?.data?.QuotePart != null) {
      this.cost =
        this.qkp.data.QuotePart.QuotePart.QuotePart_Cost *
        this.qkp.data.QuoteKitPart.QuoteKitPart_Quantity;
    } else if (this.qkp?.data?.QuoteKit != null) {
      this.cost =
        this.qe.getRawCost({ quoteKitPart: this.qkp }) *
        this.qkp.data.QuoteKitPart.QuoteKitPart_Quantity;
    }
  }

  generateTotalParts() {
    if (this.qkp.data.QuoteKit) {
      this.totalParts = this.qe.getTotalParts({ quoteKitPart: this.qkp });
    } else {
      this.totalParts = this.qkp.data.QuoteKitPart.QuoteKitPart_Quantity;
    }
  }

  getTotalParts(children: QuoteKitPartEditableInterface[]) {
    // Loop through data.children, and their children, adding all the quantities of the QuoteParts
    let totalParts = 0;
    for (const qkp of children) {
      if (qkp.data.QuotePart) {
        totalParts += qkp.data.QuoteKitPart.QuoteKitPart_Quantity;
      }
      if (qkp.data.QuoteKit) {
        totalParts +=
          this.getTotalParts(qkp.children) *
          qkp.data.QuoteKitPart.QuoteKitPart_Quantity;
      }
    }
    return totalParts;
  }

  getTotalCost(children: QuoteKitPartEditableInterface[]) {
    // Loop through data.children, and their children, adding all the costs * the quantities of the QuoteParts
    let totalCost = 0;
    for (const qkp of children) {
      if (qkp.data.QuotePart) {
        totalCost +=
          qkp.data.QuotePart.QuotePart.QuotePart_Cost *
          qkp.data.QuoteKitPart.QuoteKitPart_Quantity;
      }
      if (qkp.data.QuoteKit) {
        totalCost +=
          this.getTotalCost(qkp.children) *
          qkp.data.QuoteKitPart.QuoteKitPart_Quantity;
      }
    }
    return totalCost;
  }

  getSubkits() {
    return this.qkp.children.filter(child => child.data.QuoteKit != null);
  }

  getParts() {
    return this.qkp.children.filter(child => child.data.QuotePart != null);
  }

  selectedChanged(newSelected: boolean) {
    if (newSelected) {
      this.qe.addQuoteOptionCreditedQuoteKitPart({
        quoteKitPart: this.qkp,
        quoteOption: this.qop,
      });
    } else {
      this.qe.removeQuoteOptionCreditedQuoteKitPart({
        quoteKitPart: this.qkp,
        quoteOption: this.qop,
      });
    }
  }

  isSelected() {
    const exists = this.qe.getCreditedPartFromQuoteKitPart({
      quoteKitPart: this.qkp,
      quoteOption: this.qop,
    });
    return exists != null;
  }

  isIndeterminate() {
    return this.qe.isQuoteKitPartsChildrenCredited({
      quoteOption: this.qop,
      quoteKitPart: this.qkp,
    });
  }

  getTagColor(tagName: string) {
    return GetColorFromString(tagName);
  }
}
