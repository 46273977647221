<mat-form-field
  *ngIf="!useNativeInput"
  [appearance]="ffAppearance"
  #formFieldElement
  class="growinput-ff"
>
  <mat-label>{{ placeholder }}</mat-label>
  <input
    #inputElement
    [(formControl)]="control"
    [type]="type"
    [pattern]="pattern"
    (input)="inputEvent($event)"
    (change)="changeEvent($event)"
    (blur)="blurEvent($event)"
    (focus)="focusEvent($event)"
    (keydown)="keydownEvent($event)"
    (keyup)="keyupEvent($event)"
    ngDefaultControl
    matInput
    [class.outline]="ffAppearance == 'outline'"
    class="growinput-in"
  />
</mat-form-field>
<span class="growinput">
  <input
    *ngIf="useNativeInput"
    #inputElement
    [(formControl)]="control"
    [type]="type"
    [pattern]="pattern"
    (input)="inputEvent($event)"
    (change)="changeEvent($event)"
    (blur)="blurEvent($event)"
    (focus)="focusEvent($event)"
    (keydown)="keydownEvent($event)"
    (keyup)="keyupEvent($event)"
    matInput
    class="growinput-in"
  />
  <mat-icon
    *ngIf="!hideIcon"
    (click)="
      inputElement.nativeElement.focus();
      inputElement.nativeElement.setSelectionRange(
        inputElement.nativeElement.value.length,
        inputElement.nativeElement.value.length
      )
    "
    aria-hidden="false"
    class="growinput-i"
  >
    edit
  </mat-icon>
</span>
