<div>
  <div class="controls">
    <!-- <app-searchable-msel
      [selectedOptions]="assigned"
      (onSelectChange)="assigned.setValue($event.value)"
      placeholder="Report"
      [options]="assignedOptions"
      [disabled]="salesOrdersLoading"
      class="suprep-card-content-controls-ff"
    ></app-searchable-msel> -->
    <mat-form-field appearance="outline">
      <mat-label>Enter a date range</mat-label>
      <mat-date-range-input [rangePicker]="picker">
        <input matStartDate placeholder="Start date" [(ngModel)]="startDate" />
        <input matEndDate placeholder="End date" [(ngModel)]="endDate" />
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>

    <button
      mat-button
      mat-flat-button
      color="primary"
      (click)="getInformation()"
    >
      Refresh
    </button>
  </div>
  <div class="alerts">
    <canvas
      id="barChartByDate"
      style="max-width: 700px; max-height: 300px"
    ></canvas>
    <div class="upcomingList">
      <h3>
        {{ upcomingDue.length }} Scheduled and Not Built
        {{ Today | date: 'MM/dd/yyyy' }}
      </h3>
      <ul *ngIf="upcomingDue.length > 0">
        <li *ngFor="let upcoming of upcomingDue">
          {{ upcoming.SalesOrderNo }} -
          {{ upcoming.UDF_DATE_SCHEDULED | date: 'MM/dd/yyyy' }} -
          {{ upcoming.UDF_ASSIGNED_TO_BUILD }}
        </li>
      </ul>
    </div>
  </div>
  <app-data-table
    [rawData]="salesOrders"
    [tableColumns]="[
      { col: 'SalesOrderNo', value: 'SalesOrderNo', title: 'SO#', size: 'sm'},
      { col: 'WTNumber', value: 'WTNumber', title: 'WT#', size: 'xs'},
      { col: 'UDF_DATE_SCHEDULED', value: normalizeDate, title: 'Date Scheduled', size: 'sm'},
      { col: 'WarehouseCode', value: 'WarehouseCode', title: 'WH', size: 'xs'},
      { col: 'BillToName', value: 'BillToName', title: 'Customer', size: 'sm'},
      { col: 'UDF_SUBDIVISION', value: 'UDF_SUBDIVISION', title: 'Subdivision', size: 'md'},
      { col: 'ShipToAddress1', value: 'ShipToAddress1', title: 'Address', size: 'md'},
      { col: 'UDF_ASSIGNED_TO_BUILD', value: 'UDF_ASSIGNED_TO_BUILD', title: 'Assigned', size: 'sm'},
      { col: 'UDF_TYPE', value: 'UDF_TYPE', title: 'Type', size: 'sm'},
      { col: 'TechName', value: 'TechName', title: 'Tech Name', size: 'md'},
      { col: 'UDF_LEADMAN', value: 'UDF_LEADMAN', title: 'Leadman', size: 'md'},
      { col: 'StatusCode', value: 'StatusCode', title: 'Status Code', size: 'xs'},
      { col: 'PhasesBuilt', value: 'PhasesBuilt', title: 'Phases Built', size: 'xs'},
    ]"
    [inColumns]="[
      { col: 'SalesOrderNo', value: 'SalesOrderNo', title: 'SalesOrderNo' },
    ]"
    [mselColumns]="[
    { col: 'UDF_ASSIGNED_TO_BUILD',  title: 'Assigned' },
    { col: 'UDF_TYPE',  title: 'Type' },
    { col: 'BillToName', title: 'Customer' },
    { col: 'StatusCode', title: 'StatusCode' },
  ]"
    [drangeColumns]="[
      { col: 'UDF_DATE_SCHEDULED', value: 'UDF_DATE_SCHEDULED', title: 'Date Scheduled', range: {min: DateContrlMin, max: DateContrlMax}},
    ]"
    [isLoading]="salesOrdersLoading"
    [includeColumnSelector]="true"
    [searchOnChange]="true"
    accordianControls="true"
    accordianScreenSize="smd"
    title="Scheduled Roughin"
    [pdfTitle]="'pdfTitle'"
    [includePdfDownload]="true"
  ></app-data-table>
</div>
