<div class="container pb-5">
  <div class="card my-5 shadow-lg">
    <div class="row px-3 pt-3 pb-1" id="house-navigation">
      <div class="col-2 offset-1 p-0">
        <div class="list-group list-group-horizontal">
          <button
            class="list-group-item list-group-item-action px-0 flex-fill bg-secondary text-center text-dark"
            (click)="slideSpecific('Info')"
            [disabled]="!addressLoaded"
          >
            Info
          </button>
        </div>
      </div>
      <div class="col-2 p-0">
        <div class="list-group list-group-horizontal">
          <button
            class="list-group-item list-group-item-action px-0 flex-fill bg-secondary text-center text-dark shadow-none"
            (click)="slideSpecific('Phases')"
            [disabled]="!addressLoaded"
          >
            Phases
          </button>
        </div>
      </div>
      <div class="col-2 p-0">
        <div class="list-group list-group-horizontal">
          <button
            class="list-group-item list-group-item-action px-0 flex-fill bg-secondary text-center text-dark"
            (click)="slideSpecific('Parts')"
            [disabled]="!addressLoaded"
          >
            Parts
          </button>
        </div>
      </div>
      <div class="col-2 p-0">
        <div class="list-group list-group-horizontal">
          <button
            class="list-group-item list-group-item-action px-0 flex-fill bg-secondary text-center text-dark"
            (click)="slideSpecific('Labor')"
            [disabled]="!addressLoaded"
          >
            Labor
          </button>
        </div>
      </div>
      <div class="col-2 p-0">
        <div class="list-group list-group-horizontal">
          <button
            class="list-group-item list-group-item-action px-0 flex-fill bg-secondary text-center text-dark"
            (click)="slideSpecific('Review')"
            [disabled]="!addressLoaded"
          >
            Review
          </button>
        </div>
      </div>
      <div
        class="moving-tab position-absolute col-2 p-0"
        style="z-index: 4"
        [ngClass]="{
          'offset-1': sliderOne,
          'offset-3': sliderTwo,
          'offset-5': sliderThree,
          'offset-7': sliderFour,
          'offset-9': sliderFive
        }"
      >
        <ul class="list-group list-group-horizontal">
          <li
            class="list-group-item flex-fill bg-primary text-center text-light font-weight-bold h4 mb-0"
          >
            {{ currentSlideName }}
          </li>
        </ul>
      </div>
    </div>

    <div class="card-body">
      <div class="pl-3 pr-3 pb-3 text-center" id="house-slide-content">
        <ng-container *ngIf="sliderOne">
          <h4 class="pb-1">Information about house</h4>

          <div class="row mb-1" id="addressInfo">
            <div class="col-sm-3 pt-2">
              <label for="address">Address</label>
            </div>
            <div class="col-sm-5">
              <div class="col">
                <input
                  class="form-control"
                  [(ngModel)]="addressName"
                  (typeaheadOnSelect)="addressSelected($event)"
                  [typeahead]="addressList"
                  typeaheadOptionField="name"
                  placeholder="addressList"
                  autocomplete="off"
                />
                <small *ngIf="houseLoading"
                  >Getting {{ addressName }} Info...</small
                >
              </div>
            </div>
            <div class="col-sm-2">
              <div class="col">
                <button
                  class="btn btn-primary"
                  [disabled]="houseLoading || !addressLoaded"
                  (click)="runHouseFix()"
                >
                  Repair House
                </button>
              </div>
            </div>
          </div>
          <ng-container *ngIf="addressLoaded">
            <div class="row mb-1" id="typeInfo">
              <div class="col-sm-3 pt-2">
                <label for="type">Type</label>
              </div>
              <div class="col-sm-7">
                <div class="col">
                  <input
                    class="form-control"
                    (click)="$event.target.select()"
                    [(ngModel)]="typeName"
                    (typeaheadOnSelect)="typeSelected($event)"
                    [typeahead]="typeList"
                    typeaheadOptionField="name"
                    placeholder="typeList"
                    autocomplete="off"
                  />
                </div>
                <!-- <div class="btn-group col" dropdown>
                  <button dropdownToggle type="button" class="btn btn-outline-dark btn-lg dropdown-toggle text-capitalize">
                    {{ houseInfo.type }} Selected <span class="caret"></span>
                  </button>
                  <ul *dropdownMenu class="dropdown-menu dropdown-menu-right scrollable-menu" role="menu">
                    <li role="menuitem" *ngFor="let type of typeList; let typeIndex = index">
                      <a class="dropdown-item text-capitalize" (click)="typeSelected(type.name, typeIndex)">
                        <span>{{ type.name }}</span>
                        <i *ngIf="type.selected" class="fas fa-check fa-border fa-pull-right"></i>
                      </a>
                    </li>
                  </ul>
                </div> -->
              </div>
            </div>

            <div class="row mb-1" id="planInfo">
              <div class="col-sm-3 pt-2">
                <label for="plan">Plan number</label>
              </div>
              <div class="col-sm-7">
                <div class="col">
                  <input
                    class="form-control"
                    (click)="$event.target.select()"
                    [(ngModel)]="planName"
                    (typeaheadOnSelect)="planSelected($event)"
                    [typeahead]="planList"
                    typeaheadOptionField="name"
                    placeholder="planList"
                    autocomplete="off"
                  />
                </div>
                <!-- <div class="btn-group col" dropdown>
                  <button dropdownToggle type="button" class="btn btn-outline-dark btn-lg dropdown-toggle text-capitalize">
                    {{ houseInfo.planNumber }} Selected <span class="caret"></span>
                  </button>
                  <ul *dropdownMenu class="dropdown-menu dropdown-menu-right scrollable-menu" role="menu">
                    <li role="menuitem" *ngFor="let plan of planList; let planIndex = index">
                      <a class="dropdown-item text-capitalize" (click)="planSelected(plan.name, planIndex)">
                        <span>{{ plan.name }}</span>
                        <i *ngIf="plan.selected" class="fas fa-check fa-border fa-pull-right"></i>
                      </a>
                    </li>
                  </ul>
                </div> -->
              </div>
            </div>

            <div class="row mb-1" id="squareFootage">
              <div class="col-sm-3 pt-2">
                <label for="plan">Square Footage</label>
              </div>
              <div class="col-sm-7">
                <div class="col">
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      [(ngModel)]="houseInfo.squareFootage"
                      placeholder="4096"
                      autocomplete="off"
                    />
                    <div class="input-group-append">
                      <span class="input-group-text">sqft</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mb-1" id="WHC">
              <div class="col-sm-3 pt-2">
                <label for="plan">Warehouse/City</label>
              </div>
              <div class="col-sm-7">
                <div class="col">
                  <input
                    class="form-control"
                    (click)="$event.target.select()"
                    [(ngModel)]="warehouseName"
                    (typeaheadOnSelect)="warehouseSelected($event)"
                    [typeahead]="WhC"
                    typeaheadOptionField="name"
                    placeholder="Warehouse"
                    autocomplete="off"
                  />
                </div>
                <!-- <div class="btn-group col" dropdown>
                  <button dropdownToggle type="button" class="btn btn-outline-dark btn-lg dropdown-toggle text-capitalize">
                    {{ houseInfo.planNumber }} Selected <span class="caret"></span>
                  </button>
                  <ul *dropdownMenu class="dropdown-menu dropdown-menu-right scrollable-menu" role="menu">
                    <li role="menuitem" *ngFor="let plan of planList; let planIndex = index">
                      <a class="dropdown-item text-capitalize" (click)="planSelected(plan.name, planIndex)">
                        <span>{{ plan.name }}</span>
                        <i *ngIf="plan.selected" class="fas fa-check fa-border fa-pull-right"></i>
                      </a>
                    </li>
                  </ul>
                </div> -->
              </div>
            </div>
            <div class="row mb-1" id="WHC">
              <div class="col-sm-3 pt-2">
                <label for="plan">Builder</label>
              </div>
              <div class="col-sm-7">
                <div class="col">
                  <input
                    class="form-control"
                    [(ngModel)]="houseInfo.builder"
                    [disabled]="true"
                  />
                </div>
              </div>
            </div>
            <div class="row mb-1" id="RB">
              <div class="col-sm-3 pt-2">
                <label for="RB">Last Reviewed By</label>
              </div>
              <div class="col-sm-7">
                <div class="col">
                  <input
                    class="form-control"
                    [(ngModel)]="houseInfo.fileReviewedBy"
                    [disabled]="true"
                  />
                </div>
              </div>
            </div>
            <div class="row mb-1" id="RBD">
              <div class="col-sm-3 pt-2">
                <label for="RBD">Review Date</label>
              </div>
              <div class="col-sm-7">
                <div class="col">
                  <input
                    class="form-control"
                    [(ngModel)]="reviewedByDate"
                    [disabled]="true"
                  />
                </div>
              </div>
            </div>

            <div class="row mb-1" id="seriesInfo">
              <div class="col-sm-3 pt-2">
                <label for="series">Series</label>
              </div>
              <div class="col-sm-7">
                <div class="btn-group col" dropdown>
                  <button
                    dropdownToggle
                    type="button"
                    class="btn btn-outline-dark btn-lg dropdown-toggle text-capitalize"
                  >
                    {{ houseInfo.series }} Selected <span class="caret"></span>
                  </button>
                  <ul
                    *dropdownMenu
                    class="dropdown-menu dropdown-menu-right scrollable-menu"
                    role="menu"
                  >
                    <li
                      role="menuitem"
                      *ngFor="let series of seriesList; let seriesIndex = index"
                    >
                      <a
                        class="dropdown-item text-capitalize"
                        (click)="seriesSelected(series.code, seriesIndex)"
                      >
                        <span>{{ series.name }}</span>
                        <i
                          *ngIf="series.selected"
                          class="fas fa-check fa-border fa-pull-right"
                        ></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="row mb-1" id="energyInfo">
              <div class="col-sm-3 pt-2">
                <label for="energy">Energy Type</label>
              </div>
              <div class="col-sm-7">
                <div class="btn-group col" dropdown>
                  <button
                    dropdownToggle
                    type="button"
                    class="btn btn-outline-dark btn-lg dropdown-toggle text-capitalize"
                  >
                    {{ houseInfo.energyType }} Selected
                    <span class="caret"></span>
                  </button>
                  <ul
                    *dropdownMenu
                    class="dropdown-menu dropdown-menu-right"
                    role="menu"
                  >
                    <li
                      role="menuitem"
                      *ngFor="let energy of energyList; let energyIndex = index"
                    >
                      <a
                        class="dropdown-item text-capitalize"
                        (click)="energySelected(energy.name, energyIndex)"
                      >
                        <span>{{ energy.name }}</span>
                        <i
                          *ngIf="energy.selected"
                          class="fas fa-check fa-border fa-pull-right"
                        ></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="row mb-1" id="garageInfo">
              <div class="col-sm-3 pt-2">
                <label for="garage">Garage Location</label>
              </div>
              <div class="col-sm-7">
                <div class="btn-group col" dropdown>
                  <button
                    dropdownToggle
                    type="button"
                    class="btn btn-outline-dark btn-lg dropdown-toggle text-capitalize"
                  >
                    {{ houseInfo.garageLocation }} Selected
                    <span class="caret"></span>
                  </button>
                  <ul
                    *dropdownMenu
                    class="dropdown-menu dropdown-menu-right"
                    role="menu"
                  >
                    <li
                      role="menuitem"
                      *ngFor="
                        let garage of garageLocationList;
                        let garageIndex = index
                      "
                    >
                      <a
                        class="dropdown-item text-capitalize"
                        (click)="garageSelected(garage.name, garageIndex)"
                      >
                        <span>{{ garage.name }}</span>
                        <i
                          *ngIf="garage.selected"
                          class="fas fa-check fa-border fa-pull-right"
                        ></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="row mb-1" id="StatusInfo">
              <div class="col-sm-3 pt-2">
                <label for="status">Status Information</label>
              </div>
              <div class="col-sm-7">
                <div class="row justify-content-center my-1">
                  <button
                    class="btn"
                    (click)="
                      ShowStatus ? (ShowStatus = false) : (ShowStatus = true)
                    "
                    [ngStyle]="{
                      'background-color': ShowStatus ? 'yellow' : 'red',
                      color: ShowStatus ? 'black' : 'white'
                    }"
                  >
                    <span *ngIf="!ShowStatus">Show Status ⯇</span>
                    <span *ngIf="ShowStatus">Hide Status ▼</span>
                  </button>
                </div>
                <div>
                  <ng-container *ngIf="ShowStatus">
                    <div
                      class="table-responsive row justify-content-center ml-1"
                    >
                      <table class="table table-hover">
                        <thead>
                          <th>Phase</th>
                          <th>Schedule/Promise(*) Date</th>
                          <th>Status</th>
                          <th>Status Date</th>
                          <th>Crew ID</th>
                        </thead>
                        <tbody>
                          <ng-container *ngFor="let phase of HouseStatus">
                            <tr>
                              <td>
                                {{ phase.phase + ' (' + phase.WTNum + ')' }}
                              </td>
                              <td>{{ phase.scheduleDate }}</td>
                              <td>{{ phase.status }}</td>
                              <td>{{ phase.statusDate }}</td>
                              <td>{{ phase.CrewID }}</td>
                            </tr>
                          </ng-container>
                        </tbody>
                      </table>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="sliderTwo">
          <h4 class="pb-1">Add Phases</h4>
          <div>
            <ul class="list-group">
              <ng-container
                *ngFor="
                  let phase of houseInfo.phases;
                  let phaseIndex = index;
                  let isFirst = first
                "
              >
                <ul class="list-group list-group-horizontal" *ngIf="isFirst">
                  <li
                    class="list-group-item py-1 col-2 col-md-2 col-lg-2 font-weight-bold text-light bg-primary"
                  >
                    Phase
                  </li>
                  <li
                    class="list-group-item py-1 col-2 col-md-2 col-lg-2 font-weight-bold text-light bg-primary"
                  >
                    WT#
                  </li>
                  <li
                    class="list-group-item py-1 col-2 col-md-2 col-lg-2 font-weight-bold text-center text-light bg-primary"
                  >
                    Template
                  </li>
                  <li
                    class="list-group-item py-1 col-2 col-md-2 col-lg-2 font-weight-bold text-center text-light bg-primary"
                  >
                    PO#
                  </li>
                  <li
                    class="list-group-item py-1 col-2 col-md-2 col-lg-2 font-weight-bold text-center text-light bg-primary"
                  >
                    Price
                  </li>
                  <li
                    class="list-group-item py-1 col-2 col-md-2 col-lg-2 font-weight-bold text-center text-light bg-primary"
                  >
                    Action
                  </li>
                </ul>
                <ul class="list-group list-group-horizontal">
                  <li
                    class="list-group-item py-2 col-2 col-md-2 col-lg-2 text-center"
                    [ngClass]="{ 'list-group-item-dark': phaseIndex % 2 !== 0 }"
                  >
                    {{ phase.parent.slice(1) | titlecase }}
                  </li>
                  <li
                    class="list-group-item py-2 col-2 col-md-2 col-lg-2 text-center"
                    [ngClass]="{ 'list-group-item-dark': phaseIndex % 2 !== 0 }"
                  >
                    {{ phase.wtNumber }}
                  </li>
                  <li
                    class="list-group-item py-2 col-2 col-md-2 col-lg-2 text-center"
                    [ngClass]="{ 'list-group-item-dark': phaseIndex % 2 !== 0 }"
                  >
                    {{
                      phase.importedTemplate === ''
                        ? phase.template
                        : phase.importedTemplate
                    }}
                  </li>
                  <li
                    class="list-group-item py-2 px-1 col-2 col-md-2 col-lg-2 text-center"
                    [ngClass]="{ 'list-group-item-dark': phaseIndex % 2 !== 0 }"
                  >
                    <input
                      type="text"
                      class="form-control text-center px-1"
                      name="poNumber"
                      (change)="phase.updated = true"
                      [(ngModel)]="phase.poNumber"
                      (click)="$event.target.select()"
                    />
                  </li>
                  <li
                    class="list-group-item py-2 px-1 col-2 col-md-2 col-lg-2 text-center"
                    [ngClass]="{ 'list-group-item-dark': phaseIndex % 2 !== 0 }"
                  >
                    <input
                      type="number"
                      class="form-control text-center px-1"
                      name="poNumber"
                      (change)="phase.updated = true"
                      [(ngModel)]="phase.price"
                      (click)="$event.target.select()"
                    />
                  </li>
                  <li
                    class="list-group-item py-2 col-2 col-md-2 col-lg-2 text-center"
                    [ngClass]="{ 'list-group-item-dark': phaseIndex % 2 !== 0 }"
                  >
                    <div class="row">
                      <div
                        class="p-0 pt-1"
                        [ngClass]="{
                          'col-6': phase.importedTemplate === '',
                          'col-4': phase.importedTemplate !== ''
                        }"
                      >
                        <a
                          class="btn p-0"
                          (click)="openModal(editPhase, phaseIndex)"
                          ><i class="fas fa-edit fa-lg text-primary"></i
                        ></a>
                      </div>
                      <div
                        class="p-0 pt-1"
                        [ngClass]="{
                          'col-6': phase.importedTemplate === '',
                          'col-4': phase.importedTemplate !== ''
                        }"
                      >
                        <a
                          class="btn p-0"
                          (click)="openModal(importTemplate, phaseIndex)"
                          ><i class="fas fa-file-import fa-lg text-info"></i
                        ></a>
                      </div>
                      <div
                        class="col-4 p-0 pt-1"
                        *ngIf="phase.importedTemplate !== ''"
                      >
                        <i
                          class="fas fa-times fa-lg text-success"
                          (click)="removePhase(phaseIndex)"
                        ></i>
                      </div>
                    </div>
                  </li>
                </ul>
              </ng-container>
              <ul class="list-group list-group-horizontal">
                <li
                  class="list-group-item py-2 px-1 col-2 col-md-2 col-lg-2 text-center"
                >
                  <input
                    class="form-control"
                    [(ngModel)]="newPhase.name"
                    (typeaheadOnSelect)="phaseItemSelected($event)"
                    [typeahead]="phaseList"
                    typeaheadOptionField="name"
                    placeHolder="Phase"
                    autocomplete="off"
                  />
                </li>
                <li
                  class="list-group-item py-2 px-1 col-2 col-md-2 col-lg-2 text-center"
                >
                  <input
                    type="text"
                    class="form-control px-1"
                    name="poNumber"
                    minlength="3"
                    maxlength="3"
                    [(ngModel)]="newPhase.wtNumber"
                    placeholder="WT#"
                  />
                </li>
                <li
                  class="list-group-item py-2 px-1 col-2 col-md-2 col-lg-2 text-center"
                >
                  <input
                    class="form-control"
                    [(ngModel)]="newPhase.templateName"
                    (typeaheadOnSelect)="phaseTemplateSelected($event)"
                    [typeahead]="templateList"
                    typeaheadOptionField="name"
                    placeholder="template"
                    autocomplete="off"
                  />
                </li>
                <li
                  class="list-group-item py-2 px-1 col-2 col-md-2 col-lg-2 text-center"
                >
                  <input
                    type="text"
                    class="form-control px-1"
                    name="poNumber"
                    [(ngModel)]="newPhase.poNumber"
                    placeholder="PO#"
                  />
                </li>
                <li
                  class="list-group-item py-2 px-1 col-2 col-md-2 col-lg-2 text-center"
                >
                  <input
                    type="Number"
                    class="form-control px-1"
                    name="poNumber"
                    [(ngModel)]="newPhase.price"
                    placeholder="Price"
                  />
                </li>
                <li
                  class="list-group-item py-2 px-1 col-2 col-md-2 col-lg-2 text-center"
                >
                  <label class="form-check-label pr-2" for="newPhase-vpo">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      name="newPhase-vpo"
                      id="newPhase-vpo-checkbox"
                      (change)="newPhaseVPOSelected($event)"
                    />
                    VPO?
                  </label>
                  <button
                    class="btn btn-primary"
                    (click)="addPhase()"
                    [disabled]="!phaseReady() || phaseLoading"
                  >
                    <span *ngIf="!phaseLoading">ADD</span
                    ><span *ngIf="phaseLoading"
                      ><i class="fas fa-sync fa-spin"></i
                    ></span>
                  </button>
                </li>
              </ul>
            </ul>
          </div>
        </ng-container>

        <ng-container *ngIf="sliderThree">
          <div class="row">
            <div class="col-4 offset-4 mr-0 text-center py-1">
              <h4>Add Parts</h4>
            </div>
            <div class="col-4 text-right text-light">
              <div class="mb-1" id="locationInfo">
                <div class="btn-group" dropdown>
                  <button
                    dropdownToggle
                    type="button"
                    class="btn btn-outline-dark btn-md dropdown-toggle text-capitalize"
                  >
                    Add Location <span class="caret"></span>
                  </button>
                  <ul
                    *dropdownMenu
                    class="dropdown-menu dropdown-menu-right scrollable-menu"
                    role="menu"
                  >
                    <li
                      role="menuitem"
                      *ngFor="
                        let location of locationList;
                        let locationIndex = index
                      "
                    >
                      <a
                        class="dropdown-item text-capitalize"
                        (click)="addHouseLocation(location.code, locationIndex)"
                      >
                        <span>{{ location.name }}</span>
                        <i
                          *ngIf="location.selected"
                          class="fas fa-check fa-border fa-pull-right"
                        ></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div>
            <ul class="list-group">
              <ng-container
                *ngFor="
                  let location of houseLocationList;
                  let locationIndex = index;
                  let isLocationFirst = first
                "
              >
                <li
                  class="list-group-item p-1 col-12 font-weight-bold text-light text-left"
                  [ngClass]="
                    !location.show ? 'list-group-item-dark' : 'bg-primary'
                  "
                >
                  <div class="row">
                    <div
                      class="col-8 offset-2 mr-0 text-center text-light py-1"
                    >
                      <h4 class="m-0">
                        {{
                          location.code === ''
                            ? 'No Location'
                            : (location.code | titlecase)
                        }}
                      </h4>
                    </div>
                    <div class="col-2 p-0 text-center text-light">
                      <a
                        class="btn btn-sm"
                        (click)="showItemsToggle(locationIndex)"
                      >
                        <i
                          class="fas fa-ellipsis-h fa-lg"
                          *ngIf="!location.show"
                        ></i>
                        <i
                          class="fas fa-ellipsis-v fa-lg"
                          *ngIf="location.show"
                        ></i>
                      </a>
                    </div>
                  </div>
                </li>
                <ng-container *ngIf="location.show">
                  <ng-container
                    *ngFor="
                      let phase of houseInfo.phases;
                      let phaseIndex = index;
                      let isPhaseFirst = first
                    "
                  >
                    <li
                      class="list-group-item bg-dark p-0 pl-1 col-sm-12 text-sm font-weight-bold text-light text-left"
                      *ngIf="
                        stepLocationItemCount(
                          phaseIndex,
                          locationIndex,
                          '010'
                        ) >= 0
                      "
                    >
                      <div class="row">
                        <div class="col-10 mr-0">
                          {{ phase.parent.slice(1) | titlecase }}
                        </div>
                        <div
                          class="col-2 text-center text-light"
                          *ngIf="
                            stepLocationItemCount(
                              phaseIndex,
                              locationIndex,
                              '010'
                            ) <= 0
                          "
                        >
                          <a
                            class="btn btn-sm py-0"
                            (click)="
                              stepAddBlankItem(phaseIndex, locationIndex, '010')
                            "
                            ><i class="fas fa-plus"></i
                          ></a>
                        </div>
                      </div>
                    </li>
                    <ng-container
                      *ngFor="let step of phase.steps; let stepIndex = index"
                    >
                      <ng-container *ngIf="step.wtStep === '010'">
                        <ul class="list-group list-group-striped">
                          <ng-container
                            *ngFor="
                              let existingItem of step.existingItems;
                              let existingItemIndex = index
                            "
                          >
                            <li
                              class="list-group-item py-1"
                              *ngIf="
                                existingItem.location.toLocaleLowerCase() ===
                                location.code.toLocaleLowerCase()
                              "
                            >
                              <ul class="list-group list-group-horizontal">
                                <li
                                  class="list-group-item col-2 border-0 p-0 py-1 text-sm font-weight-bold bg-transparent"
                                >
                                  {{ existingItem.itemCode }}
                                </li>
                                <li
                                  class="list-group-item col-3 border-0 p-0 py-1 text-sm font-weight-bold bg-transparent"
                                >
                                  {{ existingItem.description }}
                                </li>
                                <li
                                  class="list-group-item col-2 border-0 p-1 text-sm font-weight-bold bg-transparent"
                                >
                                  <input
                                    type="text"
                                    class="form-control text-center"
                                    name="qtyNeeded"
                                    (click)="$event.target.select()"
                                    [(ngModel)]="existingItem.qty"
                                    (change)="existingItem.updated = true"
                                    autocomplete="off"
                                  />
                                </li>
                                <li
                                  class="list-group-item col-3 border-0 p-1 text-sm font-weight-bold bg-transparent"
                                >
                                  <input
                                    type="text"
                                    class="form-control text-center"
                                    name="notes"
                                    (click)="$event.target.select()"
                                    [(ngModel)]="existingItem.comment"
                                    (change)="existingItem.updated = true"
                                    placeholder="Notes"
                                    autocomplete="off"
                                  />
                                </li>
                                <li
                                  class="list-group-item col-2 border-0 p-0 py-1 text-sm font-weight-bold bg-transparent"
                                >
                                  <div *ngIf="existingItem.PartOfTemp === 'No'">
                                    <span class="btn p-0"
                                      ><i class="fas fa-tags"></i
                                    ></span>
                                  </div>
                                </li>
                              </ul>
                            </li>
                          </ng-container>
                          <ng-container
                            *ngFor="
                              let newItem of step.addedItems;
                              let addedItemIndex = index
                            "
                          >
                            <li
                              class="list-group-item py-1"
                              *ngIf="
                                newItem.location.toLocaleLowerCase() ===
                                  location.code.toLocaleLowerCase() &&
                                newItem.itemCode !== ''
                              "
                              [ngStyle]="
                                newItem.inactive == 'Y'
                                  ? { 'background-color': 'red' }
                                  : {}
                              "
                            >
                              <ul class="list-group list-group-horizontal">
                                <li
                                  class="list-group-item col-2 border-0 p-0 py-1 text-sm font-weight-bold bg-transparent"
                                >
                                  {{ newItem.itemCode }}
                                </li>
                                <li
                                  class="list-group-item col-3 border-0 p-0 py-1 text-sm font-weight-bold bg-transparent"
                                >
                                  {{ newItem.description }}
                                </li>
                                <li
                                  class="list-group-item col-2 border-0 p-1 text-sm font-weight-bold bg-transparent"
                                >
                                  <input
                                    type="text"
                                    class="form-control text-center"
                                    name="qtyNeeded"
                                    (click)="$event.target.select()"
                                    [(ngModel)]="newItem.qty"
                                    autocomplete="off"
                                  />
                                </li>
                                <li
                                  class="list-group-item col-3 border-0 p-1 text-sm font-weight-bold bg-transparent"
                                >
                                  <input
                                    type="text"
                                    class="form-control text-center"
                                    name="notes"
                                    (click)="$event.target.select()"
                                    [(ngModel)]="newItem.comment"
                                    placeholder="Notes"
                                    autocomplete="off"
                                  />
                                </li>
                                <li
                                  class="list-group-item col-2 border-0 p-0 my-auto bg-transparent"
                                >
                                  <div class="row m-0">
                                    <div
                                      class="col-4 offset-2 p-0 pt-2 text-center"
                                    >
                                      <a
                                        class="btn p-0"
                                        (click)="
                                          openModal(
                                            editItem,
                                            phaseIndex,
                                            stepIndex,
                                            addedItemIndex
                                          )
                                        "
                                        ><i
                                          class="fas fa-edit fa-lg text-primary"
                                        ></i
                                      ></a>
                                    </div>
                                    <div class="col-4 p-0 pt-2 text-center">
                                      <a
                                        class="btn p-0"
                                        (click)="
                                          stepItemRemove(
                                            phaseIndex,
                                            stepIndex,
                                            addedItemIndex
                                          )
                                        "
                                        ><i
                                          class="fas fa-trash-alt fa-lg text-danger"
                                        ></i
                                      ></a>
                                      <!-- <button class="btn btn-danger btn-sm" (click)="stepItemRemove(phaseIndex, stepIndex, addedItemIndex)"><span><i class="fas fa-trash-alt"></i></span></button> -->
                                    </div>
                                    <div
                                      *ngIf="newItem.upgrade === 'true'"
                                      class="col-2 p-0 pt-2 text-center"
                                    >
                                      <span class="btn p-0"
                                        ><i class="fas fa-tags"></i
                                      ></span>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </li>
                          </ng-container>
                        </ul>
                        <li
                          class="list-group-item py-1"
                          *ngIf="
                            stepLocationItemCount(
                              phaseIndex,
                              locationIndex,
                              '010'
                            ) > 0
                          "
                        >
                          <ul class="list-group list-group-horizontal">
                            <li
                              class="list-group-item col-5 border-0 px-1 pb-0 pt-2 text-sm font-weight-bold"
                            >
                              <input
                                class="form-control"
                                [(ngModel)]="
                                  step.newItem[location.code].itemSelected
                                "
                                (click)="$event.target.select()"
                                (typeaheadOnSelect)="
                                  stepItemSelected(
                                    $event,
                                    phaseIndex,
                                    stepIndex,
                                    locationIndex
                                  )
                                "
                                [typeaheadMinLength]="0"
                                [typeahead]="itemList"
                                typeaheadOptionField="name"
                                typeaheadOptionsLimit="150"
                                [typeaheadScrollable]="true"
                                [typeaheadOptionsInScrollableView]="5"
                                placeholder="Item"
                                autocomplete="off"
                              />
                            </li>
                            <li
                              class="list-group-item col-2 border-0 px-1 pb-0 pt-2 text-sm font-weight-bold"
                            >
                              <input
                                type="text"
                                class="form-control text-center"
                                name="qtyNeeded"
                                (click)="$event.target.select()"
                                [(ngModel)]="step.newItem[location.code].qty"
                                autocomplete="off"
                              />
                            </li>
                            <li
                              class="list-group-item col-3 border-0 p-0 pt-2 text-sm font-weight-bold"
                            >
                              <input
                                type="text"
                                class="form-control text-center"
                                name="notes"
                                (click)="$event.target.select()"
                                [(ngModel)]="step.newItem[location.code].notes"
                                placeholder="Notes"
                                autocomplete="off"
                              />
                            </li>
                            <li
                              class="list-group-item col-2 border-0 px-1 pb-0 pt-2"
                            >
                              <div class="row m-0">
                                <div class="col-6 p-0 pt-1">
                                  <!-- This will allow a BA to add just a kit without exploding it. Removed the option after talking to Sandra.
                                    (click)="stepItemKitToggle(phaseIndex, stepIndex, locationIndex)" -->
                                  <a
                                    class="btn p-0"
                                    *ngIf="
                                      step.newItem[location.code].kit == 'Y'
                                    "
                                    ><span
                                      *ngIf="
                                        step.newItem[location.code].explode ===
                                        'Y'
                                      "
                                      ><i
                                        class="fas fa-bomb fa-2x text-dark"
                                      ></i></span
                                    ><span
                                      class="fa-stack"
                                      *ngIf="
                                        step.newItem[location.code].explode !==
                                        'Y'
                                      "
                                      ><i
                                        class="fas fa-bomb fa-stack-1x text-dark"
                                      ></i
                                      ><i
                                        class="fas fa-ban fa-stack-2x text-primary"
                                      ></i></span
                                  ></a>
                                </div>
                                <!-- ! ADDEDD VPO SELECTION FOR PARTS -->
                                <!-- <div class="col-4 p-0 pt-1">
                                  <div class="form-check pr-2">
                                    <input (change)="stepItemVPOSelected(phaseIndex, stepIndex, locationIndex, $event)" class="form-check-input" type="checkbox" name="vpoInput" id="vpoInput">
                                    <label for="vpoInput" class="form-check-label">VPO?</label>
                                  </div>
                                </div> -->
                                <div class="col-6 p-0 pl-1 pt-1">
                                  <button
                                    class="btn btn-primary"
                                    (click)="
                                      addItem(
                                        phaseIndex,
                                        stepIndex,
                                        locationIndex
                                      )
                                    "
                                    [disabled]="
                                      !stepItemReady(
                                        phaseIndex,
                                        stepIndex,
                                        locationIndex
                                      ) || step.loading
                                    "
                                  >
                                    <span *ngIf="!step.loading">ADD</span
                                    ><span *ngIf="step.loading"
                                      ><i class="fas fa-sync fa-spin"></i
                                    ></span>
                                  </button>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </li>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ul>
          </div>
        </ng-container>

        <ng-container *ngIf="sliderFour">
          <div class="row">
            <div class="col-4 offset-4 mr-0 text-center py-1">
              <h4>Add Labor</h4>
            </div>
            <div class="col-4 text-right text-light">
              <div class="mb-1" id="locationInfo">
                <div class="btn-group" dropdown>
                  <button
                    dropdownToggle
                    type="button"
                    class="btn btn-outline-dark btn-md dropdown-toggle text-capitalize"
                  >
                    Add Location <span class="caret"></span>
                  </button>
                  <ul
                    *dropdownMenu
                    class="dropdown-menu dropdown-menu-right scrollable-menu"
                    role="menu"
                  >
                    <li
                      role="menuitem"
                      *ngFor="
                        let location of locationList;
                        let locationIndex = index
                      "
                    >
                      <a
                        class="dropdown-item text-capitalize"
                        (click)="addHouseLocation(location.code, locationIndex)"
                      >
                        <span>{{ location.name }}</span>
                        <i
                          *ngIf="location.selected"
                          class="fas fa-check fa-border fa-pull-right"
                        ></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div>
            <ul class="list-group">
              <ng-container
                *ngFor="
                  let location of houseLocationList;
                  let locationIndex = index;
                  let isLocationFirst = first
                "
              >
                <li
                  class="list-group-item p-1 col-12 font-weight-bold text-light text-left"
                  [ngClass]="
                    !location.show ? 'list-group-item-dark' : 'bg-primary'
                  "
                >
                  <div class="row">
                    <div
                      class="col-8 offset-2 mr-0 text-center text-light py-1"
                    >
                      <h4 class="m-0">
                        {{
                          location.code === ''
                            ? 'No Location'
                            : (location.code | titlecase)
                        }}
                      </h4>
                    </div>
                    <div class="col-2 p-0 text-center text-light">
                      <a
                        class="btn btn-sm"
                        (click)="showItemsToggle(locationIndex)"
                      >
                        <i
                          class="fas fa-ellipsis-h fa-lg"
                          *ngIf="!location.show"
                        ></i>
                        <i
                          class="fas fa-ellipsis-v fa-lg"
                          *ngIf="location.show"
                        ></i>
                      </a>
                    </div>
                  </div>
                </li>
                <ng-container *ngIf="location.show">
                  <ng-container
                    *ngFor="
                      let phase of houseInfo.phases;
                      let phaseIndex = index;
                      let isPhaseFirst = first
                    "
                  >
                    <li
                      class="list-group-item bg-dark p-0 pl-1 col-sm-12 text-sm font-weight-bold text-light text-left"
                      *ngIf="
                        stepLocationItemCount(
                          phaseIndex,
                          locationIndex,
                          '020'
                        ) >= 0
                      "
                    >
                      <div class="row">
                        <div class="col-10 mr-0">
                          {{ phase.parent.slice(1) | titlecase }}
                        </div>
                        <div
                          class="col-2 text-center text-light"
                          *ngIf="
                            stepLocationItemCount(
                              phaseIndex,
                              locationIndex,
                              '020'
                            ) <= 0
                          "
                        >
                          <a
                            class="btn btn-sm py-0"
                            (click)="
                              stepAddBlankItem(phaseIndex, locationIndex, '020')
                            "
                            ><i class="fas fa-plus"></i
                          ></a>
                        </div>
                      </div>
                    </li>
                    <ng-container
                      *ngFor="let step of phase.steps; let stepIndex = index"
                    >
                      <ng-container *ngIf="step.wtStep === '020'">
                        <ul class="list-group list-group-striped">
                          <ng-container
                            *ngFor="
                              let existingItem of step.existingItems;
                              let existingItemIndex = index
                            "
                          >
                            <li
                              class="list-group-item py-1"
                              *ngIf="
                                existingItem.location.toLocaleLowerCase() ===
                                location.code.toLocaleLowerCase()
                              "
                            >
                              <ul class="list-group list-group-horizontal">
                                <li
                                  class="list-group-item col-2 border-0 p-0 py-1 text-sm font-weight-bold bg-transparent"
                                >
                                  {{ existingItem.itemCode }}
                                </li>
                                <li
                                  class="list-group-item col-3 border-0 p-0 py-1 text-sm font-weight-bold bg-transparent"
                                >
                                  {{ existingItem.description }}
                                </li>
                                <li
                                  class="list-group-item col-2 border-0 p-1 text-sm font-weight-bold bg-transparent"
                                >
                                  <input
                                    type="text"
                                    class="form-control text-center"
                                    name="qtyNeeded"
                                    (click)="$event.target.select()"
                                    [(ngModel)]="existingItem.qty"
                                    (change)="existingItem.updated = true"
                                    autocomplete="off"
                                  />
                                </li>
                                <li
                                  class="list-group-item col-3 border-0 p-1 text-sm font-weight-bold bg-transparent"
                                >
                                  <input
                                    type="text"
                                    class="form-control text-center"
                                    name="notes"
                                    (click)="$event.target.select()"
                                    [(ngModel)]="existingItem.comment"
                                    (change)="existingItem.updated = true"
                                    placeholder="Notes"
                                    autocomplete="off"
                                  />
                                </li>
                                <li
                                  class="list-group-item col-2 border-0 p-0 my-auto bg-transparent"
                                >
                                  <button
                                    class="btn btn-danger btn-sm"
                                    (click)="
                                      stepItemRemove(
                                        phaseIndex,
                                        stepIndex,
                                        existingItemIndex
                                      )
                                    "
                                  >
                                    <span
                                      ><i class="fas fa-trash-alt"></i
                                    ></span>
                                  </button>
                                </li>
                                <li
                                  class="list-group-item col-0 border-0 p-0 py-1 text-sm font-weight-bold bg-transparent"
                                >
                                  <div *ngIf="existingItem.PartOfTemp === 'No'">
                                    <span class="btn p-0"
                                      ><i class="fas fa-tags"></i
                                    ></span>
                                  </div>
                                </li>
                              </ul>
                            </li>
                          </ng-container>
                          <ng-container
                            *ngFor="
                              let newItem of step.addedItems;
                              let addedItemIndex = index
                            "
                          >
                            <li
                              class="list-group-item py-1"
                              *ngIf="
                                newItem.location.toLocaleLowerCase() ===
                                  location.code.toLocaleLowerCase() &&
                                newItem.itemCode !== ''
                              "
                            >
                              <ul class="list-group list-group-horizontal">
                                <li
                                  class="list-group-item col-2 border-0 p-0 py-1 text-sm font-weight-bold bg-transparent"
                                >
                                  {{ newItem.itemCode }}
                                </li>
                                <li
                                  class="list-group-item col-3 border-0 p-0 py-1 text-sm font-weight-bold bg-transparent"
                                >
                                  {{ newItem.description }}
                                </li>
                                <li
                                  class="list-group-item col-2 border-0 p-1 text-sm font-weight-bold bg-transparent"
                                >
                                  <input
                                    type="text"
                                    class="form-control text-center"
                                    name="qtyNeeded"
                                    (click)="$event.target.select()"
                                    [(ngModel)]="newItem.qty"
                                    autocomplete="off"
                                  />
                                </li>
                                <li
                                  class="list-group-item col-3 border-0 p-1 text-sm font-weight-bold bg-transparent"
                                >
                                  <input
                                    type="text"
                                    class="form-control text-center"
                                    name="notes"
                                    (click)="$event.target.select()"
                                    [(ngModel)]="newItem.comment"
                                    placeholder="Notes"
                                    autocomplete="off"
                                  />
                                </li>
                                <li
                                  class="list-group-item col-2 border-0 p-0 my-auto bg-transparent"
                                >
                                  <button
                                    class="btn btn-danger btn-sm"
                                    (click)="
                                      stepItemRemove(
                                        phaseIndex,
                                        stepIndex,
                                        addedItemIndex
                                      )
                                    "
                                  >
                                    <span
                                      ><i class="fas fa-trash-alt"></i
                                    ></span>
                                  </button>
                                </li>
                                <li
                                  *ngIf="newItem.upgrade === 'true'"
                                  class="list-group-item col-0 border-0 p-0 my-auto bg-transparent"
                                >
                                  <span><i class="fas fa-tags"></i></span>
                                </li>
                              </ul>
                            </li>
                          </ng-container>
                        </ul>
                        <li
                          class="list-group-item py-1"
                          *ngIf="
                            stepLocationItemCount(
                              phaseIndex,
                              locationIndex,
                              '020'
                            ) > 0
                          "
                        >
                          <ul class="list-group list-group-horizontal">
                            <li
                              class="list-group-item col-5 border-0 px-1 pb-0 pt-2 text-sm font-weight-bold"
                            >
                              <input
                                class="form-control"
                                [(ngModel)]="
                                  step.newItem[location.code].itemSelected
                                "
                                (click)="$event.target.select()"
                                (typeaheadOnSelect)="
                                  stepItemSelected(
                                    $event,
                                    phaseIndex,
                                    stepIndex,
                                    locationIndex
                                  )
                                "
                                [typeaheadMinLength]="0"
                                [typeahead]="itemList"
                                typeaheadOptionField="name"
                                typeaheadOptionsLimit="150"
                                [typeaheadScrollable]="true"
                                [typeaheadOptionsInScrollableView]="5"
                                placeholder="Item"
                                autocomplete="off"
                              />
                            </li>
                            <li
                              class="list-group-item col-2 border-0 px-1 pb-0 pt-2 text-sm font-weight-bold"
                            >
                              <input
                                type="text"
                                class="form-control text-center"
                                name="qtyNeeded"
                                (click)="$event.target.select()"
                                [(ngModel)]="step.newItem[location.code].qty"
                                autocomplete="off"
                              />
                            </li>
                            <li
                              class="list-group-item col-3 border-0 p-0 pt-2 text-sm font-weight-bold"
                            >
                              <input
                                type="text"
                                class="form-control text-center"
                                name="notes"
                                (click)="$event.target.select()"
                                [(ngModel)]="step.newItem[location.code].notes"
                                placeholder="Notes"
                                autocomplete="off"
                              />
                            </li>
                            <li
                              class="list-group-item col-2 border-0 px-1 pb-0 pt-2"
                            >
                              <button
                                class="btn btn-primary"
                                (click)="
                                  addItem(phaseIndex, stepIndex, locationIndex)
                                "
                                [disabled]="
                                  !stepItemReady(
                                    phaseIndex,
                                    stepIndex,
                                    locationIndex
                                  ) || step.loading
                                "
                              >
                                <span *ngIf="!step.loading">ADD</span
                                ><span *ngIf="step.loading"
                                  ><i class="fas fa-sync fa-spin"></i
                                ></span>
                              </button>
                            </li>
                          </ul>
                        </li>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ul>
          </div>
        </ng-container>

        <ng-container *ngIf="sliderFive">
          <h4 class="pb-1">Review</h4>
          <div>
            <ul class="list-group">
              <ng-container
                *ngFor="
                  let location of houseLocationList;
                  let locationIndex = index;
                  let isLocationFirst = first
                "
              >
                <li
                  class="list-group-item p-1 col-12 font-weight-bold text-light text-left"
                  [ngClass]="
                    !location.show ? 'list-group-item-dark' : 'bg-primary'
                  "
                >
                  <div class="row">
                    <div
                      class="col-8 offset-2 mr-0 text-center text-light py-1"
                    >
                      <h4 class="m-0">
                        {{
                          location.code === ''
                            ? 'No Location'
                            : (location.code | titlecase)
                        }}
                      </h4>
                    </div>
                    <div class="col-2 p-0 text-center text-light">
                      <a
                        class="btn btn-sm"
                        (click)="showItemsToggle(locationIndex)"
                      >
                        <i
                          class="fas fa-ellipsis-h fa-lg"
                          *ngIf="!location.show"
                        ></i>
                        <i
                          class="fas fa-ellipsis-v fa-lg"
                          *ngIf="location.show"
                        ></i>
                      </a>
                    </div>
                  </div>
                </li>
                <ng-container *ngIf="location.show">
                  <ng-container
                    *ngFor="
                      let phase of houseInfo.phases;
                      let phaseIndex = index;
                      let isPhaseFirst = first
                    "
                  >
                    <li
                      class="list-group-item bg-dark p-0 pl-1 col-sm-12 text-sm font-weight-bold text-light text-left"
                      *ngIf="
                        stepAllLocationItemCount(phaseIndex, locationIndex) > 0
                      "
                    >
                      <div class="row">
                        <div class="col-10 mr-0">
                          {{ phase.parent.slice(1) | titlecase }}
                        </div>
                      </div>
                    </li>
                    <ng-container
                      *ngFor="let step of phase.steps; let stepIndex = index"
                    >
                      <li
                        class="list-group-item bg-dark p-0 pl-1 col-sm-12 text-sm font-weight-bold text-light text-left"
                        *ngIf="
                          stepLocationItemCount(
                            phaseIndex,
                            locationIndex,
                            step.wtStep
                          ) > 0
                        "
                      >
                        <div class="row">
                          <div class="col-10 mr-0">
                            {{ step.wtStep }}
                          </div>
                        </div>
                      </li>
                      <ul class="list-group list-group-striped">
                        <ng-container
                          *ngFor="
                            let existingItem of step.existingItems;
                            let existingItemIndex = index
                          "
                        >
                          <li
                            class="list-group-item py-1"
                            *ngIf="
                              existingItem.location.toLocaleLowerCase() ===
                              location.code.toLocaleLowerCase()
                            "
                            [ngStyle]="
                              existingItem.inactive == 'Y'
                                ? { 'background-color': 'red' }
                                : {}
                            "
                          >
                            <ul class="list-group list-group-horizontal">
                              <li
                                class="list-group-item col-2 border-0 p-0 py-1 text-sm font-weight-bold bg-transparent"
                              >
                                {{ existingItem.itemCode }}
                              </li>
                              <li
                                class="list-group-item col-3 border-0 p-0 py-1 text-sm font-weight-bold bg-transparent"
                              >
                                {{ existingItem.description }}
                              </li>
                              <li
                                class="list-group-item col-2 border-0 p-0 py-1 text-sm font-weight-bold bg-transparent"
                              >
                                {{ existingItem.qty }}
                              </li>
                              <li
                                class="list-group-item col-3 border-0 p-0 py-1 text-sm font-weight-bold bg-transparent"
                              >
                                {{ existingItem.comment }}
                              </li>
                              <li
                                *ngIf="existingItem.PartOfTemp == 'No'"
                                class="list-group-item col-2 border-0 p-0 my-auto bg-transparent text-right"
                              >
                                <span><i class="fas fa-tags"></i></span>
                              </li>
                            </ul>
                          </li>
                        </ng-container>
                        <ng-container
                          *ngFor="
                            let newItem of step.addedItems;
                            let addedItemIndex = index
                          "
                        >
                          <li
                            class="list-group-item py-1"
                            *ngIf="
                              newItem.location.toLocaleLowerCase() ===
                                location.code.toLocaleLowerCase() &&
                              newItem.itemCode !== ''
                            "
                          >
                            <ul class="list-group list-group-horizontal">
                              <li
                                class="list-group-item col-2 border-0 p-0 py-1 text-sm font-weight-bold bg-transparent"
                              >
                                {{ newItem.itemCode }}
                              </li>
                              <li
                                class="list-group-item col-3 border-0 p-0 py-1 text-sm font-weight-bold bg-transparent"
                              >
                                {{ newItem.description }}
                              </li>
                              <li
                                class="list-group-item col-2 border-0 p-0 py-1 text-sm font-weight-bold bg-transparent"
                              >
                                {{ newItem.qty }}
                              </li>
                              <li
                                class="list-group-item col-3 border-0 p-0 py-1 text-sm font-weight-bold bg-transparent"
                              >
                                {{ newItem.comment }}
                              </li>
                              <li
                                class="list-group-item col-2 border-0 p-0 my-auto bg-transparent"
                              >
                                <div>
                                  <button
                                    class="btn btn-danger btn-sm"
                                    (click)="
                                      stepItemRemove(
                                        phaseIndex,
                                        stepIndex,
                                        addedItemIndex
                                      )
                                    "
                                  >
                                    <span
                                      ><i class="fas fa-trash-alt"></i
                                    ></span>
                                  </button>
                                  <span
                                    *ngIf="newItem.upgrade === 'true'"
                                    class="float-right"
                                    ><i class="fas fa-tags"></i
                                  ></span>
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ng-container>
                      </ul>
                      <!-- <li class="list-group-item py-1">
                          <ul class="list-group list-group-horizontal">
                            <li class="list-group-item col-5 border-0 px-1 pb-0 pt-2 text-sm font-weight-bold">
                              <input class="form-control" [(ngModel)]="step.newItem[location.code].itemSelected" (click)="$event.target.select()" (typeaheadOnSelect)="stepItemSelected($event, phaseIndex, stepIndex, locationIndex)" [typeaheadMinLength]="0" [typeahead]="itemList" typeaheadOptionField="name" typeaheadOptionsLimit="150" [typeaheadScrollable]="true" [typeaheadOptionsInScrollableView]="5" placeholder="Item" autocomplete="off">
                            </li>
                            <li class="list-group-item col-2 border-0 px-1 pb-0 pt-2 text-sm font-weight-bold">
                              <input type="text" class="form-control text-center" name="qtyNeeded" (click)="$event.target.select()" [(ngModel)]="step.newItem[location.code].qty" autocomplete="off">
                            </li>
                            <li class="list-group-item col-3 border-0 p-0 pt-2 text-sm font-weight-bold">
                              <input type="text" class="form-control text-center" name="notes" (click)="$event.target.select()" [(ngModel)]="step.newItem[location.code].notes" placeholder="Notes" autocomplete="off">
                            </li>
                            <li class="list-group-item col-2 border-0 px-1 pb-0 pt-2">
                              <button class="btn btn-primary" (click)="addItem(phaseIndex, stepIndex, locationIndex)" [disabled]="!stepItemReady(phaseIndex, stepIndex, locationIndex) || step.loading"><span *ngIf="!step.loading">ADD</span><span *ngIf="step.loading"><i class="fas fa-sync fa-spin"></i></span></button>
                            </li>
                          </ul>
                        </li> -->
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ul>
          </div>
        </ng-container>
      </div>

      <div class="px-3 mt3" id="house-footer">
        <div class="row">
          <div class="col-4">
            <div class="float-left">
              <button
                class="btn btn-secondary btn-lg pr-5 pl-5"
                (click)="slidePrevious()"
                *ngIf="!sliderOne && addressLoaded"
              >
                Previous
              </button>
            </div>
          </div>
          <div class="col-4 text-center">
            <a
              class="btn p-0"
              (click)="openModal(fileExplorer)"
              (click)="fileNames()"
              (click)="loadImagesForViewing()"
              *ngIf="addressLoaded"
              ><i class="far fa-folder-open fa-3x text-dark"></i
            ></a>
          </div>
          <div class="col-4">
            <div class="float-right">
              <button
                class="btn btn-primary btn-lg pr-5 pl-5"
                (click)="slideNext()"
                *ngIf="!sliderFive && addressLoaded"
              >
                Next
              </button>
              <button
                class="btn btn-primary btn-lg pr-5 pl-5 ml-2"
                (click)="updateHouse()"
                *ngIf="sliderFive"
                [disabled]="!sliderFive || loading"
              >
                <span *ngIf="!loading">Finish</span
                ><span *ngIf="loading"
                  ><i class="fas fa-sync fa-spin"></i
                ></span>
              </button>
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  </div>
</div>

<!-- Loading Houses -->
<div class="container pb-5">
  <div
    class="card my-3 shadow-lg"
    *ngFor="let address of addressCollection; let addressIndex = index"
  >
    <div class="row m-0 p-3">
      <div class="col-8">
        <h4>{{ address.salesOrderNo }} {{ address.address }}</h4>
      </div>
      <div class="col-4">
        <div class="float-right">
          <button
            class="btn btn-primary btn-md pr-5 pl-5 ml-2"
            (click)="resubmitHouse(addressIndex)"
            [disabled]="address.loading"
          >
            <span *ngIf="!address.loading">Resend</span
            ><span *ngIf="address.loading"
              ><i class="fas fa-sync fa-spin"></i
            ></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Import Template -->
<ng-template #importTemplate>
  <div class="container pb-3">
    <div class="row p-2">
      <div class="col-10 offset-1 text-center">
        <h2>
          Import Template for
          {{
            houseInfo.phases[importTemplatePhaseIndex].parent.slice(1)
              | titlecase
          }}
        </h2>
        <h5>WT# {{ houseInfo.phases[importTemplatePhaseIndex].wtNumber }}</h5>
      </div>
      <div class="col-1">
        <button type="button" class="close" (click)="closeModal()">
          <span><i class="fas fa-window-close"></i></span>
        </button>
      </div>
    </div>

    <form>
      <div class="form-row col-12 mb-2">
        <div class="col-6">
          <h4>Template</h4>
        </div>
        <div class="col-6">
          <label for="" class="sr-only">Template</label>
          <input
            class="form-control text-center"
            name="template"
            [(ngModel)]="importTemplateInfo.templateName"
            (typeaheadOnSelect)="importTemplateSelected($event)"
            [typeahead]="templateList"
            typeaheadOptionField="name"
            placeholder="Template"
            autocomplete="off"
          />
        </div>
      </div>

      <!-- <div class="form-row col-12 mb-2">
        <div class="col-6">
          <h4> Step </h4>
        </div>
        <div class="col-6">
          <label for="" class="sr-only">Step</label>
          <input type="text" class="form-control text-center" name="step" [(ngModel)]="importTemplateInfo.step" placeholder="Step Number" autocomplete="off">
        </div>
      </div> -->

      <div class="form-row pt-2">
        <div class="col">
          <button
            type="submit"
            class="btn btn-primary float-right"
            (click)="addTemplateItems()"
            [disabled]="!importTemplateReady() || importTemplateLoading"
          >
            <span *ngIf="!importTemplateLoading">Import</span
            ><span *ngIf="importTemplateLoading"
              ><i class="fas fa-sync fa-spin"></i
            ></span>
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<!-- Edit Phase -->
<ng-template #editPhase>
  <div class="container pb-3">
    <div class="row p-2">
      <div class="col-10 offset-1 text-center">
        <h2>
          Edit
          {{
            houseInfo.phases[importTemplatePhaseIndex].parent.slice(1)
              | titlecase
          }}
        </h2>
        <h5>WT# {{ houseInfo.phases[importTemplatePhaseIndex].wtNumber }}</h5>
      </div>
      <div class="col-1">
        <button type="button" class="close" (click)="closeModal()">
          <span><i class="fas fa-window-close"></i></span>
        </button>
      </div>
    </div>

    <div class="row mb-1" id="pendingItemsInfo">
      <div class="col-sm-3 offset-1 pt-2">
        <label for="pendingItems">Pending Items</label>
      </div>
      <div class="col-sm-7">
        <div class="btn-group col" dropdown>
          <button
            dropdownToggle
            type="button"
            class="btn btn-outline-dark btn-lg dropdown-toggle text-capitalize"
          >
            {{
              houseInfo.phases[importTemplatePhaseIndex].pendingItems === 'Y'
                ? 'Pending Items Remain'
                : 'No Pending
            Items'
            }}
            <span class="caret"></span>
          </button>
          <ul
            *dropdownMenu
            class="dropdown-menu dropdown-menu-right"
            role="menu"
          >
            <li role="menuitem">
              <a
                class="dropdown-item text-capitalize"
                (click)="phasePendingItemsSelected('Y')"
              >
                <span>Pending Items Remain</span>
                <i
                  *ngIf="
                    houseInfo.phases[importTemplatePhaseIndex].pendingItems ===
                    'Y'
                  "
                  class="fas fa-check fa-border fa-pull-right"
                ></i>
              </a>
            </li>
            <li role="menuitem">
              <a
                class="dropdown-item text-capitalize"
                (click)="phasePendingItemsSelected('N')"
              >
                <span>No Pending Items</span>
                <i
                  *ngIf="
                    houseInfo.phases[importTemplatePhaseIndex].pendingItems !==
                    'Y'
                  "
                  class="fas fa-check fa-border fa-pull-right"
                ></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row mb-1" id="updateItemsInfo">
      <div class="col-sm-3 offset-1 pt-2">
        <label for="updateItems">Update Items</label>
      </div>
      <div class="col-sm-7">
        <div class="btn-group col" dropdown>
          <button
            dropdownToggle
            type="button"
            class="btn btn-outline-dark btn-lg dropdown-toggle text-capitalize"
          >
            {{
              houseInfo.phases[importTemplatePhaseIndex].updateItems === 'Y'
                ? 'Need to Update Items'
                : 'No Items to
            Update'
            }}
            <span class="caret"></span>
          </button>
          <ul
            *dropdownMenu
            class="dropdown-menu dropdown-menu-right"
            role="menu"
          >
            <li role="menuitem">
              <a
                class="dropdown-item text-capitalize"
                (click)="phaseUpdateItemsSelected('Y')"
              >
                <span>Need to Update Items</span>
                <i
                  *ngIf="
                    houseInfo.phases[importTemplatePhaseIndex].updateItems ===
                    'Y'
                  "
                  class="fas fa-check fa-border fa-pull-right"
                ></i>
              </a>
            </li>
            <li role="menuitem">
              <a
                class="dropdown-item text-capitalize"
                (click)="phaseUpdateItemsSelected('N')"
              >
                <span>No Items to Update</span>
                <i
                  *ngIf="
                    houseInfo.phases[importTemplatePhaseIndex].updateItems !==
                    'Y'
                  "
                  class="fas fa-check fa-border fa-pull-right"
                ></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row mb-1" id="cabinetDetailsInfo">
      <div class="col-sm-3 offset-1 pt-2">
        <label for="cabinetDetails">Cabinet Details</label>
      </div>
      <div class="col-sm-7">
        <div class="btn-group col" dropdown>
          <button
            dropdownToggle
            type="button"
            class="btn btn-outline-dark btn-lg dropdown-toggle text-capitalize"
          >
            {{
              houseInfo.phases[importTemplatePhaseIndex].cabinetDetails === 'Y'
                ? 'Cabinet Details Needed'
                : 'Cabinet
            Details Received'
            }}
            <span class="caret"></span>
          </button>
          <ul
            *dropdownMenu
            class="dropdown-menu dropdown-menu-right"
            role="menu"
          >
            <li role="menuitem">
              <a
                class="dropdown-item text-capitalize"
                (click)="phaseCabinetDetailsSelected('Y')"
              >
                <span>Cabinet Details Needed</span>
                <i
                  *ngIf="
                    houseInfo.phases[importTemplatePhaseIndex]
                      .cabinetDetails === 'Y'
                  "
                  class="fas fa-check fa-border fa-pull-right"
                ></i>
              </a>
            </li>
            <li role="menuitem">
              <a
                class="dropdown-item text-capitalize"
                (click)="phaseCabinetDetailsSelected('N')"
              >
                <span>Cabinet Details Received</span>
                <i
                  *ngIf="
                    houseInfo.phases[importTemplatePhaseIndex]
                      .cabinetDetails !== 'Y'
                  "
                  class="fas fa-check fa-border fa-pull-right"
                ></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row mb-1" id="reviewedInfo">
      <div class="col-sm-3 offset-1 pt-2">
        <label for="reviewed">Reviewed</label>
      </div>
      <div class="col-sm-7">
        <div class="btn-group col" dropdown>
          <button
            dropdownToggle
            type="button"
            class="btn btn-outline-dark btn-lg dropdown-toggle text-capitalize"
          >
            {{
              houseInfo.phases[importTemplatePhaseIndex].reviewed === 'Y'
                ? 'Reviewed'
                : 'Review Pending'
            }}
            <span class="caret"></span>
          </button>
          <ul
            *dropdownMenu
            class="dropdown-menu dropdown-menu-right"
            role="menu"
          >
            <li role="menuitem">
              <a
                class="dropdown-item text-capitalize"
                (click)="phaseReviewedSelected('Y')"
              >
                <span>Reviewed</span>
                <i
                  *ngIf="
                    houseInfo.phases[importTemplatePhaseIndex].reviewed === 'Y'
                  "
                  class="fas fa-check fa-border fa-pull-right"
                ></i>
              </a>
            </li>
            <li role="menuitem">
              <a
                class="dropdown-item text-capitalize"
                (click)="phaseReviewedSelected('N')"
              >
                <span>Review Pending</span>
                <i
                  *ngIf="
                    houseInfo.phases[importTemplatePhaseIndex].reviewed !== 'Y'
                  "
                  class="fas fa-check fa-border fa-pull-right"
                ></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row mb-1" id="auditReasonInfo">
      <div class="col-sm-3 offset-1 pt-2">
        <label for="auditReason">Audit Reason</label>
      </div>
      <div class="col-sm-7">
        <div class="btn-group col" dropdown>
          <button
            dropdownToggle
            type="button"
            class="btn btn-outline-dark btn-lg dropdown-toggle text-capitalize"
          >
            {{
              houseInfo.phases[importTemplatePhaseIndex].auditReason === ''
                ? 'Audit Pending'
                : houseInfo.phases[importTemplatePhaseIndex].auditReason
            }}
            <span class="caret"></span>
          </button>
          <ul
            *dropdownMenu
            class="dropdown-menu dropdown-menu-right scrollable-menu"
            role="menu"
          >
            <li role="menuitem" *ngFor="let auditReason of auditReasonList">
              <a
                class="dropdown-item text-capitalize"
                (click)="phaseAuditReasonSelected(auditReason.code)"
              >
                <span>{{ auditReason.name }}</span>
                <i
                  *ngIf="
                    auditReason.code ==
                    houseInfo.phases[importTemplatePhaseIndex].auditReason
                  "
                  class="fas fa-check fa-border fa-pull-right"
                ></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row mb-1" id="commentInfo">
      <div class="col-sm-3 offset-1 pt-2">
        <label for="comment">Comment</label>
      </div>
      <div class="col-sm-7">
        <div class="col">
          <textarea
            type="text"
            class="form-control"
            name="template"
            [(ngModel)]="houseInfo.phases[importTemplatePhaseIndex].comment"
            (change)="houseInfo.phases[importTemplatePhaseIndex].updated = true"
            autocomplete="off"
          ></textarea>
        </div>
      </div>
    </div>

    <form>
      <div class="form-row pt-2">
        <div class="col-6">
          <div class="form-check">
            <input
              (change)="phaseVPOSelected($event)"
              [checked]="
                houseInfo.phases[importTemplatePhaseIndex].vpo == 'N'
                  ? false
                  : true
              "
              class="form-check-input"
              type="checkbox"
              name="vpoInput"
              id="vpoInput"
            />
            <label for="vpoInput" class="form-check-label"
              >Is this a VPO?</label
            >
          </div>
        </div>
        <div class="col-6">
          <button class="btn btn-primary float-right" (click)="closeModal()">
            Close
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #fileExplorer>
  <div class="container pb-3">
    <div class="row p-2">
      <div class="col-10 offset-1 text-center">
        <h2>File Contents for {{ houseInfo.address | titlecase }}</h2>
      </div>
      <div class="col-1">
        <button type="button" class="close" (click)="closeModal()">
          <span><i class="fas fa-window-close"></i></span>
        </button>
      </div>
    </div>

    <div class="row mb-1 align-content-center">
      <div class="col-auto">
        <h5><b>WASABI Folder</b> (external: plumber/crew files)</h5>
      </div>
    </div>
    <div class="row mb-1 align-content-center">
      <div class="col-auto">
        <button
          class="btn"
          (click)="WShowImg ? (WShowImg = false) : (WShowImg = true)"
          [ngStyle]="{
            'background-color': WShowImg ? 'yellow' : 'red',
            color: WShowImg ? 'black' : 'white'
          }"
        >
          <span *ngIf="!WShowImg">Show Images</span>
          <span *ngIf="WShowImg">Show Images<i class="fas fa-check"></i></span>
        </button>
      </div>
    </div>
    <div class="row mb-1 align-content-center">
      <div class="col-auto">
        <div class="custom-file">
          <input
            type="file"
            class="custom-file-input"
            multiple="true"
            id="requestAttachments"
            (change)="plansChosen($event)"
          />
          <label class="custom-file-label text-left" for="requestAttachments"
            >Choose file</label
          >
        </div>
        <div class="col" *ngFor="let file of plansArray">
          <small> {{ file.name }}</small>
        </div>
      </div>
    </div>
    <div class="row mb-1">
      <ng-container *ngIf="WShowImg">
        <div class="col-auto justify-content-center">
          <ng-container *ngFor="let img of WasabiList; let i = index">
            <div *ngIf="img.toLowerCase().slice(-3) === 'pdf'">
              <div class="row justify-content-center">
                <div class="col-auto align-content-center">
                  <a
                    [href]="WasabiList[i]"
                    target="_blank"
                    class="text-center"
                    [innerHtml]="imageName(i)"
                  ></a>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngFor="let img of WasabiList; let i = index">
            <ng-container
              *ngIf="
                img.toLowerCase().slice(-3) !== 'pdf' && img.slice(-1) !== '/'
              "
            >
              <div class="row justify-content-center">
                <img
                  class="navbar-buttons"
                  width="80%"
                  height="80%"
                  src="{{ img }}"
                />
              </div>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>

      <ng-container *ngIf="!WShowImg">
        <ng-container *ngFor="let file of WasabiList; let i = index">
          <div class="col-sm-3 pt-2" *ngIf="file.slice(-1) !== '/'">
            <div class="card text-center">
              <a
                class="btn p-0 pt-2"
                [href]="WasabiList[i]"
                target="_blank"
                class="text-center"
              >
                <i class="fas fa-file-alt fa-4x mt-2"></i
              ></a>
              <small>{{ imageName(i) }}</small>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <hr />
    <div class="row mb-1 align-content-center">
      <div class="col-auto">
        <h5>
          <b>SO_WT_Files Folder</b> (internal: Sage/inventory/Accounting folder)
        </h5>
      </div>
    </div>
    <!--    <div class="row mb-1 align-content-center">-->
    <!--    <div class="col-auto">-->
    <!--      <button class="btn" (click)="ShowImg ? ShowImg = false : ShowImg = true"-->
    <!--              [ngStyle]="{'background-color': ShowImg ? 'yellow' : 'red', 'color': ShowImg? 'black' : 'white'}">-->
    <!--        <span *ngIf="!ShowImg">Show Images</span>-->
    <!--        <span *ngIf="ShowImg">Show Images<i class="fas fa-check"></i></span></button>-->
    <!--    </div>-->
    <!--  </div>-->
    <div class="row mb-1 align-content-center">
      <div class="col-auto">
        <div class="custom-file">
          <input
            type="file"
            class="custom-file-input"
            id="requestFileAttachments"
            multiple="true"
            (change)="fileChosen($event)"
          />
          <label
            class="custom-file-label text-left"
            for="requestFileAttachments"
            >Choose file</label
          >
        </div>
        <div class="col" *ngFor="let file of fileArray">
          <small> {{ file.name }}</small>
        </div>
      </div>
    </div>

    <ng-container *ngIf="ShowImg">
      <div class="col-auto justify-content-center">
        <ng-container *ngFor="let img of fileList; let i = index">
          <div *ngIf="img.name.toLowerCase().slice(-3) === 'pdf'">
            <div class="row justify-content-center">
              <div class="col-auto align-content-center">
                <a
                  [href]="fileList[i]"
                  target="_blank"
                  class="text-center"
                  [innerHtml]="imageName(i)"
                ></a>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngFor="let img of fileList; let i = index">
          <ng-container
            *ngIf="
              img.name.toLowerCase().slice(-3) !== 'pdf' &&
              img.slice(-1) !== '/'
            "
          >
            <div class="row justify-content-center">
              <img
                class="navbar-buttons"
                width="80%"
                height="80%"
                src="{{ img.name }}"
              />
            </div>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngIf="!ShowImg">
      <div class="row mb-1" id="files">
        <div class="col-sm-3 pt-2" *ngFor="let file of fileList">
          <div class="card text-center">
            <a class="btn p-0 pt-2" (click)="fileDownload(file.name)"
              ><i class="fas fa-file-alt fa-4x"></i
            ></a>
            <small>{{ file.name }}</small>
          </div>
        </div>
      </div>
    </ng-container>
    <form>
      <div class="form-row pt-2">
        <div class="col">
          <button class="btn btn-primary float-right" (click)="closeModal()">
            Close
          </button>
          <button
            class="btn btn-info float-right mr-2"
            (click)="plansUpload()"
            *ngIf="fileArray.length > 0 || plansArray.length > 0"
          >
            <span *ngIf="!fileLoading">Upload</span
            ><span *ngIf="fileLoading"
              ><i class="fas fa-sync fa-spin"></i
            ></span>
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #editItem>
  <div class="container pb-3">
    <div class="row p-2">
      <div class="col-10 offset-1 text-center">
        <h2>
          Edit
          {{
            houseInfo.phases[itemEditPhaseIndex].steps[itemEditStepIndex]
              .addedItems[itemEditItemIndex].itemCode
          }}
        </h2>
        <h4>
          Edit
          {{
            houseInfo.phases[itemEditPhaseIndex].steps[itemEditStepIndex]
              .addedItems[itemEditItemIndex].description
          }}
        </h4>
        <h5>
          {{ houseInfo.phases[itemEditPhaseIndex].parent.slice(1) | titlecase }}
          WT# {{ houseInfo.phases[itemEditPhaseIndex].wtNumber }}
        </h5>
      </div>
      <div class="col-1">
        <button type="button" class="close" (click)="closeModal()">
          <span><i class="fas fa-window-close"></i></span>
        </button>
      </div>
    </div>

    <div class="row mb-1" id="houseLocationInfo">
      <div class="col-sm-3 offset-1 pt-2">
        <label for="location">Location</label>
      </div>
      <div class="col-sm-7">
        <div class="mb-1" id="locationInfo">
          <div class="btn-group" dropdown>
            <button
              dropdownToggle
              type="button"
              class="btn btn-outline-dark btn-md dropdown-toggle text-capitalize"
            >
              {{
                houseInfo.phases[itemEditPhaseIndex].steps[itemEditStepIndex]
                  .addedItems[itemEditItemIndex].location
              }}
              <span class="caret"></span>
            </button>
            <ul
              *dropdownMenu
              class="dropdown-menu dropdown-menu-right scrollable-menu"
              role="menu"
            >
              <li
                role="menuitem"
                *ngFor="
                  let location of houseLocationList;
                  let locationIndex = index
                "
              >
                <a
                  class="dropdown-item text-capitalize"
                  (click)="
                    stepItemLocationSelected(
                      itemEditPhaseIndex,
                      itemEditStepIndex,
                      itemEditItemIndex,
                      location.code
                    )
                  "
                >
                  <span>{{ location.code }}</span>
                  <i
                    *ngIf="
                      houseInfo.phases[itemEditPhaseIndex].steps[
                        itemEditStepIndex
                      ].addedItems[itemEditItemIndex].location === location.code
                    "
                    class="fas fa-check fa-border fa-pull-right"
                  ></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <form>
      <div class="form-row pt-2">
        <div class="col">
          <button class="btn btn-primary float-right" (click)="closeModal()">
            Close
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
