import { Component, OnInit, TemplateRef } from '@angular/core';
import { AlertifyService } from '../../../_services/alertify/alertify.service';
import { SageApiService } from '../../../_services/sageApi/sageApi.service';
import { AuthService } from '../../../_services/auth/auth.service';
import { Router } from '@angular/router';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { concat } from 'rxjs';
import { DatePipe } from '@angular/common';
import { log } from 'util';

@Component({
  selector: 'app-job-requisition',
  templateUrl: './job-requisition.component.html',
  styleUrls: ['./job-requisition.component.css'],
})
export class JobRequisitionComponent implements OnInit {
  bsConfig: Partial<BsDatepickerConfig>;
  purchaseOrderLines: {
    itemCode: string;
    LineKey: string;
    qtyOrdered: number;
    qtyAvailable: number;
    qtyReceived: number;
    unitCost: number;
  }[] = [];
  purchaseOrderHeader = {
    purchaseOrderNo: '',
    invoiceNo: '',
    invoiceDate: new Date(),
    vendor: '',
    shippingAddress: '',
    warehouse: '',
  };
  fileArray = [];
  fileList = [];
  POItemList: {
    ItemCode: string;
    ItemCodeDesc: string;
    Requested: number;
    Issued: number;
    newIssued: number;
    QtyOnHand: number;
    UnitCost: number;
    GLAccount: string;
    GLAccountKey: string;
    Extension: number;
    Comment: string;
    LineKey: string;
    itemFinished: boolean;
  }[] = [];
  ItemCodeList = [];
  ExistingPOList = [];
  purchaseOrderList = [];
  ShiptoAddressList = [];
  GLAccountList = [];
  WarehouseCode: string;

  receiptLoading = false;
  showfinishedPOs = false;
  fileLoading = false;
  purchaseOrderLoaded = false;
  purchaseOrderLoading = false;
  shipToAddresLoading = false;
  purchaseOrderListLoading = false;
  ItemCOdeListLoading = false;
  ediReceipt = false;
  badInvoiceDate = false;
  whse003 = false;
  ItemCodeSearch: string;
  purchaseOrderSearch: string;
  ShipToAddressSearch: string;
  SelectedAddress: {
    shiptoCode: string;
    address: string;
    whse: string;
  };
  invoiceNo: string;
  purchaseOrderNo: string;
  selectedOrderDate: Date;
  hasWhse = false;

  constructor(
    private alertify: AlertifyService,
    private sageApi: SageApiService,
    private authService: AuthService,
    private modalService: BsModalService,
    private router: Router,
    private datePipe: DatePipe
  ) {}

  ngOnInit() {
    this.SelectedAddress = {
      shiptoCode: '',
      address: '',
      whse: '',
    };
    this.selectedOrderDate = new Date();
    this.ItemCOdeListLoading = true;
    this.getPOList();
    this.getGLAccountList();
    this.getShipToAddressList();
    this.addPOline();
  }

  addPOline() {
    const obj = {
      ItemCode: '',
      ItemCodeDesc: '',
      Requested: 0,
      Issued: 0,
      newIssued: 0,
      QtyOnHand: 0,
      UnitCost: 0,
      GLAccount: '',
      GLAccountKey: '',
      Extension: 0,
      Comment: '',
      GLmissing: false,
      LineKey: '',
      itemFinished: false,
    };
    this.POItemList.push(obj);
  }

  removePOLine(line, index) {
    this.POItemList.splice(index, 1);
  }

  logDate(event, orderdate) {
    if (this.selectedOrderDate.getTime() !== new Date(event).getTime()) {
      this.badInvoiceDate = false;
      this.selectedOrderDate = new Date(event);
      this.purchaseOrderHeader.invoiceDate = this.selectedOrderDate;
    }
  }

  purchaseOrderSelected(event: TypeaheadMatch) {
    this.purchaseOrderNo = event.item.purchaseOrderNo;

    const findS = this.ShiptoAddressList.find(obj => {
      return obj.shiptoCode === event.item.ShipToCode;
    });

    if (findS !== undefined) {
      this.ShipToAddressSearch = findS.address;
      this.SelectedAddress.shiptoCode = findS.shiptoCode;
      this.SelectedAddress.address = findS.address;
      this.SelectedAddress.whse = event.item.WarehouseCode;
      this.hasWhse = event.item.WarehouseCode !== '' ? false : true;
      this.purchaseOrderHeader.invoiceDate = event.item.PurchaseOrderDate;
      this.getItemList();
      this.POItemList = [];
      event.item.ItemList.forEach(item => {
        const glAcc = this.GLAccountList.find(ob => {
          return ob.AccountKey === item.PurchasesAcctKey;
        });

        const obj = {
          ItemCode: item.ItemCode,
          ItemCodeDesc: item.ItemCodeDesc,
          Requested: item.QuantityOrdered,
          Issued: item.QuantityReceived,
          newIssued: 0,
          QtyOnHand: item.QuantityOnHand,
          UnitCost: item.UnitCost,
          GLAccount: glAcc === undefined ? 'Name Not Found' : glAcc.GLAccount,
          GLAccountKey: item.PurchasesAcctKey,
          Extension: item.Extension,
          Comment: item.Comment,
          LineKey: item.LineKey,
          GLmissing: false,
          itemFinished: item.itemFinished,
        };
        this.POItemList.push(obj);
      });
    }
  }

  ShiptoAddressSelected(event: TypeaheadMatch) {
    this.SelectedAddress.shiptoCode = event.item.shiptoCode;
    this.SelectedAddress.address = event.item.address;
    this.SelectedAddress.whse = event.item.whse;
    this.hasWhse = this.SelectedAddress.whse !== '' ? false : true;
    this.getItemList();
    this.getGLAccountList();
  }

  calcExtention(step: any, event) {
    event.target.select();
    step.Extension = step.Requested * step.UnitCost;
    this.badAmount(step);
  }

  ItemCodeSelected(event: TypeaheadMatch, step: any) {
    step.UnitCost = event.item.UnitCost;
  }

  GLaccountSelected(event: TypeaheadMatch, step: any) {
    step.GLAccountKey = event.item.AccountKey;
  }

  submitRequisition() {
    this.ItemCOdeListLoading = true;
    const issueComments =
      'Error: the following issues are stopping the Requisition from being submitted:';
    let foundIssue = false;
    this.POItemList.forEach(item => {
      item.Extension = item.Requested * item.UnitCost;
      if (item.GLAccount === '') {
        this.alertify.warning('G/L is Required for part ' + item.ItemCode);
        foundIssue = true;
      }
    });

    if (foundIssue) {
      this.ItemCOdeListLoading = false;
      return;
    }

    const observables = [];
    const invoiceDateISO = new Date(this.purchaseOrderHeader.invoiceDate);
    const orderDateISO = new Date(this.selectedOrderDate);
    const invocedate = this.datePipe.transform(invoiceDateISO, 'yyyy-MM-dd');
    const selectedOrderDate = this.datePipe.transform(
      orderDateISO,
      'yyyy-MM-dd'
    );

    const body = {
      Username: this.authService.decodedToken.nameid,
      Password: this.authService.decodedToken[
        'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'
      ],
      PurchaseOrderNo: this.purchaseOrderSearch,
      ShipToCode: this.SelectedAddress.shiptoCode,
      masterRepeatingOrderNo: '',
      WarehouseCode: this.SelectedAddress.whse,
      purchaseOrderDate: invocedate,
      selectedOrderDate,
      createItems: [],
    };

    this.POItemList.forEach(item => {
      if (item.ItemCode !== '') {
        const additem = [
          item.ItemCode, //     0
          item.Requested, //    1
          item.UnitCost, //     2
          item.GLAccount, //    3
          item.Extension, //    4
          item.Comment, //      5
          item.GLAccountKey, // 6
          item.LineKey, //      7
          item.newIssued, //       8
        ];
        body.createItems.push(additem);
      }
    });

    observables.push(this.sageApi.postRequest('JobRequisition/Submit', body));

    concat(...observables).subscribe(
      (response: any) => {
        if (response !== 'Success' && response !== '') {
          this.alertify.message(
            'Batch: ' + response + ' successfully created!',
            0
          );
        }
      },
      err => {
        console.log(err);
        this.ItemCOdeListLoading = false;
        this.alertify.error('Error - ' + err.message);
      },
      () => {
        this.alertify.success('Batch Successfully created');
        this.ItemCOdeListLoading = false;
        this.clearPO();
      }
    );
  }

  clearPO() {
    this.SelectedAddress.shiptoCode = '';
    this.SelectedAddress.address = '';
    this.SelectedAddress.whse = '';
    this.ShipToAddressSearch = '';
    this.hasWhse = false;
    this.POItemList = [];
    this.addPOline();
    this.ItemCodeList = [];
    this.ItemCOdeListLoading = true;
  }

  getItemList() {
    this.POItemList = [];
    this.addPOline();
    this.ItemCodeList = [];
    this.ItemCOdeListLoading = true;
    this.sageApi
      .pullReport(
        'JobRequisition/ItemList?whseCode=' + this.SelectedAddress.whse
      )
      .subscribe(
        (itemCodes: Array<any>) => {
          if (Array.isArray(itemCodes)) {
            itemCodes.forEach(order => {
              const obj = {
                ItemCode: order.ItemCode,
                whseCode: order.WarehouseCode,
                QtyOnHand: order.QuantityOnHand,
                UnitCost: order.LastTotalUnitCost,
              };
              this.ItemCodeList.push(obj);
            });
          }
        },
        err => {
          console.log(err);
        },
        () => {
          this.ItemCOdeListLoading = false;
        }
      );
  }

  getShipToAddressList() {
    this.shipToAddresLoading = true;
    this.sageApi.pullReport('JobRequisition/Addresses').subscribe(
      (Addresses: Array<any>) => {
        if (Array.isArray(Addresses)) {
          Addresses.forEach(order => {
            const obj = {
              shiptoCode: order.ShipToCode,
              address: order.ShipToName,
              whse: order.WarehouseCode,
            };
            this.ShiptoAddressList.push(obj);
          });
        }
      },
      err => {
        console.log(err);
      },
      () => {
        this.shipToAddresLoading = false;
      }
    );
  }

  badAmount(step) {
    const what = step.newIssued > step.QuantityOnHand && step.newIssued !== 0;
    if (step.itemFinished) {
      return false;
    } else {
      if (step.newIssued < 0) {
        return true;
      } else {
        return (
          (step.newIssued > step.QtyOnHand ||
            step.newIssued > step.Requested - step.Issued) &&
          step.newIssued !== 0
        );
      }
    }
  }

  posubmitCheck() {
    let foundError = false;
    this.POItemList.forEach(item => {
      const check = this.badAmount(item);
      if (check) {
        foundError = true;
      }
    });
    return foundError;
  }

  getPOList() {
    this.ExistingPOList = [];
    this.purchaseOrderListLoading = true;
    this.sageApi.pullReport('JobRequisition/POs').subscribe(
      (POs: Array<any>) => {
        if (Array.isArray(POs)) {
          POs.forEach(order => {
            if (this.ExistingPOList === []) {
              const obj = {
                PurchaseOrderNo: order.PurchaseOrderNo,
                PurchaseOrderDate: order.PurchaseOrderDate,
                ShipToCode: order.ShipToCode,
                WarehouseCode: order.WarehouseCode,
                ItemList: [],
              };
              const item = {
                ItemCode: order.ItemCode,
                ItemCodeDesc: order.ItemCodeDesc,
                PurchasesAcctKey: order.PurchasesAcctKey,
                QuantityOrdered: order.QuantityOrdered,
                QuantityReceived: order.QuantityReceived,
                UnitCost: order.UnitCost,
                Extension: order.QuantityOrdered * order.UnitCost,
                Comment: order.CommentText,
                LineKey: order.LineKey,
                QuantityOnHand:
                  order.QuantityOnHand === undefined ? 0 : order.QuantityOnHand,
                itemFinished: order.QuantityOrdered === order.QuantityReceived,
              };
              obj.ItemList.push(item);
              this.ExistingPOList.push(obj);
            } else {
              const findpo = this.ExistingPOList.find(ob => {
                return ob.PurchaseOrderNo === order.PurchaseOrderNo;
              });
              if (findpo === undefined) {
                const obj = {
                  PurchaseOrderNo: order.PurchaseOrderNo,
                  PurchaseOrderDate: order.PurchaseOrderDate,
                  ShipToCode: order.ShipToCode,
                  WarehouseCode: order.WarehouseCode,
                  ItemList: [],
                };
                const item = {
                  ItemCode: order.ItemCode,
                  ItemCodeDesc: order.ItemCodeDesc,
                  PurchasesAcctKey: order.PurchasesAcctKey,
                  QuantityOrdered: order.QuantityOrdered,
                  QuantityReceived: order.QuantityReceived,
                  UnitCost: order.UnitCost,
                  Extension: order.QuantityOrdered * order.UnitCost,
                  Comment: order.CommentText,
                  LineKey: order.LineKey,
                  QuantityOnHand:
                    order.QuantityOnHand === undefined
                      ? 0
                      : order.QuantityOnHand,
                  itemFinished:
                    order.QuantityOrdered === order.QuantityReceived,
                };
                obj.ItemList.push(item);
                this.ExistingPOList.push(obj);
              } else {
                const item = {
                  ItemCode: order.ItemCode,
                  ItemCodeDesc: order.ItemCodeDesc,
                  PurchasesAcctKey: order.PurchasesAcctKey,
                  QuantityOrdered: order.QuantityOrdered,
                  QuantityReceived: order.QuantityReceived,
                  UnitCost: order.UnitCost,
                  Extension: order.QuantityOrdered * order.UnitCost,
                  Comment: order.CommentText,
                  LineKey: order.LineKey,
                  QuantityOnHand:
                    order.QuantityOnHand === undefined
                      ? 0
                      : order.QuantityOnHand,
                  itemFinished:
                    order.QuantityOrdered === order.QuantityReceived,
                };
                findpo.ItemList.push(item);
              }
            }
          });
        }
      },
      err => {
        console.log(err);
        this.alertify.error(err.message);
        this.purchaseOrderListLoading = false;
      },
      () => {
        this.purchaseOrderListLoading = false;
      }
    );
  }

  getGLAccountList() {
    this.shipToAddresLoading = true;
    this.sageApi.pullReport('JobRequisition/Account').subscribe(
      (Accounts: Array<any>) => {
        if (Array.isArray(Accounts)) {
          Accounts.forEach(order => {
            const obj = {
              MainAcct: order.MainAccountCode,
              AccountDesc: order.AccountDesc,
              AccountKey: order.AccountKey,
              GLAccount: order.MainAccountCode + '-' + order.AccountDesc,
            };
            this.GLAccountList.push(obj);
          });
        }
      },
      err => {
        console.log(err);
      },
      () => {
        this.shipToAddresLoading = false;
      }
    );
  }
}
