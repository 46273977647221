import { Component, OnInit, TemplateRef } from '@angular/core';
import { AlertifyService } from '../../../_services/alertify/alertify.service';
import { SageApiService } from '../../../_services/sageApi/sageApi.service';
import { AuthService } from '../../../_services/auth/auth.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { concat } from 'rxjs';

@Component({
  selector: 'app-receipt-of-invoice-approval',
  templateUrl: './receipt-of-invoice-approval.component.html',
  styleUrls: ['./receipt-of-invoice-approval.component.css'],
})
export class ReceiptOfInvoiceApprovalComponent implements OnInit {
  modalTemplate: BsModalRef;

  fileLoading: boolean;
  fileArray = [];
  fileList = [];

  receiptOfInvoicesPending = [];
  receiptOfInvoicesLoading = false;
  receiptOfInvoiceUpdating = false;

  receiptSelectedIndex: number;
  receiptSelected: boolean;

  constructor(
    private alertify: AlertifyService,
    private sageApi: SageApiService,
    private authService: AuthService,
    private modalService: BsModalService
  ) {}

  ngOnInit() {
    this.getPendingReceiptOfInvoicess();
  }

  openModal(template: TemplateRef<any>) {
    this.modalTemplate = this.modalService.show(template);
  }

  closeModal() {
    this.modalTemplate.hide();
  }

  onReceiptSelected(index: number) {
    this.sortItems(index);
    this.receiptSelectedIndex = index;
    this.receiptSelected = true;
  }

  onReceiptDeselected() {
    this.receiptSelectedIndex = 0;
    this.receiptSelected = false;
  }

  numberOfErrors(index: number) {
    let errorCount = 0;
    this.receiptOfInvoicesPending[index].Lines.forEach(item => {
      if (item.ItemsMatch === 0) {
        if (item.ItemExistsRoi === 0) {
          errorCount++;
        }
        if (item.ItemExistsHistory === 0) {
          errorCount++;
        }
      }

      if (
        item.QuantitiesMatch === 0 &&
        item.ItemExistsHistory === 1 &&
        item.ItemExistsRoi === 1
      ) {
        errorCount++;
      }

      if (
        item.ExtAmtMatch === 0 &&
        item.QuantitiesMatch === 1 &&
        item.ItemExistsHistory === 1 &&
        item.ItemExistsRoi === 1
      ) {
        errorCount++;
      }

      if (item.ItemMatchPO === 0) {
        if (item.ItemExistsInPO === 0) {
          errorCount++;
        }
      }

      if (item.CostsMatch === 0 && item.ItemExistsInPO === 1) {
        errorCount++;
      }

      if (item.ItemMatchRog === 0) {
        if (item.ItemExistsInRog === 0) {
          errorCount++;
        }
        if (item.ItemExistsInRoi === 0) {
          errorCount++;
        }
      }

      if (
        item.QuantitiesMatchRog === 0 &&
        item.ItemExistsInRoi === 1 &&
        item.ItemExistsInRog === 1
      ) {
        errorCount++;
      }
    });

    return errorCount;
  }

  numberOfErrorsIndividualLine(item: any) {
    let individualErrorCount = 0;
    if (item.ItemsMatch === 0) {
      if (item.ItemExistsRoi === 0) {
        individualErrorCount++;
      }
      if (item.ItemExistsHistory === 0) {
        individualErrorCount++;
      }
    }

    if (
      item.QuantitiesMatch === 0 &&
      item.ItemExistsHistory === 1 &&
      item.ItemExistsRoi === 1
    ) {
      individualErrorCount++;
    }

    if (
      item.ExtAmtMatch === 0 &&
      item.QuantitiesMatch === 1 &&
      item.ItemExistsHistory === 1 &&
      item.ItemExistsRoi === 1
    ) {
      individualErrorCount++;
    }

    if (item.ItemMatchPO === 0) {
      if (item.ItemExistsInPO === 0) {
        individualErrorCount++;
      }
    }

    if (item.CostsMatch === 0 && item.ItemExistsInPO === 1) {
      individualErrorCount++;
    }

    if (item.ItemMatchRog === 0) {
      if (item.ItemExistsInRog === 0) {
        individualErrorCount++;
      }
      if (item.ItemExistsInRoi === 0) {
        individualErrorCount++;
      }
    }

    if (
      item.QuantitiesMatchRog === 0 &&
      item.ItemExistsInRoi === 1 &&
      item.ItemExistsInRog === 1
    ) {
      individualErrorCount++;
    }

    return individualErrorCount;
  }

  itemDiscrepancy(lineIndex: number, receiptIndex: number) {
    return (
      this.receiptOfInvoicesPending[receiptIndex].Lines[lineIndex]
        .ItemsMatch === 0 ||
      this.receiptOfInvoicesPending[receiptIndex].Lines[lineIndex]
        .ItemExistsRoi === 0 ||
      this.receiptOfInvoicesPending[receiptIndex].Lines[lineIndex]
        .ItemExistsHistory === 0 ||
      this.receiptOfInvoicesPending[receiptIndex].Lines[lineIndex]
        .QuantitiesMatch === 0 ||
      this.receiptOfInvoicesPending[receiptIndex].Lines[lineIndex]
        .ExtAmtMatch === 0 ||
      this.receiptOfInvoicesPending[receiptIndex].Lines[lineIndex]
        .CostsMatch === 0 ||
      this.receiptOfInvoicesPending[receiptIndex].Lines[lineIndex]
        .ItemExistsInPO === 0 ||
      this.receiptOfInvoicesPending[receiptIndex].Lines[lineIndex]
        .ItemMatchPO === 0 ||
      this.receiptOfInvoicesPending[receiptIndex].Lines[lineIndex]
        .ItemMatchRog === 0 ||
      this.receiptOfInvoicesPending[receiptIndex].Lines[lineIndex]
        .ItemExistsInRoi === 0 ||
      this.receiptOfInvoicesPending[receiptIndex].Lines[lineIndex]
        .ItemExistsInRog === 0 ||
      this.receiptOfInvoicesPending[receiptIndex].Lines[lineIndex]
        .QuantitiesMatchRog === 0
    );
  }

  getPendingReceiptOfInvoicess() {
    this.receiptOfInvoicesLoading = true;
    this.receiptOfInvoicesPending = [];
    this.sageApi
      .pullReport('ReceiptApproval/ReceiptOfInvoice/Combined')
      .subscribe(
        (invoices: []) => {
          if (Array.isArray(invoices)) {
            this.receiptOfInvoicesPending = invoices;
          }
        },
        err => {
          console.log(err);
          this.receiptOfInvoicesLoading = false;
        },
        () => {
          this.receiptOfInvoicesLoading = false;
        }
      );
  }

  sortItems(index: number) {
    const arr = this.receiptOfInvoicesPending[index].Lines;
    arr.sort((a, b) => {
      const ascDirection = false; // sort numbers in descending
      const aErrorCount = this.numberOfErrorsIndividualLine(a);
      const bErrorCount = this.numberOfErrorsIndividualLine(b);

      if (aErrorCount < bErrorCount) {
        return ascDirection ? -1 : 1;
      }
      if (aErrorCount > bErrorCount) {
        return ascDirection ? 1 : -1;
      }
      // items must be equal
      return 0;
    });
  }

  onApproveReceiptOfInvoice(receipt: any, index: number) {
    this.receiptOfInvoiceUpdating = true;
    const observables = [];
    const body = {
      Username: this.authService.decodedToken.nameid,
      ReceiptNo: receipt.ReceiptNo,
      ReceiptType: receipt.ReceiptType,
      PurchaseOrderNo: receipt.PurchaseOrderNo,
      VendorNo: receipt.VendorNo,
      InvoiceNo: receipt.InvoiceNo,
      InvoiceDate: receipt.InvoiceDate,
      BatchNo: receipt.BatchNo,
      ApprovedWithError: 'Y',
      ResolutionNotes: receipt.ResolutionNotes,
      Lines: [],
    };

    if (receipt.ShippingMatch === 0) {
      const object = {
        ErrorType: 'History',
        ErrorSubType: 'ShippingNotEqual',
        HistoryItemCode: '',
        HistoryItemDesc: '',
        HistoryQuantityReceived: '0',
        HistoryQuantityInvoiced: '0',
        HistoryExtendedAmt: '0',
        HistoryHeaderShipping: receipt.HajocaShipping
          ? receipt.HajocaShipping
          : 0,
        HistoryHeaderSalesTax: receipt.HajocaSalesTax
          ? receipt.HajocaSalesTax
          : 0,
        HistoryHeaderTotalDue: receipt.HajocaTotalDue
          ? receipt.HajocaTotalDue
          : 0,
        RogItemCode: '',
        RogItemDesc: '',
        RogQuantityReceived: '0',
        RogReceiptNo: '',
        RoiItemCode: '',
        RoiItemDesc: '',
        RoiQuantityInvoiced: '0',
        RoiUnitCost: '0',
        RoiExtendedAmt: '0',
        RoiHeaderShipping: receipt.InvoiceShipping
          ? receipt.InvoiceShipping
          : 0,
        RoiHeaderSalesTax: receipt.InvoiceSalesTax
          ? receipt.InvoiceSalesTax
          : 0,
        RoiHeaderTotalDue: receipt.InvoiceTotalDue
          ? receipt.InvoiceTotalDue
          : 0,
        PoItemCode: '',
        PoItemDesc: '',
        PoQuantityOrdered: '0',
        PoUnitCost: '0',
      };
      body.Lines.push(object);
    }

    if (receipt.SalesTaxMatch === 0) {
      const object = {
        ErrorType: 'History',
        ErrorSubType: 'SalesTaxNotEqual',
        HistoryItemCode: '',
        HistoryItemDesc: '',
        HistoryQuantityReceived: '0',
        HistoryQuantityInvoiced: '0',
        HistoryExtendedAmt: '0',
        HistoryHeaderShipping: receipt.HajocaShipping
          ? receipt.HajocaShipping
          : 0,
        HistoryHeaderSalesTax: receipt.HajocaSalesTax
          ? receipt.HajocaSalesTax
          : 0,
        HistoryHeaderTotalDue: receipt.HajocaTotalDue
          ? receipt.HajocaTotalDue
          : 0,
        RogItemCode: '',
        RogItemDesc: '',
        RogQuantityReceived: '0',
        RogReceiptNo: '',
        RoiItemCode: '',
        RoiItemDesc: '',
        RoiQuantityInvoiced: '0',
        RoiUnitCost: '0',
        RoiExtendedAmt: '0',
        RoiHeaderShipping: receipt.InvoiceShipping
          ? receipt.InvoiceShipping
          : 0,
        RoiHeaderSalesTax: receipt.InvoiceSalesTax
          ? receipt.InvoiceSalesTax
          : 0,
        RoiHeaderTotalDue: receipt.InvoiceTotalDue
          ? receipt.InvoiceTotalDue
          : 0,
        PoItemCode: '',
        PoItemDesc: '',
        PoQuantityOrdered: '0',
        PoUnitCost: '0',
      };
      body.Lines.push(object);
    }

    if (receipt.TotalDueMatch === 0) {
      const object = {
        ErrorType: 'History',
        ErrorSubType: 'TotalDueNotEqual',
        HistoryItemCode: '',
        HistoryItemDesc: '',
        HistoryQuantityReceived: '0',
        HistoryQuantityInvoiced: '0',
        HistoryExtendedAmt: '0',
        HistoryHeaderShipping: receipt.HajocaShipping
          ? receipt.HajocaShipping
          : 0,
        HistoryHeaderSalesTax: receipt.HajocaSalesTax
          ? receipt.HajocaSalesTax
          : 0,
        HistoryHeaderTotalDue: receipt.HajocaTotalDue
          ? receipt.HajocaTotalDue
          : 0,
        RogItemCode: '',
        RogItemDesc: '',
        RogQuantityReceived: '0',
        RogReceiptNo: '',
        RoiItemCode: '',
        RoiItemDesc: '',
        RoiQuantityInvoiced: '0',
        RoiUnitCost: '0',
        RoiExtendedAmt: '0',
        RoiHeaderShipping: receipt.InvoiceShipping
          ? receipt.InvoiceShipping
          : 0,
        RoiHeaderSalesTax: receipt.InvoiceSalesTax
          ? receipt.InvoiceSalesTax
          : 0,
        RoiHeaderTotalDue: receipt.InvoiceTotalDue
          ? receipt.InvoiceTotalDue
          : 0,
        PoItemCode: '',
        PoItemDesc: '',
        PoQuantityOrdered: '0',
        PoUnitCost: '0',
      };
      body.Lines.push(object);
    }

    this.receiptOfInvoicesPending[index].Lines.forEach(item => {
      if (item.ItemsMatch === 0) {
        if (item.ItemExistsRoi === 0) {
          const obj = {
            ErrorType: 'History',
            ErrorSubType: 'ItemMissingFromRoi',
            HistoryItemCode: item.ItemCodeHistory ? item.ItemCodeHistory : '',
            HistoryItemDesc: item.ItemCodeDescHistory
              ? item.ItemCodeDescHistory
              : '',
            HistoryQuantityReceived: '0',
            HistoryQuantityInvoiced: item.QuantityInvoicedHistory
              ? item.QuantityInvoicedHistory.toString()
              : '0',
            HistoryExtendedAmt: item.ExtendedAmtHistory
              ? item.ExtendedAmtHistory.toString()
              : '0',
            RogItemCode: item.RogItemCode ? item.RogItemCode : '',
            RogItemDesc: item.RogItemCodeDesc ? item.RogItemCodeDesc : '',
            RogQuantityReceived: item.RogQtyReceived
              ? item.RogQtyReceived.toString()
              : '0',
            RogReceiptNo: item.RogReceiptNo ? item.RogReceiptNo : '',
            RoiItemCode: item.ItemCodeRoi ? item.ItemCodeRoi : '',
            RoiItemDesc: item.ItemCodeDescRoi ? item.ItemCodeDescRoi : '',
            RoiQuantityInvoiced: item.QuantityInvoicedRoi
              ? item.QuantityInvoicedRoi.toString()
              : '0',
            RoiUnitCost: item.UnitCostRoi ? item.UnitCostRoi.toString() : '0',
            RoiExtendedAmt: item.ExtendedAmtRoi
              ? item.ExtendedAmtRoi.toString()
              : '0',
            PoItemCode: item.POItemCode ? item.POItemCode : '',
            PoItemDesc: item.PODescription ? item.PODescription : '',
            PoQuantityOrdered: item.POQtyOrdered
              ? item.POQtyOrdered.toString()
              : '0',
            PoUnitCost: item.POUnitCost ? item.POUnitCost.toString() : '0',
          };
          body.Lines.push(obj);
        }
        if (item.ItemExistsHistory === 0) {
          const obj = {
            ErrorType: 'History',
            ErrorSubType: 'ItemMissingFromHistory',
            HistoryItemCode: item.ItemCodeHistory ? item.ItemCodeHistory : '',
            HistoryItemDesc: item.ItemCodeDescHistory
              ? item.ItemCodeDescHistory
              : '',
            HistoryQuantityReceived: '0',
            HistoryQuantityInvoiced: item.QuantityInvoicedHistory
              ? item.QuantityInvoicedHistory.toString()
              : '0',
            HistoryExtendedAmt: item.ExtendedAmtHistory
              ? item.ExtendedAmtHistory.toString()
              : '0',
            RogItemCode: item.RogItemCode ? item.RogItemCode : '',
            RogItemDesc: item.RogItemCodeDesc ? item.RogItemCodeDesc : '',
            RogQuantityReceived: item.RogQtyReceived
              ? item.RogQtyReceived.toString()
              : '0',
            RogReceiptNo: item.RogReceiptNo ? item.RogReceiptNo : '',
            RoiItemCode: item.ItemCodeRoi ? item.ItemCodeRoi : '',
            RoiItemDesc: item.ItemCodeDescRoi ? item.ItemCodeDescRoi : '',
            RoiQuantityInvoiced: item.QuantityInvoicedRoi
              ? item.QuantityInvoicedRoi.toString()
              : '0',
            RoiUnitCost: item.UnitCostRoi ? item.UnitCostRoi.toString() : '0',
            RoiExtendedAmt: item.ExtendedAmtRoi
              ? item.ExtendedAmtRoi.toString()
              : '0',
            PoItemCode: item.POItemCode ? item.POItemCode : '',
            PoItemDesc: item.PODescription ? item.PODescription : '',
            PoQuantityOrdered: item.POQtyOrdered
              ? item.POQtyOrdered.toString()
              : '0',
            PoUnitCost: item.POUnitCost ? item.POUnitCost.toString() : '0',
          };
          body.Lines.push(obj);
        }
      }

      if (
        item.QuantitiesMatch === 0 &&
        item.ItemExistsHistory === 1 &&
        item.ItemExistsRoi === 1
      ) {
        const obj = {
          ErrorType: 'History',
          ErrorSubType: 'InvoicedQuantitiesDoNotMatch',
          HistoryItemCode: item.ItemCodeHistory ? item.ItemCodeHistory : '',
          HistoryItemDesc: item.ItemCodeDescHistory
            ? item.ItemCodeDescHistory
            : '',
          HistoryQuantityReceived: '0',
          HistoryQuantityInvoiced: item.QuantityInvoicedHistory
            ? item.QuantityInvoicedHistory.toString()
            : '0',
          HistoryExtendedAmt: item.ExtendedAmtHistory
            ? item.ExtendedAmtHistory.toString()
            : '0',
          RogItemCode: item.RogItemCode ? item.RogItemCode : '',
          RogItemDesc: item.RogItemCodeDesc ? item.RogItemCodeDesc : '',
          RogQuantityReceived: item.RogQtyReceived
            ? item.RogQtyReceived.toString()
            : '0',
          RogReceiptNo: item.RogReceiptNo ? item.RogReceiptNo : '',
          RoiItemCode: item.ItemCodeRoi ? item.ItemCodeRoi : '',
          RoiItemDesc: item.ItemCodeDescRoi ? item.ItemCodeDescRoi : '',
          RoiQuantityInvoiced: item.QuantityInvoicedRoi
            ? item.QuantityInvoicedRoi.toString()
            : '0',
          RoiUnitCost: item.UnitCostRoi ? item.UnitCostRoi.toString() : '0',
          RoiExtendedAmt: item.ExtendedAmtRoi
            ? item.ExtendedAmtRoi.toString()
            : '0',
          PoItemCode: item.POItemCode ? item.POItemCode : '',
          PoItemDesc: item.PODescription ? item.PODescription : '',
          PoQuantityOrdered: item.POQtyOrdered
            ? item.POQtyOrdered.toString()
            : '0',
          PoUnitCost: item.POUnitCost ? item.POUnitCost.toString() : '0',
        };
        body.Lines.push(obj);
      }

      if (
        item.ExtAmtMatch === 0 &&
        item.QuantitiesMatch === 1 &&
        item.ItemExistsHistory === 1 &&
        item.ItemExistsRoi === 1
      ) {
        const obj = {
          ErrorType: 'History',
          ErrorSubType: 'InvoicedExtAmtDoNotMatch',
          HistoryItemCode: item.ItemCodeHistory ? item.ItemCodeHistory : '',
          HistoryItemDesc: item.ItemCodeDescHistory
            ? item.ItemCodeDescHistory
            : '',
          HistoryQuantityReceived: '0',
          HistoryQuantityInvoiced: item.QuantityInvoicedHistory
            ? item.QuantityInvoicedHistory.toString()
            : '0',
          HistoryExtendedAmt: item.ExtendedAmtHistory
            ? item.ExtendedAmtHistory.toString()
            : '0',
          RogItemCode: item.RogItemCode ? item.RogItemCode : '',
          RogItemDesc: item.RogItemCodeDesc ? item.RogItemCodeDesc : '',
          RogQuantityReceived: item.RogQtyReceived
            ? item.RogQtyReceived.toString()
            : '0',
          RogReceiptNo: item.RogReceiptNo ? item.RogReceiptNo : '',
          RoiItemCode: item.ItemCodeRoi ? item.ItemCodeRoi : '',
          RoiItemDesc: item.ItemCodeDescRoi ? item.ItemCodeDescRoi : '',
          RoiQuantityInvoiced: item.QuantityInvoicedRoi
            ? item.QuantityInvoicedRoi.toString()
            : '0',
          RoiUnitCost: item.UnitCostRoi ? item.UnitCostRoi.toString() : '0',
          RoiExtendedAmt: item.ExtendedAmtRoi
            ? item.ExtendedAmtRoi.toString()
            : '0',
          PoItemCode: item.POItemCode ? item.POItemCode : '',
          PoItemDesc: item.PODescription ? item.PODescription : '',
          PoQuantityOrdered: item.POQtyOrdered
            ? item.POQtyOrdered.toString()
            : '0',
          PoUnitCost: item.POUnitCost ? item.POUnitCost.toString() : '0',
        };
        body.Lines.push(obj);
      }

      if (item.ItemMatchPO === 0) {
        if (item.ItemExistsInPO === 0) {
          const obj = {
            ErrorType: 'PO',
            ErrorSubType: 'ItemMissingFromPO',
            HistoryItemCode: item.ItemCodeHistory ? item.ItemCodeHistory : '',
            HistoryItemDesc: item.ItemCodeDescHistory
              ? item.ItemCodeDescHistory
              : '',
            HistoryQuantityReceived: '0',
            HistoryQuantityInvoiced: item.QuantityInvoicedHistory
              ? item.QuantityInvoicedHistory.toString()
              : '0',
            HistoryExtendedAmt: item.ExtendedAmtHistory
              ? item.ExtendedAmtHistory.toString()
              : '0',
            RogItemCode: item.RogItemCode ? item.RogItemCode : '',
            RogItemDesc: item.RogItemCodeDesc ? item.RogItemCodeDesc : '',
            RogQuantityReceived: item.RogQtyReceived
              ? item.RogQtyReceived.toString()
              : '0',
            RogReceiptNo: item.RogReceiptNo ? item.RogReceiptNo : '',
            RoiItemCode: item.ItemCodeRoi ? item.ItemCodeRoi : '',
            RoiItemDesc: item.ItemCodeDescRoi ? item.ItemCodeDescRoi : '',
            RoiQuantityInvoiced: item.QuantityInvoicedRoi
              ? item.QuantityInvoicedRoi.toString()
              : '0',
            RoiUnitCost: item.UnitCostRoi ? item.UnitCostRoi.toString() : '0',
            RoiExtendedAmt: item.ExtendedAmtRoi
              ? item.ExtendedAmtRoi.toString()
              : '0',
            PoItemCode: item.POItemCode ? item.POItemCode : '',
            PoItemDesc: item.PODescription ? item.PODescription : '',
            PoQuantityOrdered: item.POQtyOrdered
              ? item.POQtyOrdered.toString()
              : '0',
            PoUnitCost: item.POUnitCost ? item.POUnitCost.toString() : '0',
          };
          body.Lines.push(obj);
        }
      }

      if (item.CostsMatch === 0 && item.ItemExistsInPO === 1) {
        const obj = {
          ErrorType: 'PO',
          ErrorSubType: 'UnitCostsDoNotMatch',
          HistoryItemCode: item.ItemCodeHistory ? item.ItemCodeHistory : '',
          HistoryItemDesc: item.ItemCodeDescHistory
            ? item.ItemCodeDescHistory
            : '',
          HistoryQuantityReceived: '0',
          HistoryQuantityInvoiced: item.QuantityInvoicedHistory
            ? item.QuantityInvoicedHistory.toString()
            : '0',
          HistoryExtendedAmt: item.ExtendedAmtHistory
            ? item.ExtendedAmtHistory.toString()
            : '0',
          RogItemCode: item.RogItemCode ? item.RogItemCode : '',
          RogItemDesc: item.RogItemCodeDesc ? item.RogItemCodeDesc : '',
          RogQuantityReceived: item.RogQtyReceived
            ? item.RogQtyReceived.toString()
            : '0',
          RogReceiptNo: item.RogReceiptNo ? item.RogReceiptNo : '',
          RoiItemCode: item.ItemCodeRoi ? item.ItemCodeRoi : '',
          RoiItemDesc: item.ItemCodeDescRoi ? item.ItemCodeDescRoi : '',
          RoiQuantityInvoiced: item.QuantityInvoicedRoi
            ? item.QuantityInvoicedRoi.toString()
            : '0',
          RoiUnitCost: item.UnitCostRoi ? item.UnitCostRoi.toString() : '0',
          RoiExtendedAmt: item.ExtendedAmtRoi
            ? item.ExtendedAmtRoi.toString()
            : '0',
          PoItemCode: item.POItemCode ? item.POItemCode : '',
          PoItemDesc: item.PODescription ? item.PODescription : '',
          PoQuantityOrdered: item.POQtyOrdered
            ? item.POQtyOrdered.toString()
            : '0',
          PoUnitCost: item.POUnitCost ? item.POUnitCost.toString() : '0',
        };
        body.Lines.push(obj);
      }

      if (item.ItemMatchRog === 0) {
        if (item.ItemExistsInRog === 0) {
          const obj = {
            ErrorType: 'Goods',
            ErrorSubType: 'ItemMissingFromRog',
            HistoryItemCode: item.ItemCodeHistory ? item.ItemCodeHistory : '',
            HistoryItemDesc: item.ItemCodeDescHistory
              ? item.ItemCodeDescHistory
              : '',
            HistoryQuantityReceived: '0',
            HistoryQuantityInvoiced: item.QuantityInvoicedHistory
              ? item.QuantityInvoicedHistory.toString()
              : '0',
            HistoryExtendedAmt: item.ExtendedAmtHistory
              ? item.ExtendedAmtHistory.toString()
              : '0',
            RogItemCode: item.RogItemCode ? item.RogItemCode : '',
            RogItemDesc: item.RogItemCodeDesc ? item.RogItemCodeDesc : '',
            RogQuantityReceived: item.RogQtyReceived
              ? item.RogQtyReceived.toString()
              : '0',
            RogReceiptNo: item.RogReceiptNo ? item.RogReceiptNo : '',
            RoiItemCode: item.ItemCodeRoi ? item.ItemCodeRoi : '',
            RoiItemDesc: item.ItemCodeDescRoi ? item.ItemCodeDescRoi : '',
            RoiQuantityInvoiced: item.QuantityInvoicedRoi
              ? item.QuantityInvoicedRoi.toString()
              : '0',
            RoiUnitCost: item.UnitCostRoi ? item.UnitCostRoi.toString() : '0',
            RoiExtendedAmt: item.ExtendedAmtRoi
              ? item.ExtendedAmtRoi.toString()
              : '0',
            PoItemCode: item.POItemCode ? item.POItemCode : '',
            PoItemDesc: item.PODescription ? item.PODescription : '',
            PoQuantityOrdered: item.POQtyOrdered
              ? item.POQtyOrdered.toString()
              : '0',
            PoUnitCost: item.POUnitCost ? item.POUnitCost.toString() : '0',
          };
          body.Lines.push(obj);
        }
        if (item.ItemExistsInRoi === 0) {
          const obj = {
            ErrorType: 'Goods',
            ErrorSubType: 'ItemMissingFromRoi',
            HistoryItemCode: item.ItemCodeHistory ? item.ItemCodeHistory : '',
            HistoryItemDesc: item.ItemCodeDescHistory
              ? item.ItemCodeDescHistory
              : '',
            HistoryQuantityReceived: '0',
            HistoryQuantityInvoiced: item.QuantityInvoicedHistory
              ? item.QuantityInvoicedHistory.toString()
              : '0',
            HistoryExtendedAmt: item.ExtendedAmtHistory
              ? item.ExtendedAmtHistory.toString()
              : '0',
            RogItemCode: item.RogItemCode ? item.RogItemCode : '',
            RogItemDesc: item.RogItemCodeDesc ? item.RogItemCodeDesc : '',
            RogQuantityReceived: item.RogQtyReceived
              ? item.RogQtyReceived.toString()
              : '0',
            RogReceiptNo: item.RogReceiptNo ? item.RogReceiptNo : '',
            RoiItemCode: item.ItemCodeRoi ? item.ItemCodeRoi : '',
            RoiItemDesc: item.ItemCodeDescRoi ? item.ItemCodeDescRoi : '',
            RoiQuantityInvoiced: item.QuantityInvoicedRoi
              ? item.QuantityInvoicedRoi.toString()
              : '0',
            RoiUnitCost: item.UnitCostRoi ? item.UnitCostRoi.toString() : '0',
            RoiExtendedAmt: item.ExtendedAmtRoi
              ? item.ExtendedAmtRoi.toString()
              : '0',
            PoItemCode: item.POItemCode ? item.POItemCode : '',
            PoItemDesc: item.PODescription ? item.PODescription : '',
            PoQuantityOrdered: item.POQtyOrdered
              ? item.POQtyOrdered.toString()
              : '0',
            PoUnitCost: item.POUnitCost ? item.POUnitCost.toString() : '0',
          };
          body.Lines.push(obj);
        }
      }

      if (
        item.QuantitiesMatchRog === 0 &&
        item.ItemExistsInRoi === 1 &&
        item.ItemExistsInRog === 1
      ) {
        const obj = {
          ErrorType: 'Goods',
          ErrorSubType: 'ReceivedInvoicedQuantitiesDoNotMatch',
          HistoryItemCode: item.ItemCodeHistory ? item.ItemCodeHistory : '',
          HistoryItemDesc: item.ItemCodeDescHistory
            ? item.ItemCodeDescHistory
            : '',
          HistoryQuantityReceived: '0',
          HistoryQuantityInvoiced: item.QuantityInvoicedHistory
            ? item.QuantityInvoicedHistory.toString()
            : '0',
          HistoryExtendedAmt: item.ExtendedAmtHistory
            ? item.ExtendedAmtHistory.toString()
            : '0',
          RogItemCode: item.RogItemCode ? item.RogItemCode : '',
          RogItemDesc: item.RogItemCodeDesc ? item.RogItemCodeDesc : '',
          RogQuantityReceived: item.RogQtyReceived
            ? item.RogQtyReceived.toString()
            : '0',
          RogReceiptNo: item.RogReceiptNo ? item.RogReceiptNo : '',
          RoiItemCode: item.ItemCodeRoi ? item.ItemCodeRoi : '',
          RoiItemDesc: item.ItemCodeDescRoi ? item.ItemCodeDescRoi : '',
          RoiQuantityInvoiced: item.QuantityInvoicedRoi
            ? item.QuantityInvoicedRoi.toString()
            : '0',
          RoiUnitCost: item.UnitCostRoi ? item.UnitCostRoi.toString() : '0',
          RoiExtendedAmt: item.ExtendedAmtRoi
            ? item.ExtendedAmtRoi.toString()
            : '0',
          PoItemCode: item.POItemCode ? item.POItemCode : '',
          PoItemDesc: item.PODescription ? item.PODescription : '',
          PoQuantityOrdered: item.POQtyOrdered
            ? item.POQtyOrdered.toString()
            : '0',
          PoUnitCost: item.POUnitCost ? item.POUnitCost.toString() : '0',
        };
        body.Lines.push(obj);
      }
    });

    observables.push(this.sageApi.postRequest('ReceiptApproval', body));

    concat(...observables).subscribe(
      response => {
        // ToDo: something
      },
      err => {
        this.receiptOfInvoiceUpdating = false;
        console.log(err);
        this.alertify.error('Error - ' + err.message);
      },
      () => {
        this.closeModal();
        this.receiptOfInvoicesPending.splice(index, 1);
        this.onReceiptDeselected();
        this.receiptOfInvoiceUpdating = false;
        this.alertify.success('Success');
      }
    );
  }

  fileNames(receipt: any) {
    const route = `ReceiptOfGoods/file?purchaseOrderNo=${receipt.PurchaseOrderNo}`;
    this.fileList = [];
    this.sageApi.pullReport(route).subscribe(
      (response: any) => {
        const splitFiles = response.split(', \\\\gp-nas2\\SO_WT_Files');
        splitFiles.forEach(file => {
          if (file) {
            this.fileList.push({
              name: file.split(receipt.PurchaseOrderNo + '\\')[1],
            });
          }
        });
      },
      err => {
        console.log(err);
        this.alertify.error(err.message);
      },
      () => {
        // ToDo: Something
      }
    );
  }

  fileDownload(receipt: any, fileName: string) {
    const route = `ReceiptOfGoods/file/download?purchaseOrderNo=${receipt.PurchaseOrderNo}&fileName=${fileName}`;

    this.sageApi.getFile(route).subscribe(
      (response: any) => {
        const dataType = response.type;
        const binaryData = [];
        binaryData.push(response);
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(
          new Blob(binaryData, { type: dataType })
        );
        if (fileName) {
          downloadLink.setAttribute('download', fileName);
        }
        document.body.appendChild(downloadLink);
        downloadLink.click();
        downloadLink.parentNode.removeChild(downloadLink);
      },
      err => {
        console.log(err);
        this.alertify.error(err.message);
      }
    );
  }

  fileChosen(event) {
    const files = event.target.files;
    for (const index in files) {
      if (files[index] instanceof File) {
        const obj = {
          name: files[index].name,
          data: files[index],
        };

        this.fileArray = [obj];
      }
    }
  }

  fileUpload(receipt: any) {
    this.fileLoading = true;

    const route = `ReceiptOfGoods/file/upload?purchaseOrderNo=${receipt.PurchaseOrderNo}&invoiceNo=${receipt.InvoiceNo}`;
    const sentFiles = [];

    this.fileArray.forEach(file => {
      sentFiles.push(file.data);
    });

    this.sageApi.uploadFile(route, sentFiles).subscribe(
      res => {
        // ToDo: Something
      },
      err => {
        console.log(err);
        this.fileLoading = false;
      },
      () => {
        this.resetUpload();
        this.alertify.success('File Loaded');
      }
    );
  }

  resetUpload() {
    this.fileArray = [];
    this.fileLoading = false;
  }
}
