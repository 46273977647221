import { Component, ViewChild, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { AlertifyService } from '../../../_services/alertify/alertify.service';
import { WasabiApiService } from '../../../_services/wasabiApi/wasabiApi.service';
import { SageApiService } from '../../../_services/sageApi/sageApi.service';
import { concat } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AuthService } from '../../../_services/auth/auth.service';

@Component({
  selector: 'app-woms-price',
  templateUrl: './woms-price.component.html',
  styleUrls: ['./woms-price.component.css'],
})
export class WomsPriceComponent implements OnInit {
  dataModel = {
    customer: '',
    address: '',
    workPerformed: '',
    assignedTo: '',
    statusCode: '',
    steps: [],
    wts: [],
    subdivisions: [],
    orders: [],
    ordersLM: [],
    tracking: '',
    loaded: [],
    loadtech: [],
    loadstatuses: [],
    salesorder: '',
    statusCodes: '',
    techid: '',
    statuscomment: '',
    qaServiceCodes: '',
    newtracking: '',
    finaltracking: '',
    leadmanReport: [],
    redFlag: '',
    miscTech: [],
    uninstalledItems: ['loading'],
    materialChanges: [],
    kpiQA: [],
    kpiPunch: [],
    kpiOpenItems: [],
    serviceCodes: [],
    womsMaterial: [],
    ListLeadman: [],
    listWomsStatus: [],
    womsHistory: [],
    qaItemsForPunch: [],
  };
  index: any = '0';
  isLoading: boolean;
  loadingStatus: string;
  showMaterial = false;
  statusDateSortDirection: string;
  errorMsg = '';
  displayReasonToCancel = false;
  reasonToCancel = '';
  // * Images
  imageUploadComplete = false;
  isImageLoading = false;
  imageArr: Array<any> = [];
  displayImagesArr: any = {};
  fileNames: Array<string> = [];
  imgLoaded = false;
  imgArr: Array<any> = this.sageApi.imageUrlList;
  modalImgUrl: string;
  modalTemplate: BsModalRef;

  importTemplateInfo = {
    templateName: '',
    template: '',
    step: '',
  };

  @ViewChild('imgModal') imgModal;
  @ViewChild('editInterface') editInterface;

  constructor(
    private router: Router,
    private sageApi: SageApiService,
    private alertify: AlertifyService,
    private wasabiApi: WasabiApiService,
    private authService: AuthService,
    private modalService: BsModalService
  ) {}

  ngOnInit() {
    this.loadHistory();
  }

  cancelTicket(ticket, index) {
    this.dataModel.steps[index].newAmount = '0';
    this.dataModel.steps[index].newType = `CANCEL`;
  }

  // * Images
  loadImages() {
    // ToDo: Something
  }

  loadImagesS3() {
    // ToDo: Something
  }

  onViewFullImg(imgUrl: string) {
    this.modalImgUrl = imgUrl;
    this.imgModal.show();
  }
  // * /Images

  materialCostLoop(index) {
    // ! This line must be first or prices will be overridden when tickets load
    this.dataModel.steps[index].laborCost =
      (this.dataModel.steps[index].workTime / 60) * 150 || 150;

    if (this.dataModel.steps[index].material.length > 0) {
      this.dataModel.steps[index].material.forEach(item => {
        this.dataModel.steps[index].matCost.push(item.itemCost);
        this.dataModel.steps[index].matQuantity.push(item.quantity);
      });
    }

    this.dataModel.steps[index].matCost.map((num, costIndex) => {
      this.dataModel.steps[index].totalItemCosts.push(
        num * this.dataModel.steps[index].matQuantity[costIndex]
      );
    });

    if (this.dataModel.steps[index].totalItemCosts.length > 1) {
      this.dataModel.steps[index].totalMaterialCost = this.dataModel.steps[
        index
      ].totalItemCosts.reduce((a, b) => {
        return a + b;
      });
    } else {
      return (this.dataModel.steps[index].totalMaterialCost =
        this.dataModel.steps[index].totalItemCosts[0] || 0);
    }
  }

  openModal(template: TemplateRef<any>) {
    this.loadingStatus = '';
    this.importTemplateInfo = {
      templateName: '',
      template: '',
      step: '',
    };

    this.modalTemplate = this.modalService.show(template);
  }

  closeModal() {
    this.modalTemplate.hide();
  }

  actionEdit(index: string) {
    this.index = index.toString();
    this.showMaterial = false;
    this.imgLoaded = false;
    this.errorMsg = '';
    this.loadingStatus = '';
    this.displayReasonToCancel = false;
    this.reasonToCancel = '';
  }

  actionClose() {
    this.closeModal();
    this.showMaterial = false;
    this.imgLoaded = false;
    this.displayReasonToCancel = false;
    this.reasonToCancel = '';
  }

  saveChanges(index: string) {
    this.isLoading = true;

    if (
      this.dataModel.steps[index].newAmount < 75 &&
      this.dataModel.steps[index].newType !== `CANCEL`
    ) {
      alert('Please Enter an Amount of $75 or more');
      this.isLoading = false;
      return;
    }
    if (
      this.dataModel.steps[index].newType === `CANCEL` &&
      this.reasonToCancel === ''
    ) {
      alert('Please choose a reason for cancelling this ticket');
      this.isLoading = false;
      return;
    }

    const observables = [];
    const submitObj = {
      username: this.authService.decodedToken.nameid,
      password: this.authService.decodedToken[
        'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'
      ],
      salesorderno: this.dataModel.steps[index].soNum,
      wtnumber: this.dataModel.steps[index].soWT,
      lineKey: this.dataModel.steps[index].lineKey,
      newAmount: this.dataModel.steps[index].newAmount,
      histObj: [],
      cancelObj: [],
    };

    if (this.dataModel.steps[index].newType.toLowerCase() === `cancel`) {
      const cancelObj = [
        this.dataModel.steps[index].newType,
        this.reasonToCancel,
      ];
      submitObj.cancelObj = cancelObj;
    } else if (this.dataModel.steps[index].newType.toLowerCase() !== `cancel`) {
      console.warn('THIS IS THE WRONG ONE!!!');

      const newStatus =
        this.dataModel.steps[index].newType === 'BUILDER'
          ? 'PRCRV'
          : this.dataModel.steps[index].newType === 'GIBSON'
          ? 'LOSS'
          : this.dataModel.steps[index].newType === 'DISPUTE'
          ? 'DISBC'
          : this.dataModel.steps[index].newType === 'CREW'
          ? 'CREWBC'
          : this.dataModel.steps[index].newType === 'CANCEL'
          ? 'CANCEL'
          : this.dataModel.steps[index].newACCStatus || '  ';

      const datePipe = new DatePipe('en-US');
      const todaydate = datePipe.transform(new Date(), 'yyyyMMddHHmmss');
      const tmpKey = this.dataModel.steps[index].soNum + todaydate + 'H';

      const histObj = [
        /*webkey: 0*/ tmpKey,
        /*address: 1*/ this.dataModel.steps[index].address,
        /*descriptionOfWork: 2*/ this.dataModel.steps[index]
          .descriptionOfWork || ' ',
        /*technician: 3*/ this.dataModel.steps[index].technician || ' ',
        /*newTime: 4*/ this.dataModel.steps[index].newTime || ' ',
        /*workTime: 5*/ this.dataModel.steps[index].workTime || ' ',
        /*dateScheduled: 6*/ this.dataModel.steps[index].dateScheduled || ' ',
        /*matNeededDate: 7*/ this.dataModel.steps[index].matNeededDate || ' ',
        /*matDescription: 8*/ this.dataModel.steps[index].matDescription || ' ',
        /*notes: 9*/ this.dataModel.steps[index].notes || ' ',
        /*type: 10*/ this.dataModel.steps[index].type || ' ',
        /*builderName: 11*/ this.dataModel.steps[index].builderName || ' ',
        /*builderPhone: 12*/ this.dataModel.steps[index].builderPhone || ' ',
        /*currentStatus: 13*/ this.dataModel.steps[index].currentStatus || ' ',
        /*currentStatusDate: 14*/ this.dataModel.steps[index]
          .currentStatusDate || ' ',
        /*matNeededCheck: 15*/ this.dataModel.steps[index].matNeededCheck ||
          ' ',
        /*newStatus: 16*/ newStatus || ' ',
        /*newType: 17*/ this.dataModel.steps[index].newType || ' ',
        /*serviceCode: 18*/ this.dataModel.steps[index].serviceCode || ' ',
        /*builderCompany: 19*/ this.dataModel.steps[index].builderCompany ||
          ' ',
      ];
      submitObj.histObj = histObj;
    }

    observables.push(this.sageApi.putRequest('WomsPrice', submitObj));

    concat(...observables).subscribe(
      e => {
        // ToDo: Something
      },
      err => {
        console.log(err);
        this.alertify.error(err.error.Message);
        this.isLoading = false;
        this.loadingStatus = this.errorMsg;
      },
      () => {
        this.isLoading = false;
        this.loadingStatus = 'Loaded Successfully - Changes Committed to SAGE';
        this.alertify.success(this.loadingStatus);
        this.dataModel.steps[index].updated = 'updated';
        this.closeModal();
      }
    );
  }

  loadHistoryMaterial(index) {
    const [soNum, wtNum] = [
      this.dataModel.steps[index].soNum,
      this.dataModel.steps[index].soWT,
    ];
    this.dataModel.womsMaterial = [];
    this.sageApi
      .pullReport(`womsMaterial?soNum=${soNum}&wtNum=${wtNum}`)
      .subscribe(
        (wts: Array<any>) => {
          if (wts && Object.keys(wts).length < 1) {
            if (this.dataModel.steps[index].materialNeeded === 'Y') {
              this.dataModel.steps[index].matNotShown = true;
            }
            return;
          }
          if (Array.isArray(wts)) {
            wts.forEach(w => {
              const obj = {
                soNum: w.SalesOrderNo,
                wtNum: w.JT158_WTNumber,
                itemCode: w.ItemCode,
                itemDesc: w.ItemDesc,
                itemCost: w.itemCOST * 1.3,
                quantity: w.QuantityOrdered,
                workTime: w.WorkTime,
              };
              this.dataModel.steps[index].material.push(obj);
            });
          }
        },
        err => {
          console.error(`Error in History Material: ${err}`);
        },
        () => {
          // ! Checks to see if the material is Non Stock - Residential and sets a boolean to display a flag on the ticket
          this.dataModel.steps[index].material.forEach(element => {
            element.itemCode.includes(`/NS-RES`)
              ? (this.dataModel.steps[index].nonStockCheck = true)
              : (this.dataModel.steps[index].nonStockCheck = false);
          });
          console.warn(`Material history successfully loaded!`);
          this.materialCostLoop(index);
        }
      );
  }

  loadHistory() {
    this.index = '0';
    let i = 0;
    this.dataModel.steps = [];
    this.sageApi.pullReport('womsHistory/2').subscribe(
      (wts: Array<any>) => {
        if (wts && Object.keys(wts).length < 1) {
          return;
        }
        if (Array.isArray(wts)) {
          wts.forEach(w => {
            const obj = {
              soNum: w.SalesOrderNo,
              soWT: w.WTNumber,
              wtNumber: w.SalesOrderNo + '-' + w.WTNumber,
              lineKey: w.LineKey,
              address: w.Address,
              technician: w.Technician,
              descriptionOfWork: w.DescriptionOfWork,
              workTime: w.WorkTime,
              builderName: w.BuilderName,
              builderPhone: w.BuilderPhone,
              builderCompany: w.BuilderCompany,
              subdivision: w.Subdivision,
              type: w.Type,
              newType: '',
              notes: w.TypeComment,
              newAmount: '',
              crew1: '',
              crew2: '',
              crew3: '',
              crewPercent1: '',
              crewPercent2: '',
              crewPercent3: '',
              updated: '',
              leadman: w.UDF_LEADMAN,
              materialNeeded: w.UDF_MAT_NEEDED_CHECK,
              materialDesc: w.UDF_MAT_DESCRIPTION,
              ticketStatus: w.Status,
              dateScheduled: w.PromiseDate,
              matNotShown: false,
              currentStatusDate: w.UDF_WOMS_STATUS_DATE || `17530101`,
              material: [],
              nonStockCheck: false,
              matQuantity: [],
              matCost: [],
              totalItemCosts: [],
              totalMaterialCost: 0,
              laborCost: 150,
              totalJobCost: 0,
              punchImgKey: w.UDF_PUNCH_IMAGE_KEY || ' ',
            };
            this.dataModel.steps.push(obj);
            this.loadHistoryMaterial(i);
            i++;
          });
        }
      },
      err => {
        console.log(err);
      },
      () => {
        // ToDo: Something
      }
    );
  }

  onColumnSort(column, order) {
    this.dataModel.steps.sort(compareValues(column, order));

    function compareValues(key, order2 = 'asc') {
      return function innerSort(a, b) {
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
          return 0;
        }

        const A = typeof a[key] === `string` ? a[key].toUpperCase() : a[key];
        const B = typeof b[key] === `string` ? b[key].toUpperCase() : b[key];

        let comparison = 0;

        if (A > B) {
          comparison = 1;
        } else if (A < B) {
          comparison = -1;
        }
        return order2 === 'desc' ? comparison * -1 : comparison;
      };
    }

    if (column === 'currentStatusDate') {
      if (
        this.statusDateSortDirection === 'asc' ||
        this.statusDateSortDirection === ''
      ) {
        this.statusDateSortDirection = 'desc';
      } else {
        this.statusDateSortDirection = 'asc';
      }
    }
  }
}
