import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';

@Injectable({
  providedIn: 'root',
})
export class MetabaseService {
  constructor(private httpClient: HttpClient) {}
  baseApiURL = 'https://metabase.gibsonplumbing.com/api';

  _getMetabaseAuthToken() {
    const requestURL = `${this.baseApiURL}/session`;

    const reportAuth = {
      Accept: '*/*',
    };
    const headers = new HttpHeaders().set(
      'JWTAuthorization',
      `Bearer ${reportAuth}`
    );

    // TODO - update this to set credentials in ENV file
    const body = {
      username: 'plumbers@gibsonplumbing.com',
      password: 'Gibson1954!!Plumbing',
    };

    return this.httpClient.post(requestURL, body, { headers }).map(response => {
      return response;
    });
  }

  metabaseDashboardUpdate(address: string, authToken: string) {
    // * The id in the URL can be changed to update other dashboards/questions - 2 is the id for House360 View * //
    const requestURL = `${this.baseApiURL}/dashboard/2`;
    // * Removes double quotes from auth token * //
    const mbAuthToken = authToken.replace(/['"]+/g, '');

    const reportAuth = {
      Accept: '*/*',
      'X-Metabase-Session': mbAuthToken,
    };
    const headers = new HttpHeaders().set(
      'JWTAuthorization',
      `Bearer ${reportAuth}`
    );

    const body = {
      resource: { dashboard: 2 },
      params: {
        Address: [address],
      },
      exp: Math.round(Date.now() / 1000) + 30 * 60, // 30 minute expiration
    };

    const url = requestURL;

    return this.httpClient
      .put(url, body, { headers })
      .map((response: Response) => {
        return response;
      });
  }
}
