import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ScreenSizeService } from 'src/app/_services/ScreenSizeService/ScreenSizeService';
import KitDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/KitDtoInterface';
import { SageApiService } from 'src/app/_services/sageApi/sageApi.service';
import { ConfirmModalComponent } from '../../Platform/confirm-modal/confirm-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BottomSheetComponent } from '../../Platform/bottom-sheet/bottom-sheet.component';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import ContextKitDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextKitDtoInterface';
import PartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/PartDtoInterface';
import { map } from 'rxjs-compat/operator/map';
import ContextKitPartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextKitPartDtoInterface';
import KitUtil from 'src/app/utils/KitUtil';
import { Context } from 'vm';
import { Observable, zip } from 'rxjs';
import KitPartPhases from '../KitPartPhases';
import kitPartPhases from '../KitPartPhases';
import KitPartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/KitPartDtoInterface';
import QuoteUtil from 'src/app/utils/QuoteUtil';
import ContextQuoteDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextQuoteDtoInterface';
import ContextQuoteKitPartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextQuoteKitPartDtoInterface';
import ContextQuotePartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextQuotePartInterface';
import QuoteKitPartEditableInterface, {
  QuoteKitPartEditableDataInterface,
} from '../../../_services/QuoteEditableService/interfaces/QuoteKitPartEditableInterface';
import QuoteKitPartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/QuoteKitPartDtoInterface';
import CustomQuoteKitToAddInterface from 'src/app/_services/sageApi/interfaces/pullReport/CustomQuoteKitToAddInterface';
import QuoteDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/QuoteDtoInterface';
import InputIntSanitizer from 'src/app/utils/InputIntSanitizer';
import QuoteDataEditableInterface from '../../../_services/QuoteEditableService/interfaces/QuoteDataEditableInterface';

@Component({
  selector: 'app-customqkit-botsheet',
  templateUrl: './CustomQuoteKitBotSheetComponent.html',
  styleUrls: ['./CustomQuoteKitBotSheetComponent.css', '../QuotingTheme.scss'],
})
export class CustomQuoteKitBotSheetComponent implements OnChanges {
  kitutil = new KitUtil();
  quoteutil = new QuoteUtil();

  @Input() parentQuoteOrKit:
    | QuoteDataEditableInterface
    | QuoteKitPartEditableInterface;

  @Input() open = false;

  loading = false;

  nameControl = new FormControl('', [
    Validators.required,
    Validators.min(1),
    Validators.max(30),
  ]);
  quantityControl = new FormControl(1, [
    Validators.required,
    Validators.min(1),
    Validators.max(9999),
  ]);
  descControl = new FormControl('', [Validators.max(255)]);

  @Output() addQuoteKit = new EventEmitter<CustomQuoteKitToAddInterface>();
  @Output() sheetClosed = new EventEmitter();

  constructor(
    public api: SageApiService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public screenSize: ScreenSizeService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    // if open changed to true, make sure you run the sheetOpened function
    if (changes?.open?.currentValue == true) {
      this.sheetOpened();
    }
    this.resetControls();
  }

  resetControls() {
    this.nameControl = new FormControl('', [
      Validators.required,
      Validators.min(1),
      Validators.max(30),
    ]);
    this.quantityControl = new FormControl(1, [
      Validators.required,
      Validators.min(1),
      Validators.max(9999),
    ]);
    this.descControl = new FormControl('', [Validators.max(255)]);
  }

  sheetOpened() {
    this.loading = false;
  }

  closeSheet() {
    this.open = false;
    this.sheetClosed.emit();
  }

  canAdd() {
    if (this.loading) {
      return false;
    }
    return (
      this.nameControl.valid &&
      this.quantityControl.valid &&
      this.descControl.valid
    );
  }

  addCustomKit() {
    this.loading = true;
    this.addQuoteKit.emit({
      name: this.nameControl.value,
      quantity: this.quantityControl.value,
      desc: this.descControl.value,
      region: 'NA',
    });
  }

  quantityInput(e: InputEvent) {
    const val = InputIntSanitizer(e);
    this.quantityControl.setValue(val);
  }

  getTitle() {
    let title = '';
    if (this.parentQuoteOrKit != null && this.parentQuoteOrKit?.data != null) {
      if (
        'Quote' in this.parentQuoteOrKit?.data &&
        this.parentQuoteOrKit?.data?.Quote?.Quote_Name != null
      ) {
        title = this.parentQuoteOrKit?.data?.Quote?.Quote_Name;
      } else if (
        'QuoteKit' in this.parentQuoteOrKit?.data &&
        this.parentQuoteOrKit?.data?.QuoteKit?.QuoteKit?.QuoteKit_Name != null
      ) {
        title = this.parentQuoteOrKit?.data?.QuoteKit.QuoteKit.QuoteKit_Name;
      }
    }
    return title;
  }
}
