import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { AlertifyService } from '../../../_services/alertify/alertify.service';
import { SageApiService } from '../../../_services/sageApi/sageApi.service';
import { AuthService } from '../../../_services/auth/auth.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { concat } from 'rxjs';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { DatePipe } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css'],
})
export class EditUserComponent implements OnInit {
  filterSearch = '';
  modalTemplate: BsModalRef;
  allUsers: UserInterface[] = [];
  loading: boolean;
  displayedColumns: string[] = [
    'username',
    'name',
    'role',
    'superName',
    'techCode',
    'jobTitle',
    'jobDepartment',
  ];
  userinformation = {
    username: '',
    role: '',
    superName: '',
    techCode: '',
    jobTitle: '',
    jobDepartment: '',
  };

  roleOptions: string[] = [];
  roles: FormControl = new FormControl([]);

  jobDepartOptions: string[] = [];
  jobDepart: FormControl = new FormControl([]);

  allTechs = [];
  allSupers = [];
  allRoles = [];
  jobTitlesForRole = [];
  jobDepartments = [];
  username = '';
  jobTitle = '';
  techCode = '';
  super = '';

  usernameSort = '';
  @ViewChild('imgModal') imgModal;

  constructor(
    private alertify: AlertifyService,
    private sageApi: SageApiService,
    private authService: AuthService,
    private modalService: BsModalService,
    private datePipe: DatePipe,
    private router: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.getAllUsers();
    this.getAllRoles();
    this.getAllTechs();
    this.getAvailableSupers();
  }
  RouteToNew() {
    this.router.navigate(['/NewUser']);
  }

  sortByUsername() {
    if (this.usernameSort == 'desc') {
      this.allUsers.sort((a, b) => b.username.localeCompare(a.username));
      this.usernameSort = 'asc';
    } else if (this.usernameSort == 'asc') {
      this.allUsers.sort((a, b) => a.username.localeCompare(b.username));
      this.usernameSort = 'desc';
    }
  }

  getAvailableSupers() {
    this.sageApi.pullReport('EditUser/AvailableSupers').subscribe(
      (supers: Array<any>) => {
        if (Array.isArray(supers)) {
          supers.forEach(lead => {
            const obj = {
              nickname: lead.nickname,
            };
            this.allSupers.push(obj);
          });
        }
      },
      err => {
        this.alertify.error(err.message);
      },
      () => {}
    );
  }
  getAllRoles() {
    this.sageApi.pullReport('EditUser/Roles').subscribe(
      (users: Array<any>) => {
        if (Array.isArray(users)) {
          users.forEach(user => {
            if (!this.allRoles.includes(user.role)) {
              this.allRoles.push(user.role);
            }
            if (!this.jobDepartments.includes(user.jobDepartment)) {
              this.jobDepartments.push(user.jobDepartment);
            }
            if (!this.jobTitlesForRole.includes(user.jobTitle)) {
              this.jobTitlesForRole.push(user.jobTitle);
            }
          });

          this.jobDepartments.forEach(element => {
            if (element != null) {
              this.jobDepartOptions.push(element);
            }
          });
          this.jobDepart.setValue(this.jobDepartOptions);

          this.roleOptions = this.allRoles;
          this.roles.setValue(this.roleOptions);
        }
      },
      err => {
        this.alertify.error(err.message);
      },
      () => {}
    );
  }
  getAllUsers() {
    this.loading = true;
    this.sageApi.pullReport('EditUser/EditExisting').subscribe(
      (users: Array<any>) => {
        if (Array.isArray(users)) {
          users.forEach(user => {
            const obj: UserInterface = {
              username: user.UserLogon,
              name: user.FirstName + ' ' + user.LastName,
              role: user.role,
              superName: user.superName,
              techCode: user.techCode,
              jobTitle: user.jobTitle,
              jobDepartment: user.jobDepartment,
            };
            this.allUsers.push(obj);
            this.allUsers.sort((a, b) => a.username.localeCompare(b.username));
            this.usernameSort = 'desc';
          });
        }
        this.loading = false;
      },
      err => {
        this.alertify.error(err.message);
      },
      () => {}
    );
  }

  isIncludedInJobDepartment(row: UserInterface) {
    const jobDepartOp = (row.jobDepartment == undefined
      ? ''
      : row.jobDepartment
    ).trim();
    if (
      this.jobDepart.value.sort().join(',') ==
      this.jobDepartOptions.sort().join(',')
    ) {
      return true;
    }
    if (this.jobDepart.value.includes(jobDepartOp)) {
      return true;
    }
    return false;
  }

  isIncludedInRole(row: UserInterface) {
    const rolesOp = (row.role == undefined ? '' : row.role).trim();
    if (
      this.roles.value.sort().join(',') == this.roleOptions.sort().join(',')
    ) {
      return true;
    }
    if (this.roles.value.includes(rolesOp)) {
      return true;
    }
    return false;
  }

  isIncludedInSearch(isContainedStep: UserInterface) {
    if (this.filterSearch.trim() == '') {
      return true;
    }
    if (
      isContainedStep.name
        .toUpperCase()
        .includes(this.filterSearch.toUpperCase()) ||
      isContainedStep.username
        .toUpperCase()
        .includes(this.filterSearch.toUpperCase())
    ) {
      return true;
    }
    return false;
  }
  getIncludedSteps(): UserInterface[] {
    const isteps: UserInterface[] = [];
    for (const step of this.allUsers) {
      if (this.isStepIncluded(step)) {
        isteps.push(step);
      }
    }
    return isteps;
  }

  isStepIncluded(step: UserInterface) {
    const filterIncluded = this.isIncludedInRole(step);
    const jobDepartIncluded = this.isIncludedInJobDepartment(step);
    const searchIncluded = this.isIncludedInSearch(step);
    if (filterIncluded && jobDepartIncluded && searchIncluded) {
      return true;
    }
    return false;
  }

  selectRole(event: TypeaheadMatch) {
    if (this.allRoles.includes(event.value)) {
      this.userinformation.role = event.value;
    }
  }

  confirmRole() {
    if (!this.allRoles.includes(this.userinformation.role)) {
      this.userinformation.role = '';
    }
  }
  confirmSuper() {
    if (!this.allSupers.includes(this.userinformation.superName)) {
      this.userinformation.superName = '';
    }
  }

  selectSuper(event: TypeaheadMatch) {
    this.super = event.value;
  }
  selectUser(event: TypeaheadMatch) {
    this.username = event.value;
  }
  selectTech(event: TypeaheadMatch) {
    this.techCode = event.item.techCode;
    this.userinformation.techCode = event.item.techCode;
  }
  checkForm() {
    if (this.allRoles.includes(this.userinformation.role)) {
      if (
        this.allSupers.some(
          x => x.nickname == this.userinformation.superName
        ) ||
        this.userinformation.superName == ''
      ) {
        if (this.allTechs.some(x => x.techCode == this.techCode)) {
          return false;
        }
      }
    } else {
      return true;
    }
  }
  submitForm(): void {
    const obj = {
      username: this.userinformation.username,
      role: this.userinformation.role,
      jobTitle: this.userinformation.jobTitle,
      jobDepartment: this.userinformation.jobDepartment,
      techCode: this.userinformation.techCode,
      superName: this.userinformation.superName,
    };

    const observables = [];

    observables.push(this.sageApi.putRequest('EditUser/EditUser', obj));

    concat(...observables).subscribe(
      (response: any) => {
        this.alertify.success('Successfully edited user');
        this.closeModal();
      },
      err => {
        this.alertify.error('Error editing user.');
      },
      () => {
        this.closeModal();
        this.clear();
      }
    );
  }
  getAllTechs() {
    this.sageApi.pullReport('EditUser/Technicians').subscribe(
      (techs: Array<any>) => {
        if (Array.isArray(techs)) {
          techs.forEach(tech => {
            const obj = {
              techCode: tech.TechnicianDeptNo + tech.TechnicianNo,
              name: tech.FirstName + ' ' + tech.LastName,
            };
            this.allTechs.push(obj);
          });
          const blankObj = {
            techCode: '',
            name: '',
          };
          this.allTechs.push(blankObj);
        }
      },
      err => {
        this.alertify.error(err.message);
      },
      () => {}
    );
  }

  clear() {
    this.userinformation = {
      username: '',
      role: '',
      superName: '',
      techCode: '',
      jobTitle: '',
      jobDepartment: '',
    };
    this.allUsers = [];
    this.roleOptions = [];
    this.roles.setValue([]);
    this.jobDepart.setValue([]);
    this.jobDepartOptions = [];

    this.allTechs = [];
    this.allSupers = [];
    this.allRoles = [];
    this.jobTitlesForRole = [];
    this.jobDepartments = [];
    this.ngOnInit();
  }
  openModal(template: TemplateRef<any>, row) {
    this.userinformation = {
      username: row.username,
      role: row.role,
      superName: row.superName,
      techCode: row.techCode,
      jobTitle: row.jobTitle,
      jobDepartment: row.jobDepartment,
    };
    // const dialogRef = this.dialog.open(DialogOverviewExampleDialog)
    this.modalTemplate = this.modalService.show(template, {
      class: 'modal-lg',
    });
  }
  closeModal() {
    this.modalTemplate.hide();
  }
}
export interface UserInterface {
  username: string;
  name: string;
  role: string;
  superName: string;
  techCode: string;
  jobTitle: string;
  jobDepartment: string;
}
