import { element } from 'protractor';
import { SalesOrder } from './../../../_services/sageApi/interfaces/pullReport/SalesOrderLookup';
import {
  AfterViewInit,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { AlertifyService } from 'src/app/_services/alertify/alertify.service';
import { SageApiService } from 'src/app/_services/sageApi/sageApi.service';
import { DatePipe } from '@angular/common';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { AuthService } from 'src/app/_services/auth/auth.service';
interface Woms {
  SalesOrderNo: string;
  Parent: string;
  WTNumber: string;
  WomsType: string;
  WOMSStatus: string;
}
interface Status {
  SalesOrderNo: string;
  Parent: string;
  StatusCode: string;
  StatusDate: string;
  WTNumber: string;
}
@Component({
  selector: 'app-work-ticket-update',
  templateUrl: './work-ticket-update.component.html',
  styleUrls: ['./work-ticket-update.component.css'],
})
export class WorkTicketUpdateComponent implements OnInit, AfterViewInit {
  modalTemplate: BsModalRef;

  addressList = [];
  addressName = '';
  houseLoading: boolean;
  loading: boolean;
  WOMSworkticket: MatTableDataSource<Woms>;
  Statusworkticket: MatTableDataSource<Status>;
  WomsColumns: string[] = [
    'SalesOrderNo',
    'Parent',
    'WTNumber',
    'WomsType',
    'WOMSStatus',
    'Action',
  ];
  StatusColumns: string[] = [
    'SalesOrderNo',
    'Parent',
    'StatusDate',
    'WTNumber',
    'StatusCode',
    'Action',
  ];
  WomsStatuses = [];
  StatusCodes = [];
  newWomsStatus = '';
  salesorderno = '';
  newWorkTicketStatus = '';
  womsUpdate: Woms;
  workticketUpdate: Status;
  @ViewChild('womUpdate') unknownCode: TemplateRef<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('womsSort') womsSort: MatSort;
  @ViewChild('statusSort') statusSort: MatSort;

  constructor(
    private alertify: AlertifyService,
    private sageApi: SageApiService,
    private modalService: BsModalService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.loadAddressList();
    this.WomsStatus();
    this.workticketStatus();
  }

  ngAfterViewInit(): void {
    this.WOMSworkticket.paginator = this.paginator;
    this.WOMSworkticket.sort = this.womsSort;

    this.Statusworkticket.paginator = this.paginator;
    this.Statusworkticket.sort = this.statusSort;
  }

  loadAddressList() {
    this.loading = true;
    this.addressList = [];
    this.sageApi.pullReport('HouseEditor/Addresses').subscribe(
      (rows: Array<any>) => {
        if (Array.isArray(rows)) {
          rows.forEach(row => {
            const address = {
              name: row.Address + ' - ' + row.SalesOrderNo,
              address: row.Address,
              salesOrderNo: row.SalesOrderNo,
            };

            this.addressList.push(address);
          });
        }
        this.loading = false;
      },
      err => {
        this.alertify.error(err.message);
        console.log(err);
        this.loading = false;
      }
    );
  }
  loadIt(e) {
    const opt = this.addressList.find(x => x.name == e);
    this.addressSelected(opt.salesOrderNo);
  }

  addressSelected(e) {
    let salesorder = '';
    if (e.item != undefined) {
      salesorder = e.item.salesOrderNo;
    } else {
      salesorder = e;
    }
    this.loading = true;
    const WomsList = [];
    const statusList = [];
    this.sageApi.pullReport('WorkTicket?SalesOrderNo=' + salesorder).subscribe(
      (rows: Array<any>) => {
        if (Array.isArray(rows)) {
          rows.forEach(row => {
            if (row.WTStep == '000') {
              const opt = row.HdrParentItemCode;
              if (opt.toUpperCase().includes('PUNCH')) {
                const info = {
                  SalesOrderNo: row.SalesOrderNo,
                  Parent: row.HdrParentItemCode,
                  WOMSStatus: row.UDF_WOMS_STATUS,
                  WTNumber: row.WTNumber,
                  WomsType: row.UDF_WOMS_TYPE,
                };
                WomsList.push(info);
              }
              const statusInfo = {
                SalesOrderNo: row.SalesOrderNo,
                Parent: row.HdrParentItemCode,
                StatusCode: row.StatusCode,
                StatusDate: row.StatusDate,
                WTNumber: row.WTNumber,
                DateScheduled: row.UDF_DATE_SCHEDULED,
                TechCode: row.UDF_TECHNICIAN_CODE,
              };
              statusList.push(statusInfo);
            }
          });
        }
        this.WOMSworkticket = new MatTableDataSource(WomsList);
        this.Statusworkticket = new MatTableDataSource(statusList);

        this.Statusworkticket.sort = this.statusSort;
        this.WOMSworkticket.sort = this.womsSort;
        this.loading = false;
      },
      err => {
        this.loading = false;
        console.log(err);
        this.alertify.error(err.message);
      }
    );
  }
  editWomsStatus(e: Woms) {
    this.newWomsStatus = '';
    this.womsUpdate = e;
  }

  editStatus(e: Status) {
    this.newWorkTicketStatus = '';
    this.workticketUpdate = e;
  }
  WomsStatus(): void {
    this.WomsStatuses = [];
    this.sageApi.pullReport('WomsStatuses').subscribe((rows: Array<any>) => {
      if (Array.isArray(rows)) {
        rows.forEach(row => {
          const obj = {
            statusCode: row.status_code,
            desc: row.status_code_desc,
            fullDesc: row.status_code + ' - ' + row.status_code_desc,
          };
          this.WomsStatuses.push(obj);
        });
      }
    });
  }
  workticketStatus(): void {
    this.StatusCodes = [];
    this.sageApi.pullReport('statusCode').subscribe((rows: Array<any>) => {
      if (Array.isArray(rows)) {
        rows.forEach(row => {
          const obj = {
            statusCode: row.StatusCode,
            desc: row.StatusDescription,
            fullDesc: row.StatusCode + ' - ' + row.StatusDescription,
          };
          this.StatusCodes.push(obj);
        });
      }
    });
  }

  openModal(template: TemplateRef<any>) {
    this.modalTemplate = this.modalService.show(template);
  }
  closeModal() {
    this.modalTemplate.hide();
  }
  updateWoms() {
    this.loading = true;
    const obj = {
      username: this.authService.decodedToken?.nameid.toLocaleLowerCase(),
      password: this.authService.decodedToken[
        'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'
      ],
      salesorderno: this.womsUpdate.SalesOrderNo,
      wtnumber: this.womsUpdate.WTNumber,
      wtstep: '000',
      womsStatus: this.newWomsStatus,
      womsnotes: '',
    };

    this.sageApi.putRequest('WorkTicket/UpdateWOMS', obj).subscribe(
      (response: any) => {
        this.alertify.success(response);
      },
      err => {
        this.alertify.error(err.error.Message);
        this.loading = false;
        this.closeModal();
        this.addressSelected(this.womsUpdate.SalesOrderNo);
      },
      () => {
        this.loading = false;
        this.closeModal();
        this.addressSelected(this.womsUpdate.SalesOrderNo);
      }
    );
  }

  updateStatus() {
    this.loading = true;
    this.closeModal();
    const obj = {
      username: this.authService.decodedToken?.nameid.toLocaleLowerCase(),
      password: this.authService.decodedToken[
        'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'
      ],
      salesorderno: this.workticketUpdate.SalesOrderNo,
      wtNumber: this.workticketUpdate.WTNumber,
      status: this.newWorkTicketStatus,
    };

    this.sageApi.putRequest('WorkTicket/UpdateStatus', obj).subscribe(
      (response: any) => {
        this.alertify.success(response);
      },
      err => {
        this.alertify.error(err.error.Message);
        this.loading = false;
        this.addressSelected(this.workticketUpdate.SalesOrderNo);
      },
      () => {
        this.loading = false;
        this.addressSelected(this.workticketUpdate.SalesOrderNo);
      }
    );
  }
}
