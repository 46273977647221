import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { BaseModalService } from 'src/app/_services/BaseModalService/BaseModalService';
import { MultiIsLoadingService } from 'src/app/_services/multi-is-loading/multi-is-loading.service';
import BidOverviewDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/BidOverviewDtoInterface';
import { SageApiService } from 'src/app/_services/sageApi/sageApi.service';
import { ScreenSizeService } from 'src/app/_services/ScreenSizeService/ScreenSizeService';
import KitRegions from '../KitRegions';
import QuoteStatuses from '../QuoteStatuses';
import BidDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/BidDtoInterface';
import { QuoteEditableService } from 'src/app/_services/QuoteEditableService/QuoteEditableService';
import QuoteOverviewDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/QuoteOverviewDtoInterface';
import { forkJoin, Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import ContextBidOverviewDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextBidOverviewDtoInterface';
import QuoteOverviewWithOptionsDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/QuoteOverviewWithOptionsDtoInterface';
import BidInclusionDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/BidInclusionDtoInterface';
import BidExclusionDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/BidExclusionDtoInterface';
import BidBillingTermDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/BidBillingTermDtoInterface';
import BidSpecDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/BidSpecDtoInterface';
import BuildLocationDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/BuildLocationDtoInterface';
import ContextQuotePartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextQuotePartInterface';
import ContextQuoteKitPartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextQuoteKitPartDtoInterface';
import ContextQuoteDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextQuoteDtoInterface';

interface QuotesTableDataInterface {
  Quote_guid: string;
  Quote_Name: string;
  Quote_Desc: string;
  Quote_TotalPart: number;
  Quote_TotalCost: number;
}

interface AddBotSheetResultInterface {
  quote: QuoteOverviewDtoInterface;
  selected: boolean;
}

@Component({
  selector: 'app-bidview',
  templateUrl: './BidViewComponent.html',
  styleUrls: ['./BidViewComponent.scss', '../QuotingTheme.scss'],
  providers: [
    { provide: 'initLoading', useClass: MultiIsLoadingService },
    { provide: 'saving', useClass: MultiIsLoadingService },
  ],
})
export class BidViewComponent implements OnInit {
  bidGuid = this.activatedRoute.snapshot.params.bidGuid;

  bid: ContextBidOverviewDtoInterface;
  quotesDataTable: QuotesTableDataInterface[] = [];

  regions = KitRegions;
  statuses = QuoteStatuses;

  // Now make a form control for each of the fields on the bid
  bidNameCtrl = new FormControl('', [
    Validators.required,
    Validators.minLength(5),
    Validators.maxLength(30),
  ]);
  bidDescCtrl = new FormControl('', [Validators.maxLength(1024)]);
  bidBuilderCtrl = new FormControl('', [
    Validators.required,
    Validators.maxLength(30),
  ]);
  bidRegionCtrl = new FormControl('', [Validators.required]);
  bidStatusCtrl = new FormControl('', [Validators.required]);

  addQuoteBotSheetOpen = false;
  addQuoteBotSheetLoading = false;
  addQuoteBotSheetResults: AddBotSheetResultInterface[] = [];
  addQuoteBotSheetSearchCtrl = new FormControl('');

  inclusionsSaving: { guid: string; lid: string }[] = []; // An array of the guids of the currently saving guids of BidInclusions and the loading id for each request
  exclusionsSaving: { guid: string; lid: string }[] = []; // An array of the guids of the currently saving guids of BidExclusions and the loading id for each request
  billingTermsSaving: { guid: string; lid: string }[] = []; // An array of the guids of the currently saving guids of BidBillingTerms and the loading id for each request
  specsSaving: { guid: string; lid: string }[] = []; // An array of the guids of the currently saving guids of BidSpecs and the loading id for each request

  finalizeSaving = false;

  constructor(
    private api: SageApiService,
    private activatedRoute: ActivatedRoute,
    private bm: BaseModalService,
    private snkbr: MatSnackBar,
    private qe: QuoteEditableService,
    public screenSize: ScreenSizeService,
    @Inject('initLoading') public initLoading: MultiIsLoadingService,
    @Inject('saving') public saving: MultiIsLoadingService
  ) {}

  ngOnInit(): void {
    this.initLoading
      .loadingUntilComplete(this.api.pullReport(`bid/${this.bidGuid}`))
      .subscribe(
        (result: ContextBidOverviewDtoInterface) => {
          this.bid = result;
          this.quotesDataTable = result.Quotes.map(quote => ({
            Quote_guid: quote.Quote.Quote_guid,
            Quote_Name: quote.Quote.Quote_Name,
            Quote_Desc: quote.Quote.Quote_Desc,
            Quote_TotalPart: quote.Quote.Quote_TotalPart,
            Quote_TotalCost: quote.Quote.Quote_TotalCost,
          }));
          this.resetBidControls();
        },
        err => {
          console.error(err);
          this.snkbr.open('Failed to load bid', 'Dismiss', {
            duration: Infinity,
          });
        }
      );
  }

  finalizeBid(): void {
    const lid = this.saving.startLoading();
    this.finalizeSaving = true;
    this.api
      .postBlob(`bid-pdf/${this.bidGuid}`, {
        bidGuid: this.bidGuid,
        finalized: true,
      })
      .subscribe(
        response => {
          this.bid.Bid.Bid_Revision++;
          const blob = new Blob([response], { type: 'application/pdf' });
          const file = window.URL.createObjectURL(blob);
          window.open(file);
          this.saving.stopLoading([lid]);
          this.finalizeSaving = false;
        },
        err => {
          console.error(err);
          this.snkbr.open('Failed to finalize bid', 'Dismiss', {
            duration: Infinity,
          });
          this.saving.stopLoading([lid]);
          this.finalizeSaving = false;
        }
      );
  }

  resetBidControls(changes?: {
    Bid_Name?: boolean;
    Bid_Desc?: boolean;
    Bid_Builder?: boolean;
    Bid_Region?: boolean;
    Bid_Status?: boolean;
  }): void {
    if (changes?.Bid_Name || changes == undefined) {
      this.bidNameCtrl.reset(this.bid.Bid.Bid_Name);
    }
    if (changes?.Bid_Desc || changes == undefined) {
      this.bidDescCtrl.reset(this.bid.Bid.Bid_Desc);
    }
    if (changes?.Bid_Builder || changes == undefined) {
      this.bidBuilderCtrl.reset(this.bid.Bid.Bid_Builder);
    }
    if (changes?.Bid_Region || changes == undefined) {
      this.bidRegionCtrl.reset(this.bid.Bid.Bid_Region);
    }
    if (changes?.Bid_Status || changes == undefined) {
      this.bidStatusCtrl.reset(this.bid.Bid.Bid_Status);
    }
  }

  bidControlsChanged(): boolean {
    return (
      this.bidNameCtrl.value !== this.bid.Bid.Bid_Name ||
      this.bidDescCtrl.value !== this.bid.Bid.Bid_Desc ||
      this.bidBuilderCtrl.value !== this.bid.Bid.Bid_Builder ||
      this.bidRegionCtrl.value !== this.bid.Bid.Bid_Region ||
      this.bidStatusCtrl.value !== this.bid.Bid.Bid_Status
    );
  }

  bidControlsValid(): boolean {
    return (
      this.bidNameCtrl.valid &&
      this.bidDescCtrl.valid &&
      this.bidBuilderCtrl.valid &&
      this.bidRegionCtrl.valid &&
      this.bidStatusCtrl.valid
    );
  }

  saveBidChanges(bidChanges: {
    Bid_Name?: string;
    Bid_Desc?: string;
    Bid_Builder?: string;
    Bid_Region?: string;
    Bid_Status?: string;
  }): void {
    this.saving
      .loadingUntilComplete(
        this.api.patchRequest(`bid/${this.bidGuid}`, bidChanges)
      )
      .subscribe(
        (bidChanges: BidDtoInterface) => {
          this.bid.Bid = bidChanges;
          this.snkbr.open('Changes saved', 'Dismiss', {
            duration: 3000,
          });
        },
        err => {
          console.error(err);
          this.snkbr.open('Failed to save bid', 'Dismiss', {
            duration: Infinity,
          });
        }
      );
  }

  removeQuote(quoteIndex: number): void {
    // First, get the quote at the index
    const quote = this.bid.Quotes[quoteIndex];
    this.bm
      .confirm(
        `Remove ${quote.Quote.Quote_Name}?`,
        `Really remove ${quote.Quote.Quote_Name} from ${this.bid.Bid.Bid_Name}?`
      )
      .subscribe(result => {
        if (result) {
          this.saving
            .loadingUntilComplete(
              this.qe.patchQuoteReq({
                quoteGuid: quote.Quote.Quote_guid,
                quoteChanges: {
                  Bid_guid: '',
                },
              })
            )
            .subscribe(() => {
              this.bid.Quotes = this.bid.Quotes.filter(
                q => q.Quote.Quote_guid !== quote.Quote.Quote_guid
              );
              this.quotesDataTable = this.quotesDataTable.filter(
                q => q.Quote_guid !== quote.Quote.Quote_guid
              );
              this.snkbr.open('Quote removed', 'Dismiss', {
                duration: 5000,
              });
            });
        }
      });
  }

  resetAddQuoteBotSheet(): void {
    this.addQuoteBotSheetSearchCtrl = new FormControl('');
    this.addQuoteBotSheetResults = [];
    this.addQuoteBotSheetLoading = false;
  }

  canAddToBid(): boolean {
    return this.addQuoteBotSheetResults.some(quote => quote.selected);
  }

  searchForQuoteToAdd(): void {
    this.addQuoteBotSheetLoading = true;
    if (this.addQuoteBotSheetSearchCtrl.value.trim() === '') {
      return;
    }
    this.api
      .pullReport(
        `quotes-overview?size=50&offset=0&Quote_Name=${this.addQuoteBotSheetSearchCtrl.value}&inBid=false`
      )
      .subscribe(
        (results: QuoteOverviewDtoInterface[]) => {
          this.addQuoteBotSheetLoading = false;
          // First get the quotes that are already selected
          const selectedQuotes = this.addQuoteBotSheetResults.filter(
            quote => quote.selected
          );

          // Filter out the quotes that are already in the bid
          const filteredResults = results.filter(
            result =>
              !this.bid.Quotes.some(
                quote => quote.Quote.Quote_guid === result.Quote_guid
              )
          );

          this.addQuoteBotSheetResults = [
            ...selectedQuotes,
            ...filteredResults.map(quote => ({
              quote,
              selected: false,
            })),
          ];
        },
        err => {
          this.addQuoteBotSheetLoading = false;
          console.error(err);
          this.snkbr.open('Failed to search for quotes', 'Dismiss', {
            duration: Infinity,
          });
        }
      );
  }

  addSelectedQuotesToBid(): void {
    this.addQuoteBotSheetLoading = true;
    // ToDo: Add the selected quotes to the bid
    // Make a patch request to each quote in this.addQuoteBotSheetResults with the Bid_guid of this.bidGuid
    // Then add the quotes to this.bid.Quotes and this.quotesDataTable
    // Then close the bot sheet
    const selectedQuotes = this.addQuoteBotSheetResults.filter(
      quote => quote.selected
    );
    const obs: Observable<any>[] = [];
    selectedQuotes.forEach(quote => {
      const preq = this.qe.patchQuoteReq({
        quoteGuid: quote.quote.Quote_guid,
        quoteChanges: {
          Bid_guid: this.bidGuid,
        },
      });
      // ShareReplay the observable so that it can be subscribed to multiple times
      const sob = preq.pipe(shareReplay());
      obs.push(sob);
      sob.subscribe(
        () => {
          // As soon as this is done, make a request to quote-overview/{Quote_guid} to get the QuoteOverviewDtoInterface with the Quote_TotalCost and Quote_TotalPart
          this.saving
            .loadingUntilComplete(
              this.api.pullReport(
                `quote-extended-overview/${quote.quote.Quote_guid}`
              )
            )
            .subscribe(
              (quoteOverview: QuoteOverviewWithOptionsDtoInterface) => {
                this.addQuoteBotSheetLoading = false;
                // Find the quote in this.quotesDataTable and update the Quote_TotalCost and Quote_TotalPart
                // Then add the quoteOverview to this.bid.Quotes
                this.bid.Quotes.push(quoteOverview);
                this.quotesDataTable = [
                  ...this.quotesDataTable,
                  {
                    Quote_guid: quoteOverview.Quote.Quote_guid,
                    Quote_Name: quoteOverview.Quote.Quote_Name,
                    Quote_Desc: quoteOverview.Quote.Quote_Desc,
                    Quote_TotalPart: quoteOverview.Quote.Quote_TotalPart,
                    Quote_TotalCost: quoteOverview.Quote.Quote_TotalCost,
                  },
                ];
              },
              () => {
                this.addQuoteBotSheetLoading = false;
              }
            );
        },
        () => {
          this.addQuoteBotSheetLoading = false;
        }
      );
    });
    this.saving.loadingUntilComplete(forkJoin(obs)).subscribe(
      (results: QuoteOverviewWithOptionsDtoInterface[]) => {
        this.addQuoteBotSheetLoading = false;
        this.resetAddQuoteBotSheet();
        this.addQuoteBotSheetOpen = false;
      },
      err => {
        this.addQuoteBotSheetLoading = false;
        console.error(err);
        this.snkbr.open('Failed to add quotes to bid', 'Dismiss', {
          duration: Infinity,
        });
      }
    );
  }

  inclusionLoading(inclusionGuid: string, lid?: string): boolean {
    if (lid != undefined) {
      return this.inclusionsSaving.some(
        inclusion => inclusion.guid === inclusionGuid && inclusion.lid === lid
      );
    } else {
      return this.inclusionsSaving.some(
        inclusion => inclusion.guid === inclusionGuid
      );
    }
  }

  addInclusion(): void {
    // Make a request to create a new inclusion in the bid
    this.saving
      .loadingUntilComplete(
        this.api.postRequest(`bid-inclusion/${this.bidGuid}`, {
          BidInclusion_Name: 'Untitled',
          BidInclusion_Desc: '',
        })
      )
      .subscribe(
        (inclusion: BidInclusionDtoInterface) => {
          this.bid.BidInclusions.push(inclusion);
          this.bid = {
            ...this.bid,
          };
        },
        err => {
          console.error(err);
          this.snkbr.open('Failed to add inclusion', 'Dismiss', {
            duration: Infinity,
          });
        }
      );
  }

  saveInclusion(
    changes: { name: string; desc: string },
    changeIndex: number
  ): void {
    const lid = this.saving.startLoading(); // Just puts a loading id in the loading array and returns it
    this.inclusionsSaving.push({
      guid: this.bid.BidInclusions[changeIndex].BidInclusion_guid,
      lid,
    });

    this.api
      .patchRequest(
        `bid-inclusion/${this.bid.BidInclusions[changeIndex].BidInclusion_guid}`,
        {
          BidInclusion_Name: changes.name,
          BidInclusion_Desc: changes.desc,
        }
      )
      .subscribe(
        () => {
          this.bid.BidInclusions[changeIndex].BidInclusion_Name = changes.name;
          this.bid.BidInclusions[changeIndex].BidInclusion_Desc = changes.desc;
          this.saving.stopLoading([lid]);
          this.inclusionsSaving = this.inclusionsSaving.filter(
            inclusion =>
              inclusion.guid !==
                this.bid.BidInclusions[changeIndex].BidInclusion_guid &&
              inclusion.lid !== lid
          );
        },
        err => {
          this.saving.stopLoading([lid]);
          this.inclusionsSaving = this.inclusionsSaving.filter(
            inclusion =>
              inclusion.guid !==
                this.bid.BidInclusions[changeIndex].BidInclusion_guid &&
              inclusion.lid !== lid
          );
          this.snkbr.open('Error Saving Inclusion', 'Dismiss', {
            duration: Infinity,
          });
        }
      );
  }

  deleteInclusion(toDel: { name: string; desc: string }, delIndex: number) {
    const lid = this.saving.startLoading(); // Just puts a loading id in the loading array and returns it
    this.inclusionsSaving.push({
      guid: this.bid.BidInclusions[delIndex].BidInclusion_guid,
      lid,
    });

    this.api
      .deleteRequest(
        `bid-inclusion/${this.bid?.BidInclusions[delIndex]?.BidInclusion_guid}`
      )
      .subscribe(
        () => {
          this.bid.BidInclusions = this.bid.BidInclusions.filter(
            (inc, index) => index !== delIndex
          );
          this.saving.stopLoading([lid]);
          this.inclusionsSaving = this.inclusionsSaving.filter(
            inclusion =>
              inclusion.guid !==
                this.bid.BidInclusions[delIndex].BidInclusion_guid &&
              inclusion.lid !== lid
          );
        },
        err => {
          this.saving.stopLoading([lid]);
          this.inclusionsSaving = this.inclusionsSaving.filter(
            inclusion =>
              inclusion.guid !==
                this.bid.BidInclusions[delIndex].BidInclusion_guid &&
              inclusion.lid !== lid
          );
          this.snkbr.open('Error Deleting Inclusion', 'Dismiss', {
            duration: Infinity,
          });
        }
      );
  }

  exclusionLoading(exclusionGuid: string, lid?: string): boolean {
    if (lid != undefined) {
      return this.exclusionsSaving.some(
        exclusion => exclusion.guid === exclusionGuid && exclusion.lid === lid
      );
    } else {
      return this.exclusionsSaving.some(
        exclusion => exclusion.guid === exclusionGuid
      );
    }
  }

  addExclusion(): void {
    // Make a request to create a new exclusion in the bid
    this.saving
      .loadingUntilComplete(
        this.api.postRequest(`bid-exclusion/${this.bidGuid}`, {
          BidExclusion_Name: 'Untitled',
          BidExclusion_Desc: '',
        })
      )
      .subscribe(
        (exclusion: BidExclusionDtoInterface) => {
          this.bid.BidExclusions.push(exclusion);
          this.bid = {
            ...this.bid,
          };
        },
        err => {
          console.error(err);
          this.snkbr.open('Failed to add exclusion', 'Dismiss', {
            duration: Infinity,
          });
        }
      );
  }

  saveExclusion(
    changes: { name: string; desc: string },
    changeIndex: number
  ): void {
    const lid = this.saving.startLoading(); // Just puts a loading id in the loading array and returns it
    this.exclusionsSaving.push({
      guid: this.bid.BidExclusions[changeIndex].BidExclusion_guid,
      lid,
    });

    this.api
      .patchRequest(
        `bid-exclusion/${this.bid.BidExclusions[changeIndex].BidExclusion_guid}`,
        {
          BidExclusion_Name: changes.name,
          BidExclusion_Desc: changes.desc,
        }
      )
      .subscribe(
        () => {
          this.bid.BidExclusions[changeIndex].BidExclusion_Name = changes.name;
          this.bid.BidExclusions[changeIndex].BidExclusion_Desc = changes.desc;
          this.saving.stopLoading([lid]);
          this.exclusionsSaving = this.exclusionsSaving.filter(
            exclusion =>
              exclusion.guid !==
                this.bid.BidExclusions[changeIndex].BidExclusion_guid &&
              exclusion.lid !== lid
          );
        },
        err => {
          this.saving.stopLoading([lid]);
          this.exclusionsSaving = this.exclusionsSaving.filter(
            exclusion =>
              exclusion.guid !==
                this.bid.BidExclusions[changeIndex].BidExclusion_guid &&
              exclusion.lid !== lid
          );
          this.snkbr.open('Error Saving Exclusion', 'Dismiss', {
            duration: Infinity,
          });
        }
      );
  }

  deleteExclusion(toDel: { name: string; desc: string }, delIndex: number) {
    const lid = this.saving.startLoading(); // Just puts a loading id in the loading array and returns it
    this.exclusionsSaving.push({
      guid: this.bid.BidExclusions[delIndex].BidExclusion_guid,
      lid,
    });

    this.api
      .deleteRequest(
        `bid-exclusion/${this.bid.BidExclusions[delIndex].BidExclusion_guid}`
      )
      .subscribe(
        () => {
          this.bid.BidExclusions = this.bid.BidExclusions.filter(
            (exc, index) => index !== delIndex
          );
          this.saving.stopLoading([lid]);
          this.exclusionsSaving = this.exclusionsSaving.filter(
            exclusion =>
              exclusion.guid !==
                this.bid.BidExclusions[delIndex].BidExclusion_guid &&
              exclusion.lid !== lid
          );
        },
        err => {
          this.saving.stopLoading([lid]);
          this.exclusionsSaving = this.exclusionsSaving.filter(
            exclusion =>
              exclusion.guid !==
                this.bid.BidExclusions[delIndex].BidExclusion_guid &&
              exclusion.lid !== lid
          );
          this.snkbr.open('Error Deleting Exclusion', 'Dismiss', {
            duration: Infinity,
          });
        }
      );
  }

  billingTermLoading(billingTermsGuid: string, lid?: string): boolean {
    if (lid != undefined) {
      return this.billingTermsSaving.some(
        billingTerm =>
          billingTerm.guid === billingTermsGuid && billingTerm.lid === lid
      );
    } else {
      return this.billingTermsSaving.some(
        billingTerm => billingTerm.guid === billingTermsGuid
      );
    }
  }

  addBillingTerm(): void {
    // Make a request to create a new billing term in the bid
    this.saving
      .loadingUntilComplete(
        this.api.postRequest(`bid-billing-term/${this.bidGuid}`, {
          BidBillingTerm_Name: 'Untitled',
          BidBillingTerm_Desc: '',
        })
      )
      .subscribe(
        (billingTerm: BidBillingTermDtoInterface) => {
          this.bid.BidBillingTerms.push(billingTerm);
          this.bid = {
            ...this.bid,
          };
        },
        err => {
          console.error(err);
          this.snkbr.open('Failed to add billing term', 'Dismiss', {
            duration: Infinity,
          });
        }
      );
  }

  saveBillingTerm(
    changes: { name: string; desc: string },
    changeIndex: number
  ): void {
    const lid = this.saving.startLoading(); // Just puts a loading id in the loading array and returns it
    this.billingTermsSaving.push({
      guid: this.bid.BidBillingTerms[changeIndex].BidBillingTerm_guid,
      lid,
    });

    this.api
      .patchRequest(
        `bid-billing-term/${this.bid.BidBillingTerms[changeIndex].BidBillingTerm_guid}`,
        {
          BidBillingTerm_Name: changes.name,
          BidBillingTerm_Desc: changes.desc,
        }
      )
      .subscribe(
        () => {
          this.bid.BidBillingTerms[changeIndex].BidBillingTerm_Name =
            changes.name;
          this.bid.BidBillingTerms[changeIndex].BidBillingTerm_Desc =
            changes.desc;
          this.saving.stopLoading([lid]);
          this.billingTermsSaving = this.billingTermsSaving.filter(
            billingTerm =>
              billingTerm.guid !==
                this.bid.BidBillingTerms[changeIndex].BidBillingTerm_guid &&
              billingTerm.lid !== lid
          );
        },
        err => {
          this.saving.stopLoading([lid]);
          this.billingTermsSaving = this.billingTermsSaving.filter(
            billingTerm =>
              billingTerm.guid !==
                this.bid.BidBillingTerms[changeIndex].BidBillingTerm_guid &&
              billingTerm.lid !== lid
          );
          this.snkbr.open('Error Saving Billing Term', 'Dismiss', {
            duration: Infinity,
          });
        }
      );
  }

  deleteBillingTerm(toDel: { name: string; desc: string }, delIndex: number) {
    const lid = this.saving.startLoading(); // Just puts a loading id in the loading array and returns it
    this.billingTermsSaving.push({
      guid: this.bid.BidBillingTerms[delIndex].BidBillingTerm_guid,
      lid,
    });

    this.api
      .deleteRequest(
        `bid-billing-term/${this.bid.BidBillingTerms[delIndex].BidBillingTerm_guid}`
      )
      .subscribe(
        () => {
          this.bid.BidBillingTerms = this.bid.BidBillingTerms.filter(
            (bt, index) => index !== delIndex
          );
          this.saving.stopLoading([lid]);
          this.billingTermsSaving = this.billingTermsSaving.filter(
            billingTerm =>
              billingTerm.guid !==
                this.bid.BidBillingTerms[delIndex].BidBillingTerm_guid &&
              billingTerm.lid !== lid
          );
        },
        err => {
          this.saving.stopLoading([lid]);
          this.billingTermsSaving = this.billingTermsSaving.filter(
            billingTerm =>
              billingTerm.guid !==
                this.bid.BidBillingTerms[delIndex].BidBillingTerm_guid &&
              billingTerm.lid !== lid
          );
          this.snkbr.open('Error Deleting Billing Term', 'Dismiss', {
            duration: Infinity,
          });
        }
      );
  }

  specLoading(specGuid: string, lid?: string): boolean {
    if (lid != undefined) {
      return this.specsSaving.some(
        spec => spec.guid === specGuid && spec.lid === lid
      );
    } else {
      return this.specsSaving.some(spec => spec.guid === specGuid);
    }
  }

  addSpec(): void {
    // Make a request to create a new spec in the bid
    this.saving
      .loadingUntilComplete(
        this.api.postRequest(`bid-spec/${this.bidGuid}`, {
          BidSpec_Name: 'Untitled',
          BidSpec_Desc: '',
        })
      )
      .subscribe(
        (spec: BidSpecDtoInterface) => {
          this.bid.BidSpecs.push(spec);
          this.bid = {
            ...this.bid,
          };
        },
        err => {
          console.error(err);
          this.snkbr.open('Failed to add spec', 'Dismiss', {
            duration: Infinity,
          });
        }
      );
  }

  saveSpec(changes: { name: string; desc: string }, changeIndex: number): void {
    const lid = this.saving.startLoading(); // Just puts a loading id in the loading array and returns it
    this.specsSaving.push({
      guid: this.bid.BidSpecs[changeIndex].BidSpec_guid,
      lid,
    });

    this.api
      .patchRequest(`bid-spec/${this.bid.BidSpecs[changeIndex].BidSpec_guid}`, {
        BidSpec_Name: changes.name,
        BidSpec_Desc: changes.desc,
      })
      .subscribe(
        () => {
          this.bid.BidSpecs[changeIndex].BidSpec_Name = changes.name;
          this.bid.BidSpecs[changeIndex].BidSpec_Desc = changes.desc;
          this.saving.stopLoading([lid]);
          this.specsSaving = this.specsSaving.filter(
            spec =>
              spec.guid !== this.bid.BidSpecs[changeIndex].BidSpec_guid &&
              spec.lid !== lid
          );
        },
        err => {
          this.saving.stopLoading([lid]);
          this.specsSaving = this.specsSaving.filter(
            spec =>
              spec.guid !== this.bid.BidSpecs[changeIndex].BidSpec_guid &&
              spec.lid !== lid
          );
          this.snkbr.open('Error Saving Spec', 'Dismiss', {
            duration: Infinity,
          });
        }
      );
  }

  deleteSpec(toDel: { name: string; desc: string }, delIndex: number) {
    const lid = this.saving.startLoading(); // Just puts a loading id in the loading array and returns it
    this.specsSaving.push({
      guid: this.bid.BidSpecs[delIndex].BidSpec_guid,
      lid,
    });

    this.api
      .deleteRequest(`bid-spec/${this.bid.BidSpecs[delIndex].BidSpec_guid}`)
      .subscribe(
        () => {
          this.bid.BidSpecs = this.bid.BidSpecs.filter(
            (spec, index) => index !== delIndex
          );
          this.saving.stopLoading([lid]);
          this.specsSaving = this.specsSaving.filter(
            spec =>
              spec.guid !== this.bid.BidSpecs[delIndex].BidSpec_guid &&
              spec.lid !== lid
          );
        },
        err => {
          this.saving.stopLoading([lid]);
          this.specsSaving = this.specsSaving.filter(
            spec =>
              spec.guid !== this.bid.BidSpecs[delIndex].BidSpec_guid &&
              spec.lid !== lid
          );
          this.snkbr.open('Error Deleting Spec', 'Dismiss', {
            duration: Infinity,
          });
        }
      );
  }

  generateInclusions() {
    const gilid = this.saving.startLoading();

    const partsByBuildLocation: {
      location: BuildLocationDtoInterface | null;
      parts: ContextQuotePartDtoInterface[];
    }[] = [];

    let allBuildLocations: BuildLocationDtoInterface[];
    const getBuildLocations = () => {
      this.api.pullReport('buildlocations').subscribe(
        (data: BuildLocationDtoInterface[]) => {
          allBuildLocations = data;
          getQuotes();
        },
        err => {
          console.log(err);
          this.snkbr.open('Failed to get build locations', 'Dismiss', {
            duration: Infinity,
          });
          this.saving.stopLoading([gilid]);
        }
      );
    };
    getBuildLocations();

    const getQuotes = () => {
      // First, make a request to get each quote in the bid at the same time.
      const qobs = this.bid.Quotes.map(quote =>
        this.api
          .pullReport(`quote/${quote.Quote.Quote_guid}`)
          .pipe(shareReplay())
      );

      for (const buildLocation of allBuildLocations) {
        partsByBuildLocation.push({
          location: buildLocation,
          parts: [],
        });
      }
      partsByBuildLocation.push({
        location: null,
        parts: [],
      });

      const getPartsByLocation = (
        children: ContextQuoteKitPartDtoInterface[],
        buildLocationGuid: string | null
      ) => {
        for (const qkp of children) {
          if (
            qkp.QuotePart?.QuotePartTags.some(
              qpt => qpt.Tag.Tag_Name == 'Fixture'
            )
          ) {
            const locationGuid =
              qkp.BuildLocation != null
                ? qkp.BuildLocation.BuildLocation_guid
                : buildLocationGuid;
            const locationIndex = partsByBuildLocation.findIndex(
              loc => loc.location?.BuildLocation_guid == locationGuid
            );
            if (locationIndex > -1) {
              const partAlreadyExists = partsByBuildLocation[
                locationIndex
              ].parts.some(
                part =>
                  part.QuotePart.QuotePart_Code ==
                  qkp.QuotePart.QuotePart.QuotePart_Code
              );
              if (!partAlreadyExists) {
                partsByBuildLocation[locationIndex].parts.push(qkp.QuotePart);
              }
            }
          }
          if (qkp.QuoteKit && Array.isArray(qkp?.QuoteKit?.QuoteKitParts)) {
            const locationGuid =
              qkp.BuildLocation != null
                ? qkp.BuildLocation.BuildLocation_guid
                : buildLocationGuid;
            getPartsByLocation(qkp.QuoteKit.QuoteKitParts, locationGuid);
          }
        }
      };

      // Wait for them all to complete
      forkJoin(qobs).subscribe(
        (quotes: ContextQuoteDtoInterface[]) => {
          // For each quote, get the parts and add them to the correct location in inclusionsByLocation
          quotes.forEach((quote, index) => {
            getPartsByLocation(quote.QuoteKitParts, null);
          });
          inclusionRequest();
          this.saving.stopLoading([gilid]);
        },
        err => {
          console.log(err);
          this.snkbr.open('Failed to get quotes for bid', 'Dismiss', {
            duration: Infinity,
          });
          this.saving.stopLoading([gilid]);
        }
      );
    };

    const inclusionRequest = () => {
      // Start making PostQuoteInclusionInterface[] from partsByBuildLocation where the BuildLocation_Code is the QuoteInclusion_Name and the QuotePart_Desc is the QuoteInclusion_Desc
      const inclusionsToAdd: {
        BidInclusion_Name: string;
        BidInclusion_Desc: string;
      }[] = [];
      partsByBuildLocation.forEach(location => {
        // First, check if the current BidInclusions already has a BidInclusion with the same name as the BuildLocation_Code
        const existingInclusion = this.bid.BidInclusions.find(
          inclusion =>
            inclusion.BidInclusion_Name == location.location?.BuildLocation_Code
        );

        if (!existingInclusion) {
          const description = location.parts
            .map(part => part.QuotePart.QuotePart_Desc)
            .join('\n');
          if (location.parts.length > 0) {
            if (location.location != null) {
              inclusionsToAdd.push({
                BidInclusion_Name: location.location.BuildLocation_Code,
                BidInclusion_Desc: description,
              });
            } else {
              inclusionsToAdd.push({
                BidInclusion_Name: 'N/A',
                BidInclusion_Desc: description,
              });
            }
          }
        }
      });

      // Now make the post request with the inclusionsToAdd
      this.api
        .postRequest(`bid-inclusions/${this.bidGuid}`, inclusionsToAdd)
        .subscribe(
          (inclusions: BidInclusionDtoInterface[]) => {
            this.bid.BidInclusions = [...this.bid.BidInclusions, ...inclusions];
            this.bid = {
              ...this.bid,
            };
          },
          err => {
            console.error(err);
            this.snkbr.open('Failed to generate inclusions', 'Dismiss', {
              duration: Infinity,
            });
            this.saving.stopLoading([gilid]);
          }
        );
    };
  }
}
