import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ScreenSizeService } from 'src/app/_services/ScreenSizeService/ScreenSizeService';
import { SageApiService } from 'src/app/_services/sageApi/sageApi.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import {
  PartBreakdownItemGroupInterface,
  PartBreakdownItemInterface,
  QuoteEditableService,
} from 'src/app/_services/QuoteEditableService/QuoteEditableService';

interface AuditPartsInterface {
  PartCode: string;
  Quantity: number;
  Cost: number;
  TotalCost: number;
  Phase: string;
  Kit: string;
  KitParents: string; // (string[]).join(", ")
  Desc: string;
  Tags: string; // (string[]).join(", ")
  Location: string;
}

@Component({
  selector: 'app-qpartbreakdown',
  templateUrl: './QuotePartBreakdownComponent.html',
  styleUrls: ['./QuotePartBreakdownComponent.css', '../QuotingTheme.scss'],
})
export class QuotePartBreakdownComponent implements OnInit, OnChanges {
  @Input() pdfTitle = 'BREAKDOWN: ';

  @Input('partGroups') rawPartGroups: PartBreakdownItemGroupInterface[] = [];
  partGroups: PartBreakdownItemGroupInterface[] = this.rawPartGroups;

  sortOptions = ['Name', 'Total Cost', 'Quantity', 'Part Cost'];
  sortControl = new FormControl(this.sortOptions[0]);
  sortDirectionControl = new FormControl('ASC'); // Alt value is "DESC"

  allParts: AuditPartsInterface[] = [];

  constructor(
    private screenSizeService: ScreenSizeService,
    private router: Router,
    private api: SageApiService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private qe: QuoteEditableService
  ) {}

  ngOnInit(): void {
    this.sortItems();
    this.allParts = this.getAuditParts(this.partGroups);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.partGroups = this.rawPartGroups;
    this.sortItems();
  }

  sortItems() {
    switch (this.sortControl.value) {
      case 'Name':
        if (this.sortDirectionControl.value == 'ASC') {
          // Faster to use the if statement here than in the sort function, hence the duplication
          this.rawPartGroups.sort((a, b) => a.name.localeCompare(b.name));
        } else {
          this.rawPartGroups.sort((a, b) => b.name.localeCompare(a.name));
        }
        break;
      case 'Total Cost':
        if (this.sortDirectionControl.value == 'ASC') {
          this.rawPartGroups.sort((a, b) => a.totalCost - b.totalCost);
        } else {
          this.rawPartGroups.sort((a, b) => b.totalCost - a.totalCost);
        }
        break;
      case 'Quantity':
        if (this.sortDirectionControl.value == 'ASC') {
          this.rawPartGroups.sort((a, b) => a.quantity - b.quantity);
        } else {
          this.rawPartGroups.sort((a, b) => b.quantity - a.quantity);
        }
        break;
      case 'Part Cost':
        if (this.sortDirectionControl.value == 'ASC') {
          this.rawPartGroups.sort((a, b) => a.partCost - b.partCost);
        } else {
          this.rawPartGroups.sort((a, b) => b.partCost - a.partCost);
        }
        break;
    }
  }

  trackGroup(index: number, group: PartBreakdownItemGroupInterface) {
    return group.name + group.items.length;
  }

  trackItem(index: number, item: PartBreakdownItemInterface) {
    return item.data.QuoteKitPart.QuoteKitPart_guid;
  }

  openPdf() {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    const d = {
      title: this.pdfTitle + this.qe.quote.data.Quote.Quote_Name,
      data: [
        {
          tableHeaders: [
            'Part_Code',
            'TotalQuantity',
            'TotalCost',
            'Part_Cost',
          ],
          tableData: this.partGroups.map(group => {
            return [
              group.name,
              group.quantity,
              group.totalCost.toFixed(4),
              group.partCost,
            ];
          }),
        },
      ],
    };

    this.qe
      .loadingUntilComplete(this.api.postBlob('json-obj-to-pdf-table', d))
      .subscribe(
        response => {
          const blob = new Blob([response], { type: 'application/pdf' });
          const file = window.URL.createObjectURL(blob);
          window.open(file);
        },
        e => {
          throw Error(e);
        }
      );
  }

  getAuditParts(breakdownGroups: PartBreakdownItemGroupInterface[]) {
    const flat: AuditPartsInterface[] = [];
    for (const group of breakdownGroups) {
      for (const item of group.items) {
        flat.push({
          PartCode: item.data.QuotePart.QuotePart.QuotePart_Code,
          Quantity: item.data.QuoteKitPart.QuoteKitPart_Quantity,
          Cost: item.data.QuotePart.QuotePart.QuotePart_Cost,
          TotalCost:
            item.data.QuoteKitPart.QuoteKitPart_Quantity *
            item.data.QuotePart.QuotePart.QuotePart_Cost,
          Phase: item.data.QuoteKitPart.QuoteKitPart_Phase,
          Kit: item.parents[item.parents.length - 1],
          KitParents: item.parents.join(', '),
          Desc: item.data.QuotePart.QuotePart.QuotePart_Desc,
          Tags: item.data.QuotePart.QuotePartTags.map(t => t.Tag.Tag_Name).join(
            ', '
          ),
          Location: item.data.BuildLocation.BuildLocation_Code,
        });
      }
    }
    return flat;
  }

  normalizeMoney(row: AuditPartsInterface, val: string): string {
    return `$${parseInt(val).toFixed(2)}`;
  }
}
